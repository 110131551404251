/* eslint-disable react-hooks/exhaustive-deps */
// Still needs to review explanation part
// Revamp color and scaling

import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import { selectQuizEvents } from "../../reducers/dailyMissionSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import { sumWithButton } from "../helper/calculateWidthButton";
import jsonParser from "../helper/jsonParser";
import { getCacheImage } from "../helper/parseImage";
import { getConstantStringByLanguage } from "../helper/getConstantStringByLanguage";

export const MultipleChoice = memo(function MultipleChoice({
  question,
  questions,
  explanation,
  backgroundImage,
  questionIndex,
  questionId,
  handleQuestionChange,
  submitAnswers,
  setNextScroll,
  selectedQuestion,
  isPreview,
  seenQuestions,
}) {
  const cache = getCachesQuestion("daily-mission", questionIndex);
  var questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  //check if explanation is null
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  // var dailyQuizState = JSON.parse(localStorage.getItem("dailyQuizState"));
  const cookies = new Cookies();
  const answerQuizEvent = useSelector(selectQuizEvents);

  const [selectedItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat()
      .indexOf(true);
  });

  //create a state variable to store the selected answer
  var [selected, setSelected] = useState(() => {
    if (questions[selectedQuestion].correct !== null) {
      if (question.state) {
        return question.state.selected;
      } else {
        if (selectedItem !== -1) {
          return selectedItem;
        } else {
          return -1;
        }
      }
    } else {
      return -1;
    }
  });
  //create a state variable to store the checking state
  var [checkingState, setCheckingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  var [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  //get max length of a choice
  //get max length of a choice
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  const [imgWidth, setImgWidth] = useState(() => {
    if (cache?.imgWidth) {
      return cache.imgWidth;
    } else {
      return 0;
    }
  });
  const [widthButton, setWidthButton] = useState(
    checkImage(questionChoices[0]) ? 0 : maxLength >= 11 ? 20 : 10
  );
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });

  const [isLoading, setIsLoading] = useState(
    imgWidth === 0 && checkImage(questionChoices[0]) ? true : false
  );

  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
    opacity: 1,
  };

  if (
    backgroundImage !== null &&
    backgroundImage &&
    checkImage(backgroundImage)
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      // backgroundImage: `url(${memoizedImageData[2]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "3rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125 + 2}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        width: "20rem",
        height: "auto",
        padding: "0.75rem 1.25rem",
        whiteSpace: "pre-wrap",
        outline: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    outline: "0.125rem solid",
  };

  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };

  //McqButton component
  var maxHeight = 0;
  var maxWidthButton = 0;
  const divRef = useRef(null);
  const handleSetWidthImage = (width) => {
    setImgWidth(width);
  };
  const McqButton = ({ id, index, children }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
      if (checkImage(questionChoices[0])) {
        if (buttonRef.current.clientHeight > maxHeight) {
          maxHeight = buttonRef.current.clientHeight;
        }
        if (buttonRef.current.clientWidth >= maxWidthButton) {
          maxWidthButton = buttonRef.current.clientWidth;
        }
        if (widthButton < maxWidthButton / ConstantStrings.BASE_REM) {
          setWidthButton(maxWidthButton / ConstantStrings.BASE_REM);
        }
        buttonRef.current.style.width = `${maxWidthButton / ConstantStrings.BASE_REM
          }rem`;
      }

      //set all auto-height buttons to the same height
    }, [id]);

    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {index === selected && questions[selectedQuestion].correct !== null ? (
          questions[selectedQuestion].correct === true ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <img
              src="/images/common/redX.svg"
              style={iconStyle}
              alt="loading"
            />
          )
        ) : (
          <div
            className="icon"
            style={{
              ...iconStyle,
              width: "0.75rem",
              height: "0.75rem",
            }}
          ></div>
        )}
        <button
          id={id}
          ref={buttonRef}
          className={`${index === selected
            ? questions[selectedQuestion].correct !== null
              ? questions[selectedQuestion].correct === true
                ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
              : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
            : questions[selectedQuestion].correct !== null
              ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
              : "has-text-kurio-main-color-black is-kurio-main-color-black"
            } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={index === selected ? selectedStyle : buttonStyle}
          onClick={() => handleSelect(index)}
          disabled={questions[selectedQuestion].correct !== null}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
                getCachesQuestion("daily-mission", questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div
        className="column is-narrow"
        style={{ padding: "0.5rem 0.75rem" }}
        key={index}
      >
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, set the selected state variable to the index of the answer
  const handleSelect = (index) => {
    var answer = questionSolutions.map((item) => false);
    if (selected === index) {
      setSelected(-1);
      saveAnswer(-1);
      answer = null;
    } else {
      setSelected(index);
      saveAnswer(index);
      answer[index] = true;
    }
    // var questionState = {
    //   index: questionIndex,
    //   state: {
    //     selected: index === selected ? -1 : index,
    //     checkingState: checkingState,
    //     explanationState: explanationState,
    //   },
    // };
    // localStorage.setItem(
    //   "dailyQuizState",
    //   JSON.stringify({ answer, questionState, correct: null })
    // );
  };

  const saveAnswer = (index) => {
    var questionState = {
      index: questionIndex,
      state: {
        selected: Number(index),
        isCorrect: questionSolutions[index],
      },
    };
    var answer = questionChoices.map((item) => false);
    answer[index] = true;
    if (index !== -1) {
      answer[index] = true;
    } else {
      answer = null;
    }

    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
      selected: index,
    };
    // setSelected(selected);
    var result = [...answerQuizEvent];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answerQuizEvent.length; question++) {
        if (answerQuizEvent[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answerQuizEvent.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }

    var questionsAnswered = result;

    const submit = { submit: false, questions: [newAnswer] };
    var questionAnswered = { questionIndex: questionIndex, status: true };

    if (index === -1) {
      questionAnswered.status = false;
    }

    cookies.set("quizEventState", {
      questionState,
      questionAnswered,
      questionsAnswered,
      submit,
    });
  };

  const submitAnswerDailyMission = () => {
    setNextScroll(true)
    submitAnswers();
  };

  //create a function to handle the checking of the answer
  //when the answer is checked, set the checking state variable to true
  //if the selected answer is correct, alert "Correct!", change the background color of the selected answer to green
  //if the selected answer is incorrect, alert "Incorrect!", change the background color of the selected answer to red
  // const handleCheck = () => {
  //   var correct = null;
  //   var answer = questionSolutions.map((item, index) =>
  //     index === selected ? true : false
  //   );
  //   if (selected !== -1) {
  //     if (questionSolutions[selected] === true) {
  //       // alert("Correct!");

  //       correct = true;
  //     } else {
  //       // alert("Incorrect!");
  //       correct = false;
  //     }

  //     if (questionIndex === questions.length - 1) {
  //       setFinished(true);
  //     }

  //     setLocalStorage(answer, correct, selected, true, false);
  //     submitAnswers(answer);

  //     setCheckingState(true);
  //   } else {
  //     // alert("Please select an answer!");
  //   }
  // };

  //create a function to handle the reset of the question
  //when the question is reset, set the selected state variable to -1
  //set the checking state variable to false
  const handleReset = () => {
    setSelected(-1);
    setCheckingState(false);
  };

  //create a function to handle the showing of the explanation
  // const handleShowExplanation = () => {
  //   // if (dailyQuizState) {
  //   //   dailyQuizState.questionState.state.explanationState = true;
  //   //   localStorage.setItem("dailyQuizState", JSON.stringify(dailyQuizState));
  //   // } else {
  //   //   var questionState = {
  //   //     index: questionIndex,
  //   //     state: {
  //   //       selected: selected,
  //   //       checkingState: checkingState,
  //   //       explanationState: true,
  //   //     },
  //   //   };
  //   //   localStorage.setItem("dailyQuizState", JSON.stringify({ questionState }));
  //   // }
  //   setExplanationState(true);
  // };

  const showNextQuestion = () => {
    setNextScroll(false)
    if (selectedQuestion !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    }

    // if (dailyQuizState === null || !checkingState) {
    //   setLocalStorage(null, null, selected, checkingState, explanationState);
    // }
    // // console.log(questions[questionIndex].isCorrect);
    // if (!isPreview) {
    //   dispatch(setQuestionFinished(questionIndex));
    //   if (questionIndex !== questions.length - 1) {
    //     handleCorrectAnswer(questions[questionIndex - 1]?.isCorrect);
    //     nextQuestion();
    //   } else {
    //     showEndPractice();
    //   }
    // } else {
    //   const dailyQuizState = JSON.parse(localStorage.getItem("dailyQuizState"));
    //   dispatch(setQuestionState(dailyQuizState.questionState));
    //   dispatch(setQuestionVisible(questionIndex + 1));
    // }
  };
  const McqImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
          sumWithButton("IMAGE", imgWidth, 4) >= maxWidth ? (
          <div>
            <div className="columns is-centered m-0 p-0">
              {MapChoices[0]}
              {MapChoices[1]}
            </div>
            <div className="columns is-centered m-0 p-0">
              {MapChoices[2]}
              {MapChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 5) >= maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 6) >= maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 3) >= maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered m-0 p-0">
            {MapChoices}
          </div>
        )}
      </>
    );
  };
  if (imgWidth !== 0) {
    cachesQuestion("daily-mission", questionIndex, imgWidth, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);
  //map each choice to a component
  return (
    <div ref={divRef}>
      <BackgroundImageFromUrl
        objectId={backgroundImage}
        style={backgroundStyle}
        className={`${!checkImage(backgroundImage)
          ? "has-background-kurio-main-color-light-gray"
          : ""
          }`}
      >
        {!explanationState && (
          <div
            className="question-container is-flex is-flex-direction-column has-text-kurio-main-color-black"
            style={{
              padding: "1.5rem 2rem",
              gap: "1rem",
            }}
          >
            {/* {jsonParser("$$\\begin{aligned} 12 \\times (20 - 15) &= 12 \\times 5 \\\\ &=  \\color{red}{60}\\end{aligned}$$  \\n @@<p style='color:red'>test ok</p>@@ ")} */}
            {
              //render questionTexts if its array has length greater than 0
              questionTexts !== null &&
              questionTexts !== undefined &&
              questionTexts?.length > 0 && (
                <div
                  className=" has-text-centered m-auto"
                  style={{ maxWidth: "40rem" }}
                >
                  {questionTexts}
                </div>
              )
            }
            {checkImage(questionChoices[0]) ? (
              <>
                <McqImageWrap />
              </>
            ) : (
              <>
                {questionChoices.length === 4 &&
                  sumWithButton("TEXT", widthButton, 4) >= maxWidth ? (
                  <div>
                    <div className="columns is-centered m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                    </div>
                    <div className="columns is-centered m-0">
                      {MapChoices[2]}
                      {MapChoices[3]}
                    </div>
                  </div>
                ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                  questionChoices.length === 5 ? (
                    <>
                      {sumWithButton("TEXT", widthButton, 5) > maxWidth ? (
                        <div>
                          <div className="columns is-centered m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns is-centered m-0">
                            {MapChoices[2]}
                            {MapChoices[3]}
                          </div>
                          <div className="columns is-centered m-0">
                            {MapChoices[4]}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="columns is-centered m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                          <div className="columns is-centered m-0">
                            {MapChoices[3]}
                            {MapChoices[4]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                    questionChoices.length === 6 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 6) > maxWidth ? (
                          <div>
                            <div className="columns is-centered m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoices[2]}
                              {MapChoices[3]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoices[4]}
                              {MapChoices[5]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                              {MapChoices[2]}
                            </div>
                            <div className="columns is-centered m-0">
                              {MapChoices[3]}
                              {MapChoices[4]}
                              {MapChoices[5]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : questionChoices.length === 3 ? (
                      <>
                        {sumWithButton("TEXT", widthButton, 3) > maxWidth ? (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                            </div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[2]}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="columns is-centered p-0 m-0">
                              {MapChoices[0]}
                              {MapChoices[1]}
                              {MapChoices[2]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="columns p-0 m-0 is-multiline is-centered">
                        {MapChoices}
                      </div>
                    )}
              </>
            )}
          </div>
        )}

        {/* only appears when explanationState is true */}
        {explanationState && (
          <div
            className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
            style={{
              gap: "1.5rem",
              fontSize: "1.25rem",
              padding: " 2rem 2rem 1.5rem",
            }}
          >
            {((explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0)) &&
              explanation.texts?.length >= explanation.images?.length && (
                <>
                  {explanation.texts.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="is-flex is-flex-direction-column has-text-justified has-text-kurio-main-color-black"
                        style={{
                          gap: "1.5rem",
                          width: "100%"
                        }}
                      >
                        {explanationTexts !== null &&
                          explanationTexts !== undefined &&
                          item?.length > 0 && (
                            <div key={index}>{explanationTexts[index]}</div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanationImages !== null &&
                          explanationImages !== undefined &&
                          explanationImages[index]?.length > 0 && (
                            <div
                              className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                              style={{
                                width: "100%",
                              }}
                            >
                              <ImageFromUrl objectId={explanationImages[index]} />
                            </div>
                          )}
                      </div>
                    );
                  })}
                </>
              )
            }
            {((explanationTexts !== null &&
              explanationTexts !== undefined &&
              explanationTexts?.length > 0) ||
              (explanationImages !== null &&
                explanationImages !== undefined &&
                explanationImages?.length > 0)) &&
              explanation.texts?.length < explanation.images?.length && (
                <>
                  {explanationImages.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                        style={{ gap: "1.5rem", width: "100%" }}
                      >
                        {explanationTexts !== null &&
                          explanationTexts !== undefined &&
                          explanation.texts[index]?.length > 0 && (
                            <div key={index}>{explanationTexts[index]}</div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanationImages !== null &&
                          explanationImages !== undefined &&
                          item?.length > 0 && (
                            <div
                              className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                              style={{
                                width: "100%",
                              }}
                            >
                              <ImageFromUrl objectId={explanationImages[index]} />
                            </div>
                          )}
                      </div>
                    );
                  })}
                </>
              )}
          </div>
        )}

        <div
          className={` is-flex is-flex-direction-row ${!isPreview
            ? !question.state
              ? checkingState &&
                questionSolutions[selected] === true &&
                explanation?.texts?.every((item) => item?.length <= 0) &&
                explanation?.images?.every((item) => item?.length <= 0)
                ? "is-align-items-center is-justify-content-center"
                : "is-align-items-center is-justify-content-center"
              : questionIndex < seenQuestions - 1
                ? "is-align-items-center is-justify-content-center"
                : checkingState &&
                  questionSolutions[selected] === true &&
                  explanation?.texts?.every((item) => item?.length <= 0) &&
                  explanation?.images?.every((item) => item?.length <= 0)
                  ? "is-align-items-center is-justify-content-center"
                  : "is-align-items-center is-justify-content-center"
            : "is-align-items-center is-justify-content-center"
            }`}
          style={{
            padding: "0 2rem 2rem",
            gap: "2rem",
          }}
        >
          {/* only appears when checkingState is false */}
          {questions[selectedQuestion].correct === null && (
            <PrimaryButtonQuestion
              className="button"
              disabled={selected === -1}
              onClick={submitAnswerDailyMission}
            >
              {getConstantStringByLanguage(ConstantStrings.SUBMIT)}
            </PrimaryButtonQuestion>
          )}

          {questions[selectedQuestion].correct !== null &&
            questionIndex !== questions.length - 1 && (
              <div
                className={` ${question.state &&
                  questionIndex < seenQuestions - 1 &&
                  !isPreview
                  ? "is-hidden"
                  : ""
                  }`}
              >
                <PrimaryButtonQuestion onClick={showNextQuestion}>
                  {getConstantStringByLanguage(ConstantStrings.NEXT)}
                </PrimaryButtonQuestion>
              </div>
            )}

          {questions[selectedQuestion].correct === null &&
            questionIndex !== questions.length - 1 && (
              <div
                className={` ${question.state &&
                  questionIndex < seenQuestions - 1 &&
                  !isPreview
                  ? "is-hidden"
                  : ""
                  }`}
              >
                <TertiaryButtonQuestion
                  style={{ maxWidth: true }}
                  onClick={showNextQuestion}
                  suffix={
                    "admin/frontend-images/button/button-skip-icon-daily-quiz"
                  }
                >
                  {getConstantStringByLanguage(ConstantStrings.SKIP_THIS_QUESTION)}
                </TertiaryButtonQuestion>
              </div>
            )}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
});

export default MultipleChoice;
