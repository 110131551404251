import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import NotificationUpgradeAccount from "../../components/NotificationUpgradeAccount";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { getUserInfo } from "../../config/auth";
import { createPracticeByElo } from "../../config/practiceByElo";
import ConstantStrings from "../../constants/ConstantStrings";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import { getGradeUserInfo } from "../../reducers/gradesSlice";
import { getPracticeSmartQuestion } from "../../reducers/practiceElectiveSlice";
import FilterQuestion from "./FilterQuestion";
import PracticeSmartMainWrapper from "./practice-smart/PracticeSmartMainWrapper";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const ExerciseLayout = ({ setShowLayout, listUserInfo }) => {
  const heightRef = useRef(null);
  const dispatch = useDispatch();
  const [heightDiv, setHeightDiv] = useState(36);
  const [showExercise, setShowExercise] = useState(false);
  const [practiceByEloId, setPracticeByEloId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const showLayoutExercise = () => {
    setShowLayout(true);
    setShowExercise(true);
  };
  const [showElo, setShowElo] = useState(false);

  const showLayoutPracticeSmart = async () => {
    localStorage.setItem("eloPractice", parseInt(listUserInfo?.elo));

    try {
      const { result } = await createPracticeByElo();
      setPracticeByEloId(result);

      dispatch(
        getPracticeSmartQuestion({
          id: result,
          page: 1,
          size: 50,
        })
      );
      setShowModal(true);
    } catch (error) {
      if (error.response.status === 400) {
        setShowPopupError(true);
      }
    }
  };
  const handleEndPractice = async () => {
    dispatch(getGradeUserInfo());
    localStorage.removeItem("eloPractice");
    setPracticeByEloId(null);
    setShowModal(false);
    setShowPopupError(false);
  };

  useEffect(() => {
    if (heightRef?.current && heightDiv === 36) {
      setHeightDiv(heightRef.current.clientHeight / ConstantStrings.BASE_REM);
    }
  }, [heightDiv]);

  return (
    <>
      <ScrollRefWrapper />

      {showPopupError ? (
        <NotificationUpgradeAccount
          isShowPopup={showPopupError}
          handleSetShowError={(state) => handleEndPractice(state)}
        />
      ) : (
        <></>
      )}
      {showModal ? (
        <PracticeSmartMainWrapper
          setPracticeByEloId={setPracticeByEloId}
          practiceByEloId={practiceByEloId}
          handleEndPractice={handleEndPractice}
          setShowPopupError={setShowPopupError}
        />
      ) : (
        // <DailyMission
        //   practiceQuestionListId={practiceQuestionListId}
        //   handleEndPractice={handleEndPractice}
        // />
        <></>
      )}

      {showExercise ? (
        <FilterQuestion
          setShowExercise={setShowExercise}
          setShowLayout={setShowLayout}
        />
      ) : (
        <div
          ref={heightRef}
          style={{
            // borderRadius: "2.5rem",
            // background: "rgba(250, 251, 252, 0.80)",
            height: "calc(100% - 6.5rem)",
            gap: "2rem",
            paddingBottom: "2rem",
          }}
          className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/exercise/background1.svg"
            className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            style={{
              boxShadow: "2px 4px 0px 0px #0A2A66",
              border:
                "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
              borderRadius: "2.5rem",
              width: "60rem",
              height: "25rem",
              gap: "2.5rem",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              style={{ gap: "1rem" }}
            >
              <img
                style={{
                  width: "16.69119rem",
                  height: "8.49rem",
                }}
                src={getConstantStringByLanguage(ConstantStrings.ELORIO_TEXT_URL)}
                alt=""
              />

              <div
                className="has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center"
                style={{
                  width: "12.5rem",
                  boxShadow: "2px 2px 0px 0px #0A2A66",
                  height: "5rem",
                  padding: "0.5rem 1rem",
                  gap: "1rem",
                  borderRadius: "1rem",
                  border:
                    "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                }}
              >
                <p
                  style={{
                    lineHeight: "normal",
                    textAlign: "center",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                  className="has-text-kurio-main-color-black"
                >
                  {getConstantStringByLanguage(ConstantStrings.ELORIO)}
                </p>

                <div
                  className="has-text-kurio-main-color-purple has-background-kurio-purple-light-2 is-flex is-justify-content-center is-align-items-center"
                  style={{
                    height: "3.4rem",
                    fontSize: "2rem",
                    fontWeight: 700,
                    gap: "0.625rem",
                    padding: "0.5rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {isNaN(parseInt(listUserInfo?.elo))
                    ? 0
                    : parseInt(listUserInfo?.elo)}
                </div>
              </div>
            </div>
            <div>
              <img src="/images/exercise/icon-background.png" alt="" />
            </div>

            <div
              className="is-flex is-flex-direction-column is-align-items-center"
              style={{ width: "15rem", gap: "1.5rem" }}
            >
              <p
                className="has-text-kurio-main-color-black"
                style={{
                  lineHeight: "normal",
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: 500,
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.PERSONALIZED_QUESTIONS)}
              </p>

              <PrimaryButtonPageLevel
                onClick={showLayoutPracticeSmart}
                style={{
                  border:
                    "2px solid var(--kurio-main-colors-kurio-main-white, #FAFBFC)",
                  borderRadius: "1rem",
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.PLAY_NOW)}
              </PrimaryButtonPageLevel>
            </div>
          </BackgroundImageFromUrl>

          <BackgroundImageFromUrl
            objectId="admin/frontend-images/exercise/background2.svg"
            className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            style={{
              boxShadow: "2px 4px 0px 0px #0A2A66",
              border:
                "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
              borderRadius: "2.5rem",
              width: "60rem",
              height: "16.5rem",
              gap: "2.5rem",
            }}
          >
            <img
              style={{
                width: "18.53525rem",
                height: "7.892rem",
              }}
              src={getConstantStringByLanguage(ConstantStrings.ELECTIVE_TEXT_URL)}
              alt=""
            />

            <img
              style={{
                width: "9.42169rem",
                height: "14.1875rem",
              }}
              src="/images/exercise/icon-background2.svg"
              alt=""
            />

            <div
              className="is-flex is-flex-direction-column is-align-items-center"
              style={{ width: "15.9375rem", gap: "1.5rem" }}
            >
              <p
                className="has-text-kurio-main-color-white"
                style={{
                  lineHeight: "normal",
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: 500,
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.PRACTICE_IN_SELECTED_LESSONS)}
              </p>

              <PrimaryButtonPageLevel
                onClick={showLayoutExercise}
                style={{
                  border:
                    "2px solid var(--kurio-main-colors-kurio-main-white, #FAFBFC)",
                  borderRadius: "1rem",
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.CHOOSE_EXERCISE)}
              </PrimaryButtonPageLevel>
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    listUserInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(ExerciseLayout);
