import React, { useState } from 'react'
import ConstantStrings from '../../../constants/ConstantStrings'
import './ToggleButton.style.css'

const ToggleLanguageButton = () => {
  const currentLanguage= sessionStorage.getItem("language") || ConstantStrings.VN_LANGUAGE;
  const [isChange, setIsChange] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleChangeLanguage = (language) => {
    if (isDisabled) return
    if (language === ConstantStrings.EN_LANGUAGE) {
      sessionStorage.setItem("language", ConstantStrings.VN_LANGUAGE);
    }else{
      sessionStorage.setItem("language", ConstantStrings.EN_LANGUAGE);
    }
    setIsChange(!isChange);
    setIsDisabled(true);
   setTimeout(() => {
      window.location.reload();
    }, 200);
  }
  const checkLanguage = (language) => {
    return language.toUpperCase() === currentLanguage
  }
  return (
    <div className="switch">
	    <input onChange={() => handleChangeLanguage(currentLanguage)} id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox" checked={checkLanguage(ConstantStrings.EN_LANGUAGE)}/>
	    <label htmlFor="language-toggle" />
	    <span className={`${checkLanguage(ConstantStrings.VN_LANGUAGE) ? "is-select": ""} on`}>{ConstantStrings.VN_LANGUAGE}</span>
	    <span className={`${checkLanguage(ConstantStrings.EN_LANGUAGE) ? "is-select": ""} off`}>{ConstantStrings.EN_LANGUAGE}</span>
  	</div>
  )
}

export default ToggleLanguageButton