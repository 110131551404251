import React, { useState } from "react";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../../constants/ConstantStrings";
import { getImageUrl } from "../../../templates/helper/parseImage";

const ExerciseCloseButton = ({ onClick, children }) => {
  const [defaultState, setDefaultState] = useState(true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  const audio = new Audio("/sounds/click.mp3");
  var buttonStyle = {
    width: "7.80769rem",
    height: "7.90338rem",
    border: "none",
    boxShadow: "none",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${getImageUrl(getConstantStringByLanguage(ConstantStrings.POPUP_EXERCISE_CLOSE_BUTTON_URL))})`,
  };

  const handleMouseEnter = () => {
    if (!clickedState && defaultState && !hoveredState) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !defaultState && hoveredState) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    setClickedState(!clickedState);
    setHoveredState(false);
    setDefaultState(clickedState);
    audio.play();
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className="main is-relative is-clickable"
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      type="button"
    ></div>
  );
};

export default ExerciseCloseButton;
