/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { getListUnit } from "../../config/ListLesson";
import { submitLesson } from "../../config/lesson";
import ConstantStrings from "../../constants/ConstantStrings";
import {
  countCurrentSLide,
  countTotalSlide,
  findSlideBySlideId,
} from "../../constants/CountSlide";
import ScrollRef from "../../detail-components/ScrollRef";
import {
  clearStateQuestion,
  getALesson,
  getLessonSuccess,
  setQuestionVisible,
  setSectionFinished,
  setSectionVisible,
} from "../../reducers/questionsSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import jsonParser from "../../templates/helper/jsonParser";
import EndLessonWrapper from "./EndLessonWrapper";
import LessonLeftWrapper from "./left/LessonLeftWrapper";
import LessonRightWrapper from "./right/LessonRightWrapper";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

function LessonMainWrapper({
  sections,
  unit,
  lesson,
  currentLesson,
  header,
  errorLesson,
  units,
}) {
  const dispatch = useDispatch();
  const backgroundRef = useRef([]);
  const [selectedSection, setSelectedSection] = React.useState(0);
  const [isSectionFinished, setIsSectionFinished] = React.useState(false);
  const [isClick, setIsClick] = useState(false);
  const [endLessonState, setEndLessonState] = useState(false);
  const [isSlideFinished, setIsSLideFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [nextState, setNextState] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [heightScreen, setHeightScreen] = useState(0);
  const [apiCalled, setAPICalled] = useState(false);
  const [listAnUnit, setListAnUnit] = useState(units);
  const [showBottomBar, setShowBottomBar] = useState(true);
  const [scrollTop, setScrollTop] = useState(true);

  let isLast = true;
  if (sections) {
    isLast = selectedSection === sections.length - 1;
  }
  const cookies = new Cookies();
  const token = cookies.get("signinUser");
  const lessonId = cookies.get("lessonId");
  var unitId = cookies.get("unitId");
  if (!unitId && unit?.id) {
    cookies.set("unitId", unit?.id);
    unitId = unit.id;
  }
  // const submitProgress = async () => {
  //   try {
  //     const result = submitLesson({
  //       progress: 0,
  //       lessonId: lesson._id,
  //       currentSectionId: sections[0]._id,
  //       currentSectionName: sections[0].sectionName,
  //       currentSlideId: sections[0].slides[0]._id,
  //       currentSlideName: "slide ",
  //     });
  //     dispatch(getLessonSuccess({ unitId }));
  //   } catch (error) {
  //     console.log("error", error.response.data.errorMessage);
  //   }
  // };

  const navigate = useNavigate();
  var state = JSON.parse(localStorage.getItem("lesson_end_lesson"));

  const checkTitleLesson = () => {
    if (!cookies.get("indexLesson")) {
      return listAnUnit?.lessons.findIndex(
        (obj) =>
          obj._id ===
          listAnUnit?.lessons.findIndex(
            (obj) => obj._id === cookies.get("lessonId")
          )
      ) > 9
        ? listAnUnit?.lessons.findIndex(
            (obj) => obj._id === cookies.get("lessonId")
          )
        : `0` +
            listAnUnit?.lessons.findIndex(
              (obj) => obj._id === cookies.get("lessonId")
            );
    } else {
      return cookies.get("indexLesson");
    }
  };

  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  const handleSetShowPopup = (state) => {
    setShowPopup(state);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  if (isLoading) {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }

  const handleResetSlide = async () => {
    try {
      await submitLesson({
        progress: 0,
        reset: false,
        lessonId: lesson._id,
        currentSectionId: sections[0]._id,
        currentSectionName: sections[0].sectionName,
        currentSlideId: sections[0].slides[0]._id,
        currentSlideName: sections[0].slides[0].title,
      });
      setIsSLideFinished(false);
      setIsClick(false);
      setSelectedSection(0);
      dispatch(clearStateQuestion());
      dispatch(getLessonSuccess({ unitId }));
      dispatch(getALesson({ lessonId }));
    } catch (error) {
      console.log("error", error.response.data.errorMessage);
    }
  };

  const scrollToBottom = (index) => {
    // console.log(sections[selectedSection].slides.length);
    // console.log(backgroundRef.current);
    const windowHeight = window.innerHeight;
    const bodyHeight = document.body.offsetHeight;
    const scrollToBottom = document.getElementById("scrollToBottom");

    if (windowHeight === bodyHeight) {
      scrollToBottom.style.visibility = "hidden";
    }

    if (
      sections &&
      !sections[selectedSection].slides[
        sections[selectedSection]?.slides?.length - 1
      ]?.visible
    ) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
      return;
    }

    if (
      sections &&
      sections[selectedSection].slides[
        sections[selectedSection]?.slides?.length - 1
      ]?.visible
    ) {
      const nextDivIndex = Math.min(
        Number(JSON.parse(localStorage.getItem("currentDivIndex"))) + 1,
        backgroundRef.current.length - 1
      );

      const nextDiv = backgroundRef.current[nextDivIndex];

      if (nextDivIndex <= backgroundRef.current.length - 1) {
        if (
          Number(JSON.parse(localStorage.getItem("currentDivIndex"))) ===
          backgroundRef.current.length - 1
        ) {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
          });
        } else {
          nextDiv.scrollIntoView({ behavior: "smooth" });
          localStorage.setItem("currentDivIndex", nextDivIndex); // Cập nhật chỉ số của div hiện tại
        }
      } else {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  const checkVisibleDiv = () => {
    const windowHeight = window.innerHeight;
    for (let i = 0; i < backgroundRef.current.length; i++) {
      const div = backgroundRef.current[i];
      const rect = div?.getBoundingClientRect();
      if (rect?.top >= 0) {
        localStorage.setItem("currentDivIndex", i); // Cập nhật chỉ số của div hiện tại
        break;
      }
    }
  };

  useEffect(() => {
    var scrollToBottom = document.getElementById("scrollToBottom");

    if (sections && sections[0]) {
      setTimeout(() => {
        if (document.body.offsetHeight === window.innerHeight) {
          scrollToBottom.style.visibility = "hidden";
        } else {
          scrollToBottom.style.visibility = "visible";
        }
      }, 300);
    }
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Kiểm tra xem màn hình đã được kéo xuống cuối cùng hay không
      // if (!localStorage.getItem("arrowDown")) {
      if (windowHeight + scrollTop + 50 >= bodyHeight) {
        // localStorage.setItem("arrowDown", false)
        // setShowBottomBar(false);
        scrollToBottom.style.visibility = "hidden";
      } else {
        // setShowBottomBar(true);
        scrollToBottom.style.visibility = "visible";
      }

      if (scrollTop === 0) {
        scrollToBottom.style.visibility = "visible";
      }
    };
    // };
    const lastLoginKey = "lastLoginDate";

    window.onbeforeunload = () => {
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key !== lastLoginKey&&
          key !== "accessToken") {
          localStorage.removeItem(key);
          localStorage.setItem("currentDivIndex", 0);
        }
      });
    };

    // Cleanup function để loại bỏ sự kiện lắng nghe khi component unmount

    if (state && nextState) {
      setLoadingImage(true);
    }
    if (sections) {
      setIsSectionFinished(sections[selectedSection].isFinished);
      if (!isClick) {
        if (currentLesson && currentLesson.length > 0) {
          var filterCurrentLesson = currentLesson.filter(
            (item) => item._id === lesson._id
          );
          if (filterCurrentLesson && filterCurrentLesson.length > 0) {
            var totalProgress = filterCurrentLesson[0].totalProgress;
            var currentProgress = filterCurrentLesson[0].currentProgress;
            var totalSlide = countTotalSlide(sections);
            if (
              !findSlideBySlideId(
                sections,
                filterCurrentLesson[0].currentSlideId
              )
            ) {
              handleReset("ERROR_SLIDE");
            }
            var progress = 0;
            sectionLoop: for (
              let sectionIndex = 0;
              sectionIndex < sections.length;
              sectionIndex++
            ) {
              for (
                let slideIndex = 0;
                slideIndex < sections[sectionIndex].slides.length;
                slideIndex++
              ) {
                var currentSlide = countCurrentSLide(
                  sections,
                  sectionIndex,
                  slideIndex
                );
                progress = (currentSlide / totalSlide) * 100;
                if (
                  filterCurrentLesson[0].currentSlideId !==
                    sections[0].slides[0]._id &&
                  sections[sectionIndex].slides[slideIndex]._id ===
                    filterCurrentLesson[0].currentSlideId &&
                  (progress < totalProgress || progress > totalProgress) &&
                  !apiCalled
                ) {
                  handleResetSlide();
                  setAPICalled(true);
                }
                if (progress <= totalProgress) {
                  dispatch(setSectionVisible(sectionIndex));

                  if (slideIndex > 0 && !state && !state?.nextLessonState) {
                    dispatch(
                      setQuestionVisible({
                        section: sectionIndex,
                        index: slideIndex,
                      })
                    );
                  }
                  if (
                    sections[sectionIndex].slides[slideIndex].isLast &&
                    !state &&
                    !state?.nextLessonState
                  ) {
                    dispatch(setSectionFinished(sectionIndex));
                  }
                  if (
                    progress === totalProgress &&
                    sections[sectionIndex].slides[slideIndex].isLast
                  ) {
                    if (sectionIndex + 1 <= sections.length - 1) {
                      dispatch(setSectionVisible(sectionIndex + 1));
                    }
                  }
                } else {
                  if (
                    filterCurrentLesson[0].currentSlideId ===
                    sections[sectionIndex].slides[slideIndex]._id
                  ) {
                    dispatch(setSectionVisible(sectionIndex));
                    break sectionLoop;
                  }
                }
              }
            }
          }
          if (!state && !state?.nextLessonState) {
            filterCurrentLesson.map((item, i) => {
              sections.map((section, index) => {
                if (section.visible && section._id === item.currentSectionId) {
                  if (
                    sections[index].slides.every(
                      (item) => item.visible === true
                    )
                  ) {
                    setIsSLideFinished(true);
                  }
                  setSelectedSection(index);
                }
                return true;
              });
              return true;
            });
          } else {
            setSelectedSection(0);
            setNextState(false);
          }
        }
      }
    } else {
      if (lessonId && errorLesson === null) {
        if (!apiCalled) {
          dispatch(getALesson({ lessonId }));
          if (unitId || unit?.id) {
            dispatch(
              getLessonSuccess({
                unitId: !unitId && unit?.id ? unit.id : unitId,
              })
            );
          }
          setAPICalled(true);
        }
      } else {
        navigate("/");
      }
    }
    const setHeight = () => {
      setHeightScreen(window.innerHeight / ConstantStrings.BASE_REM);
    };
    if (window.innerHeight / ConstantStrings.BASE_REM !== 0) {
      setHeightScreen(window.innerHeight / ConstantStrings.BASE_REM);
    }
    if (!window.location.pathname.includes("preview")) {
      const listUnit = async () => {
        try {
          if (unitId !== null && unitId) {
            const response = await getListUnit(unitId, token);
            setListAnUnit(response.result);
          } else {
            const response = await getListUnit(cookies.get("unitId"), token);
            setListAnUnit(response.result);
          }
        } catch (error) {
          console.log(error.message);
        }
      };
      listUnit();
    }
    window.addEventListener("resize", setHeight);
    if (heightScreen > 35) {
      handleSetShowPopup(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", checkVisibleDiv);

    return () => {
      window.removeEventListener("resize", setHeight);
      window.removeEventListener("scroll", handleScroll, false);
      window.removeEventListener("scroll", checkVisibleDiv);
    };
  }, [selectedSection, sections, currentLesson, isClick, apiCalled]);
  if (errorLesson !== null && errorLesson === 409) {
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }
  if (loadingImage) {
    if (sections !== null && sections?.length > 0) {
      const result = getAllImage(sections, "LESSON");
      if (result && result?.length > 0) {
        return (
          <>
            <LoadingImage
              images={result}
              router={"lesson"}
              handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            />
          </>
        );
      } else {
        navigate("/lesson");
        // setLoadingImage(false);
      }
    }
  }
  function findLastIndexVisible(sectionIndex) {
    var index = 0;
    sections[sectionIndex].slides.map((slideItem, slideIndex) => {
      if (slideIndex >= index && slideItem.visible) {
        index = slideIndex;
        if (
          sections[sectionIndex].slides.every((item) => item.visible === true)
        ) {
          index = 0;
        } else {
          index = slideIndex;
        }
      }
      return index;
    });
    return index;
  }

  function handleSectionChange(val) {
    const scrollToBottom = document.getElementById("scrollToBottom");

    localStorage.setItem("currentDivIndex", 0);
    scrollToBottom.style.visibility = "visible";
    backgroundRef.current = [];
    setSelectedSection(val - 1);
    // nếu section chỉ có 1 slide visible và không phải section đầu => tính số slide giảm đi giống với nextSection
    if (findLastIndexVisible(val - 1) === 0 && val - 1 !== 0) {
      submitLessonProgress(val - 1, findLastIndexVisible(val - 1), "NEXT");
    } else {
      submitLessonProgress(val - 1, findLastIndexVisible(val - 1));
    }

    setIsSLideFinished(true);
    setIsClick(true);
    setIsLoading(true);
  }

  function nextSection() {
    if (isLast) {
      // handleEndLesson();
      return;
    }

    localStorage.setItem("currentDivIndex", 0);
    setScrollTop(true);
    backgroundRef.current = [];
    setIsClick(true);
    setShowBottomBar(true);

    if (sections) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    dispatch(setSectionVisible(selectedSection + 1));
    setSelectedSection(selectedSection + 1);
    submitLessonProgress(selectedSection + 1, 0, "NEXT");
  }
  function finishSection() {
    setIsSectionFinished(true);
    setIsSLideFinished(true);
    dispatch(setSectionFinished(selectedSection));
    if (!isLast) {
      if (
        !(
          sections[selectedSection].slides[
            sections[selectedSection].slides.length - 1
          ].template.question.length > 0
        )
      ) {
        dispatch(setSectionVisible(selectedSection + 1));
        setIsClick(true);
        setSelectedSection(selectedSection + 1);
        submitLessonProgress(selectedSection + 1, 0, "FINISH");
      }
    } else {
      // submitLessonProgress(
      //   selectedSection,
      //   sections[selectedSection].slides.length - 1
      // );
      // handleEndLesson();
    }
  }

  const findCurrentLessonProgress = (currentLesson, sections) => {
    var result;
    currentLesson.map((current, index) => {
      if (current._id === lesson._id) {
        sections.map((item, i) => {
          if (item._id === current.currentSectionId) {
            result = index;
          }
          return true;
        });
      }
      return true;
    });
    return result;
  };

  const submitLessonProgress = async (section, slide, action) => {
    var progress = 0;
    var totalSlide = 0;
    var currentSlide = 0;
    var currentProgress = 0;
    let index = null;
    if (apiCalled) {
      setAPICalled(false);
    }
    if (currentLesson && currentLesson.length > 0) {
      index = findCurrentLessonProgress(currentLesson, sections);
      if (index !== null) {
        currentProgress = currentLesson[index]?.currentProgress
          ? currentLesson[index]?.currentProgress
          : 0;
      }
    }
    totalSlide = countTotalSlide(sections);
    if (action && (action === "FINISH" || action === "NEXT")) {
      if (
        sections.every((item) => item.visible === true) &&
        action === "FINISH"
      ) {
        currentSlide = countCurrentSLide(
          sections,
          section,
          sections[section].slides.length - 1
        );
      } else {
        currentSlide = countCurrentSLide(
          sections,
          section - 1,
          sections[section - 1].slides.length - 1
        );
      }
    } else if (action === "ERROR_SLIDE") {
      progress = 0;
    } else {
      currentSlide = countCurrentSLide(sections, section, slide);
    }
    progress = (currentSlide / totalSlide) * 100;

    const data = {
      progress: progress,
      lessonId: lesson._id,
      currentSectionId: sections[section]._id,
      currentSectionName: sections[section].sectionName,
      currentSlideId: sections[section].slides[slide]._id,
      currentSlideName: sections[section].slides[slide].title,
    };
    if (index !== undefined && index !== null) {
      if (currentLesson[index].isCompleted === 0 && progress === 100) {
        data.isCompleted = 1; // Set isCompleted to 1 if it was 0
      }
    } else {
      if (progress === 100) {
        data.isCompleted = 1;
      }
    }
    try {
      await submitLesson(data);

      // if (isLast) {
      //   dispatch(getLessonSuccess({ unitId }));
      // }
    } catch (error) {
      console.log("error", error.response.data.errorMessage);
    }
  };

  const handleReset = async (action) => {
    try {
      await submitLesson({
        progress: 0,
        reset: false,
        // totalProgress: 0,
        lessonId: lesson._id,
        currentSectionId: sections[0]._id,
        currentSectionName: sections[0].sectionName,
        currentSlideId: sections[0].slides[0]._id,
        currentSlideName: sections[0].slides[0].title,
      });
      setEndLessonState(action ? endLessonState : !endLessonState);
      setIsSLideFinished(false);
      setIsClick(false);
      setSelectedSection(0);
      dispatch(clearStateQuestion());
      dispatch(getLessonSuccess({ unitId }));
      dispatch(getALesson({ lessonId }));
    } catch (error) {
      console.log("error", error.response.data.errorMessage);
    }
  };

  const orderNumber = (slides, index) => {
    var order = 0;
    for (let i = 0; i <= index; i++) {
      if (
        slides[i].template.question !== null &&
        slides[i].template.question &&
        slides[i].template.question.length > 0
      ) {
        order += 1;
      }
    }
    if (order === 0) {
      order = 1;
    }
    return order;
  };

  const handleEndLesson = async () => {
    console.log(currentLesson);
    setEndLessonState(!endLessonState);
    localStorage.setItem("currentDivIndex", 0);
    setIsSLideFinished(false);
    submitLessonProgress(
      selectedSection,
      sections[selectedSection].slides.length - 1
    );
  };

  return (
    <>
      {/* {loadingImage && <LoadingImage />} */}
      {endLessonState && (
        <EndLessonWrapper
          totalProgress={
            currentLesson &&
            currentLesson.filter((item) => item._id === lesson._id)[0]
              ?.totalProgress
          }
          handleEndLesson={() => handleEndLesson()}
          handleReset={handleReset}
        />
      )}
      {showPopup && heightScreen <= 35 && (
        <div
          className="modal is-active is-align-items-flex-start"
          style={{ zIndex: 21, paddingLeft: "7.5rem" }}
        >
          <div
            className="modal-background"
            onClick={handleClosePopup}
            style={{ opacity: 0.6, zIndex: 20 }}
          ></div>
          <div
            className="is-relative has-background-kurio-main-color-light-gray"
            style={{
              padding: "2rem",
              border: "1.5px solid #0A2A66",
              borderRadius: "2.5rem",
              boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
              maxWidth: "16.5rem",
              zIndex: 21,
            }}
          >
            <div
              className="is-flex is-flex-direction-column has-text-kurio-main-color-black is-flex-gap-4 is-align-items-flex-start"
              style={{ maxWidth: "100%" }}
            >
              <div
                className="has-text-weight-bold is-uppercase has-background-kurio-gray-light-2"
                style={{
                  fontSize: "0.875rem",
                  lineHeight: "normal",
                  padding: "0.5rem 0.75rem",
                  borderRadius: "1rem",
                  maxWidth: "100%",
                }}
              >
                <p>{jsonParser(header?.unit?.name, 0, "UnitName")}</p>
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-flex-start is-uppercase has-text-weight-bold">
                <p
                  style={{
                    fontSize: "0.875rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  Bài
                </p>
                <p
                  className=""
                  style={{
                    fontSize: "2.5rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >{`${
                  !window.location.pathname.includes("preview")
                    ? Number(checkTitleLesson()) === 0
                      ? "Khởi động"
                      : checkTitleLesson()
                    : "Preview"
                }`}</p>
              </div>
              {Number(checkTitleLesson()) !== 0 && (
                <p
                  className="has-text-weight-medium"
                  style={{ fontSize: "1.125rem", lineHeight: "normal" }}
                >
                  {jsonParser(header.title, 0, "Title")}
                </p>
              )}
            </div>
            <div
              className="has-background-kurio-main-color-light-gray has-text-centered is-flex is-align-items-center"
              onClick={handleClosePopup}
              style={{
                position: "absolute",
                top: "-1rem",
                right: "-0.59375rem",
                height: "2.5rem",
                padding: "0.5rem 0.75rem",
                border: "1.5px solid #0a2a66",
                boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
                borderRadius: "1rem",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="#0A2A66"
              >
                <path
                  d="M7.91425 6.00001L12.2072 1.70701C12.5982 1.31601 12.5982 0.684006 12.2072 0.293006C11.8162 -0.0979941 11.1842 -0.0979941 10.7933 0.293006L6.50025 4.58601L2.20725 0.293006C1.81625 -0.0979941 1.18425 -0.0979941 0.79325 0.293006C0.40225 0.684006 0.40225 1.31601 0.79325 1.70701L5.08625 6.00001L0.79325 10.293C0.40225 10.684 0.40225 11.316 0.79325 11.707C0.98825 11.902 1.24425 12 1.50025 12C1.75625 12 2.01225 11.902 2.20725 11.707L6.50025 7.41401L10.7933 11.707C10.9882 11.902 11.2443 12 11.5002 12C11.7562 12 12.0122 11.902 12.2072 11.707C12.5982 11.316 12.5982 10.684 12.2072 10.293L7.91425 6.00001Z"
                  fill="#0A2A66"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {!endLessonState && !loadingImage && (
        <BackgroundImageFromUrl
          className="columns is-mobile m-0"
          objectId={"admin/frontend-images/hub/background-lesson.svg"}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundImage: "url('/images/hub/background-lesson.svg')",
            backgroundAttachment: "fixed",
          }}
        >
          {/* <div class="modal-background"></div> */}
          <div
            className="column is-one-fifth m-0 "
            style={{
              position: "fixed",
              minHeight: "100vh",
              width: "17.5rem",
              padding: "2rem 0rem",
            }}
          >
            {/* <GetALesson /> */}
            {/* <button className="is-clickable" onClick={handleReset}>
              Reset
            </button> */}
            <div
              className="has-background-kurio-main-color-white"
              style={{
                height: "calc(100vh - 4rem)",
                borderRadius: "0rem 2.5rem 2.5rem 0rem",
                border: "1.5px solid #0A2A66",
                boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
              }}
            >
              <LessonLeftWrapper
                handleSetShowPopup={(state) => handleSetShowPopup(state)}
                selected={selectedSection + 1}
                setScrollTop={setScrollTop}
                handleSectionChange={handleSectionChange}
                heightScreen={heightScreen}
              />
            </div>
          </div>
          <BackgroundImageFromUrl
            className={` column is-offset-one-fifth is-relative`}
            style={{
              // backgroundColor: "#F8F6FF",
              marginLeft: "17.5rem",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              padding: "5rem 5rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
            objectId={sections && sections[selectedSection]?.backgroundImage}
          >
            {/* <div> */}
            {sections &&
              sections[selectedSection].slides.map((item, index) => {
                if (item.visible) {
                  var order = orderNumber(
                    sections[selectedSection].slides,
                    index
                  );
                  return (
                    <>
                      {/* <ScrollRef /> */}

                      <LessonRightWrapper
                        backgroundRef={(el) => {
                          if (el !== null) {
                            backgroundRef.current[index] = el;
                          }
                        }}
                        setScrollTop={setScrollTop}
                        scrollTop={scrollTop}
                        key={index}
                        isLast={
                          (index === 0
                            ? sections[selectedSection]?.slides.length === 1
                              ? true
                              : false
                            : true) &&
                          isSectionFinished &&
                          sections[selectedSection]?.isFinished &&
                          isSlideFinished
                        }
                        data={item}
                        section={selectedSection}
                        unitId={unitId}
                        slide={index}
                        submitLesson={(section, slide) =>
                          submitLessonProgress(section, slide)
                        }
                        className={
                          isLoading ? "is-hidden is-relative" : "is-relative"
                        }
                        order={order}
                        handleEndLesson={handleEndLesson}
                        nextSection={finishSection}
                      />
                    </>
                  );
                } else {
                  return null;
                }
              })}
            {/* </div> */}

            {sections && (
              <div
                id="scrollToBottom"
                className="container-arrows"
                style={{
                  zIndex: 9999,
                  position: "fixed",
                  bottom: "-2rem",
                  // left: "2rem",
                  // width: "100%",
                  // height: '40rem',npm
                }}
              >
                <div
                  onClick={scrollToBottom}
                  style={{
                    width: "4.5rem",
                    height: "4.5rem",
                    left: `${(window.innerWidth / 16 - 17.5) / 2}vw`,
                  }}
                  className="box-arrows"
                >
                  <svg
                    className="arrows"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      className="a1"
                      fillRule=" evenodd"
                      clipRule="evenodd"
                      d="M12.0008 12.0003C11.6116 12.0003 11.2207 11.8683 10.9036 11.6025L0.617354 3.0307C-0.109538 2.42381 -0.207257 1.34376 0.397915 0.616867C1.0048 -0.11174 2.08314 -0.207745 2.81175 0.397427L12.0196 8.06923L21.2121 0.673441C21.9492 0.0802693 23.031 0.196846 23.6225 0.934025C24.2174 1.6712 24.0991 2.74954 23.3619 3.34443L13.0757 11.6214C12.7619 11.8734 12.3814 12.0003 12.0008 12.0003ZM12.0198 20.0707L21.2123 12.6732C21.9495 12.0817 23.0312 12.1983 23.6227 12.9338C24.2159 13.6727 24.0993 14.751 23.3621 15.3459L13.0759 23.6229C12.7622 23.8749 12.3816 24 12.001 24C11.6101 24 11.2209 23.868 10.9038 23.6023L0.617576 15.0304C-0.111031 14.4253 -0.20875 13.3452 0.398136 12.6166C1.00331 11.8897 2.08336 11.7903 2.81197 12.3972L12.0198 20.0707Z"
                      fill="#48A9F8"
                    />
                  </svg>

                  <p>Cuộn Xuống</p>
                </div>
              </div>
            )}

            {!isLast && isSectionFinished && !isLoading && (
              <>
                {scrollTop ? "" : <ScrollRef />}
                <PrimaryButtonPageLevel
                  onClick={nextSection}
                  style={{ height: "4rem", margin: "0 auto" }}
                >
                  {getConstantStringByLanguage(ConstantStrings.NEXT)}
                </PrimaryButtonPageLevel>
              </>
            )}
            {sections && isLast && isSectionFinished && !isLoading && (
              <>
                {scrollTop ? "" : <ScrollRef />}
                <PrimaryButtonPageLevel
                  onClick={handleEndLesson}
                  style={{ height: "4rem", margin: "0 auto" }}
                >
                  {getConstantStringByLanguage(ConstantStrings.END_LESSON)}
                </PrimaryButtonPageLevel>
              </>
            )}
          </BackgroundImageFromUrl>
        </BackgroundImageFromUrl>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
    lesson: state.questions.lesson,
    errorLesson: state.questions.error,
    currentLesson: state.questions.currentLesson,
    unit: state.questions.header?.unit,
    header: state.questions.header,
    units: state.grades.units,
  };
}

export default connect(mapStateToProps)(LessonMainWrapper);
