import React from "react";
import InputText from "../../components/input/InputText";
import { UpgradeAccountContext } from "./UpgradeAccount";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../constants/ConstantStrings";

const UserDirectTransferConfirm = () => {
  const { directTransferConfirm } = React.useContext(UpgradeAccountContext);
  const handleCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    alert("Copied to clipboard");
  };
  return (
    <div className="is-flex is-flex-direction-row" style={{ gap: "4rem" }}>
      <div style={{ width: "13.875rem" }}>
        <div
          className="is-flex has-text-kurio-main-color-black is-italic has-text-weight-bold is-flex-direction-column"
          style={{
            width: "13.3125rem",
            display: "inline-block",
            gap: "1.5rem",
          }}
        >
          <p style={{ fontSize: "0.875rem", lineHeight: "normal" }}>{getConstantStringByLanguage(ConstantStrings.NOTE)}:</p>
          <p style={{ lineHeight: "normal", fontSize: "1.125rem" }}>
            {getConstantStringByLanguage(ConstantStrings.PLEASE_TRANSFER_AS_INSTRUCTED)}
          </p>
          <p
            style={{
              fontStyle: "normal",
              fontSize: "0.875rem",
              lineHeight: "150%",
              fontWeight: 500,
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.AFTER_TRANSFER_CONTACT_SUPPORT)}
          </p>
          <div
            className="is-flex is-flex-direction-row is-align-items-center"
            style={{ gap: "1.5rem" }}
          >
            <img src="/images/upgrades/image-zalo-vnpay.svg" alt="" />
            <p
              style={{
                fontStyle: "normal",
                fontSize: "0.875rem",
                lineHeight: "normal",
                fontWeight: 500,
              }}
            >
              {getConstantStringByLanguage(ConstantStrings.OR_ZALO)} <br />{" "}
              <b
                style={{
                  fontSize: "1.125rem",
                  width: "7.5rem",
                  display: "inline-block",
                }}
              >
                0971 500 120
              </b>
            </p>
          </div>
        </div>
      </div>
      <div
        className="is-flex is-flex-direction-column select-service pt-1"
        style={{
          gap: "1rem",
          width: "39.875rem",
        }}
      >
        <div
          className="is-flex is-flex-direction-row is-align-items-flex-start"
          style={{ gap: "1.5rem", color: "#1A2B49" }}
        >
          <div
            className="is-flex is-flex-direction-column is-size-7"
            style={{ gap: "2.6rem" }}
          >
            <p>{getConstantStringByLanguage(ConstantStrings.ACCOUNT_OWNER)}</p>
            <p>{getConstantStringByLanguage(ConstantStrings.BANK)}</p>
          </div>
          <div
            className="is-flex is-flex-direction-column has-text-weight-bold"
            style={{
              width: "18.75rem",
              gap: "1rem",
              fontSize: "1.125rem",
            }}
          >
            <p
              className="is-uppercase"
              style={{ lineHeight: "normal", marginTop: "-0.2rem" }}
            >
              CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
            </p>
            <p style={{ lineHeight: "normal" }}>
              Ngân hàng Techcombank (TCB) Chi nhánh Ba Đình
            </p>
          </div>
        </div>
        <div
          className="is-flex is-flex-direction-row is-align-items-center"
          style={{ gap: "0.875rem" }}
        >
          <InputText
            readOnly={true}
            value={directTransferConfirm?.bankAccount}
            name="bankAccount"
            label={getConstantStringByLanguage(ConstantStrings.BANK_ACCOUNT_NUMBER)}
          />
          <span
            onClick={() => {
              handleCopyToClipboard(directTransferConfirm?.bankAccount);
            }}
            className="is-clickable is-underlined has-text-kurio-main-color-black F "
            style={{
              fontSize: "0.875rem",
              width: "6rem",
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.COPY)}
          </span>
        </div>
        <div
          className="is-flex is-flex-direction-row is-align-items-center"
          style={{ gap: "0.875rem" }}
        >
          <InputText
            readOnly={true}
            value={directTransferConfirm?.message || ""}
            name="message"
            label={getConstantStringByLanguage(ConstantStrings.TRANSFER_CONTENT)}
          />
          <span
            onClick={() => {
              handleCopyToClipboard(directTransferConfirm?.message);
            }}
            className="is-clickable is-underlined has-text-kurio-main-color-black"
            style={{
              fontSize: "0.875rem",
              width: "6rem",
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.COPY)}
          </span>
        </div>
        <div
          className="is-flex is-flex-direction-row is-align-items-center"
          style={{ gap: "0.875rem" }}
        >
          <InputText
            className="is-uppercase"
            readOnly={true}
            value={
              directTransferConfirm?.transferAmount?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
              }) || ""
            }
            name="transferAmount"
            label={getConstantStringByLanguage(ConstantStrings.TRANSFER_AMOUNT)}
          />
          <span
            onClick={() => {
              handleCopyToClipboard(directTransferConfirm?.transferAmount);
            }}
            className="is-clickable is-underlined has-text-kurio-main-color-black "
            style={{
              width: "6rem",
              fontSize: "0.875rem",
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.COPY)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserDirectTransferConfirm;
