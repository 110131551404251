import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import InputText from "../../components/input/InputText";
import { getPremiumAccountRenewal } from "../../config/voucher";
import ConstantStrings from "../../constants/ConstantStrings";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const Voucher = () => {
  const [voucher, setVoucher] = useState("");
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const handleChange = (value) => {
    setError("");
    setVoucher(value);
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!isSuccess) {
      if (voucher?.length === 0) {
        setError(getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK));
      } else {
        try {
          const result = await getPremiumAccountRenewal(voucher);
          if (result !== undefined) {
            setIsSuccess(true);
          } else{
            navigate("/error")
          }
        } catch (error) {
          setError(error?.response?.data?.errorMessage)
        }
      }
    } else {
      navigate("/");
    }
  };
  return (
    <div
      className="is-flex is-justify-content-center"
      style={{ height: "calc(100% - 6.5rem)" }}
    >
      <div
        className="has-background-kurio-main-color-white is-flex"
        style={{
          marginTop: "4.75rem",
          width: "50rem",
          height: "25rem",
          border: "1.5px solid #0A2A66",
          boxShadow: "2px 4px 0px 0px #0A2A66",
          borderRadius: "2rem",
        }}
      >
        <img
          style={{ borderRadius: "2rem 0rem 0rem 2rem" }}
          src={
            isSuccess
              ? "/images/voucher/voucher-left-success.svg"
              : "/images/voucher/voucher-left.svg"
          }
          alt=""
        />
        <div
          className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center has-text-kurio-main-color-black"
          style={{ width: "27.5rem", height: "25rem" }}
        >
          <p
            className=" has-text-weight-bold has-text-centered"
            style={{
              fontSize: "2.25rem",
              lineHeight: "normal",
              width: "16.8125rem",
            }}
          >
            {isSuccess ? getConstantStringByLanguage(ConstantStrings.RECEIVED_VOUCHER) : getConstantStringByLanguage(ConstantStrings.VOUCHER_CODE)}
          </p>
          <div>
            {isSuccess ? (
              <p
                className="has-text-centered has-text-kurio-main-color-black"
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "normal",
                  width: "20rem",
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.EXPLORE_LESSON)}
              </p>
            ) : (
              <form onSubmit={handleSubmit}>
                <input hidden type="submit" />
                <div style={{ width: "20rem" }}>
                  <InputText
                    name={"voucher"}
                    onChange={(e) => handleChange(e.target.value)}
                    value={voucher}
                    label={getConstantStringByLanguage(ConstantStrings.INPUT_VOUCHER_CODE)}
                  />
                </div>
              </form>
            )}
          </div>
          <PrimaryButtonPageLevel onClick={handleSubmit}>
            {isSuccess ? getConstantStringByLanguage(ConstantStrings.BACK_TO_HOME) : getConstantStringByLanguage(ConstantStrings.CONFIRM)}
          </PrimaryButtonPageLevel>
          {error && error?.length > 0 && (
            <p
              className="has-text-kurio-red-main-accent"
              style={{ fontSize: "1rem", lineHeight: "normal" }}
            >
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Voucher;
