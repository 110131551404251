/* eslint-disable react-hooks/exhaustive-deps */
// a React template for Gapfill type question

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import VerticalGapFill from "../../components/question/VerticalGapFill";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  setQuestionFinished,
  setQuestionState,
  setQuestionVisible,
} from "../../reducers/practiceElectiveSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";
import { getConstantStringByLanguage } from "../helper/getConstantStringByLanguage";

function VerticalGapfill({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  isLast,
  nextQuestion,
  showEndPractice,
  submitAnswers,
  isPreview,
  seenQuestions,
}) {
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  const dispatch = useDispatch();
  const [checkInput, setCheckInput] = useState(false);
  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      return [];
    }
  });
  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (question.state) {
      return question.state.correctAnswers;
    } else {
      return [];
    }
  });
  const [gapFillWithImages, setGapFillWithImages] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [explanationState, setExplanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });
  const [checkingState, setCheckingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });
  const [seenAnswer, setSeenAnswer] = useState(() => {
    if (question.state) {
      return question.state.seenAnswer;
    } else {
      return false;
    }
  });
  const [maxWidth, setMaxWidth] = useState(60);
  const [changeInput, setChangeInput] = useState(false);

  const divRef = useRef(null);

  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    height: "35rem",
    width: "50%",
  };

  var backgroundLeftStyle = {};
  if (
    backgroundImage[0] !== null &&
    backgroundImage[0] &&
    checkImage(backgroundImage[0])
  ) {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
      // backgroundImage: `url(${memoizedImageData[1][0]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
    };
  }
  if (
    backgroundImage[1] !== null &&
    backgroundImage[1] &&
    checkImage(backgroundImage[1])
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      // backgroundImage: `url(${memoizedImageData[1][1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
    };
  }
  var exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
  const setLocalStorage = (
    answer,
    correct,
    answers,
    correctAnswers,
    checkingState,
    explanationState,
    seenAnswer
  ) => {
    localStorage.setItem(
      "exerciseState",
      JSON.stringify({
        correct,
        answer,
        questionState: {
          index: questionIndex,
          state: {
            answers: answers,
            correctAnswers: correctAnswers,
            checkingState: checkingState,
            explanationState: explanationState,
            seenAnswer: seenAnswer,
          },
        },
      })
    );
  };

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  const handleChange = (index) => (e) => {
    e.preventDefault();
    var answer = questionSolutions.map((item) => false);
    let newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
    setChangeInput(true);
    if (newAnswers.length > 0) {
      newAnswers.map((item, index) => (answer[index] = item));
    } else {
      answer = null;
    }
    setLocalStorage(
      answer,
      null,
      newAnswers,
      correctAnswers,
      checkingState,
      explanationState,
      seenAnswer
    );
  };

  const handleCheck = (e) => {
    e.preventDefault();
    //alert please fill in all gaps if not all gaps are filled
    if (
      answers.length < questionSolutions.length ||
      answers.some((item) => item === undefined || item === "")
    ) {
      setCheckInput(true);
      return;
    }
    var answer = questionSolutions.map((item) => false);
    answer = answers.map(
      (item, index) =>
        (answer[index] = item !== null && item.length > 0 ? item : false)
    );
    setCheckingState(true);
    //check answer to each gap and set correctAnswers
    let newCorrectAnswers = [];
    for (let i = 0; i < questionSolutions.length; i++) {
      if (answers[i] === questionSolutions[i]) {
        newCorrectAnswers[i] = true;
      } else {
        newCorrectAnswers[i] = false;
      }
    }
    setCorrectAnswers(newCorrectAnswers);
    //check if all answers are correct
    let allCorrect = true;
    for (let i = 0; i < newCorrectAnswers.length; i++) {
      if (newCorrectAnswers[i] === false) {
        allCorrect = false;
      }
    }
    setLocalStorage(
      answer,
      allCorrect,
      answers,
      newCorrectAnswers,
      true,
      explanationState,
      seenAnswer
    );
    setCorrect(allCorrect);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setAnswers([]);
    setCorrect(false);
    setLocalStorage(null, null, [], [], false, false, seenAnswer);
    setExplanationState(false);
    setCheckingState(false);
    setCheckInput(false);
    setCorrectAnswers([]);
    setChangeInput(false);
  };

  const handleShowExplanation = (e) => {
    e.preventDefault();
    setExplanationState(true);
    if (exerciseState) {
      exerciseState.questionState.state.explanationState = true;
      exerciseState.questionState.state.seenAnswer = true;
      localStorage.setItem("exerciseState", JSON.stringify(exerciseState));
    } else {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
          correctAnswers: correctAnswers,
          checkingState: checkingState,
          explanationState: true,
          seenAnswer: true,
        },
      };
      localStorage.setItem("exerciseState", JSON.stringify({ questionState }));
    }
    setSeenAnswer(true);
  };

  const showNextQuestion = () => {
    if (exerciseState === null || !checkingState) {
      setLocalStorage(
        null,
        null,
        answers,
        correctAnswers,
        checkingState,
        explanationState,
        seenAnswer
      );
    }
    if (!isPreview) {
      dispatch(setQuestionFinished(questionIndex));
      if (!isLast) {
        nextQuestion();
      } else {
        showEndPractice();
      }
    } else {
      const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
      dispatch(setQuestionState(exerciseState.questionState));
      dispatch(setQuestionVisible(questionIndex + 1));
    }
  };

  return (
    <div style={{ height: `35rem` }}>
      <div className="is-flex is-flex-direction-row">
        <div style={{ width: "50%" }} ref={divRef}>
          <BackgroundImageFromUrl
            objectId={backgroundImage[0]}
            style={backgroundLeftStyle}
            className={`${
              !checkImage(backgroundImage[0])
                ? "has-background-kurio-main-color-light-gray"
                : ""
            }`}
          ></BackgroundImageFromUrl>
        </div>
        <BackgroundImageFromUrl
          objectId={backgroundImage[1]}
          style={{
            ...backgroundStyle,
            padding: "2rem",
            width: "50%",
            position: "relative",
          }}
          className={`${
            !checkImage(backgroundImage[1])
              ? "has-background-kurio-main-color-light-gray"
              : ""
          }  is-flex is-flex-direction-column is-justify-content-space-between has-text-left`}
        >
          <div>
            {!explanationState && (
              <>
                {questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div className="mb-4 has-text-kurio-main-color-black">
                      {questionTexts}
                    </div>
                  )}
              </>
            )}

            {/* if gapFillWithImages is false
                if questionChoices has length 4, render the textinput in a 2x2 grid
                else use columns is-multiline is-centered
            */}
            {!explanationState && !gapFillWithImages && (
              <div
                className="question-container"
                style={{
                  padding: "1.5rem 2rem",
                }}
              >
                <div
                  className="columns is-multiline is-centered m-0"
                  style={{ gap: "1rem", padding: "0.5rem" }}
                >
                  {questionChoices.map((choice, index) => {
                    return (
                      <VerticalGapFill
                        key={index}
                        index={index}
                        onChange={handleChange(index)}
                        answer={answers[index] || ""}
                        correctAnswers={correctAnswers[index]}
                        checkingState={checkingState}
                        choice={questionChoices[index]}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {!explanationState && gapFillWithImages && (
              <div>
                <div
                  className="columns m-0 is-multiline is-flex-direction-column is-centered"
                  style={{ gap: "1rem", padding: "0.5rem" }}
                >
                  {questionChoices.map((choice, index) => {
                    return (
                      <VerticalGapFill
                        key={index}
                        index={index}
                        onChange={handleChange(index)}
                        answer={answers[index] || ""}
                        correctAnswers={correctAnswers[index]}
                        checkingState={checkingState}
                        choice={questionChoices[index]}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {explanationState && (
              <div
                className="explanation-container main-content-multiple-vertical has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                style={{
                  overflow: "hidden",
                  height: "22rem",
                  overflowY: "scroll",
                  gap: "1.5rem",
                }}
              >
                {((explanationTexts !== null &&
                  explanationTexts !== undefined &&
                  explanationTexts?.length > 0) ||
                  (explanationImages !== null &&
                    explanationImages !== undefined &&
                    explanationImages?.length > 0)) &&
                  explanation.texts?.length >= explanation.images?.length && (
                    <>
                      {explanation.texts.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="is-flex is-flex-direction-column is-align-items-center"
                            style={{ gap: "1.5rem" }}
                          >
                            {explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              item?.length > 0 && (
                                <div key={index}>{explanationTexts[index]}</div>
                              )}
                            {/* only appears when explanationImages has length greater than 0 */}
                            {explanationImages !== null &&
                              explanationImages !== undefined &&
                              explanationImages[index]?.length > 0 && (
                                <ImageFromUrl
                                  objectId={explanationImages[index]}
                                />
                              )}
                          </div>
                        );
                      })}
                    </>
                  )}
                {((explanationTexts !== null &&
                  explanationTexts !== undefined &&
                  explanationTexts?.length > 0) ||
                  (explanationImages !== null &&
                    explanationImages !== undefined &&
                    explanationImages?.length > 0)) &&
                  explanation.texts?.length < explanation.images?.length && (
                    <>
                      {explanationImages.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="is-flex is-flex-direction-column is-align-items-center"
                            style={{ gap: "1.5rem" }}
                          >
                            {explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              explanation.texts[index]?.length > 0 && (
                                <div key={index}>{explanationTexts[index]}</div>
                              )}
                            {/* only appears when explanationImages has length greater than 0 */}
                            {explanationImages !== null &&
                              explanationImages !== undefined &&
                              item?.length > 0 && (
                                <ImageFromUrl
                                  objectId={explanationImages[index]}
                                />
                              )}
                          </div>
                        );
                      })}
                    </>
                  )}

                {/* only appears when explanationImages has length greater than 0 */}
              </div>
            )}
          </div>

          <div
            className={` is-flex is-flex-direction-row ${
              (checkingState && !correct) || !checkingState
                ? "is-align-items-flex-end is-justify-content-space-between"
                : "is-align-items-flex-end"
            } `}
            style={{
              height: "9rem",
            }}
          >
            {/* only appears when checkingState is false */}
            {!checkingState && !seenAnswer && (
              <>
                <PrimaryButtonQuestion
                  className="button"
                  disabled={!changeInput}
                  onClick={handleCheck}
                >
                  {getConstantStringByLanguage(ConstantStrings.SUBMIT)}
                </PrimaryButtonQuestion>
                {checkInput && (
                  <p
                    style={{
                      color: "red",
                      display: "inline-block",
                      fontSize: "1.1rem",
                      lineHeight: "2.5rem",
                    }}
                  >
                    {ConstantStrings.REQUEST_FILL_IN_ALL_GAPS}
                  </p>
                )}
              </>
            )}

            {/* only appears when checkingState is true and the answers are incorrect */}
            <div>
              {/* Next and "Xem giai thich" button appears when checkingState is true */}
              <div
                className="is-flex is-flex-direction-column"
                style={{ gap: "1rem" }}
              >
                {checkingState && explanationState === false && (
                  <span>
                    {explanation !== null &&
                      explanation !== undefined &&
                      ((explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages.length > 0) ||
                        (explanationTexts !== null &&
                          explanationTexts !== undefined &&
                          explanationTexts.length > 0)) && (
                        <SecondaryButtonQuestion
                          className="button"
                          defaultColor={"is-kurio-purple-light-2"}
                          onClick={handleShowExplanation}
                        >
                          {getConstantStringByLanguage(ConstantStrings.SHOW_EXPLANATION)}
                        </SecondaryButtonQuestion>
                      )}
                  </span>
                )}
                {checkingState &&
                  answers.toString() !== questionSolutions.toString() &&
                  !seenAnswer && (
                    <PrimaryButtonQuestion
                      className="button"
                      onClick={handleReset}
                    >
                      {getConstantStringByLanguage(ConstantStrings.TRY_AGAIN)}
                    </PrimaryButtonQuestion>
                  )}

                {/* "Xem cau hoi" button appears when explanationState is true */}
                {explanationState && (
                  <SecondaryButtonQuestion
                    className="button"
                    defaultColor={"is-kurio-purple-light-2"}
                    onClick={() => {
                      setLocalStorage(
                        null,
                        null,
                        answers,
                        correctAnswers,
                        checkingState,
                        false,
                        seenAnswer
                      );
                      setExplanationState(false);
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.SHOW_QUESTION)}
                  </SecondaryButtonQuestion>
                )}
                {(seenAnswer ||
                  (checkingState &&
                    answers.toString() === questionSolutions.toString())) && (
                  <div
                    className={` ${
                      question.state &&
                      questionIndex < seenQuestions -1&&
                      !isPreview
                        ? "is-hidden"
                        : ""
                    }`}
                  >
                    <PrimaryButtonQuestion onClick={showNextQuestion}>
                      {getConstantStringByLanguage(ConstantStrings.NEXT)}
                    </PrimaryButtonQuestion>
                  </div>
                )}
              </div>
            </div>

            {((checkingState && !correct) || !checkingState) && (
              <div
                className={` ${
                  question.state && questionIndex < seenQuestions -1&& !isPreview
                    ? "is-hidden"
                    : ""
                }`}
                style={{
                  float: "right",
                }}
              >
                <TertiaryButtonQuestion
                  onClick={showNextQuestion}
                  suffix={"admin/frontend-images/button/button-skip-icon"}
                >
                  {getConstantStringByLanguage(ConstantStrings.SKIP)}
                </TertiaryButtonQuestion>
              </div>
            )}
          </div>
        </BackgroundImageFromUrl>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(VerticalGapfill);
