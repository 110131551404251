/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PaginationButtonSmall from "../../components/button/pagination-button/PaginationButtonSmall";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import {
  createPaymentRecord,
  getPaymentMethod,
  getSubcriptionPackge,
} from "../../config/payment";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import convertTimestamp from "../../templates/helper/convertTimestamp";
import PaymentMethod from "./PaymentMethod";
import ResultPayment from "./ResultPayment";
import SelectService from "./SelectService";
import UserDirectTransferConfirm from "./UserDirectTransferConfirm";
import {
  checkPremium,
  SCREEN_UPGRADE,
} from "../../templates/helper/CheckAndGetAnonymousUserId";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../constants/ConstantStrings";

export const UpgradeAccountContext = React.createContext();

const UpgradeAccount = ({ userInfo }) => {
  const [methods, setMethods] = useState([]);
  const [selectServiceState, setSelectServiceState] = useState(true);
  const [services, setServices] = useState([]);
  const [selectMethodsState, setSelectMethodsState] = useState(false);
  const [vnPayState, setVnPayState] = useState(false);
  const [directTransferState, setDirectTransferState] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [SubscriptionPackageId, setSubscriptionPackageId] = useState("");
  const [PaymentMethodId, setPaymentMethodId] = useState("");
  const [nameSelectedService, setNameSelectedService] = useState(getConstantStringByLanguage(ConstantStrings.SELECT_PACKAGE));
  const [nameSelectedPaymentMethod, setnameSelectedPaymentMethod] =
    useState(getConstantStringByLanguage(ConstantStrings.METHOD));
  const [directTransferConfirm, setDirectTransferConfirm] = useState({
    paymentRecordId: "",
    bankAccount: "19036816201018",
    transferAmount: "",
    message: `NC ${
      userInfo !== null && userInfo
        ? userInfo.phone + " " + userInfo.fullName
        : ""
    }`,
  });
  const [error, setError] = useState(null);
  const [resultState, setResultState] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [paymentRecord, setPaymentRecord] = useState("");
  const [showErrorPayment, setshowErrorPayment] = useState(false);

  const handleChangeShowPhone = (state) => {
    setShowPhone(state);
  };
  const handleClosePopup = () => {
    setShowPhone(false);
  };
  const handleNextStep = async (_id) => {
    if (selectServiceState) {
      setSelectServiceState(false);
      setSelectMethodsState(true);
    } else if (selectMethodsState) {
      const studentType = userInfo?.studentType?.type;
      if (checkPremium({ userInfo: userInfo, screen: SCREEN_UPGRADE })) {
        setshowErrorPayment(true);
      } else {
        try {
          const { result } = await createPaymentRecord({
            SubscriptionPackageId,
            PaymentMethodId: _id,
          });
          setShowTermsPopup(true);
          setPaymentRecord(result);
        } catch (error) {
          setshowErrorPayment(true);
          console.log("error", error);
        }
      }
    } else if (confirmState) {
      // var arrayKeys = Object.keys(directTransferConfirm);
      // var arrayValues = Object.values(directTransferConfirm);
      // if (arrayValues.some((value) => value === "")) {
      //   var objError = {};
      //   for (let index = 0; index < arrayValues.length; index++) {
      //     if (arrayValues[index] === "") {
      //       objError = {
      //         ...objError,
      //         [arrayKeys[index]]: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
      //       };
      //     }
      //   }
      //   setError(objError);
      // } else {
      //   console.log("direct", directTransferConfirm)
      // }
      // try {
      //   const { result } = await createPaymentRecord({
      //     ...directTransferConfirm,
      //     SubscriptionPackageId,
      //     PaymentMethodId,
      //   });
      //   setError(null);
      //   setConfirmState(false);
      setResultState(true);
      // } catch (error) {
      //   alert("CO loi xay ra");
      //   console.log("error", error);
      // }
    } else {
      if (selectServiceState) {
        alert("Phai chon goi dich vu");
      }
      if (selectMethodsState) {
        alert("Phai chon phuong thuc thanh toan");
      }
    }
  };

  const handleSelectStep = (step) => {
    if (step === 1 && (selectMethodsState || confirmState)) {
      setSelectServiceState(true);
      setSelectMethodsState(false);
      setConfirmState(false);
      setPaymentMethodId("");
      setSubscriptionPackageId("");
      setDirectTransferConfirm({
        ...directTransferConfirm,
        transferAmount: "",
        paymentRecordId: "",
      });
      setError(null);
      setResultState(false);
    } else if (step === 2 && confirmState) {
      setSelectServiceState(false);
      setSelectMethodsState(true);
      setConfirmState(false);
      setResultState(false);
      setPaymentMethodId("");
      // setDirectTransferConfirm(null);
      setError(null);
    } else if (step === 3 && selectMethodsState && confirmState) {
      setSelectServiceState(false);
      setSelectMethodsState(false);
      setConfirmState(true);
    }
  };

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const { result } = await getPaymentMethod();
        setMethods(result.reverse());
      } catch (error) {
        // alert("Co loi xay ra ");
        console.log("error", error);
      }
    };
    const getServices = async () => {
      try {
        const { result } = await getSubcriptionPackge();
        // đổi vị trí gói 1 năm và 6 tháng
        // if (!userInfo?.isCombo) {
        // const filter = result.filter((item) => !item.name.includes("1 năm"));
        // filter.splice(1, 0, result[2]);
        setServices(result);
        // } else {
        //   const filter = result.filter(
        //     (item, index) => index === 0 || index === 3
        //   );
        //   setServices(filter);
        // }
      } catch (error) {
        if (error?.response?.status === 409) {
          setShowError(true);
        } else {
          // alert("Co loi xay ra ");
          console.log("error", error);
        }
      }
    };
    if (userInfo !== null && directTransferConfirm?.message === "NC ") {
      setDirectTransferConfirm({
        ...directTransferConfirm,
        message: `NC ${
          userInfo !== null && userInfo
            ? userInfo.phone + " " + userInfo.fullName
            : ""
        }`,
      });
    }
    if (userInfo !== null) {
      if (selectServiceState) {
        getServices();
      } else if (selectMethodsState) {
        getPaymentMethods();
      }
    }
  }, [
    directTransferConfirm,
    directTransferConfirm?.message,
    selectMethodsState,
    selectServiceState,
    userInfo,
  ]);
  return (
    <div
      className=" is-flex is-relative is-align-items-center is-align-content-center is-justify-content-center"
      style={{
        minHeight: "100vh",
        // padding: "3rem 4.5rem 3rem 4.5rem",
        // position: "absolute",
      }}
    >
      <ScrollRefWrapper />
      {/* {showPhone && resultState && (
        <div className="modal is-active " style={{ zIndex: 21 }}>
          <div
            className="modal-background"
            onClick={handleClosePopup}
            style={{ opacity: 0.6, zIndex: 20 }}
          ></div>
          <div
            className="is-relative has-background-kurio-main-color-light-gray"
            style={{
              padding: "2rem",
              border: "1.5px solid #0A2A66",
              borderRadius: "2.5rem",
              boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
              maxWidth: "16.5rem",
              zIndex: 21,
            }}
          >
            <div
              className="is-flex is-flex-direction-column has-text-kurio-main-color-black is-flex-gap-4 is-align-items-flex-start"
              style={{ maxWidth: "100%" }}
            >
              <p
                className="has-text-centered"
                style={{
                  fontSize: "0.875rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                Xin hãy liên lạc với bộ phận chăm sóc khách hàng qua Zalo hoặc
                qua số hotline <b>0971 500 120</b>
              </p>
            </div>
            <div
              className="has-background-kurio-main-color-light-gray has-text-centered is-flex is-align-items-center"
              onClick={handleClosePopup}
              style={{
                position: "absolute",
                top: "-1rem",
                right: "-0.59375rem",
                height: "2.5rem",
                padding: "0.5rem 0.75rem",
                border: "1.5px solid #0a2a66",
                boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
                borderRadius: "1rem",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="#0A2A66"
              >
                <path
                  d="M7.91425 6.00001L12.2072 1.70701C12.5982 1.31601 12.5982 0.684006 12.2072 0.293006C11.8162 -0.0979941 11.1842 -0.0979941 10.7933 0.293006L6.50025 4.58601L2.20725 0.293006C1.81625 -0.0979941 1.18425 -0.0979941 0.79325 0.293006C0.40225 0.684006 0.40225 1.31601 0.79325 1.70701L5.08625 6.00001L0.79325 10.293C0.40225 10.684 0.40225 11.316 0.79325 11.707C0.98825 11.902 1.24425 12 1.50025 12C1.75625 12 2.01225 11.902 2.20725 11.707L6.50025 7.41401L10.7933 11.707C10.9882 11.902 11.2443 12 11.5002 12C11.7562 12 12.0122 11.902 12.2072 11.707C12.5982 11.316 12.5982 10.684 12.2072 10.293L7.91425 6.00001Z"
                  fill="#0A2A66"
                />
              </svg>
            </div>
          </div>
        </div>
      )} */}

      <div
        style={{
          padding: "2rem 4.5rem 3rem 4.5rem",
        }}
      >
        <div
          className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          style={{ gap: "1.5rem" }}
        >
          <div
            className="is-flex is-flex-direction-row is-justify-content-center"
            style={{ gap: "2.5rem", width: "57.5rem" }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start">
              <p
                style={{ width: "11rem" }}
                className="has-text-kurio-main-color-black has-text-weight-bold is-size-3"
              >
                {getConstantStringByLanguage(ConstantStrings.UPGRADE)}
              </p>
              <div
                className="has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-flex-start is-flex-gap-1"
                style={{
                  fontSize: "0.875rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                <p>{getConstantStringByLanguage(ConstantStrings.CURRENT_STATUS)}:</p>

                {checkPremium({
                  userInfo: userInfo,
                  screen: SCREEN_UPGRADE,
                }) ? (
                  <>
                    <p className="has-text-weight-bold">
                      {getConstantStringByLanguage(ConstantStrings.VIP_ACCOUNT)+" - "+ getConstantStringByLanguage(ConstantStrings.EXPIRED)+" : " +
                        convertTimestamp(userInfo?.premiumTo)}
                    </p>
                  </>
                ) : (
                  <p className="has-text-weight-bold">{getConstantStringByLanguage(ConstantStrings.FREE_ACCOUNT)}</p>
                )}
              </div>
            </div>
            <div
              className="is-flex is-flex-direction-row has-text-weight-bold"
              style={{
                fontStyle: "normal",
                lineHeight: "normal",
                height: "5.8125rem",
              }}
            >
              {/** Step 1 */}
              <div
                className={`is-flex is-align-items-center is-justify-content-center is-flex-direction-row ${
                  selectServiceState
                    ? "has-background-kurio-purple-light-3"
                    : "has-background-kurio-main-color-white"
                }`}
                style={{
                  gap: "1rem",
                  padding: "0.75rem 1rem",
                  cursor: "pointer",
                  width: "14rem",
                  borderRadius: "1rem",
                  height: "5.8125rem",
                  border: "1.5px solid #0A2A66",
                  boxShadow: "2px 2px 0px 0px #0A2A66",
                }}
                onClick={() => {
                  handleSelectStep(1);
                }}
              >
                <PaginationButtonSmall
                  children={1}
                  isClick={selectServiceState}
                ></PaginationButtonSmall>
                <div
                  className={`${
                    selectServiceState
                      ? "has-text-kurio-main-color-purple"
                      : "has-text-kurio-main-color-black"
                  }`}
                >
                  <p
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      textAlign: "left",
                      textTransform: "uppercase",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.STEP)} 1
                  </p>
                  <p
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {selectMethodsState ? nameSelectedService : getConstantStringByLanguage(ConstantStrings.SELECT_PACKAGE)}
                  </p>
                </div>
              </div>
              {/** Step 2 */}
              <>
                <div className="is-flex is-align-items-center">
                  <div
                    style={{
                      width: "1.46875rem",
                      height: "0rem",
                      borderTop: "0.25rem dotted #0A2A66",
                    }}
                  ></div>
                </div>
                <div
                  className={`is-flex is-align-items-center is-justify-content-center is-flex-direction-row ${
                    selectMethodsState
                      ? "has-background-kurio-purple-light-3"
                      : confirmState
                      ? "has-background-kurio-main-color-white"
                      : "has-background-kurio-main-color-light-gray"
                  }`}
                  style={{
                    gap: "1rem",
                    padding: "0.75rem 1rem",
                    width: "14rem",
                    cursor: "pointer",
                    borderRadius: "1rem",
                    height: "5.8125rem",
                    border: `1.5px solid ${
                      selectMethodsState
                        ? "#0A2A66"
                        : confirmState
                        ? "#0A2A66"
                        : "#8C9DC1"
                    }`,
                    boxShadow: selectMethodsState
                      ? "2px 2px 0px 0px #0A2A66"
                      : confirmState
                      ? "2px 2px 0px 0px #0A2A66"
                      : "",
                  }}
                  onClick={() => {
                    handleSelectStep(2);
                  }}
                  disabled={selectServiceState}
                >
                  <PaginationButtonSmall
                    children={2}
                    isClick={selectMethodsState}
                    disabled={selectServiceState}
                  ></PaginationButtonSmall>
                  <div
                    className={`${
                      selectMethodsState
                        ? "has-text-kurio-main-color-purple"
                        : confirmState
                        ? "has-text-kurio-main-color-black"
                        : "has-text-kurio-gray-main-accent"
                    }`}
                  >
                    <p
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 700,
                        textAlign: "left",
                        textTransform: "uppercase",
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.STEP)} 2
                    </p>
                    <p
                      style={{
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {confirmState ? nameSelectedPaymentMethod : getConstantStringByLanguage(ConstantStrings.METHOD)}
                    </p>
                  </div>
                </div>
              </>
              {/** Step 3 */}
              <>
                <div className="is-flex is-align-items-center">
                  <div
                    style={{
                      width: "1.46875rem",
                      height: "0rem",
                      borderTop: "0.25rem dotted #0A2A66",
                    }}
                  ></div>
                </div>
                <div
                  className={`is-flex is-align-items-center is-justify-content-center is-flex-direction-row ${
                    confirmState || resultState
                      ? "has-background-kurio-purple-light-3"
                      : "has-background-kurio-main-color-light-gray"
                  }`}
                  style={{
                    gap: "1rem",
                    padding: "0.75rem 1rem",
                    width: "14rem",
                    cursor: "pointer",
                    borderRadius: "1rem",
                    height: "5.8125rem",
                    border: `1.5px solid ${
                      confirmState || resultState ? "#0A2A66" : "#8C9DC1"
                    }`,
                    boxShadow:
                      confirmState || resultState
                        ? "2px 2px 0px 0px #0A2A66"
                        : "",
                  }}
                  onClick={() => {
                    handleSelectStep(3);
                  }}
                  disabled={!confirmState && !resultState}
                >
                  <PaginationButtonSmall
                    children={3}
                    disabled={!confirmState && !resultState}
                    isClick={confirmState || resultState}
                  >
                    3
                  </PaginationButtonSmall>
                  <div
                    className={`${
                      confirmState || resultState
                        ? "has-text-kurio-main-color-purple"
                        : "has-text-kurio-gray-main-accent"
                    }`}
                  >
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "0.75rem",
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.STEP)} 3
                    </p>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "1.125rem",
                        fontWeight: 500,
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.PAYMENT)}
                    </p>
                  </div>
                </div>
              </>
            </div>
          </div>

          {userInfo !== null ? (
            checkPremium({ userInfo: userInfo, screen: SCREEN_UPGRADE }) ? (
              <></>
            ) : (
              <>
                {/* <div
                  style={{
                    borderRadius: "1rem",
                    width: "57.5rem",
                    height: "5rem",
                    background:
                      'url("/images/upgrades/banner-homepage-acc-free.png")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    gap: "1.5rem",
                  }}
                  alt=""
                  className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                >
                  <p
                    className="has-text-kurio-main-color-white"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      textTransform: "uppercase",
                    }}
                  >
                    tiếp tục trải nghiệm phiên bản miễn phí của kurio?
                  </p>

                  <Link to={"/"}>
                    <div
                      className="has-background-kurio-main-color-white is-flex is-flex-direction-row is-align-items-center"
                      style={{
                        gap: "0.7rem",
                        padding: "0.6rem 1rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <img src="/images/upgrades/icon-home.svg" alt="" />
                      <p
                        className="has-text-kurio-main-color-purple"
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.875rem",
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        Về Trang Chủ
                      </p>
                    </div>
                  </Link>
                </div> */}
              </>
            )
          ) : (
            <></>
          )}

          <div
            className={`${
              confirmState && !resultState
                ? "is-flex is-flex-direction-column"
                : "is-flex is-flex-direction-row"
            }`}
            style={{
              width: "100%",
              justifyContent: "center",
              gap: "2.5rem",
              marginTop: !userInfo?.isCombo ? "3rem" : "0rem",
            }}
          >
            <UpgradeAccountContext.Provider
              value={{
                services,
                methods,
                PaymentMethodId,
                directTransferConfirm,
                SubscriptionPackageId,
                error,
                vnPayState,
                selectServiceState,
                selectMethodsState,
                nameSelectedService,
                directTransferState,
                setError,
                setDirectTransferConfirm,
                setSubscriptionPackageId,
                setPaymentMethodId,
                setSelectMethodsState,
                setVnPayState,
                setDirectTransferState,
              }}
            >
              {selectServiceState && (
                <SelectService
                  handleSelectStep={(step) => handleSelectStep(step)}
                  handleNextStep={handleNextStep}
                  setNameSelectedService={setNameSelectedService}
                />
              )}
              {selectMethodsState && (
                <PaymentMethod
                  handleSelectStep={(step) => handleSelectStep(step)}
                  handleNextStep={(_id) => handleNextStep(_id)}
                  setShowTermsPopup={setShowTermsPopup}
                  showTermsPopup={showTermsPopup}
                  setDirectTransferConfirm={setDirectTransferConfirm}
                  setSelectMethodsState={setSelectMethodsState}
                  setConfirmState={setConfirmState}
                  isPremium={checkPremium({ userInfo: userInfo ,screen: SCREEN_UPGRADE})}
                  studentType={userInfo?.studentType?.type}
                  paymentRecord={paymentRecord}
                  setshowErrorPayment={setshowErrorPayment}
                  showErrorPayment={showErrorPayment}
                  setnameSelectedPaymentMethod={setnameSelectedPaymentMethod}
                />
              )}
              {confirmState && !resultState && <UserDirectTransferConfirm />}
              {resultState && (
                <ResultPayment
                  handleChangeShowPhone={(state) =>
                    handleChangeShowPhone(state)
                  }
                />
              )}
            </UpgradeAccountContext.Provider>
            {confirmState && !resultState && (
              <PrimaryButtonPageLevel
                onClick={handleNextStep}
                style={{
                  width: "37.064rem",
                  maxWidth: "37.064rem",
                  margin: "0 auto",
                  height: "5rem",
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.TRANSFER_CONFIRMED)}
              </PrimaryButtonPageLevel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(UpgradeAccount);
