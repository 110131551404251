import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollRefWrapper from '../../detail-components/ScrollRefWrapper';

var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";
var colorBlack = "#0A2A66";

const Commitment = () => {
  const [showPopupDownload, setShowPopupDownload] = useState(true)

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Cuộn mượt mà
    });
  };
  const handleClosePopupDownload = ()=>{
    setShowPopupDownload(false)
  }

  const  getMobileOperatingSystem=()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  const handleDownloadApp = ()=>{
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=vn.kurio.android"
    } else if (mobileOS === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
    }
  }

  return (
    <>
      <ScrollRefWrapper />
      {/* Desktop */}
      <div className='container-landingpage' id='dekstop-landingpage'>
        <section id='header-landingpage' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", fontWeight: 700, color: colorTextH1,marginBottom: "1rem"  }} className='is-uppercase'>Chính sách học lại miễn phí</h1>
          <div> 
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
              1. Điều kiện để học lại miễn phí Kurio
            </h1>
            <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>Học sinh sẽ được học lại miễn phí nếu đáp ứng trong các điều kiện sau:</p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học sinh đã tham gia và học liên tục trên Kurio web hoặc app trong 3 tháng, hoàn thành trên 90% nội dung khóa học và bài tập yêu cầu, nếu điểm số trên trường không đạt 8 điểm trở lên.
            </p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học sinh bị gián đoạn việc học do lý do cá nhân (ốm đau, gia đình có việc gấp, hoặc sự cố bất khả kháng từ tài khoản,..) và có đơn đề nghị từ phụ huynh.
            </p>
          </div>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
            2. Thời gian và phạm vi áp dụng
            </h1>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b> Học sinh được phép đăng ký học lại trong vòng 6 tháng kể từ khi kết thúc khóa học ban đầu.
            </p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học lại miễn phí áp dụng cho tất cả các khóa học Toán tư duy và Luyện thi toán học trên Kurio.vn.
            </p>
          </div>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
            3. Cách thức đăng ký học lại
            </h1>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh cần gửi yêu cầu học lại qua hotline <b>0971500120</b> .
            </p>
            <div
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <p><b style={{ marginRight: "1rem" }}>-</b>Yêu cầu cần bao gồm:</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Tên học sinh</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Khóa học muốn học lại</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Lý do</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Cung cấp ảnh/giấy tờ chứng nhận việc học bị gián đoạn</p>
            </div>
            <p style={{ color: "#272266", fontWeight: 500,marginLeft:"2rem" }}>
            Trong vòng 15 ngày làm việc, hệ thống sẽ xác nhận và kích hoạt lại khóa học.
            </p>
            <p style={{ color: "#272266", fontWeight: 700}}>
              Lưu ý:
            </p>
            <div
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <p><b style={{ marginRight: "1rem" }}>+</b>Mỗi học sinh chỉ được học lại miễn phí tối đa 1 lần/khoá học.</p>
              <p><b style={{ marginRight: "1rem" }}>+</b>Học sinh được học miễn phí tương đương với thời gian học đã đăng ký.</p>
            </div>
          </div>
          
        </div>

        <section id='footer-landingpage' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem", width: "20rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>

              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
              <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div style={{ height: "24.125rem" }} className='is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Tablet */}
      <div className='container-landingpage' id='tablet-landingpage'>
        <section id='tablet-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
                {/* <img src="/images/landing-page/icon-arrow.svg" alt="" /> */}
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", fontWeight: 700, color: colorTextH1,marginBottom: "1rem"  }}className='is-uppercase'>Chính sách học lại miễn phí</h1>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
              1. Điều kiện để học lại miễn phí Kurio
            </h1>
            <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>Học sinh sẽ được học lại miễn phí nếu đáp ứng trong các điều kiện sau:</p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học sinh đã tham gia và học liên tục trên Kurio web hoặc app trong 3 tháng, hoàn thành trên 90% nội dung khóa học và bài tập yêu cầu, nếu điểm số trên trường không đạt 8 điểm trở lên.
            </p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học sinh bị gián đoạn việc học do lý do cá nhân (ốm đau, gia đình có việc gấp, hoặc sự cố bất khả kháng từ tài khoản,..) và có đơn đề nghị từ phụ huynh.
            </p>
          </div>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
            2. Thời gian và phạm vi áp dụng
            </h1>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b> Học sinh được phép đăng ký học lại trong vòng 6 tháng kể từ khi kết thúc khóa học ban đầu.
            </p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học lại miễn phí áp dụng cho tất cả các khóa học Toán tư duy và Luyện thi toán học trên Kurio.vn.
            </p>
          </div>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
            3. Cách thức đăng ký học lại
            </h1>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh cần gửi yêu cầu học lại qua hotline <b>0971500120</b> .
            </p>
            <div
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <p><b style={{ marginRight: "1rem" }}>-</b>Yêu cầu cần bao gồm:</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Tên học sinh</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Khóa học muốn học lại</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Lý do</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Cung cấp ảnh/giấy tờ chứng nhận việc học bị gián đoạn</p>
            </div>
            <p style={{ color: "#272266", fontWeight: 500,marginLeft:"2rem" }}>
            Trong vòng 15 ngày làm việc, hệ thống sẽ xác nhận và kích hoạt lại khóa học.
            </p>
            <p style={{ color: "#272266", fontWeight: 700 }}>
              Lưu ý:
            </p>
            <div
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <p><b style={{ marginRight: "1rem" }}>+</b>Mỗi học sinh chỉ được học lại miễn phí tối đa 1 lần/khoá học.</p>
              <p><b style={{ marginRight: "1rem" }}>+</b>Học sinh được học miễn phí tương đương với thời gian học đã đăng ký.</p>
            </div>
          </div>
        </div>

        <section id='tablet-footer' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học, <br />
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start' style={{ gap: "4rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                  <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                  <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                </div>

                <Link to={'/payment-policy'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                    <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                  </div>
                </Link>
                <Link to={'/commitment'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                    <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                      <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                      <p>QUYỀN LỢI
                      KHÁCH HÀNG</p>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                    <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                  </div>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://www.facebook.com/kurio.vn'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                    </Link>
                    {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Mobile */}
      <div className='container-landingpage' id='mobile-landingpage'>
          {showPopupDownload && (
            <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
              <div id='bottom-popup' className='is-relative' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <span className='icon-cancel is-flex is-justify-content-center is-align-items-center' onClick={handleClosePopupDownload} style={{ position: "absolute",zIndex:2, right: "1rem", top: "15%", width: "1.5rem", height: "1.5rem", borderRadius: "1rem 1rem 0rem 0rem", background: "#E8E3F8" }}>
                  <img src="/images/landing-page/icon-cancel.svg" alt="" />
                </span>
                <div style={{ position: "relative", maxWidth: "100%", width: "100%", bottom: "-0.7rem" }}>
                  <img style={{ width: "100%" }} src="/images/landing-page/image-mobile-popup.svg" alt="" />
                  <button className='btn-mobile-popup is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{ position: "absolute",zIndex:1, top: "60%", right: "5%", transform: "translateY(-50%)", gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "11.5rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <img src="/images/landing-page/icon-download.svg" alt="" />
                    <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>tải app ngay</p>
                  </button>
                </div>
              </div>
            </div>
          )}

        <section id='mobile-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "5rem", padding: "0rem 1rem" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
            <img style={{ width: "2.63394rem", height: "3rem" }} src="/images/landing-page/logo-mobile.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "12.5rem", height: "3rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>
        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", fontWeight: 700, color: colorTextH1,marginBottom: "1rem",  }}className='is-uppercase'>Chính sách học lại miễn phí</h1>
          
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
              1. Điều kiện để học lại miễn phí Kurio
            </h1>
            <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>Học sinh sẽ được học lại miễn phí nếu đáp ứng trong các điều kiện sau:</p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học sinh đã tham gia và học liên tục trên Kurio web hoặc app trong 3 tháng, hoàn thành trên 90% nội dung khóa học và bài tập yêu cầu, nếu điểm số trên trường không đạt 8 điểm trở lên.
            </p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học sinh bị gián đoạn việc học do lý do cá nhân (ốm đau, gia đình có việc gấp, hoặc sự cố bất khả kháng từ tài khoản,..) và có đơn đề nghị từ phụ huynh.
            </p>
          </div>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
            2. Thời gian và phạm vi áp dụng
            </h1>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b> Học sinh được phép đăng ký học lại trong vòng 6 tháng kể từ khi kết thúc khóa học ban đầu.
            </p>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Học lại miễn phí áp dụng cho tất cả các khóa học Toán tư duy và Luyện thi toán học trên Kurio.vn.
            </p>
          </div>
          <div>
            <h1 style={{ color: "#272266", fontWeight: 700 }}>
            3. Cách thức đăng ký học lại
            </h1>
            <p
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh cần gửi yêu cầu học lại qua hotline <b>0971500120</b> .
            </p>
            <div
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <p><b style={{ marginRight: "1rem" }}>-</b>Yêu cầu cần bao gồm:</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Tên học sinh</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Khóa học muốn học lại</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Lý do</p>
              <p style={{marginLeft:"1rem"}}><b style={{ marginRight: "1rem" }}>+</b>Cung cấp ảnh/giấy tờ chứng nhận việc học bị gián đoạn</p>
            </div>
            <p style={{ color: "#272266", fontWeight: 500,marginLeft:"2rem" }}>
            Trong vòng 15 ngày làm việc, hệ thống sẽ xác nhận và kích hoạt lại khóa học.
            </p>
            <p style={{ color: "#272266", fontWeight: 700 }}>
              Lưu ý:
            </p>
            <div
              style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
            >
              <p><b style={{ marginRight: "1rem" }}>+</b>Mỗi học sinh chỉ được học lại miễn phí tối đa 1 lần/khoá học.</p>
              <p><b style={{ marginRight: "1rem" }}>+</b>Học sinh được học miễn phí tương đương với thời gian học đã đăng ký.</p>
            </div>
          </div>
          
        </div>

        <section id='mobile-footer' style={{ background: colorWhite, width: "100%", marginBottom: "10%" }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "3rem", padding: "4rem 2rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "0.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.75rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>

              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
              <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "4rem" }}>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "6.90625rem", height: "2.625rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1rem" }}>
                <img onClick={() => backToTop()} style={{ zIndex: 2, width: "2.5rem", height: "2.5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ zIndex: 2, fontSize: "0.875rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "3.75rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  )
}

export default Commitment