import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import InputText from "../../components/input/InputText";
import { resetPasswordEmail } from "../../config/auth";
import ConstantStrings from "../../constants/ConstantStrings";
import { selectCheck } from "../../reducers/authSlice";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const ChangePassword = () => {
  const [resetPassWord, setResetPassword] = useState({
    email: "",
    phone: "",
    newPassword: "",
    repeatPassword: "",
    requestId: "",
    otp: "",
  });
  const [error, setError] = useState({
    error: "",
    newPassword: "",
    repeatPassword: "",
  });
  const cookies = new Cookies();
  const [resetPasswordState, setResetPasswordState] = useState(true);
  const [valueInput] = useState({
    email: cookies.get("email"),
  });

  const [complete, setComplete] = useState(false);

  const navigate = useNavigate();
  const check = useSelector(selectCheck);
  if (check) {
    resetPassWord.requestId = check?.requestId;
    resetPassWord.otp = check?.otp;
  } else {
    navigate("/reset-password");
  }
  var backgroundImage = {
    backgroundImage: resetPasswordState
      ? "url('/images/auth/bg-desktop-change-pw.svg')"
      : complete
      ? "url('/images/auth/bg-desktop-reset-pw-success.svg')"
      : "url('/images/auth/bg-desktop-reset-pw.svg')",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };

  const handleChange = (value, index, name) => {
    setResetPassword({ ...resetPassWord, [name]: value });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (
      resetPassWord.newPassword === "" &&
      resetPassWord.repeatPassword === ""
    ) {
      resetPassWord.newPassword === ""
        ? setError({
            ...error,
            newPassword: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
          })
        : setError({
            ...error,
            repeatPassword: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
          });
    } else if (resetPassWord.newPassword !== resetPassWord.repeatPassword) {
      setError({
        ...error,
        newPassword: getConstantStringByLanguage(ConstantStrings.PASSWORD_INCORRECT),
        repeatPassword: getConstantStringByLanguage(ConstantStrings.PASSWORD_INCORRECT),
      });
    } else {
      try {
        await resetPasswordEmail(resetPassWord);
        cookies.remove("email");
        setComplete(true);
        setResetPasswordState(false);
      } catch (error) {

        if (error?.response?.data?.errorMessage) {
          setError({
            ...error,
            newPassword: error?.response?.data?.errorMessage,
            repeatPassword: error?.response?.data?.errorMessage,
          });
        }
        setError({
          ...error,
          error: error.response.data?.errorMessage,
        });
      }
    }
    if (complete) {
      navigate("/signin");
    }
  };

  return (
    <div className="hero is-fullheight is-relative" style={backgroundImage}>
      <div
        style={{
          width: "21.5rem",
          height: "8.3125rem",
          backgroundImage: "url('/images/auth/logo-reset-pw.svg')",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          left: "0",
          bottom: "0",
        }}
      ></div>
      <div className="columns m-0" style={{ padding: "3.5rem" }}>
        <div className="column m-0 p-0">
          <div className="">
            <Link to={`/signin`}>
              <p className="is-uppercase  is-align-items-center">
                <span className="has-text-weight-bold mr-2">&lt;</span>
                Về đăng nhập
              </p>
            </Link>
          </div>
        </div>
        <div
          className={
            complete
              ? "column m-0 is-one-third box has-text-kurio-main-color-black"
              : "column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between has-background-white m-0 has-text-kurio-main-color-black"
          }
          style={{
            padding: complete
              ? "5.125rem 4rem 5.125rem 3.5rem"
              : "3.1875rem 4rem 2.5rem 3.5rem",
            height: "40rem",
            width: "27.5rem",
            maxWidth: "27.5rem",
            boxShadow: "0.75rem 0.6875rem #0A2A66",
            border: "0.0625rem solid #0A2A66",
          }}
        >
          {complete ? (
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "2rem" }}
            >
              <p
                style={{ fontSize: "2.25rem", lineHeight: "2.75rem" }}
                className=" has-text-weight-bold has-text-centered"
              >
                Đặt lại mật khẩu thành công
              </p>
              <div
                style={{
                  height: "10rem",
                  width: "20rem",
                  backgroundImage:
                    "url('/images/auth/image-reset-pw-success.svg')",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "0.875rem", lineHeight: "1rem" }}>
                Bạn đã hoàn thành reset mật khẩu cho tài khoản
                <span className="has-text-weight-bold ml-1">
                  {valueInput.email}
                </span>{" "}
                Đăng nhập ngay để tiếp tục trải nghiệm Toán tư duy cùng KURIO.
              </p>
              <PrimaryButtonPageLevel
                onClick={handleSubmit}
                style={{
                  height: "4rem",
                  width: "20rem",
                  maxWidth: "20rem",
                }}
                type="submit"
              >
                Đăng nhập
              </PrimaryButtonPageLevel>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <input hidden type="submit" />
                <div
                  className="is-flex is-flex-direction-column is-align-items-center p-0 "
                  style={{ gap: "2rem", width: "20rem" }}
                >
                  <h2
                    style={{ fontSize: "2.25rem" }}
                    className=" has-text-weight-bold"
                  >
                    Quên mật khẩu
                  </h2>
                  {resetPasswordState && !complete && (
                    <>
                      <div
                        className="is-flex is-flex-direction-column "
                        style={{ gap: "1.5rem" }}
                      >
                        {error.error && (
                          <p className="has-text-danger has-text-centered subtitle is-size-6">
                            {error.error}
                          </p>
                        )}
                        <div style={{ width: "20rem", height: "5rem" }}>
                          <InputText
                            readOnly={true}
                            label={"Email đăng nhập"}
                            type="text"
                            value={valueInput.email}
                            name={"email"}
                            onChange={(e) =>
                              handleChange(e.target.value, 0, e.target.name)
                            }
                          />
                          {error.email && (
                            <p className="has-text-danger subtitle is-size-6">
                              {error.email}
                            </p>
                          )}
                        </div>
                        <div style={{ width: "20rem", height: "5rem" }}>
                          <InputText
                            label={"Tạo mật khẩu"}
                            type="password"
                            value={resetPassWord.newPassword}
                            name="newPassword"
                            onChange={(e) =>
                              handleChange(e.target.value, 0, e.target.name)
                            }
                          />

                          {error.password && (
                            <p className="has-text-danger subtitle is-size-6">
                              {error.password}
                            </p>
                          )}
                        </div>
                        <div style={{ width: "20rem", height: "5rem" }}>
                          <InputText
                            label={"Xác nhận mật khẩu"}
                            type="password"
                            value={resetPassWord.repeatPassword}
                            name="repeatPassword"
                            onChange={(e) =>
                              handleChange(e.target.value, 0, e.target.name)
                            }
                          />

                          {error.repeatPassword && (
                            <p className="has-text-danger subtitle is-size-6">
                              {error.repeatPassword}
                            </p>
                          )}
                        </div>
                      </div>
                      <PrimaryButtonPageLevel
                        onClick={handleSubmit}
                        style={{
                          height: "4rem",
                          width: "20rem",
                          maxWidth: "20rem",
                        }}
                        type="submit"
                      >
                        Tiếp theo
                      </PrimaryButtonPageLevel>
                    </>
                  )}
                  {!resetPasswordState && complete && (
                    <>
                      <div className="field ">
                        <span>
                          Bạn đã hoàn thành reset mật khẩu cho tài khoản
                        </span>
                        <span className="has-text-weight-bold">
                          {valueInput.email}.
                        </span>
                        <span>
                          Đăng nhập ngay để trải nghiệm Toán tư duy cùng KURIO
                        </span>
                        <button
                          className="button mt-6 is-medium is-fullwidth is-black is-uppercase has-text-weight-semibold"
                          style={{ borderRadius: "0.5rem" }}
                          type="submit"
                        >
                          Đăng nhập
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </form>
              <div
                className="has-text-centered is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "1rem" }}
              >
                <div>
                  <div
                    className={``}
                    style={{ width: "2.125rem", height: "0.5rem" }}
                  ></div>
                </div>
                <Link
                  className=" has-text-kurio-main-color-black"
                  to="/register"
                >
                  <p style={{ fontSize: "0.875rem" }}>
                    <span className=" mr-2">Chưa có tài khoản ?</span>
                    <span className="has-text-weight-bold is-underlined">
                      Đăng ký
                    </span>
                  </p>
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="column" style={backgroundImage}></div>
      </div>
    </div>
  );
};

export default ChangePassword;
