import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollRefWrapper from '../../detail-components/ScrollRefWrapper';

var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";
var colorBlack = "#0A2A66";

const PrivacyPolicy = () => {
  const [showPopupDownload, setShowPopupDownload] = useState(true)

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Cuộn mượt mà
    });
  };
  const handleClosePopupDownload = ()=>{
    setShowPopupDownload(false)
  }

  const  getMobileOperatingSystem=()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  const handleDownloadApp = ()=>{
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=vn.kurio.android"
    } else if (mobileOS === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
    }
  }

  return (
    <>
      <ScrollRefWrapper />
      {/* Desktop */}
      <div className='container-landingpage' id='dekstop-landingpage'>
        <section id='header-landingpage' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", background: colorWhite, color: "#272266", width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", color: colorTextH1, fontWeight: 700, textTransform: "uppercase" }}>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            1. Mục đích và phạm vi thu thập thông tin
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> không bán, chia sẻ hay trao đổi thông tin
            cá nhân của khách hàng thu thập trên trang web cho một bên thứ ba
            nào khác.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Thông tin cá nhân thu thập được sẽ chỉ được sử dụng để công ty
            cung cấp sản phẩm, dịch vụ đến Khách hàng.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Khi bạn nhập thông tin để đăng ký tài khoản , thông tin cá nhân mà
            website <b>Kurio.vn</b> thu thập bao gồm: Họ và tên, Ngày tháng
            năm sinh, Điện thoại, Email.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>2. Phạm vi sử dụng thông tin</h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Thông tin cá nhân thu thập được sẽ chỉ được website{" "}
            <b>Kurio.vn</b> sử dụng trong nội bộ công ty và cho một hoặc tất
            cả các mục đích sau đây:
            <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Hỗ trợ khách hàng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Cung cấp thông tin liên
            quan đến dịch vụ
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Xử lý và cung cấp dịch vụ,
            thông tin qua trang web của chúng tôi theo yêu cầu của bạn
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Chúng tôi có thể sẽ gửi
            thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện chương
            trình sắp tới Quý khách.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp để hỗ trợ
            quản lý tài khoản khách hàng; xác nhận và thực hiện các giao dịch
            tài chính liên quan đến các khoản thanh toán trực tuyến của bạn.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            3. Thời gian lưu trữ thông tin
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Đối với thông tin cá nhân, website <b>Kurio.vn</b> chỉ xóa đi dữ
            liệu này nếu khách hàng yêu cầu và/hoặc theo quy định của Công ty,
            Khách hàng yêu cầu gửi mail về <b>cskh@kurio.vn</b>.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            4. Những người hoặc tổ chức có thể được tiếp cận với thông tin cá
            nhân
          </h2>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            5. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân
          </h2>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Địa chỉ:</b> Số 128 Nguyễn Thái Học, Phường Điện Biên, Quận Ba
            Đình, Thành phố Hà Nội, Việt Nam
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Email:</b> contact@ikemso.com
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Điện thoại:</b> 0971500120
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Website:</b> ikemso.com
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            6. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân của mình
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> thu thập thông tin dữ liệu cá nhân chi
            tiết của khách hàng trực tiếp qua trang website. Thông tin cá nhân
            khách hàng có thể tự điều chỉnh trên website <b>Kurio.vn</b> hoặc
            khách hàng có thể liên hệ qua email{" "}
            <b className="is-underlined">cskh@kurio.vn</b> số điện thoại
            hotline
            <b> 0971500120</b> để yêu cầu chỉnh sửa dữ liệu cá nhân của mình.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            7. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng
            liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc
            phạm vi đã thông báo
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Tại website <b>Kurio.vn</b> , việc bảo vệ thông tin cá nhân của
            bạn là rất quan trọng là ưu tiên hàng đầu của chúng tôi. Các thông
            tin đăng ký mua hàng của Khách hàng sẽ được bảo mật bằng cách sử
            dụng công nghệ mã hóa an toàn Secure Sockets Layer (SSL). Chúng
            tôi cam kết sẽ cố gắng để đảm bảo an ninh ở mức cao nhất cho Khách
            hàng.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> cam kết chỉ sử dụng các thông tin của bạn
            vào các trường hợp sau:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Nâng cao chất lượng dịch vụ cho khách hàng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Giải quyết các tranh chấp,
            khiếu nại
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi cơ quan pháp luật có
            yêu cầu
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> hiểu rằng quyền lợi của bạn trong việc bảo
            vệ thông tin cá nhân cũng chính là trách nhiệm của chúng tôi nên
            trong bất kỳ trường hợp có thắc mắc, góp ý nào liên quan đến chính
            sách bảo mật của website <b>Kurio.vn</b> và liên quan đến việc
            thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông
            báo, vui lòng liên hệ qua số hotline <b>0971500120</b> hoặc email{" "}
            <b className="is-underlined">cskh@kurio.vn</b>.
          </p>
          <p />
        </div>

        <section id='footer-landingpage' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem", width: "20rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>

              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
              <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div style={{ height: "24.125rem" }} className='is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Tablet */}
      <div className='container-landingpage' id='tablet-landingpage'>
        <section id='tablet-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
                {/* <img src="/images/landing-page/icon-arrow.svg" alt="" /> */}
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", background: colorWhite, color: "#272266", width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", color: colorTextH1, fontWeight: 700, textTransform: "uppercase" }}>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            1. Mục đích và phạm vi thu thập thông tin
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> không bán, chia sẻ hay trao đổi thông tin
            cá nhân của khách hàng thu thập trên trang web cho một bên thứ ba
            nào khác.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Thông tin cá nhân thu thập được sẽ chỉ được sử dụng để công ty
            cung cấp sản phẩm, dịch vụ đến Khách hàng.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Khi bạn nhập thông tin để đăng ký tài khoản , thông tin cá nhân mà
            website <b>Kurio.vn</b> thu thập bao gồm: Họ và tên, Ngày tháng
            năm sinh, Điện thoại, Email.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>2. Phạm vi sử dụng thông tin</h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Thông tin cá nhân thu thập được sẽ chỉ được website{" "}
            <b>Kurio.vn</b> sử dụng trong nội bộ công ty và cho một hoặc tất
            cả các mục đích sau đây:
            <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Hỗ trợ khách hàng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Cung cấp thông tin liên
            quan đến dịch vụ
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Xử lý và cung cấp dịch vụ,
            thông tin qua trang web của chúng tôi theo yêu cầu của bạn
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Chúng tôi có thể sẽ gửi
            thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện chương
            trình sắp tới Quý khách.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp để hỗ trợ
            quản lý tài khoản khách hàng; xác nhận và thực hiện các giao dịch
            tài chính liên quan đến các khoản thanh toán trực tuyến của bạn.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            3. Thời gian lưu trữ thông tin
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Đối với thông tin cá nhân, website <b>Kurio.vn</b> chỉ xóa đi dữ
            liệu này nếu khách hàng yêu cầu và/hoặc theo quy định của Công ty,
            Khách hàng yêu cầu gửi mail về <b>cskh@kurio.vn</b>.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            4. Những người hoặc tổ chức có thể được tiếp cận với thông tin cá
            nhân
          </h2>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            5. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân
          </h2>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Địa chỉ:</b> Số 128 Nguyễn Thái Học, Phường Điện Biên, Quận Ba
            Đình, Thành phố Hà Nội, Việt Nam
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Email:</b> contact@ikemso.com
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Điện thoại:</b> 0971500120
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Website:</b> ikemso.com
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            6. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân của mình
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> thu thập thông tin dữ liệu cá nhân chi
            tiết của khách hàng trực tiếp qua trang website. Thông tin cá nhân
            khách hàng có thể tự điều chỉnh trên website <b>Kurio.vn</b> hoặc
            khách hàng có thể liên hệ qua email{" "}
            <b className="is-underlined">cskh@kurio.vn</b> số điện thoại
            hotline
            <b> 0971500120</b> để yêu cầu chỉnh sửa dữ liệu cá nhân của mình.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            7. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng
            liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc
            phạm vi đã thông báo
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Tại website <b>Kurio.vn</b> , việc bảo vệ thông tin cá nhân của
            bạn là rất quan trọng là ưu tiên hàng đầu của chúng tôi. Các thông
            tin đăng ký mua hàng của Khách hàng sẽ được bảo mật bằng cách sử
            dụng công nghệ mã hóa an toàn Secure Sockets Layer (SSL). Chúng
            tôi cam kết sẽ cố gắng để đảm bảo an ninh ở mức cao nhất cho Khách
            hàng.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> cam kết chỉ sử dụng các thông tin của bạn
            vào các trường hợp sau:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Nâng cao chất lượng dịch vụ cho khách hàng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Giải quyết các tranh chấp,
            khiếu nại
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi cơ quan pháp luật có
            yêu cầu
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> hiểu rằng quyền lợi của bạn trong việc bảo
            vệ thông tin cá nhân cũng chính là trách nhiệm của chúng tôi nên
            trong bất kỳ trường hợp có thắc mắc, góp ý nào liên quan đến chính
            sách bảo mật của website <b>Kurio.vn</b> và liên quan đến việc
            thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông
            báo, vui lòng liên hệ qua số hotline <b>0971500120</b> hoặc email{" "}
            <b className="is-underlined">cskh@kurio.vn</b>.
          </p>
          <p />
        </div>

        <section id='tablet-footer' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học, <br />
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start' style={{ gap: "4rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                  <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                  <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                </div>

                <Link to={'/payment-policy'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                    <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                  </div>
                </Link>
                <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                    <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>  
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                  </div>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://www.facebook.com/kurio.vn'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                    </Link>
                    {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Mobile */}
      <div className='container-landingpage' id='mobile-landingpage'>
          {showPopupDownload && (
            <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
              <div id='bottom-popup' className='is-relative' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <span className='icon-cancel is-flex is-justify-content-center is-align-items-center' onClick={handleClosePopupDownload} style={{ position: "absolute",zIndex:2, right: "1rem", top: "15%", width: "1.5rem", height: "1.5rem", borderRadius: "1rem 1rem 0rem 0rem", background: "#E8E3F8" }}>
                  <img src="/images/landing-page/icon-cancel.svg" alt="" />
                </span>
                <div style={{ position: "relative", maxWidth: "100%", width: "100%", bottom: "-0.7rem" }}>
                  <img style={{ width: "100%" }} src="/images/landing-page/image-mobile-popup.svg" alt="" />
                  <button className='btn-mobile-popup is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{ position: "absolute",zIndex:1, top: "60%", right: "5%", transform: "translateY(-50%)", gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "11.5rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <img src="/images/landing-page/icon-download.svg" alt="" />
                    <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>tải app ngay</p>
                  </button>
                </div>
              </div>
            </div>
          )}

        <section id='mobile-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "5rem", padding: "0rem 1rem" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
            <img style={{ width: "2.63394rem", height: "3rem" }} src="/images/landing-page/logo-mobile.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "12.5rem", height: "3rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>
        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", background: colorWhite, color: "#272266", width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "1rem", color: colorTextH1, fontWeight: 700, textTransform: "uppercase" }}>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            1. Mục đích và phạm vi thu thập thông tin
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> không bán, chia sẻ hay trao đổi thông tin
            cá nhân của khách hàng thu thập trên trang web cho một bên thứ ba
            nào khác.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Thông tin cá nhân thu thập được sẽ chỉ được sử dụng để công ty
            cung cấp sản phẩm, dịch vụ đến Khách hàng.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Khi bạn nhập thông tin để đăng ký tài khoản , thông tin cá nhân mà
            website <b>Kurio.vn</b> thu thập bao gồm: Họ và tên, Ngày tháng
            năm sinh, Điện thoại, Email.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>2. Phạm vi sử dụng thông tin</h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Thông tin cá nhân thu thập được sẽ chỉ được website{" "}
            <b>Kurio.vn</b> sử dụng trong nội bộ công ty và cho một hoặc tất
            cả các mục đích sau đây:
            <br />
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Hỗ trợ khách hàng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Cung cấp thông tin liên
            quan đến dịch vụ
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Xử lý và cung cấp dịch vụ,
            thông tin qua trang web của chúng tôi theo yêu cầu của bạn
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>+</b>Chúng tôi có thể sẽ gửi
            thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện chương
            trình sắp tới Quý khách.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp để hỗ trợ
            quản lý tài khoản khách hàng; xác nhận và thực hiện các giao dịch
            tài chính liên quan đến các khoản thanh toán trực tuyến của bạn.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            3. Thời gian lưu trữ thông tin
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Đối với thông tin cá nhân, website <b>Kurio.vn</b> chỉ xóa đi dữ
            liệu này nếu khách hàng yêu cầu và/hoặc theo quy định của Công ty,
            Khách hàng yêu cầu gửi mail về <b>cskh@kurio.vn</b>.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            4. Những người hoặc tổ chức có thể được tiếp cận với thông tin cá
            nhân
          </h2>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            5. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân
          </h2>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Địa chỉ:</b> Số 128 Nguyễn Thái Học, Phường Điện Biên, Quận Ba
            Đình, Thành phố Hà Nội, Việt Nam
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Email:</b> contact@ikemso.com
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Điện thoại:</b> 0971500120
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            <b>Website:</b> ikemso.com
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            6. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân của mình
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> thu thập thông tin dữ liệu cá nhân chi
            tiết của khách hàng trực tiếp qua trang website. Thông tin cá nhân
            khách hàng có thể tự điều chỉnh trên website <b>Kurio.vn</b> hoặc
            khách hàng có thể liên hệ qua email{" "}
            <b className="is-underlined">cskh@kurio.vn</b> số điện thoại
            hotline
            <b> 0971500120</b> để yêu cầu chỉnh sửa dữ liệu cá nhân của mình.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            7. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng
            liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc
            phạm vi đã thông báo
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Tại website <b>Kurio.vn</b> , việc bảo vệ thông tin cá nhân của
            bạn là rất quan trọng là ưu tiên hàng đầu của chúng tôi. Các thông
            tin đăng ký mua hàng của Khách hàng sẽ được bảo mật bằng cách sử
            dụng công nghệ mã hóa an toàn Secure Sockets Layer (SSL). Chúng
            tôi cam kết sẽ cố gắng để đảm bảo an ninh ở mức cao nhất cho Khách
            hàng.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> cam kết chỉ sử dụng các thông tin của bạn
            vào các trường hợp sau:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Nâng cao chất lượng dịch vụ cho khách hàng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Giải quyết các tranh chấp,
            khiếu nại
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Khi cơ quan pháp luật có
            yêu cầu
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Website <b>Kurio.vn</b> hiểu rằng quyền lợi của bạn trong việc bảo
            vệ thông tin cá nhân cũng chính là trách nhiệm của chúng tôi nên
            trong bất kỳ trường hợp có thắc mắc, góp ý nào liên quan đến chính
            sách bảo mật của website <b>Kurio.vn</b> và liên quan đến việc
            thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông
            báo, vui lòng liên hệ qua số hotline <b>0971500120</b> hoặc email{" "}
            <b className="is-underlined">cskh@kurio.vn</b>.
          </p>
          <p />
        </div>

        <section id='mobile-footer' style={{ background: colorWhite, width: "100%", marginBottom: "10%" }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "3rem", padding: "4rem 2rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "0.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.75rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>
              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
              <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal" ,width:"9.125rem"}}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "4rem" }}>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "6.90625rem", height: "2.625rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1rem" }}>
                <img onClick={() => backToTop()} style={{ zIndex: 2, width: "2.5rem", height: "2.5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ zIndex: 2, fontSize: "0.875rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "3.75rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  )
}

export default PrivacyPolicy