/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../../../../components/DuplicateLoginErrorComponent";
import PrimaryButtonPageLevel from "../../../../../components/button/primary-button/PrimaryButtonLevelPage";
import BackgroundImageFromUrl from "../../../../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../../../../components/parseImage/ImageFromUrl";
import { getListUnit } from "../../../../../config/ListLesson";
import { getLesson } from "../../../../../config/lesson";
import { setLastLesson } from "../../../../../reducers/gradesSlice";
import {
  getALesson,
  setCurrentLessonProgress,
} from "../../../../../reducers/questionsSlice";
import { LoadingImage } from "../../../../../templates/helper/LoadingImage";
import { getAllImage } from "../../../../../templates/helper/getAllImages";
import jsonParser from "../../../../../templates/helper/jsonParser";
import MainContentListUnit from "../MainContentListUnit";
import "./../ListUnit.css";
import { getConstantStringByLanguage, getGradeName } from "../../../../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../../../../constants/ConstantStrings";

const ListUnitContent = ({
  listGradeUnits,
  gradeUnitProgress,
  listGradeUnit,
  gradeId,
  lastLesson,
  listUserInfo,
  sections,
  errorLesson,
}) => {
  const [defaultState, setDefaultState] = useState(true);
  const [hoverState, setHoverState] = useState(false);
  const [clickState, setClickState] = useState(false);
  const [firstLesson, setFirstLesson] = useState(null);
  const [checkLesson, setCheckLesson] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isNotPremium, setIsNotPremium] = useState(false);
  // const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const findImage = (lessonId) => {
    var result = "";
    for (let i = 0; i < listGradeUnit?.length; i++) {
      if (listGradeUnit[i]?.lessons.includes(lessonId)) {
        result = listGradeUnit[i]?.image;
      }
    }
    return result;
  };
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");
  const checkALesson = async (id) => {
    try {
      const { result: lesson } = await getLesson(id, token);
      dispatch(setLastLesson({ ...lastLesson, order: lesson.order }));
      setCheckLesson(false);
    } catch (error) {
      if (error?.response?.status === 409) {
        setShowError(true);
      } else {
        if (
          !error?.response?.data?.errorMessage.includes(
            "Bạn không có quyền truy cập vào chương premium"
          )
        ) {
          setCheckLesson(true);
        } else {
          setIsNotPremium(true);
        }
      }
    }
  };
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const handleClick = async (lessonId) => {
    const { result: lesson } = await getLesson(lessonId, token);
    const { result } = await getListUnit(lesson?.header?.unit.id);
    if (result !== null) {
      const indexLesson =
        result?.lessons?.findIndex(
          (obj) =>
            obj._id ===
            result?.lessons?.findIndex((obj) => obj._id === lessonId)
        ) > 9
          ? result?.lessons?.findIndex((obj) => obj._id === lessonId)
          : `0` + result?.lessons?.findIndex((obj) => obj._id === lessonId);
      cookies.set("indexLesson", indexLesson);
    }
    if (lessonId !== null) {
      try {
        await dispatch(getALesson({ lessonId }));
        dispatch(setCurrentLessonProgress([lastLesson]));
        localStorage.removeItem("lesson_end_lesson");
        if (lessonId !== null) {
          cookies.set("lessonId", lessonId);
        }
        // navigate("/lesson");
        setLoadingImage(true);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleHover = () => {
    if (defaultState && !hoverState && !clickState) {
      setDefaultState(false);
      setHoverState(true);
    } else if (!defaultState && hoverState && !clickState) {
      setDefaultState(true);
      setHoverState(false);
    }
  };
  const handleClickState = () => {
    setClickState(!clickState);
    setDefaultState(clickState);
    if (clickState === true) {
      setDefaultState(false);
      setHoverState(true);
    }
  };
  const getFirstLesson = async (unitId) => {
    try {
      const { result } = await getListUnit(unitId);
      setFirstLesson(result.lessons[0]);
    } catch (error) {
      if (error?.response?.status === 409) {
        setShowError(true);
      }
    }
  };

  useEffect(() => {
    if (listGradeUnit && listGradeUnit?.length > 0) {
      getFirstLesson(listGradeUnit[0]._id);
    }
    if (lastLesson && lastLesson._id !== null) {
      checkALesson(lastLesson._id);
    }
  }, [gradeId, listGradeUnit, checkLesson, lastLesson?._id]);
  if (showError) {
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }
  if (loadingImage) {
    if (sections !== null && sections?.length > 0) {
      const result = getAllImage(sections, "LESSON");
      if (result && result?.length > 0) {
        return (
          <>
            <LoadingImage
              images={result}
              router={"lesson"}
              handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            />
          </>
        );
      } else {
        navigate("/lesson");
        // setLoadingImage(false);
      }
    }
    if (errorLesson !== null && errorLesson === 409) {
      localStorage.setItem("clearAll", true);
      return <DuplicateLoginErrorComponent isShowPopup={true} />;
    }
  }

  // console.log(listGradeUnit);
  return (
    <div
      className=" is-inline-flex is-flex-direction-column is-align-items-center "
      style={{ gap: "1.5rem" }}
    >
      <h2
        className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered"
        style={{
          fontSize: "2rem",
          lineHeight: "2.4rem",
        }}
      >
        {getGradeName(listUserInfo?.gradeName)}
      </h2>

      <div
        className={`${
          clickState
            ? "has-background-kurio-purple-light-2"
            : "has-background-kurio-purple-light-3 current-lesson"
        }  is-flex is-align-items-center is-justify-content-flex-start`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        onClick={handleClickState}
        style={{
          width: "60rem",
          borderRadius: "1rem",
          height: "15.625rem",
          backgroundSize: "cover",
          backgroundImage: `url('/images/unit/background-last-lesson.svg')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          border: "0.09375rem solid #0A2A66",
          boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
        }}
      >
        {lastLesson?._id === null ? (
          <>
            <div
              style={{
                width: "23.4375rem",
                height: "15.625rem",
                borderRadius: "0 0 0 1rem",
                backgroundSize: "cover",
              }}
            >
              <ImageFromUrl
                objectId="admin/frontend-images/unit/banner-current-unit.svg"
                alt=""
              />
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-4">
              <div
                className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-3"
                style={{ width: "30rem" }}
              >
                <span
                  className={`${hoverState && !clickState ? "" : ""} 
                  title-grade-unit__list-unit has-background-kurio-purple-light-2 has-text-weight-bold`}
                >
                  <p
                    style={{
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "30rem",
                    }}
                  >
                    {listGradeUnit?.length > 0 &&
                      jsonParser(
                        listGradeUnit[0]?.unitName,
                        0,
                        "last-lesson-unit-name"
                      )}
                  </p>
                </span>
                <div className="content-document-home-page">
                  <p
                    style={{
                      fontWeight: 700,
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "30rem",
                    }}
                    className="document-grade-unit has-text-kurio-main-color-black has-text-centered"
                  >
                    {firstLesson?.title &&
                      jsonParser(firstLesson?.title, 0, "lats-lesson-title")}
                  </p>
                </div>
              </div>
              <PrimaryButtonPageLevel
                className="column"
                style={{ height: "4rem", borderRadius: "1rem" }}
                onClick={() => handleClick(firstLesson?._id)}
              >
                <p
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "1.4rem",
                  }}
                >
                  {getConstantStringByLanguage(ConstantStrings.START_LEARNING)}
                </p>
              </PrimaryButtonPageLevel>
            </div>
          </>
        ) : (
          <>
            <div
              className="is-relative"
              style={{
                width: "23.4375rem",
                height: "15.625rem",
                borderRadius: "0 0 0 1rem",
                backgroundSize: "cover",
                // backgroundImage: `url('/images/unit/banner-current-last-lesson.svg')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
              }}
            >
              <BackgroundImageFromUrl
                objectId="admin/frontend-images/unit/background-banner-last-lesson.svg"
                style={{
                  width: "17.5rem",
                  height: "12.375rem",
                  borderRadius: "1rem",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "absolute",
                  zIndex: 1,
                  top: "1.75rem",
                  left: "2rem",
                }}
              ></BackgroundImageFromUrl>
              <div
                style={{
                  width: "8.9375rem",
                  height: "8.32414rem",
                  borderRadius: "0.25rem",
                  backgroundSize: "cover",
                  backgroundImage: `url('/images/unit/box-image-current-lesson.svg')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "absolute",
                  zIndex: 2,
                  top: "5.06rem",
                  left: "12.12rem",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  zIndex: 2,
                  top: "0rem",
                  left: "1.25rem",
                }}
              >
                <img
                  src="/images/unit/cu-Nam.svg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
              {!checkLesson && (
                <ImageFromUrl
                  objectId={findImage(
                    isNotPremium ? firstLesson?._id : lastLesson?._id
                  )}
                  style={{
                    width: "7rem",
                    height: "4.6rem",
                    position: "absolute",
                    zIndex: 2,
                    top: "7.57rem",
                    borderRadius: "0.35rem",
                    left: "13.12rem",
                  }}
                  alt=""
                />
              )}
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-4">
              {checkLesson ? (
                <div className="content-document-home-page">
                  <p
                    className="has-text-kurio-main-color-black has-text-centered"
                    style={{
                      textAlign: "left",
                      width: "31rem",
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: "140%",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.YOUR_LESSON_IS_UPDATING)}
                  </p>
                </div>
              ) : (
                <>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-4"
                    style={{ width: "30rem" }}
                  >
                    {lastLesson &&
                      lastLesson?.unitName?.length > 0 &&
                      lastLesson?.order !== undefined && (
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            lineHeight: "1rem",
                            textAlign: "center",
                            color: "#0a2a66",
                            maxWidth: "30rem",
                            padding: "0.5rem 0.75rem",
                            display: "inline-block",
                            height: "2rem",
                            borderRadius: "1rem",
                          }}
                          className={`${
                            hoverState && !clickState
                              ? "has-background-kurio-main-color-white"
                              : " has-text-kurio-main-color-black"
                          } has-background-kurio-purple-light-2 has-text-weight-bold`}
                        >
                          <p
                            style={{
                              WebkitBoxOrient: "vertical",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {isNotPremium
                              ? listGradeUnit?.length > 0 &&
                                jsonParser(
                                  listGradeUnit[0]?.unitName,
                                  0,
                                  "last-lesson-unit-name"
                                )
                              : lastLesson &&
                                lastLesson?.unitName?.length > 0 &&
                                jsonParser(
                                  lastLesson?.unitName,
                                  0,
                                  "list-unit-name"
                                )}{" "}
                          </p>
                        </span>
                      )}
                    <div className="content-document-home-page">
                      <p
                        className="has-text-kurio-main-color-black has-text-centered"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "30rem",
                          fontSize: "1.5rem",
                          fontWeight: 700,
                          lineHeight: "120%",
                        }}
                      >
                        {isNotPremium
                          ? jsonParser(
                              firstLesson?.title,
                              0,
                              "lats-lesson-title"
                            )
                          : lastLesson?.title &&
                            jsonParser(
                              lastLesson?.title,
                              0,
                              "lats-lesson-title"
                            )}
                      </p>
                    </div>
                  </div>
                  <PrimaryButtonPageLevel
                    className="column"
                    style={{ height: "4rem", borderRadius: "1rem" }}
                    onClick={() =>
                      handleClick(
                        isNotPremium ? firstLesson?._id : lastLesson._id
                      )
                    }
                  >
                    <p
                      style={{
                        fontSize: "1.125rem",
                        lineHeight: "1.4rem",
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.CONTINUE_LEARNING)}
                    </p>
                  </PrimaryButtonPageLevel>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div>
        <MainContentListUnit
          listGradeUnits={listGradeUnits}
          gradeUnitProgress={gradeUnitProgress}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
    errorLesson: state.questions.error,
    currentLesson: state.questions.currentLesson,
    lesson: state.questions.lesson,
    listGradeUnit: state.grades.grades,
    gradeId: state.grades.gradeId,
    lastLesson: state.grades.lastlesson,
    listUserInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(ListUnitContent);
