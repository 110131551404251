import ConstantStrings from "../../constants/ConstantStrings";

// export const getConstantStringByLanguage = (constantString,) => {
//   const language = sessionStorage.getItem("language");
//   if (constantString[language]) {
//     return constantString[language];
//   } else {
//     return constantString[ConstantStrings.VN_LANGUAGE];
//   }
// };
export const getConstantStringByLanguage = (constantString, ...args) => {
  const language = sessionStorage.getItem("language") || ConstantStrings.VN_LANGUAGE;
  const value = constantString[language] || constantString[ConstantStrings.VN_LANGUAGE];
  return typeof value === "function" ? value(...args) : value;
};

export const getGradeName = (gradeName)=>{
  var result = getConstantStringByLanguage(ConstantStrings.GRADE);
  if (gradeName?.length > 4) {
    result += " " + gradeName.slice(4);
  }
  return result
}

export const checkIsVn = () => {
    const language = sessionStorage.getItem("language");
    return language === ConstantStrings.VN_LANGUAGE;
  };