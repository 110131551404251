const ConstantStrings = {
  FINISHED: "Hoàn thành",
  GRADEID: "63e52faf42ec4c0907d63e14",
  IKMC_ID: "65f1161405ca2b2f1f9db971",
  HK2_ID: "65f266c978e5e505a5441505",
  HK1_ID: "671afe454743f4e3289eb28d",
  ROAD_MAP_LV1: "63e52faf42ec4c0907d63e14",
  ROAD_MAP_LV2: "63e530a500953d155a8fd0bd",
  ROAD_MAP_LV3: "63e530ac5bbafe81be69d40d",
  ROAD_MAP_LV4: "63e530b32acd470b84e46c95",
  ROAD_MAP_LV5: "63e530baf3a5d5e917183b02",
  SLIDE: "Slide",
  SECTION: "Section",
  TEMPLATE_CONTENT: "Template Content",
  SUBMIT: {
    EN: "Submit",
    VN: "Trả lời",
  },
  NEXT_QUESTION: {
    EN: "Next Question",
    VN: "Câu sau",
  },
  NEXT: {
    EN: "Next",
    VN: "Tiếp theo",
  },
  PREVIOUS: {
    EN: "Previous",
    VN: "Trước",
  },
  PREVIOUS_QUESTION: {
    EN: "Prev Question",
    VN: "Câu trước",
  },
  SKIP: {
    EN: "Skip",
    VN: "Bỏ qua",
  },
  SKIP_THIS_QUESTION: {
    EN: "Skip This Question",
    VN: "Bỏ qua câu này",
  },
  RETRY: {
    EN: "Retry",
    VN: "Thử lại",
  },
  RESET: "Reset",
  SHOW_EXPLANATION: {
    EN: "Show Explanation",
    VN: "Xem lời giải",
  },
  SHOW_QUESTION: {
    EN: "Show Question",
    VN: "Xem bài làm",
  },
  REQUEST_FILL_IN_ALL_GAPS: "Please fill in all gaps",
  MULTIPLIERIMAGE: 0.25,
  BASE_REM: 16,
  OTP_IS_INCORRECT: "Sai mã OPT",
  DO_NOT_LEAVE_BLANK: {
    VN: "Vui lòng không để trống",
    EN: "Please do not leave blank",
  },
  PASSWORD_INCORRECT: {
    VN: "Mật khẩu không khớp",
    EN: "Password does not match",
  },
  PASSWORD_CHECK:
    "Mật khẩu phải có ít nhất 6 ký tự. Mật khẩu phải bao gồm chữ và số.",
  PASSWORD_NEW_ERR: {
    VN: "Mật khẩu mới không được giống mật khẩu cũ",
    EN: "The new password must not be the same as the old password",
  },
  PASSWORD_OLD: {
    VN: "Mật khẩu cũ",
    EN: "Old password",
  },
  PASSWORD_NEW: {
    VN: "Mật khẩu mới",
    EN: "New password",
  },
  CONFIRM_PASSWORD_NEW: {
    VN: "Nhập lại mật khẩu mới",
    EN: "Confirm new password",
  },
  PASSWORD_CHANGE_SUCCESS : {
    VN: (email) => (
      <>
        Bạn đã hoàn thành đổi mật khẩu cho tài khoản <br />
        <span>{email}</span>.
      </>
    ),
    EN: (email) => (
      <>
        You have successfully changed password for the account <br />
        <span>{email}</span>.
      </>
    ),
  },
  FORGOT_PASSWORD: {
    VN: "Quên mật khẩu",
    EN: "Forgot password",
  },
  TIME_OUT: "Hết thời gian vui lòng bấm gửi lại để nhận mã OTP mới",
  VNPAY_CONTENT: "Cổng thanh toán VNPAY",
  DIRECT_TRANFER_CONTENT:
    "Thanh toán bằng cách chuyển trực tiếp vào số tài khoản của KURIO sau đó liên lạc với bộ phận chăm sóc khách hàng để xác nhận.",
  SERVICE_CONTENT:
    "Truy cập toàn bộ bài giảng, bài tập và các mục trong phần hoạt động trong ",
  UPGRADE_SUCCESS: {
    EN: "Upgrade account successfully",
    VN: "Nâng cấp tài khoản thành công",
  },
  PAYMENT_FAILED: {
    VN: "Vui lòng thanh toán bằng cách chuyển khoản trực tiếp hoặc liên hệ với bộ phận chăm sóc khách hàng để được tư vấn.",
    EN: "Please pay by direct transfer or contact with customer service for assistance.",
  },
  PAYMENT_FAILED_KURIO:{
    EN: "Please try again or contact Kurio's customer support for assistance.",
    VN: "Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của Kurio để được hỗ trợ.",
  },
  DATA_SITE_KEY: "6LfNQnckAAAAAConbJxXRdzYPGv_FnYMajhagYts",
  HIDE_GRADES: ["Lớp 6", "Lớp 7", "Lớp 8", "Lớp 9"],
  ARRAY_LANDING_PAGE_PATHS: [
    "/payment-policy",
    "/privacy-policy",
    "/return-policy",
    "/payment-methods",
    "/policy",
    "/kho-tro-choi",
    "/kho-tro-choi/day-so-bi-an",
    "/kho-tro-choi/to-mau",
    "/kho-tro-choi/bai-toan-noi",
    "/flutter/",
    "/elocalibration",
    "/ios",
    "/android",
    "/policy",
    "/register-mobile",
    "/payment-success",
    "/feedback2025",
    "/commitment"
  ],
  IMAGE_URL:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_IMAGE_URL
      : process.env.REACT_APP_IMAGE_URL,
  GA_MEASUREMENT_ID:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_GA_MEASUREMENT_ID
      : process.env.REACT_APP_GA_MEASUREMENT_ID,

  GRADES_USE_CATEGORY_ROADMAP_1: ["Lớp 1", "Lớp 2", "Lớp 3", "Lớp 4", "Lớp 5"],
  GRADES_USE_CATEGORY_ROADMAP_2: ["Lớp 6", "Lớp 7", "Lớp 8", "Lớp 9"],
  DEFAULT_ACHIEVEMENT_IMAGE: "/images/achievement/achievement-default.png",
  DEFAULT_IMAGE: "/images/landing-page/logo-mobile.svg",
  VN_MATH_SUBJECT_ID: "67b5920cfb6c4b8a723368d8",
  EN_MATH_SUBJECT_ID: "67b5921bfb6c4b8a723368da",
  VN_LANGUAGE: "VN",
  EN_LANGUAGE: "EN",
  CONTINUE: {
    EN: "Continue",
    VN: "Tiếp tục",
  },
  CONTINUE_LEARNING: {
    EN: "Continue",
    VN: "Học tiếp",
  },
  CHOOSE_EXERCISE: {
    EN: "Choose Exercise",
    VN: "Chọn bài",
  },
  CHOOSE: {
    EN: "Choose",
    VN: "Chọn",
  },
  UNIT: {
    EN: "Unit",
    VN: "Chương",
  },
  COMPLETED: {
    EN: "Completed",
    VN: "Hoàn thành",
  },
  NEW: {
    EN: "New",
    VN: "MỚI",
  },
  ON_GOING: {
    EN: "On Going",
    VN: "Đang Học",
  },
  START: {
    EN: "Start",
    VN: "Khởi động",
  },
  TRY_AGAIN: {
    EN: "Retry",
    VN: "Làm Lại",
  },
  PLAY_NOW: {
    EN: "Play Now",
    VN: "Chơi ngay",
  },
  // đổi lớp
  CHANGE_GRADE: {
    EN: "Change Grade",
    VN: "Đổi lớp",
  },
  LOADING: {
    EN: "Loading",
    VN: "Đang tải",
  },
  EXIT: {
    EN: "Exit",
    VN: "Thoát",
  },
  LESSON: {
    EN: "Lesson",
    VN: "Bài",
  },
  TRY_NOW: {
    EN: "Try Now",
    VN: "Làm ngay",
  },
  START_LEARNING: {
    EN: "Start Learning",
    VN: "Bắt đầu học",
  },
  LOCK: {
    EN: "Lock",
    VN: "Khóa",
  },
  BACK_TO_LIST_UNIT: {
    EN: "Back to list unit",
    VN: "Về danh sách chương",
  },
  VIEW_MORE: {
    EN: "View More",
    VN: "Xem thêm",
  },
  PRACTICE: {
    EN: "Practice",
    VN: "Luyện tập",
  },
  GOAL: {
    EN: "Goal",
    VN: "Về đích",
  },
  QUESTION: {
    EN: "Question",
    VN: "Câu",
  },
  ELORIO: {
    EN: "Elorio",
    VN: "Điểm Elorio",
  },
  PRACTICE_IN_SELECTED_LESSONS: {
    EN: "Practice within the scope of selected Lessons",
    VN: "Luyện tập trong phạm vi các Bài giảng được chọn.",
  },
  PERSONALIZED_QUESTIONS: {
    EN: "Personalized question sets based on student level, powered by AI.",
    VN: "Bộ câu hỏi cá nhân hóa theo trình độ học sinh, với sự hỗ trợ của AI.",
  },
  HI: {
    EN: "Hi",
    VN: "Xin chào",
  },
  GRADE: {
    EN: "Grade",
    VN: "Lớp",
  },
  HOME: {
    EN: "Home",
    VN: "Trang chủ",
  },
  LESSONS: {
    EN: "Lessons",
    VN: "Bài giảng",
  },
  COMPLETED_EXERCISE: {
    EN: "Complete exercise and get more points!",
    VN: "Hoàn thành các bài tập thú vị và kiếm thêm điểm số!",
  },
  REPEAT_AND_REVIEW: {
    EN: "Repeat and review the knowledge learned in class",
    VN: "Ôn lại và đào sâu các kiến thức được học trên lớp.",
  },
  YOUR_LESSON_IS_UPDATING: {
    EN: "Your lesson is updating.",
    VN: "Bài bằn đang học đang được cập nhật.",
  },
  TRY: {
    EN: "Try",
    VN: "Thử sức",
  },
  ACCOUNT: {
    EN: "Account",
    VN: "Tài khoản",
  },
  UPGRADE: {
    EN: "Upgrade",
    VN: "Nâng cấp",
  },
  ACHIEVEMENT: {
    EN: "Achievement",
    VN: "Thành tích",
  },
  PROFILE: {
    EN: "Profile",
    VN: "Sổ liên lạc",
  },
  CONTACT_US: {
    EN: "Contact Kurio via Zalo QR",
    VN: "Liên hệ với Kurio qua Zalo QR",
  },
  OR_ZALO: {
    EN: "or Zalo",
    VN: "hoặc Zalo",
  },
  END_LESSON: {
    EN: "End Lesson",
    VN: "Kết thúc bài học",
  },
  PASSWORD: {
    EN: "Password",
    VN: "Mật khẩu",
  },
  NAME: {
    EN: "Name",
    VN: "Tên",
  },
  PHONE_NUMBER: {
    EN: "Phone number",
    VN: "Số diện thoại",
  },
  CHANGE_PASSWORD: {
    EN: "Change Password",
    VN: "Đổi mật khẩu",
  },
  EXPLAIN: {
    EN: "Explain",
    VN: "Giải thích",
  },
  LESSON_INFORMATION: {
    EN: "Lesson Information",
    VN: "Thông tin bài học",
  },
  CONGRATULATION: {
    EN: "Congratulation!",
    VN: "Chúc mừng!",
  },
  COMPLETED_LESSON: {
    EN: "You have completed the lesson",
    VN: "Bạn đã hoàn thành bài giảng",
  },
  COMPLETE_LESSON: {
    EN: "Complete Lesson",
    VN: "Hoàn thành bài giảng",
  },
  BACK_TO_UNIT: {
    EN: "Back to unit",
    VN: "Về chương",
  },
  VIEW_AGAIN: {
    EN: "View Again",
    VN: "Xem lại",
  },
  WANT_TO_VIEW_AGAIN: {
    EN: "Do you want to review this lesson again or restart from the beginning?",
    VN: "Bạn muốn xem lại kiến thức trong bài hay học lại từ đầu?",
  },
  REPEAT_FROM_BEGINNING: {
    EN: "Restart",
    VN: "Học lại từ đầu",
  },
  ANSWER: {
    EN: "Answer",
    VN: "Đáp án",
  },
  PREVIOUSLY: {
    EN: "Previously",
    VN: "Lần trước",
  },
  COMPLETED_ALL_EXERCISE: {
    EN: "You have completed all the exercises of the selected section.",
    VN: "Bạn đã hoàn thành toàn bộ số bài tập hiện có của phần đã chọn.",
  },
  END: {
    EN: "End",
    VN: "Kết thúc",
  },
  SLEEP_TIGHT: {
    EN: (count) => `Take a break! You have completed ${count} questions!`,
    VN: (count) => `Nghỉ ngơi chút nào! Bạn đã làm tới ${count} câu rồi!`,
  },
  ALL_UNIT: {
    EN: "All Unit",
    VN: "Tất cả chương",
  },
  ALL_LESSON: {
    EN: "All Lesson",
    VN: "Tất cả bài học",
  },
  SELECT_UNIT: {
    EN: "Select the unit you want to practice",
    VN: "Chọn các chương bạn muốn luyện tập",
  },
  STEP: {
    EN: "Step",
    VN: "Bước",
  },
  // luyện tập tự chọn
  OPTIONAL_PRACTICE: {
    EN: "Elective Practice",
    VN: "Luyện tập Tự chọn",
  },
  // quay lại
  BACK: {
    EN: "Back",
    VN: "Quay lại",
  },
  // Chọn các bài bạn muốn luyện tập
  SELECT_LESSON: {
    EN: "Select the lesson you want to practice",
    VN: "Chọn các bài bạn muốn luyện tập",
  },
  //Chọn độ khó câu hỏi
  SELECT_DIFFICULTY: {
    EN: "Select the difficulty of the question",
    VN: "Chọn độ khó của câu hỏi",
  },
  // bắt đầu
  START_PRACTICE: {
    EN: "Start",
    VN: "Bắt đầu",
  },
  // tất cả
  ALL: {
    EN: "All",
    VN: "Tất cả",
  },
  // Bạn đã làm xong <br /> chuỗi 10 câu hỏi <br /> Luyện tập Thông minh!
  COMPLETE_10_QUESTION: {
    EN: (
      <>
        You have completed <br /> 10 questions <br /> in Elorio practice!
      </>
    ),
    VN: (
      <>
        Bạn đã làm xong <br /> chuỗi 10 câu hỏi <br /> Luyện tập Thông minh!
      </>
    ),
  },
  // Làm đúng
  CORRECT: {
    EN: "Correct",
    VN: "Làm đúng",
  },
  // Tiếp tục làm bài và tăng hạng Elorio?
  WANT_TO_CONTINUE: {
    EN: "Continue doing exercises and increase Elorio rank?",
    VN: "Tiếp tục làm bài và tăng hạng Elorio?",
  },
  //Xem 10 câu tiếp theo
  VIEW_NEXT_10_QUESTION: {
    EN: "View next 10 questions",
    VN: "Xem 10 câu tiếp theo",
  },
  // Hoàn thành xuất sắc 10 câu hỏi
  EXCELLENT_10_QUESTION: {
    EN: (
      <>
        Completed excellently <br /> 10 questions <br /> in Elorio practice!
      </>
    ),
    VN: (
      <>
        Hoàn thành xuất sắc <br /> 10 câu hỏi <br /> Luyện tập Thông minh!
      </>
    ),
  },
  // đăng xuất
  LOGOUT: {
    EN: "Logout",
    VN: "Đăng xuất",
  },
  // mã voucher
  VOUCHER_CODE: {
    EN: "Voucher code",
    VN: "Mã voucher",
  },
  // nhập mã voucher
  INPUT_VOUCHER_CODE: {
    EN: "Input voucher code",
    VN: "Nhập mã voucher",
  },
  // xác nhận
  CONFIRM: {
    EN: "Confirm",
    VN: "Xác nhận",
  },
  // chỉnh sửa
  EDIT: {
    EN: "Edit",
    VN: "Chỉnh sửa",
  },
  // lưu thay đổi
  SAVE_CHANGE: {
    EN: "Save change",
    VN: "Lưu thay đổi",
  },
  // tải ảnh lên
  UPLOAD_IMAGE: {
    EN: "Upload image",
    VN: "Tải lên ảnh khác",
  },
  // thay ảnh
  CHANGE_IMAGE: {
    EN: "Change image",
    VN: "Thay ảnh",
  },
  // Hoặc chọn ảnh đại diện
  OR_SELECT_AVATAR: {
    EN: "Or select avatar",
    VN: "Hoặc chọn ảnh đại diện",
  },
  // thông tin cá nhân
  PERSONAL_INFORMATION: {
    EN: "Personal information",
    VN: "Thông tin cá nhân",
  },
  // tài khoản vip
  VIP_ACCOUNT: {
    EN: "VIP account",
    VN: "Tài khoản VIP",
  },
  // tài khoản miễn phí
  FREE_ACCOUNT: {
    EN: "Free account",
    VN: "Tài khoản miễn phí",
  },
  // hết hạn
  EXPIRED: {
    EN: "Expired",
    VN: "Hết hạn",
  },
  // học sinh
  STUDENT: {
    EN: "Student",
    VN: "Học sinh",
  },
  // phụ huynh
  PARENT: {
    EN: "Parent",
    VN: "Phụ huynh",
  },
  // xóa tài khoản
  DELETE_ACCOUNT: {
    EN: "Delete account?",
    VN: "Xóa tài khoản?",
  },
  // tên bố
  FATHER_NAME: {
    EN: "Father name",
    VN: "Tên bố",
  },
  // SỐ ĐIỆN THOẠI cỦA BỐ
  FATHER_PHONE_NUMBER: {
    EN: "Father phone number",
    VN: "Số điện thoại bố",
  },
  // email bố
  FATHER_EMAIL: {
    EN: "Father email",
    VN: "Email bố",
  },
  // tên mẹ
  MOTHER_NAME: {
    EN: "Mother name",
    VN: "Tên mẹ",
  },
  // SỐ ĐIỆN THOẠI cỦA mẹ
  MOTHER_PHONE_NUMBER: {
    EN: "Mother phone number",
    VN: "Số điện thoại mẹ",
  },
  // email mẹ
  MOTHER_EMAIL: {
    EN: "Mother email",
    VN: "Email mẹ",
  },
  // Chúng tôi không hỗ trợ định dạng file này !
  NOT_SUPPORT_FILE: {
    EN: "We do not support this file format.",
    VN: "Chúng tôi không hỗ trợ định dạng file này.",
  },
  //file size của ảnh quá lớn
  FILE_SIZE: {
    EN: "File size of image is too large.",
    VN: "Dung lượng file hình ảnh quá lớn.",
  },
  // Số điện thoại không hợp lệ
  INVALID_PHONE_NUMBER: {
    EN: "Invalid phone number.",
    VN: "Số điện thoại không hợp lệ.",
  },
  //Bạn không thể thay đổi số điện thoại
  CANT_CHANGE_PHONE_NUMBER: {
    EN: "You can't change phone number. Contact customer service for support.",
    VN: "Bạn không thể thay đổi số điện thoại. Liên lạc CSKH để được hỗ trợ.",
  },
  // chỉnh sửa thành công
  EDIT_SUCCESS: {
    EN: "Edit successfully.",
    VN: "Chỉnh sửa thành công.",
  },
  // hủy
  CANCEL: {
    EN: "Cancel",
    VN: "Hủy",
  },
  // ngày sinh
  BIRTHDAY: {
    EN: "Birthday",
    VN: "Ngày sinh",
  },
  // bài thi
  EXAM: {
    EN: "Exams",
    VN: "Bài thi",
  },
  // xếp hạng
  RANK: {
    EN: "Rank",
    VN: "Xếp hạng",
  },
  //Chuyên cần
  DILIGENCE: {
    EN: "Diligence",
    VN: "Chuyên cần",
  },
  // Hạ gục Quái vật Tháng {new Date().getMonth() + 1}
  DEFEAT_MONSTER: {
    EN: (month) => `Defeat the Monster of Month ${month}`,
    VN: (month) => `Hạ gục Quái vật Tháng ${month}`,
  },
  // điểm tích lũy
  XP_SCORE: {
    EN: "XP Score",
    VN: "Điểm tích lũy",
  },
  // Bắt đầu hành trình thu thập các Huy hiệu Đặc biệt của Kurio nào!
  START_COLLECTING: {
    EN: "Start collecting special Kurio badges!",
    VN: "Bắt đầu hành trình thu thập các Huy hiệu Đặc biệt của Kurio nào!",
  },
  //Hoàn thành Bài giảng để mở khóa các Huy hiệu mới!
  COMPLETE_LESSON_ACHIEVEMENT: {
    EN: "Complete lesson to unlock new Kurio badges!",
    VN: "Hoàn tích Bài giảng để mở khóa các Huy hiệu mới!",
  },
  // Đánh bại Quái vật Tháng để thu thập các Huy hiệu Đặc biệt!
  FIGHT_MONSTER: {
    EN: "Fight the Monster of Month to collect special Kurio badges!",
    VN: "Đánh bại Quái vật Tháng để thu thập các Huy hiệu Đặc biệt!",
  },
  // Chăm chỉ Luyện tập để thu thập các Huy hiệu Đặc biệt!
  COLLECT_BADGES: {
    EN: "Practice diligently to collect Special Badges!",
    VN: "Chăm chỉ Luyện tập để thu thập các Huy hiệu Đặc biệt!",
  },
  RANK_HIGH_EXAM: {
    EN: "Achieve a high rank in the Monthly Exam to collect Special Badges!",
    VN: "Đạt thứ hạng cao trong kỳ Thi tháng để thu thập các Huy hiệu Đặc biệt!",
  },
  // chiến đấu ngay
  FIGHT_NOW: {
    EN: "Fight now",
    VN: "Chiến đấu ngay",
  },
  // về trang chủ
  BACK_TO_HOME: {
    EN: "Back to Home",
    VN: "Về trang chủ",
  },
  // Tiếp tục trải nghiệm và khám phá các bài học thú vị trên KURIO!
  EXPLORE_LESSON: {
    EN: "Explore and experience various lessons on KURIO!",
    VN: "Tiếp tục trải nghiệm và khám phá các bài học thú vị trên KURIO!",
  },
  // Bài giảng đã học
  LESSON_LEARNED: {
    EN: (
      <>
        Lessons <br /> learned{" "}
      </>
    ),
    VN: (
      <>
        Bài giảng <br /> đã học{" "}
      </>
    ),
  },
  // Bài tập đã làm
  EXERCISE_DONE: {
    EN: (
      <>
        Exercises <br /> done
      </>
    ),
    VN: (
      <>
        Bài tập <br /> đã làm
      </>
    ),
  },
  // chuỗi ngày đăng nhập liên tiếp
  CONSECUTIVE_LOGIN: {
    EN: (
      <>
        Consecutive <br /> login streak:
      </>
    ),
    VN: (
      <>
        Chuỗi ngày <br /> đăng nhập liên tiếp:
      </>
    ),
  },
  // Xếp hạng thi tháng
  RANK_MONTHLY_EXAM: {
    EN: "Monthly Exam Rank",
    VN: "Xếp hạng thi tháng",
  },
  // tháng
  MONTH: {
    EN: "Month",
    VN: "Tháng",
  },
  // chọn gói
  SELECT_PACKAGE: {
    EN: "Select Package",
    VN: "Chọn gói",
  },
  // phương thức
  METHOD: {
    EN: "Method",
    VN: "Phương thức",
  },
  // thanh toán
  PAYMENT: {
    EN: "Payment",
    VN: "Thanh toán",
  },
  // trạng thái hiện tại
  CURRENT_STATUS: {
    EN: "Current Status",
    VN: "Trạng thái hiện tại",
  },
  // năm
  YEAR: {
    EN: "Year",
    VN: "Năm",
  },
  // trải nghiệm
  EXPERIENCE: {
    EN: "Experience",
    VN: "Trải nghiệm",
  },
  // ưu đãi
  PROMOTION: {
    EN: "Promotion",
    VN: "Ưu đãi",
  },
  // đề xuất
  SUGGESTION: {
    EN: "Suggestion",
    VN: "Đề xuất",
  },
  // tiết kiệm
  SAVE: {
    EN: "Save",
    VN: "Tiết kiệm",
  },
  // chi tiết về gói kurio max
  DETAIL_PACKAGE_KURIO_MAX: {
    EN: "Detail about Kurio Max Package",
    VN: "Chi tiết về gói Kurio Max",
  },
  // quyền lợi gói kurio max
  KURIO_MAX_BENEFITS: {
    EN: "Benefits of Package ",
    VN: "Quyền lợi gói ",
  },
  // so sánh tính năng
  COMPARE_ABILITY: {
    EN: "Compare Ability",
    VN: "So sánh tính năng",
  },
  // đóng
  CLOSE: {
    EN: "Close",
    VN: "Đóng",
  },
  // luyện tập thông minh
  SMART_PRACTICE: {
    EN: "Elorio Practice",
    VN: "Luyện tập Thông minh",
  },
  // bài giảng sáng tạo
  CREATIVE_LESSON: {
    EN: "Creative Lesson",
    VN: "Bài giảng sáng tạo",
  },
  // Trải nghiệm TOÀN BỘ các tính năng của KURIO không giới hạn!
  EXPLORE_ABILITY: {
    EN: "Explore ALL of Kurio without limits!",
    VN: "Trải nghiệm TOÀN BỘ các tính năng của KURIO không giới hạn!",
  },
  // Cổ tích Logic
  LOGIC_FAIRY_TALE: {
    EN: "Logic Fairy Tale",
    VN: "Cổ tích Logic",
  },
  // Luyện thi toàn diện
  COMPREHENSIVE_EXAM_PREP: {
    EN: "Comprehensive Exam Preparation",
    VN: "Luyện thi toàn diện",
  },
  EXCITING_QUEST: {
    EN: "Exciting Quest",
    VN: "Nhiệm vụ kỳ thú",
  },
  AI_MATH_SOLVER: {
    EN: "Solve Math with AI",
    VN: "Giải toán cùng AI",
  },
  TRANSFER_CONFIRMED: {
    EN: (
      <>
        I HAVE TRANSFERRED <br /> AS INSTRUCTED
      </>
    ),
    VN: (
      <>
        TÔI ĐÃ CHUYỂN KHOẢN <br /> ĐÚNG NHƯ HƯỚNG DẪN
      </>
    ),
  },
  // Gói 6 tháng
  PACKAGE_6_MONTH: {
    EN: "6 Month",
    VN: "Gói 6 tháng",
  },
  // Gói 2 năm
  PACKAGE_2_YEAR: {
    EN: "2 Year",
    VN: "Gói 2 năm",
  },
  // Gói 1 năm
  PACKAGE_1_YEAR: {
    EN: "1 Year",
    VN: "Gói 1 năm",
  },
  // hình thức thanh toán
  PAYMENT_METHOD: {
    EN: "Payment Method",
    VN: "Hình thức thanh toán",
  },
  // loại
  TYPE: {
    EN: "Type",
    VN: "Loại",
  },
  // giá
  PRICE: {
    EN: "Price",
    VN: "Giá",
  },
  // gói đang chọn
  SELECTED_PACKAGE: {
    EN: "Selected Package",
    VN: "Gói đang chọn",
  },
  // chuyển khoản ngân hàng
  TRANSFER_BANK: {
    EN: "Bank Transfer",
    VN: "Chuyển khoản ngân hàng",
  },
  // thanh toán qua VNPAY
  PAYMENT_VNPAY: {
    EN: "Payment via VNPAY",
    VN: "Thanh toán qua VNPAY",
  },
  // thanh toán qua
  PAY_VIA: {
    EN: "Pay via",
    VN: "Thanh toán qua",
  },
  // chuyển khoản trực tiếp
  TRANSFER_DIRECTLY: {
    EN: "Transfer directly",
    VN: "Chuyển khoản trực tiếp",
  },
  // Mã QR hoặc thẻ ngân hàng
  QR_CODE_OR_BANK_CODE: {
    EN: (
      <>
        QR CODE <span style={{ fontSize: "1.25rem" }}>or</span>
        <br></br> Bank account number
      </>
    ),
    VN: (
      <>
        Mã QR <span style={{ fontSize: "1.25rem" }}>hoặc</span>
        <br></br> Thẻ Ngân hàng
      </>
    ),
  },
  DIRECT_TRANSFER_PAYMENT: {
    EN: "Make a direct transfer to KURIO's account, then contact customer support for confirmation.",
    VN: "Thanh toán bằng cách chuyển trực tiếp vào số tài khoản của KURIO sau đó liên lạc với bộ phận chăm sóc khách hàng để xác nhận.",
  },
  PAY_VIA_VNPAY: {
    EN: (
      <>
        Pay via <br /> VNPAY payment gateway
      </>
    ),
    VN: (
      <>
        Thanh toán qua <br /> cổng thanh toán VNPAY
      </>
    ),
  },
  CONTINUE_PAYMENT: {
    EN: "Continue payment",
    VN: "Tiếp tục thanh toán",
  },
  KURIO_ADDRESS: {
    EN: (
      <>
        No. 128, Nguyen Thai Hoc Street, <br /> Dien Bien Ward, Ba Dinh
        District, Hanoi
      </>
    ),
    VN: (
      <>
        Số 128, phố Nguyễn Thái Học, <br /> phường Điện Biên, quận Ba Đình, Hà
        Nội
      </>
    ),
  },
  // Lưu ý
  NOTE: {
    EN: "Note",
    VN: "Lưu ý",
  },
  // Xin hãy chuyển khoản đúng như hướng dẫn!
  PLEASE_TRANSFER_AS_INSTRUCTED: {
    EN: "Please transfer as instructed!",
    VN: "Xin hãy chuyển khoản đúng như hướng dẫn!",
  },
  AFTER_TRANSFER_CONTACT_SUPPORT: {
    EN: "After making the transfer, please contact Customer Support to complete the Account Upgrade process.",
    VN: "Sau khi chuyển khoản Quý khách vui lòng liên hệ với Bộ phận Chăm sóc Khách hàng để hoàn tất thủ tục Nâng cấp Tài khoản.",
  },
  // Chủ tài khoản
  ACCOUNT_OWNER: {
    EN: "Account Owner",
    VN: "Chủ tài khoản",
  },
  // sao chép
  COPY: {
    EN: "Copy",
    VN: "Sao chép",
  },
  // nội dung chuyển khoản
  TRANSFER_CONTENT: {
    EN: "Transfer Content",
    VN: "Nội dung chuyển khoản",
  },
  // số tài khoản
  BANK_ACCOUNT_NUMBER: {
    EN: "Bank Account Number",
    VN: "Số tài khoản",
  },
  TRANSFER_AMOUNT: {
    EN: "Transfer Amount",
    VN: "Số tiền chuyển khoản",
  },
  // ngân hàng
  BANK: {
    EN: "Bank",
    VN: "Ngân hàng",
  },
  // gọi điện
  CALL: {
    EN: "Call",
    VN: "Gọi điện",
  },
  CONTACT_SUPPORT_FOR_VIP_UPGRADE: {
    EN: (
      <>
        Please contact customer support via Zalo or through the hotline{" "}
        <b>0971 500 120</b> and follow the instructions to complete the direct
        transfer confirmation process and successfully upgrade to a VIP account.
      </>
    ),
    VN: (
      <>
        Xin hãy liên lạc với bộ phận chăm sóc khách hàng qua Zalo hoặc qua số
        hotline <b>0971 500 120</b> và làm theo hướng dẫn để hoàn thành thủ tục
        xác nhận chuyển khoản trực tiếp và thực hiện nâng cấp tài khoản VIP
        thành công.
      </>
    ),
  },
  CONTACT_SUPPORT: {
    EN: (
      <>
        Please contact customer support via Zalo or through the hotline{" "}
        <b>0971 500 120</b>.
      </>
    ),
    VN: (
      <>
        Xin hãy liên lạc với bộ phận chăm sóc khách hàng qua Zalo hoặc qua số
        hotline <b>0971 500 120</b>.
      </>
    ),
  },
  PAYMENT_ERROR_VNPAY: {
    EN: (
      <>
        An error occurred while <br /> processing payment via VNPAY.
      </>
    ),
    VN: (
      <>
        Có lỗi đã xảy ra khi <br /> thanh toán qua VNPAY.
      </>
    ),
  },
  // ngày hết hạn
  EXPIRED_DATE: {
    EN: "Expired Date",
    VN: "Ngày hết hạn",
  },
  UPGRADE_COMPLETED: {
    EN: (phone) => (
      <>
        You have successfully upgraded the phone number{" "}
        <span className="has-text-weight-bold">{phone}</span>.
      </>
    ),
    VN: (phone) => (
      <>
        Bạn đã hoàn thành nâng cấp cho số điện thoại{" "}
        <span className="has-text-weight-bold">{phone}</span>.
      </>
    ),
  },
  // Có lỗi đã xảy ra...
  ERROR: {
    EN: "An error occurred...",
    VN: "Có lỗi bạn xảy ra...",
  },
  // chọn lớp
  SELECT_GRADE: {
    EN: "Select Grade",
    VN: "Chọn lớp",
  },
  // Elorio text
  ELORIO_TEXT_URL: {
    EN: "/images/exercise/elorio-text-en.svg",
    VN: "/images/exercise/text.svg",
  },
  // elective text
  ELECTIVE_TEXT_URL: {
    EN: "/images/exercise/elective-text-en.svg",
    VN: "/images/exercise/text-background2.svg",
  },
  // Tính năng dành riêng cho
  EXCLUSIVE_FEATURE: {
    EN: "Exclusive feature for",
    VN: "Tính năng dành riêng cho",
  },
  UPGRADE_ACCOUNT: {
    EN: (
      <>
        Please <span className="has-text-weight-bold">Upgrade Your Account</span>{" "}
        to unlock the full power of
        <span className="has-text-weight-bold"> KURIO!</span>
      </>
    ),
    VN: (
      <>
        Hãy <span className="has-text-weight-bold">Nâng cấp Tài khoản</span> để khai phá toàn bộ sức mạnh của
        <span className="has-text-weight-bold"> KURIO!</span>
      </>
    ),
  },
  GO_TO_UPGRADE_PAGE: {
    EN: "Go to Upgrade Page",
    VN: "Đến trang nâng cấp",
  },
  // btn premium url
  BTN_PREMIUM_URL: {
    EN: "/images/hub/btn-premium-en.svg",
    VN: "/images/hub/btn-premium.svg",
  },
  // elective url
  ELECTIVE_NAV_URL: {
    EN: "admin/frontend-images/exercise/practice-elective-nav-en.svg",
    VN: "admin/frontend-images/exercise/practice-elective-nav.svg",
  },
  // practice smart nav url
  PRACTICE_SMART_NAV_URL: {
    EN: "admin/frontend-images/exercise/practice-smart-nav-en.svg",
    VN: "admin/frontend-images/exercise/practice-smart-nav.svg",
  },
  PREMIUM_REGISTRATION: {
    EN: (
      <>
        <b>You have registered a Premium account via the mobile app</b>
        <br />
        If you need further assistance, please contact <b>Customer Support</b>.
      </>
    ),
    VN: (
      <>
        <b>Bạn đã đăng ký tài khoản Premium qua ứng dụng di động</b>
        <br />
        Nếu bạn cần hỗ trợ thêm, vui lòng liên hệ với <b>Chăm sóc khách hàng</b>.
      </>
    ),
  },
  SUPPORT_ASSISTANCE: {
    EN: (
      <>
        If you need further assistance, please contact <b>Customer Support</b>.
      </>
    ),
    VN: (
      <>
        Nếu bạn cần hỗ trợ thêm, vui lòng liên hệ với <b>Chăm sóc khách hàng</b>.
      </>
    ),
  },
  CURRENT_TEST_PREP_PLAN: {
    EN: "You are on the TEST PREP plan and it is still active.",
    VN: "Bạn đang ở gói TEST PREP và vẫn còn hạn.",
  },
  PREMIUM_ACCOUNT_ACTIVE: {
    EN: "You are already a Premium account.",
    VN: "Bạn đã đăng ký tài khoản Premium và vẫn còn hạn sử dụng.",
  },
  TERMS_AGREEMENT: {
    EN: (
      <>
        I have read and agree to the <b>Terms of Service</b>.
      </>
    ),
    VN: (
      <>
        Tôi đã đọc và đồng ý với <b>Điều khoản Sử dụng</b>.
      </>
    ),
  },
  ACCOUNT_TERMS_OF_USE: {
    EN: "Kurio Account Terms of Use",
    VN: "Điều khoản Sử dụng Tài khoản Kurio",
  },
  ACCOUNT_TERMS_OF_USE_CONTENT:{
    EN: (
      <>
        The <b>Kurio Account Terms of Use</b> (hereinafter referred to as the
        "Terms of Use") is an electronic agreement/contract that establishes
        and binds the rights and obligations between <b>IKEMSO Global Joint
        Stock Company</b> ("Company") and Customers when using the services
        and products provided by the Company. <br /> <br />
        To access and use a Kurio account, Customers are required to carefully
        review and confirm their agreement to these Terms of Use before
        proceeding, under the following terms and conditions: <br />
      </>
    ),
    VN: (
      <>
        <b>Điều khoản sử dụng tài khoản Kurio</b> (sau đây gọi tắt là “Điều
        khoản sử dụng”) là một thỏa thuận/hợp đồng điện tử được thiết lập và
        ràng buộc các quyền và nghĩa vụ giữa <b>CÔNG TY CỔ PHẦN IKEMSO TOÀN
        CẦU</b> (“Công ty”) và Khách hàng khi Khách hàng sử dụng dịch vụ, sản
        phẩm mà Công ty cung cấp. <br /> <br />
        Để truy cập và sử dụng tài khoản Kurio, Khách hàng vui lòng xem xét
        cẩn trọng và xác nhận đồng ý với Điều khoản sử dụng này trước khi bắt
        đầu sử dụng tài khoản Kurio với các điều khoản và điều kiện sau đây:{" "}
        <br />
      </>
    ),
  },
  TERMS_OF_USE: {
    EN: (
      <>
        <h2 className="has-text-left" style={{ color: "#272266", fontWeight: 700 }}>
          1. Terms of Use
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          By using a Kurio account, Customers agree to comply with all the following terms:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>When registering an account, learners must provide complete and accurate information such as Name, Email, Phone Number... This information will be used as a basis to support resolving any arising issues during their studies at Kurio.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>When creating an account on Kurio, Customers agree to receive emails updating new features and articles, as well as promotional emails from Kurio. Kurio will provide an unsubscribe link at the end of the email for Customers to opt out.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Customers will not access Kurio using automated programs.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>During the use of a Kurio account, all data, including learning results, study history, learning materials, images, audio, and all other data created or collected by Kurio through the Customer's learning process, whether free or not, shall be the absolute and exclusive property of Kurio.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Customers shall not copy, distribute, or publish any Kurio materials, whether free or not, without Kurio’s written consent. However, Customers may share links to those materials.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Account password: After registration, learners are provided with an account and password. The password is used to log in to Kurio.vn and Kurio’s system services. Learners are responsible for keeping their passwords secure. If the password is exposed in any way, Kurio will not be liable for any resulting losses.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>If Customers violate any of the above terms of use, Kurio reserves the right to permanently suspend their accounts, which may prevent them from using the products or services they have purchased on Kurio (if applicable).
        </p>
      </>
    ),
    VN: (
      <>
        <h2 className="has-text-left" style={{ color: "#272266", fontWeight: 700 }}>
          1. Các điều khoản về việc sử dụng
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Với việc sử dụng tài khoản Kurio, Khách hàng đồng ý tuân theo tất cả những điều khoản dưới đây:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Khi đăng ký tài khoản, học viên phải cung cấp đầy đủ và chính xác thông tin về Tên, Email, Số điện thoại... Những thông tin này sẽ được dùng làm căn cứ để hỗ trợ giải quyết các vấn đề phát sinh trong quá trình học viên theo học tại Kurio.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Khi tạo tài khoản trên Kurio, Khách hàng đồng ý nhận những email cập nhật các tính năng và bài viết mới, cũng như những email thông báo các khuyến mãi từ Kurio. Kurio sẽ cung cấp đường link ở cuối email để Khách hàng có thể hủy đăng ký nhận thông báo đó bằng cách kích vào link đó.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không truy cập Kurio bằng các chương trình tự động.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Trong quá trình sử dụng tài khoản Kurio, tất cả những dữ liệu, bao gồm các thông tin về kết quả và lịch sử học tập của Khách hàng, tài liệu học tập, hình ảnh, âm thanh và tất cả các dữ liệu khác do Khách hàng tạo ra hay được thu thập bởi Kurio thông qua việc học của Khách hàng, dù miễn phí hay không, sẽ là tài sản tuyệt đối và của riêng Kurio.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không sao chép, phát tán hay phát hành bất kì tài liệu nào của Kurio, dù miễn phí hay không, mà không có sự cho phép bằng văn bản của Kurio. Tuy nhiên, Khách hàng có thể để đường dẫn (link) đến những tài liệu trên Kurio.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Mật khẩu của tài khoản: Sau khi đăng ký, học viên được cung cấp tài khoản và mật khẩu. Mật khẩu được sử dụng để đăng nhập vào website Kurio.vn và các dịch vụ trong hệ thống của Kurio. Học viên có trách nhiệm tự bảo quản mật khẩu, nếu mật khẩu bị lộ ra ngoài dưới bất kỳ hình thức nào, Kurio sẽ không chịu trách nhiệm về mọi tổn thất phát sinh.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng không còn có thể sử dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua ở Kurio (nếu có).
        </p>
      </>
    ),
  },
  PURCHASE_SERVICE_TERMS: {
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          2. Terms of Purchase/Service Usage
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          When purchasing a product or service on Kurio, for example buying an account for study, Customers agree to abide by all of the following terms:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>The products and services purchased by Customers on Kurio, such as study accounts, are for the exclusive use of the Customer. Customers are not allowed to let any other person log into their account to use the products or services purchased.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>When making a payment on Kurio, Customers agree to provide the most authentic and accurate payment information.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>If Customers violate any of the above terms of use, Kurio reserves the right to permanently suspend their account, which may prevent them from using the products or services they purchased on Kurio (if applicable).
        </p>
      </>
    ),
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          2. Các điều khoản về việc mua hàng/sử dụng dịch vụ
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Khi mua một sản phẩm hay dịch vụ trên Kurio, ví dụ như mua tài khoản để học, Khách hàng đồng ý tuân theo tất cả những điều khoản sau:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Những sản phẩm và dịch vụ mà Khách hàng đã mua trên Kurio, ví dụ như các tài khoản học, là để chỉ mình Khách hàng sử dụng. Khách hàng không được phép để bất kì người nào khác đăng nhập vào tài khoản của Khách hàng để sử dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Khi thực hiện thanh toán trên Kurio, Khách hàng đồng ý sẽ cung cấp những thông tin thanh toán xác thực và chính xác nhất.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi phạm bất kì điều khoản sử dụng nào ở trên, Kurio giữ quyền khóa tài khoản của Khách hàng vô thời hạn và việc này có thể khiến cho Khách hàng không còn có thể sử dụng những sản phẩm hay dịch vụ mà Khách hàng đã mua ở Kurio (nếu có).
        </p>
      </>
    ),
  },
  PAYMENT_METHODS: {
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>3. Phương thức thanh toán</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Kurio chấp nhận nhiều hình thức thanh toán khác nhau, bao gồm:
          <br />
          <br />
          a. Thanh toán bằng thẻ quốc tế như Visa/Master được phát hành hợp pháp
          bởi một tổ chức tín dụng tại Việt Nam.
          <br />
          <br />
          b. Thanh toán bằng thẻ ATM nội địa có chức năng Internet Banking hoặc
          QR Code được cung cấp bởi một tổ chức tín dụng tại Việt Nam.
          <br />
          <br />
          c. Thanh toán bằng chuyển khoản theo thông tin dưới đây:
          <br />
          <br />
          <b>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</b>
          <br />
          <br />
          Số tài khoản: 19036816201018
          <br />
          <br />
          Ngân hàng: TMCP Kỹ thương Việt Nam (Techcombank) - Chi nhánh Ba Đình - Hà Nội
          <br />
          <br />
          Ghi chú: Khi chuyển khoản tại mục "Nội dung" Khách hàng ghi rõ: Số điện thoại - Họ Tên - Email đăng ký học - Khóa học đăng ký.
          <br />
          <br />
          Ví dụ: 0901234567 – Nguyen Van A - nguyevana@gmail.com – Toan tu duy
          <br />
          <br />
          <b>Hướng dẫn thanh toán:</b>
          <br />
          <br />
          Sau khi Khách hàng đã đăng ký tài khoản mới trên Kurio hoặc đăng nhập
          vào tài khoản đã đăng ký trên Kurio, Khách hàng thực hiện các bước sau để mua khóa học phù hợp và tiến hành thanh toán:
        </p>
        {[
          "Bước 1: Tại trang chủ Kurio, nhấn chọn “Nâng cấp” sau đó chọn khóa học mà Khách hàng muốn mua.",
          "Bước 2: Nhấn chọn “mua khóa học”.",
          "Bước 3: Nhấn chọn “thanh toán” để tiến hành thanh toán.",
          'Bước 4: Ở màn hình chọn Phương thức thanh toán (ví dụ: người dùng chọn phương thức "Thẻ ATM") rồi nhấn Thanh toán.',
          "Bước 5: Tiến hành nhập đầy đủ các thông tin theo yêu cầu và nhấn Thanh toán.",
          'Bước 6: Khách hàng sẽ nhận được thông báo thanh toán hoàn tất và có thể tiến hành truy cập khóa học. Khách hàng vào mục "Tài khoản" để kiểm tra thông tin khóa học.',
        ].map((step, index) => (
          <p key={index} style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>+</b>
            {step}
          </p>
        ))}
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Trường hợp sau khi thanh toán, nếu Khách hàng phát hiện Gói dịch vụ có lỗi xin vui lòng thông báo ngay theo số điện thoại 0971 500 120 của Kurio để được trợ giúp.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>3. Payment Methods</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Kurio accepts various payment methods, including:
          <br />
          <br />
          a. Payment by international cards such as Visa/Master, legally issued by a credit institution in Vietnam.
          <br />
          <br />
          b. Payment by domestic ATM cards with Internet Banking functionality or QR Code provided by a credit institution in Vietnam.
          <br />
          <br />
          c. Payment via bank transfer using the following details:
          <br />
          <br />
          <b>IKEMSO GLOBAL JOINT STOCK COMPANY</b>
          <br />
          <br />
          Account number: 19036816201018
          <br />
          <br />
          Bank: Vietnam Technological and Commercial Joint Stock Bank (Techcombank) - Ba Dinh Branch - Hanoi
          <br />
          <br />
          Note: When making a bank transfer, please specify in the "Content" field: Phone number - Full name - Registered email - Registered course.
          <br />
          <br />
          Example: 0901234567 – Nguyen Van A - nguyevana@gmail.com – Mental Math
          <br />
          <br />
          <b>Payment Instructions:</b>
          <br />
          <br />
          After registering a new account on Kurio or logging into an existing account, follow these steps to purchase a course and proceed with payment:
        </p>
        {[
          'Step 1: On the Kurio homepage, click "Upgrade" and select the course you want to purchase.',
          'Step 2: Click "Buy Course".',
          'Step 3: Click "Pay" to proceed with the payment.',
          'Step 4: On the payment method selection screen (e.g., choosing "ATM Card"), then click Pay.',
          'Step 5: Enter all required information and click Pay.',
          'Step 6: You will receive a payment confirmation and can access the course. Check your course details under "Account".',
        ].map((step, index) => (
          <p key={index} style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>+</b>
            {step}
          </p>
        ))}
        <p style={{ color: "#272266", fontWeight: 500 }}>
          If you encounter any issues after payment, please contact Kurio customer support at 0971 500 120 for assistance.
        </p>
      </>
    ),
  },
  AGREEMENT: {
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          4. Toàn bộ thỏa thuận
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Tất cả những điều khoản trên là toàn bộ bản hợp đồng giữa Kurio và
          Khách hàng về tất cả những khía cạnh của việc sử dụng tài khoản Kurio
          của Khách hàng. Những điều khoản này sẽ thay thế cho những điều khoản
          ở trước đó.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          4. Entire Agreement
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          The above terms constitute the entire agreement between Kurio and the
          Customer regarding all aspects of the Customer's use of their Kurio
          account. These terms supersede any prior agreements.
        </p>
      </>
    ),
  },
  FREE_RELEARNING_POLICY: {
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
          5. Chính sách học lại miễn phí
        </h2>
        <div>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            Điều kiện để học lại miễn phí Kurio
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Học sinh sẽ được học lại miễn phí nếu đáp ứng trong các điều kiện sau:
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Học sinh đã tham gia và học liên tục trên Kurio web hoặc app trong 3 tháng, hoàn thành trên 90% nội dung khóa học và bài tập yêu cầu, nếu điểm số trên trường không đạt 8 điểm trở lên.
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Học sinh bị gián đoạn việc học do lý do cá nhân (ốm đau, gia đình có việc gấp, hoặc sự cố bất khả kháng từ tài khoản,..) và có đơn đề nghị từ phụ huynh.
          </p>
        </div>
        <div>
          <p style={{ color: "#272266", fontWeight: 700 }}>
            Thời gian và phạm vi áp dụng
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Học sinh được phép đăng ký học lại trong vòng 6 tháng kể từ khi kết thúc khóa học ban đầu.
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Học lại miễn phí áp dụng cho tất cả các khóa học Toán tư duy và Luyện thi toán học trên Kurio.vn.
          </p>
        </div>
        <div>
          <p style={{ color: "#272266", fontWeight: 700 }}>Cách thức đăng ký học lại</p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh cần gửi yêu cầu học lại qua hotline <b>0971500120</b>.
          </p>
          <div style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <p><b style={{ marginRight: "1rem" }}>-</b>Yêu cầu cần bao gồm:</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Tên học sinh</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Khóa học muốn học lại</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Lý do</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Cung cấp ảnh/giấy tờ chứng nhận việc học bị gián đoạn</p>
          </div>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            Trong vòng 15 ngày làm việc, hệ thống sẽ xác nhận và kích hoạt lại khóa học.
          </p>
          <p style={{ color: "#272266", fontWeight: 700 }}>Lưu ý:</p>
          <div style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <p><b style={{ marginRight: "1rem" }}>+</b>Mỗi học sinh chỉ được học lại miễn phí tối đa 1 lần/khoá học.</p>
            <p><b style={{ marginRight: "1rem" }}>+</b>Học sinh được học miễn phí tương đương với thời gian học đã đăng ký.</p>
          </div>
        </div>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700, marginBottom: "1rem" }}>
          5. Free Relearning Policy
        </h2>
        <div>
          <p style={{ color: "#272266", fontWeight: 700 }}>Conditions for Free Relearning at Kurio</p>
          <p style={{ color: "#272266", fontWeight: 500, marginBottom: "1rem" }}>
            Students are eligible for free relearning if they meet the following conditions:
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Students have continuously participated in Kurio web or app for 3 months, completed more than 90% of the course content and required exercises, and scored below 8 points in school.
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Students experience interruptions in their learning due to personal reasons (illness, urgent family matters, or account-related force majeure) and have a request from their parent.
          </p>
        </div>
        <div>
          <p style={{ color: "#272266", fontWeight: 700 }}>Timeframe and Scope of Application</p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Students can register for relearning within 6 months after completing the initial course.
          </p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Free relearning applies to all Logical Math and Math Exam Preparation courses on Kurio.vn.
          </p>
        </div>
        <div>
          <p style={{ color: "#272266", fontWeight: 700 }}>How to Register for Relearning</p>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <b style={{ marginRight: "1rem" }}>-</b>Parents must submit a relearning request via the hotline <b>0971500120</b>.
          </p>
          <div style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <p><b style={{ marginRight: "1rem" }}>-</b>The request must include:</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Student’s Name</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Course for relearning</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Reason</p>
            <p style={{ marginLeft: "1rem" }}><b style={{ marginRight: "1rem" }}>+</b>Supporting documents proving the learning interruption</p>
          </div>
          <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            Within 15 working days, the system will verify and reactivate the course.
          </p>
          <p style={{ color: "#272266", fontWeight: 700 }}>Note:</p>
          <div style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
            <p><b style={{ marginRight: "1rem" }}>+</b>Each student is allowed free relearning only once per course.</p>
            <p><b style={{ marginRight: "1rem" }}>+</b>Students will receive free study time equivalent to the registered course duration.</p>
          </div>
        </div>
      </>
    ),
  },
  REFUND_POLICY:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>6. Chính sách hoàn trả</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Trong trường hợp Khách hàng muốn hỗ trợ hoàn trả khóa học trên web Kurio, vui lòng liên hệ với chúng tôi theo hotline
          <b>0971500120</b> hoặc email <b>cskh@kurio.vn</b> để được hỗ trợ.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>6. Refund Policy</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          If customers need assistance with refunding a course on Kurio's website, please contact us via hotline
          <b>0971500120</b> or email <b>cskh@kurio.vn</b> for support.
        </p>
      </>
    ),
  },
  CONTACT_INFO : {
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>7. Thông tin liên lạc</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Nếu Khách hàng có câu hỏi nào về những điều khoản này, Khách
          hàng hãy liên lạc với chúng tôi: <br />
          <br />
          CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
          Email: <b>cskh@kurio.vn</b> <br /> <br />
          Tel: <b>0971500120</b> <br /> <br />
          Địa chỉ trụ sở: số 128 Nguyễn Thái Học, phường Điện Biên,
          quận Ba Đình, Hà Nội <br /> <br />
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>7. Contact Information</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          If customers have any questions regarding these terms, please contact us:
          <br />
          <br />
          IKEMSO GLOBAL JOINT STOCK COMPANY <br /> <br />
          Email: <b>cskh@kurio.vn</b> <br /> <br />
          Tel: <b>0971500120</b> <br /> <br />
          Headquarters address: 128 Nguyen Thai Hoc, Dien Bien Ward,
          Ba Dinh District, Hanoi <br /> <br />
        </p>
      </>
    ),
  },
  PRIVACY_POLICY: {
    EN: "Privacy Policy",
    VN: "Chính sách bảo mật thông tin",
  },
  PRIVACY_POLICY_CONTENT_1:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          1. Chúng tôi thu thập những thông tin gì?
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký trên trang web của
          chúng tôi. <br /> <br />
          Dữ liệu cá nhân mà Kurio có thể thu thập bao gồm, nhưng không giới hạn:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Họ tên
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ email
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Ngày sinh
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Tài khoản ngân hàng và thông tin
          thanh toán
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Số điện thoại
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Giới tính
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ thanh toán
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Thông tin được gửi bởi hoặc liên
          quan đến (các) thiết bị được sử dụng để truy cập vào Các Dịch vụ hoặc
          Nền tảng của chúng tôi.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Bất kỳ thông tin nào khác về
          người dùng khi người dùng đăng nhập để sử dụng Các Dịch Vụ hoặc Nền tảng
          của chúng tôi.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Dữ liệu tổng hợp về nội dung
          người dùng sử dụng.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Bạn có thể tự chỉnh sửa được tên
          trong trang quản lý tài khoản. Nếu cần đổi email đăng nhập hoặc số điện
          thoại, bạn cần gửi yêu cầu cho chúng tôi và trải qua các bước xác thực.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          1. What information do we collect?
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          We collect information from you when you register on our website.
          <br /> <br />
          The personal data that Kurio may collect includes, but is not limited
          to:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Full name
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Email address
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Date of birth
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Bank account and payment
          information
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Phone number
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Gender
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Billing address
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Information sent by or related
          to the device(s) used to access our Services or Platform.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Any other user information when
          the user logs in to use our Services or Platform.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>Aggregate data on the content
          that users engage with.
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b>You can edit your name in the
          account management page. If you need to change your login email or phone
          number, you must submit a request and go through the verification steps.
        </p>
      </>
    ),
  },
  PRIVACY_POLICY_CONTENT_2:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>2. Chúng tôi sẽ làm gì với thông tin của bạn?</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Kurio sử dụng thông tin chúng tôi thu thập cho các mục đích sau:
        </p>
        <ul style={{ color: "#272266", fontWeight: 500 }}>
          <li>Để xem xét và/hoặc xử lý đơn đăng ký/giao dịch; đăng ký email của bạn với chúng tôi.</li>
          <li>Để quản lý việc bạn sử dụng dịch vụ của chúng tôi.</li>
          <li>
            Để đáp ứng, xử lý, giải quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của bạn đối với các sản phẩm và dịch vụ nhất định, đồng thời thông báo cho bạn về các vấn đề dịch vụ và hoạt động tài khoản bất thường.
          </li>
          <li>Để thực thi các Điều Khoản Dịch Vụ của chúng tôi.</li>
          <li>Để bảo vệ sự an toàn cá nhân và các quyền, tài sản hoặc sự an toàn của người khác.</li>
          <li>Để nhận dạng và/hoặc xác minh.</li>
          <li>
            Để giải quyết hoặc tạo điều kiện cho dịch vụ khách hàng, xử lý khiếu nại của bạn, giải quyết hoặc trả lời bất kỳ thắc mắc nào được gửi bởi bạn hoặc thay mặt bạn.
          </li>
          <li>
            Để liên hệ hoặc giao tiếp với bạn qua điện thoại, tin nhắn văn bản, email nhằm mục đích quản trị quan hệ của bạn với chúng tôi.
          </li>
          <li>Để gửi thông báo tới bạn qua trình duyệt hoặc ứng dụng Kurio trên điện thoại.</li>
          <li>
            Để nghiên cứu, phân tích và phát triển, bao gồm khảo sát, phân tích dữ liệu, phát triển sản phẩm và dịch vụ nhằm cải thiện trải nghiệm khách hàng của bạn.
          </li>
          <li>
            Vì mục đích tiếp thị, gửi cho bạn thông tin và tài liệu tiếp thị về các sản phẩm và/hoặc dịch vụ. Bạn có thể hủy đăng ký nhận thông tin tiếp thị bất cứ lúc nào.
          </li>
          <li>Để ngăn chặn hoặc điều tra bất kỳ hoạt động gian lận, phi pháp hoặc hành vi sai trái nào.</li>
        </ul>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>2. What do we do with your information?</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Kurio uses the information we collect for the following purposes:
        </p>
        <ul style={{ color: "#272266", fontWeight: 500 }}>
          <li>To review and/or process registration/transactions; register your email with us.</li>
          <li>To manage your use of our services.</li>
          <li>
            To respond, process, resolve, or complete a transaction and/or fulfill your requests for certain products and services and notify you of service issues and unusual account activities.
          </li>
          <li>To enforce our Terms of Service.</li>
          <li>To protect personal safety and the rights, property, or safety of others.</li>
          <li>To identify and/or verify.</li>
          <li>
            To resolve or facilitate customer service, handle your complaints, resolve or respond to any inquiries submitted by (or intended to be submitted by) you or on your behalf.
          </li>
          <li>
            To contact you or communicate with you via phone, text messages, email for administrative purposes related to your relationship with us.
          </li>
          <li>To send notifications to you via browser or Kurio mobile application.</li>
          <li>
            To conduct research, analysis, and development (including but not limited to data analysis, surveys, product, and service development) to analyze how you use our Services and improve customer experience.
          </li>
          <li>
            For marketing purposes, to send you marketing and promotional information and materials related to products and/or services. You can unsubscribe from marketing emails at any time.
          </li>
          <li>To prevent or investigate any fraudulent, illegal, or improper activity.</li>
        </ul>
      </>
    ),
  },
  PRIVACY_POLICY_CONTENT_3:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>3. Bảo mật</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Bảo mật thông tin cá nhân của Khách hàng là ưu tiên hàng đầu của chúng tôi.
          Các thông tin đăng ký mua hàng của Khách hàng sẽ được bảo mật bằng cách sử dụng 
          công nghệ mã hóa an toàn Secure Sockets Layer (SSL). Chúng tôi cam kết sẽ cố gắng 
          để đảm bảo an ninh ở mức cao nhất cho Khách hàng. Tuy nhiên, Kurio xin nhấn mạnh 
          không có phương án nào đảm bảo được tính bảo mật 100%. Nếu bạn có bất kỳ câu hỏi về 
          vấn đề bảo mật trên trang web của chúng tôi, bạn có thể gửi thắc mắc về email 
          <b>cskh@kurio.vn</b>.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>3. Security</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Protecting customers' personal information is our top priority.
          Your purchase registration information will be secured using Secure Sockets Layer (SSL) encryption.
          We are committed to making every effort to ensure the highest level of security for our customers.
          However, Kurio emphasizes that no method guarantees 100% security.
          If you have any questions regarding security on our website, please send your inquiries to 
          <b>cskh@kurio.vn</b>.
        </p>
      </>
    ),
  },
  PRIVACY_POLICY_CONTENT_4:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>4. Cookies và công nghệ phân tích dữ liệu khác</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Cookies là một lượng nhỏ dữ liệu, được gửi tới trình duyệt của bạn từ một trang web 
          và được lưu trữ trên ổ đĩa cứng của máy tính. Cookies cho phép trang web hoặc hệ thống 
          của các bên cung cấp dịch vụ nhận diện trình duyệt của bạn và lưu lại một số thông tin nhất định. 
          Chúng tôi dùng cookies để hiểu và lưu trữ hoạt động của bạn và đáp ứng các nhu cầu của bạn tốt hơn 
          cho lần truy cập sau.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>4. Cookies and Other Data Analytics Technologies</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Cookies are small amounts of data sent to your browser from a website and stored on your computer's 
          hard drive. Cookies allow the website or service provider's system to recognize your browser and 
          retain certain information. We use cookies to understand and store your activities, enabling us to 
          better meet your needs during future visits.
        </p>
      </>
    ),
  },
  PRIVACY_POLICY_CONTENT_5:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          5. Chúng tôi có cung cấp thông tin cho bên khác không?
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức thương mại khác với những thông tin cá nhân của bạn. 
          Tuy nhiên, thông tin không định danh của những người truy cập vào trang web có thể được sử dụng và cung cấp 
          cho các bên khác nhằm mục đích marketing, quảng cáo hoặc mục đích sử dụng khác.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>
          5. Do We Share Your Information with Others?
        </h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          We do not sell, trade, or otherwise engage in commercial activities with your personal information. 
          However, non-identifiable visitor information may be used and shared with third parties for marketing, 
          advertising, or other purposes.
        </p>
      </>
    ),
  },
  PRIVACY_POLICY_CONTENT_6:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>6. Xóa tài khoản</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Các cá nhân sở hữu tài khoản trên Kurio được quyền xóa bỏ tài khoản bất kỳ thời điểm nào. 
          Trong trường hợp Khách hàng muốn xóa tài khoản, vui lòng liên hệ với Kurio theo hotline{" "}
          <b>0971500120</b> hoặc email <b>cskh@kurio.vn</b> để được hỗ trợ. 
          Ngoài ra, chúng tôi chỉ hỗ trợ khôi phục lại tài khoản trong trường hợp nhầm lẫn hoặc tài khoản 
          bị tấn công trong thời gian 30 ngày kể từ thời điểm xóa. Vui lòng đọc kỹ các thông tin được cung 
          cấp trước khi thực sự làm hành động này.
        </p>
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>6. Account Deletion</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Users who own an account on Kurio have the right to delete their account at any time. 
          If you wish to delete your account, please contact Kurio via hotline <b>0971500120</b> or email{" "}
          <b>cskh@kurio.vn</b> for assistance. 
          Additionally, we only support account recovery in case of errors or account attacks within 
          30 days from the time of deletion. Please read the provided information carefully before 
          proceeding with this action.
        </p>
      </>
    ),
  },
  PRIVACY_POLICY_CONTENT_7:{
    VN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>7. Thông tin liên hệ</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          Nếu bạn có thắc mắc, hãy liên hệ với chúng tôi:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b> Hotline: 0971500120
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b> Email: cskh@kurio.vn
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b> Địa chỉ: số 128 Nguyễn Thái Học, phường Điện Biên, quận Ba Đình, Hà Nội.
        </p>
        <br />
      </>
    ),
    EN: (
      <>
        <h2 style={{ color: "#272266", fontWeight: 700 }}>7. Contact Information</h2>
        <p style={{ color: "#272266", fontWeight: 500 }}>
          If you have any questions, please contact us:
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b> Hotline: 0971500120
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b> Email: cskh@kurio.vn
        </p>
        <p style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}>
          <b style={{ marginRight: "1rem" }}>+</b> Address: 128 Nguyen Thai Hoc, Dien Bien Ward, Ba Dinh District, Hanoi.
        </p>
        <br />
      </>
    ),
  },
  // ! Cảnh báo !
  WARNING: {
    EN: "! Warning !",
    VN: "! Cảnh báo !",
  },
  CHANGE_PASSWORD_WARNING : {
    VN: (
      <>
        Bạn sẽ tự động <b>ĐĂNG XUẤT</b> để tới trang <b>QUÊN MẬT KHẨU</b>. <br />
        Bạn chỉ được khởi tạo lại mật khẩu 1 lần trong vòng 1 tiếng.
      </>
    ),
    EN: (
      <>
        You will be automatically <b>LOGGED OUT</b> to go to the <b>FORGOT PASSWORD</b> page. <br />
        You can only reset your password once every hour.
      </>
    ),
  },
  // button next slide url
  BUTTON_NEXT_SLIDE_URL: {
    EN: "images/button/button-next-slide-en.svg",
    VN: "images/button/button-next-slide.svg",
  },
  // Nhận Voucher thành công!
  RECEIVED_VOUCHER: {
    VN: "Nhận Voucher thành công!",
    EN: "Received Voucher successfully!",
  },
  // nhiệm vụ
  MISSION: {
    EN: "Mission",
    VN: "Nhiệm vụ",
  },
  // cách chơi url
  HOW_TO_PLAY_URL: {
    EN: "images/how-to-play-en.png",
    VN: "images/how-to-play.png",
  },
  // bắt đầu ngay url
  START_NOW_URL: {
    EN: "images/start-now-en.png",
    VN: "images/start-now.png",
  },
  // cách tính điểm 
  HOW_TO_CALCULATE_SCORE: {
    EN: "Score calculation",
    VN: "Cách tính điểm",
  },
  COMPLETE_TODAY_MISSION: {
    VN: "Hãy hoàn thành nhiệm vụ hôm nay!",
    EN: "Complete today's mission!",
  },
  TODAY_MISSION_COMPLETED: {
    VN: "Bạn đã hoàn thành Nhiệm vụ hôm nay!",
    EN: "You have completed today's mission!",
  },
  RETURN_TO_BATTLE_TOMORROW: {
    VN: "Hãy trở lại cuộc chiến vào ngày mai nhé!",
    EN: "Return to the battle tomorrow!",
  },
  REMAINING_HP: {
    VN: "HP còn lại: ",
    EN: "Remaining HP: ",
  },
  CORRECT_ANSWER: {
    VN: "Trả lời đúng: ",
    EN: "Correct answer: ",
  },
  MONSTER_MISSION_DESCRIPTION: {
    VN: <>  
      Nhiệm vụ của bạn là đánh bại quái vật để thu thập trọn bộ sticker quái vật của Kurio. <br />
      1. Quái vật được sinh ra vào ngày đầu tiên của mỗi tháng với 100 điểm sức khỏe. <br />
      2. Mỗi ngày, bạn sẽ gặp phải 5 rào cản là 5 câu hỏi toán học. Trả lời đúng mỗi câu hỏi sẽ trừ 1 điểm sức khỏe của quái vật. <br />
      3. Với mỗi ngày bạn không tham gia trận chiến, quái vật sẽ hồi sức! Vậy nên các bạn nhớ tham gia nhiệm vụ hằng ngày nhé! <br />
      4. Phần thưởng khi đánh bại quái vật là huy hiệu quái vật tương ứng để bạn lưu vào bộ sưu tập của mình. <br />
    </>,
    EN: <>  
      Your mission is to defeat monsters and collect the full set of Kurio monster stickers. <br />
      1. A monster is born on the first day of each month with 100 health points. <br />
      2. Each day, you will face 5 obstacles in the form of 5 math questions. Answering each question correctly will reduce the monster’s health by 1 point. <br />
      3. If you don’t join the battle for a day, the monster will recover! So make sure to complete your daily mission! <br />
      4. The reward for defeating a monster is a corresponding monster badge to add to your collection. <br />
    </>,
  },
  // còn
  REMAIN:{
    EN: "Remaining:",
    VN: "Còn:",
  },
   // button popup daily mission
  BUTTON_POPUP_DAILY_MISSION_URL: {
    EN: "admin/frontend-images/hub/button-popup-daily-mission-en.svg",
    VN: "admin/frontend-images/hub/button-popup-daily-mission.svg",
  } ,
  // button-finished-success-daily-mission.svg
  BUTTON_FINISHED_SUCCESS_DAILY_MISSION_URL: {
    EN: "admin/frontend-images/hub/button-finished-success-daily-mission-en.svg",
    VN: "admin/frontend-images/hub/button-finished-success-daily-mission.svg",
  } ,
  // admin/frontend-images/hub/title-finished-success-quiz-daily.svg
  TITLE_FINISHED_SUCCESS_QUIZ_DAILY_URL: {
    EN: "admin/frontend-images/hub/title-finished-success-quiz-daily-en.svg",
    VN: "admin/frontend-images/hub/title-finished-success-quiz-daily.svg",
  },
  ///images/hub/messerger-kurio.svg 
  MESSENGER_KURIO_URL: {
    EN: "/images/hub/messerger-kurio-en.svg",
    VN: "/images/hub/messerger-kurio.svg",
  } ,
  // header-top-popup-daily-mission.svg
  HEADER_TOP_POPUP_DAILY_MISSION_URL: {
    EN: "admin/frontend-images/hub/header-top-popup-daily-mission-en.svg",
    VN: "admin/frontend-images/hub/header-top-popup-daily-mission.svg",
  },
  // admin/frontend-images/hub/image-button-submit-exam-daily-mission.svg
  IMAGE_BUTTON_SUBMIT_EXAM_DAILY_MISSION_URL: {
    EN: "admin/frontend-images/hub/image-button-submit-exam-daily-mission-en.svg",
    VN: "admin/frontend-images/hub/image-button-submit-exam-daily-mission.svg",
  },
  // "admin/frontend-images/hub/image-button-finished-exam-daily-mission.svg"
  IMAGE_BUTTON_FINISHED_EXAM_DAILY_MISSION_URL: {
    EN: "admin/frontend-images/hub/image-button-finished-exam-daily-mission-en.svg",
    VN: "admin/frontend-images/hub/image-button-finished-exam-daily-mission.svg",
  },
  // admin/frontend-images/hub/button-daily-mission.svg
  BUTTON_DAILY_MISSION_URL: {
    EN: "admin/frontend-images/hub/button-daily-mission-en.svg",
    VN: "admin/frontend-images/hub/button-daily-mission.svg",
  },
  
  // /images/hub/nav-bar-calculate-points.svg
  NAV_BAR_CALCULATE_POINTS_URL: {
    EN: "/images/hub/nav-bar-calculate-points-en.svg",
    VN: "/images/hub/nav-bar-calculate-points.svg",
  },
  // /images/hub/button-calculate-points.svg
  BUTTON_CALCULATE_POINTS_URL: {
    EN: "/images/hub/button-calculate-points-en.svg",
    VN: "/images/hub/button-calculate-points.svg",
  },
  QUESTION_DAILY_MISSION: {
    EN:" ",
    VN:"Câu"
  },
  // hiện tại chưa có nhiệm vụ hằng ngày
  NO_DAILY_MISSION: {
    EN:"No daily mission",
    VN:"Hiện tại chưa có nhiệm vụ hằng ngày"
  },
  // Coming Soon!
  COMING_SOON: {
    EN:"Coming Soon!",
    VN:"Sắp Ra Mắt!"
  },
  // Học hết các bài để mở khóa!
  COMPLETE_LESSONS_TO_UNLOCK: {
    VN: "Học hết các bài để mở khóa!",
    EN: "Complete all lessons to unlock!",
  },
  EXCEL_AT_THE_CHALLENGE: {
    VN: "Vượt qua thử thách xuất sắc!",
    EN: "Excel at the challenge!",
  },
  FINAL_CHALLENGE_COMPLETED: {
    VN: "Vượt qua thử thách cuối cùng!",
    EN: "Overcome the final challenge!",
  },
  CONTINUE_PREVIOUS_SESSION: {
    VN: <>Lần trước bạn đã thoát ra trong lúc đang làm bài. <br /> Bạn có muốn làm tiếp?</>,
    EN: <>Last time, you exited while working on the lesson. <br /> Do you want to continue?</>,
  },
  START_OVER: {
    VN: "Làm lại từ đầu",
    EN: "Start over",
  },
  QUIZ_CONTINUE: {
    VN: "Làm tiếp",
    EN: "Continue",
  },
  VIEW_COMPLETED_LESSONS: {
    VN: "Xem bài đã làm",
    EN: "View completed lessons",
  },
  PREVIOUS_SCORE: {
    VN: "Điểm lần trước của bạn.",
    EN: "Your previous score.",
  },
  FINISH_RESULTS: {
    VN: "Kết quả về đích",
    EN: "Finish results",
  },
  IN_PROGRESS_WARNING: {
    VN: (
      <>
        <p className="has-text-weight-bold" style={{ fontSize: "2.25rem" }}>
          ! Đang làm dở !
        </p>
        <p>
          Lần trước bạn đã thoát ra trong lúc đang làm bài. Bạn có muốn tiếp tục?
        </p>
      </>
    ),
    EN: (
      <>
        <p className="has-text-weight-bold" style={{ fontSize: "2.25rem" }}>
          ! In Progress !
        </p>
        <p>
          Last time, you exited while working on the lesson. Do you want to
          continue?
        </p>
      </>
    ),
  },
  CONTINUE_LAST_SESSION: {
    VN: "Lần trước bạn đã thoát ra trong lúc đang làm bài. Bạn có muốn tiếp tục?",
    EN: "Last time, you exited while working on the lesson. Do you want to continue?",
  },
  NEXT_UNIT: {
    VN: "Chương Tiếp",
    EN: "Next unit",
  },
  SUBMIT_ANSWER: {
    VN: "Nộp bài",
    EN: "Submit answer",
  },
  QUIZ: {
    VN: "Đề thi",
    EN: "Exam",
  },
  ANSWERED_QUIZ:{
    VN: "Đã làm",
    EN: "Answered",
  },
  NOT_ANSWERED_QUIZ:{
    VN: "Chưa làm",
    EN: "Unanswered",
  },
  // chưa làm
  NOT_COMPLETED_QUIZ:{
    VN: "Chưa làm",
    EN: "Not complete",
  },
  LAST_SCORE: {
    VN: "Điểm lần trước",
    EN: "Last score",
  },
  QUESTIONS_ANSWERED: {
    VN: "Số câu đã làm: ",
    EN: "Questions answered: ",
  },
  
  QUESTIONS_NOT_ANSWERED: {
    VN: "Số câu chưa làm: ",
    EN: "Questions not answered: ",
  },
  TOTAL_QUESTIONS: {
    EN: (total) => `Total questions: ${total} questions`,
    VN: (total) => `Tổng số câu hỏi: ${total} câu`,
  },
  CONFIRM_SUBMISSION: {
    EN: "Confirm submission",
    VN: "Xác nhận nộp bài",
  },
  NOT_SUBMITTED: {
    EN: "Not submitted",
    VN: "Chưa nộp",
  },
  SUBMIT_NOW: {
    EN: "Submit now",
    VN: "Nộp bài ngay",
  },
  
  WAIT_A_MOMENT: {
    EN: "Wait a moment",
    VN: "Chờ đã",
  },
  
  NOT_YET_SUBMITTED: {
    EN: "You haven't submitted your answer",
    VN: "Bạn chưa nộp bài",
  },
  // sai
  INCORRECT : {
    EN: "Incorrect",
    VN: "Sai",
  },
  CURRENT_PAGE: {
    EN: "Current",
    VN: "Hiện tại",
  },
  
  PREVIOUS_PAGE: {
    EN: "Previous page",
    VN: "Trang trước",
  },
  
  NEXT_PAGE: {
    EN: "Next page",
    VN: "Trang sau",
  },
  VIEWED: {
    EN: "Viewed",
    VN: "Đã xem xong",
  },
  CALCULATING: {
    EN: "Calculating...",
    VN: "Đang tính toán...",
  },
  YOUR_SCORE: {
    EN: "Your score",
    VN: "Số điểm của bạn",
  },
  RESULT:{
    EN: "Result",
    VN: "Kết quả",
  },
  // điểm
  SCORE: {
    EN: "Score",
    VN: "Điểm",
  },
  ACHIEVE_100_PERCENT: {
    EN: <>Achieve <b>100%</b> to <br /> earn an additional <b>40 points</b></>,
    VN: <>Đạt <b>100%</b> để <br /> nhận được thêm <b>40 điểm</b></>,
  },
  PROGRESS_ACHIEVED: {
    EN: (percent) => <>Achieved: <b>{percent}%</b></>,
    VN: (percent) => <>Đạt: <b>{percent}%</b></>,
  },
  // /images/button/popup-exercise-close-button.svg
  POPUP_EXERCISE_CLOSE_BUTTON_URL: {
    EN: "admin/frontend-images/button/popup-exercise-close-button-en.svg",
    VN: "admin/frontend-images/button/popup-exercise-close-button.svg",
  },
  // /images/button/thoat.svg
  EXIT_BUTTON_URL: {
    EN: "/images/button/exit.svg",
    VN: "/images/button/thoat.svg",
  }
};

export default ConstantStrings;
