import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { loadWidthImage } from "../../constants/loadWidthImage";
import "./../../styling/layout-components/LessonRightWrapper.css";
import { getConstantStringByLanguage } from "./getConstantStringByLanguage";
import ConstantStrings from "../../constants/ConstantStrings";

export const LoadingImage = ({
  images,
  router,
  handleSetLoadingImage,
  type,
}) => {
  const navigate = useNavigate();
  var backgroundImage = {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [startTime] = useState(Date.now());
  if (loadingProgress < 100) {
    backgroundImage = {
      ...backgroundImage,
      backgroundImage: "url('/images/logo-k-mau.gif')",
    };
  } else {
    backgroundImage = {
      ...backgroundImage,
      backgroundImage: "url('/images/logomau_noloop.gif')",
    };
  }

  useEffect(() => {
    if (images && images?.length > 0 && type !== "PRACTICE") {
      const increment = 100 / images.length;
      const minDuration =
        images.length * 200 > 2000 ? images.length * 300 : 2000; // Minimum duration in milliseconds (2 seconds)
      for (let index = 0; index < images.length; index++) {
        const elapsedTime = Date.now() - startTime;
        const targetProgress = Math.min((elapsedTime / minDuration) * 100, 100);

        loadWidthImage(images[index], (err, img) => {
          setTimeout(() => {
            setLoadingProgress((prevProgress) =>
              Math.min(prevProgress + increment, targetProgress, 100)
            );
          }, 300);
        });
      }
      if (loadingProgress >= 100) {
        loadWidthImage("/images/logomau_noloop.gif", (err, img) => {
          setTimeout(() => {
            handleSetLoadingImage(false);
            if (router?.length > 0) {
              navigate(`/${router}`);
            }
          }, 2750);
        });
      }
    }
  }, [
    loadingProgress,
    handleSetLoadingImage,
    navigate,
    router,
    images,
    startTime,
    type,
  ]);
  return (
    <>
      {(!type || !type.includes("PRACTICE")) && (
        <div
          className="modal is-active"
          style={backgroundImage}
        >
          <div className="modal-background has-background-white"></div>
          <div className="is-relative is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-end has-text-centered" style={{...backgroundImage, width:"100%", height:"100%", zIndex:1000}}>
          {/* {loadingProgress === 100 ? (
            <ReactPlayer
              width="100%"
              height="100%"
              url={"/images/logo-mau.webm"}
              controls={false}
              muted={true}
              playing={true}
              onEnded={() => {
                handleSetLoadingImage(false);
                if (router?.length > 0) {
                  navigate(`/${router}`);
                }
              }}
            />
          ) : (
            <ReactPlayer
              width="100%"
              height="100%"
              url={"/images/logo-k-mau.webm"}
              controls={false}
              muted={true}
              playing={true}
              loop={true}
            />
          )} */}
          {/* <div
            className="m-auto"
            style={{ position: "absolute", bottom: "0", width: "100%" }}
          >
          </div> */}
            <p
              style={{ fontSize: "0.8rem", marginBottom: "0.5rem" }}
              className=" has-text-centered has-text-kurio-main-color-black"
            >
              {getConstantStringByLanguage(ConstantStrings.LOADING)}
            </p>
            <Progress
              style={{
                paddingBottom: "2.5rem",
                width: "15rem",
                margin: "0 auto",
              }}
              percent={loadingProgress}
              status={"active"}
              theme={{
                active: {
                  symbol: " ",
                  trailColor: "rgba(10, 42, 102, 0.2)",
                  color: "#0A2A66",
                },
              }}
            />
          </div>
          {images &&
            images?.length > 0 &&
            images.map((item, index) => {
              return (
                <div key={index}>
                  <img src={item} className="is-hidden" alt="" />

                  {/* {index === images.length - 1 &&
                    setTimeout(() => {
                      handleSetLoadingImage(false);
                      if (router?.length > 0) {
                        navigate(`/${router}`);
                      }
                    }, 300)} */}
                </div>
              );
            })}
        </div>
      )}
      {type && type.includes("PRACTICE") && (
        <>
          {images &&
            images?.length > 0 &&
            images.map((item, index) => {
              return (
                <div
                  key={index}
                  className="is-hidden"
                  style={{ position: "absolute" }}
                >
                  <img src={item} className="is-hidden" alt="" />
                </div>
              );
            })}
        </>
      )}
    </>
  );
};
