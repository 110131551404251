import React from "react";

const PackageItem = ({ item, isSelected, handleSelect, isMobile = false }) => {
  return (
    <div
      className={`${
        isSelected
          ? "has-text-kurio-main-color-purple has-text-weight-bold"
          : " has-text-kurio-main-color-black has-text-weight-medium"
      } is-flex is-flex-direction-column is-align-items-center is-justify-content-center pt-2 is-clickable package-item`}
      style={{
        gap: "0.75rem",
        background: isSelected ? "#E6DFFE" : "#FAFBFC",
        borderRadius: "1rem",
        height: "5.75rem",
        width: isMobile ? "auto" : "16.75rem",
        border: isSelected ? "0.125rem solid #572CD1" : "none",
      }}
      onClick={() => handleSelect(item.package)}
    >
      <div style={{ width: "12.00664rem" }}>
        <div className="is-flex is-align-items-center is-justify-content-space-between">
          <p
            className=""
            style={{
              fontSize: "0.75rem",
              fontStyle: "normal",
              lineHeight: "normal",
              fontWeight: 500,
            }}
          >
            Gói <br />
            <b className="is-uppercase" style={{ fontSize: "1rem" }}>
              {item.content}
            </b>
          </p>
          <div className="is-flex is-flex-gap-1">
            {item.isSuggest && (
              <div
                className="has-text-kurio-main-color-black is-uppercase has-text-weight-bold is-inline-flex is-align-items-center is-justify-content-center"
                style={{
                  borderRadius: "0.5rem",
                  background:
                    "linear-gradient(90deg, #F3C00E 0%, #FFE059 50%, #F3C00E 100%)",
                  padding: "0.25rem",
                  transform: "rotate(2deg)",
                  height: "auto",
                }}
              >
                <p
                  style={{
                    fontSize: "0.75rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  Đề xuất
                </p>
              </div>
            )}
            <div
              className="has-text-kurio-main-color-white has-text-weight-bold is-inline-flex is-align-items-center is-justify-content-center"
              style={{
                borderRadius: "0.5rem",
                background: item.isSuggest ? "#572CD1" : "#1D95F7",
                padding: "0.25rem",
                transform: "rotate(-6deg)",
                height: "auto",
              }}
            >
              <p
                style={{
                  fontSize: "0.75rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                -{item.sale}%
              </p>
            </div>
          </div>
        </div>
        <div className="is-flex is-justify-content-flex-end">
          <div className="is-uppercase is-flex is-align-items-flex-end is-align-self-stretch is-flex-gap-1">
            <p
              style={{
                color: "#9EAFD3",
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "normal",
                textDecoration: "line-through",
                marginBottom: "0.25rem",
              }}
            >
              {item.oldPrice}
            </p>
            <p
              className="has-text-weight-bold"
              style={{
                fontSize: "2.5rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              {item.price}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageItem;
