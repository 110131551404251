import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollRefWrapper from '../../detail-components/ScrollRefWrapper';

var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";
var colorBlack = "#0A2A66";

const ReturnPolicy = () => {
  const [showPopupDownload, setShowPopupDownload] = useState(true)

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Cuộn mượt mà
    });
  };
  const handleClosePopupDownload = ()=>{
    setShowPopupDownload(false)
  }

  const  getMobileOperatingSystem=()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  const handleDownloadApp = ()=>{
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=vn.kurio.android"
    } else if (mobileOS === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
    }
  }

  return (
    <>
      <ScrollRefWrapper />
      {/* Desktop */}
      <div className='container-landingpage' id='dekstop-landingpage'>
        <section id='header-landingpage' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", background: colorWhite, color: "#272266", width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", color: colorTextH1, fontWeight: 700, textTransform: "uppercase" }}>
            chính sách vận chuyển - trả hàng & hoàn tiền
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            1. Chính sách hoàn trả
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Quý khách có thể được hoàn tiền sản phẩm dịch vụ khi sử dụng dịch
            vụ của website Kurio.vn như sau:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Với gói học 1 tháng :
            Không áp dụng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b> Với gói học 6 tháng trở
            lên: Học sinh được hoàn trả học phí 100% nếu đáp ứng đủ điều kiện
            theo quy định.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Quy trình hoàn tiền: được thông báo trên website Kurio.vn hoặc
            thông báo trực tiếp cho Khách hàng tại thời điểm Khách hàng đăng
            ký thành công sản phẩm, dịch vụ.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Mọi thắc mắc, khiếu nại về vấn đề chính sách hoàn tiền vui lòng
            liên hệ số hotline <b>0971500120</b> để được hỗ trợ tốt nhất
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Điều kiện:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh đã thanh toán
            100% học phí của gói học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b> Học sinh chưa sử dụng tài
            khoản.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Thủ tục yêu cầu hoàn trả:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 1:</b>Liên hệ số Hotline
            0971500120 cung cấp thông tin số điện thoại đăng ký tài khoản của
            học sinh và thông tin gói học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 2:</b>Kurio.vn xem xét đề
            nghị hoàn học phí và điều kiện
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 3:</b>Kurio.vn thông báo
            quyết định hoàn học phí và xin thông tin ngân hàng để hoàn lại học
            phí cho học sinh
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 4:</b>Kurio.vn tiến hành
            hoàn phí. Thời gian hoàn tất hoàn phí dự kiến được thực hiện trong
            15 ngày làm việc kể từ ngày xác nhận đủ điều kiện hoàn phí.
          </p>
          <p className="has-text-weight-bold" style={{ color: "#272266" }}>
            Lưu ý: Để thông tin hoàn học phí được xác thực Quý phụ huynh vui
            lòng cung cấp chứng từ chuyển khoản để Kurio tiến hành kiểm tra
            thông tin và hoàn phí đúng quy định.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            2. Nghĩa vụ của người bán và nghĩa vụ của khách hàng trong mỗi
            giao dịch
          </h2>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Nghĩa vụ của người bán:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Hướng dẫn, tư vấn tất cả các thông tin cụ thể liên quan đến dịch
            vụ để Người mua hiểu và có thể sử dụng.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Cung cấp dịch vụ cho Người mua đúng thời hạn sau khi Người mua đã
            thanh toán đầy đủ cho Người bán.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Giải quyết các thắc mắc và những khó khăn trong quá trình sử dụng
            dịch vụ.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Cung cấp các chứng từ, giấy tờ liên quan tới việc Người mua thanh
            toán cho Người bán như hóa đơn, phiếu thu, .... với tổng số tiền
            mà Người mua yêu cầu.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Nghĩa vụ của người mua:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Thực hiện đúng quy định, quy trình liên quan đến dịch vụ do Người
            bán quy định.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Thanh toán đầy đủ cho Người bán số tiền theo mức phí đã quy định.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Hỗ trợ và cung cấp thông tin đầy đủ cho Người bán liên quan tới
            các giao dịch khi Người bán có yêu cầu.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            3. Thông tin về vận chuyển và giao nhận
          </h2>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Hình thức chuyển khoản:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Khi khách hàng đăng ký tài khoản và thực hiện thanh toán mua khóa
            học trên website Kurio.vn thành công. Đội ngũ chăm sóc khách hàng
            của Kurio sẽ kích hoạt tài khoản của khách hàng và liên hệ trực
            tiếp cho khách hàng. Hoặc sau khi chuyển khoản thành công khách
            hàng có thể chủ động liên hệ qua Hotline/Zalo 0971500120 của Kurio
            để thông báo về việc chuyển tiền mua khóa học Kurio sẽ hỗ trợ kích
            hoạt khóa học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Hình thức thanh toán qua cổng thanh toán VNPAY:
          </p>
          <p
            style={{
              color: "#272266",
              fontWeight: 500,
              marginLeft: "2rem",
            }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Khi khách hàng đăng ký tài khoản và chọn hình thức thanh toán mua
            khóa học qua cổng thanh toán VNPAY, sau khi thanh toán xong thì
            tài khoản sẽ được kích hoạt luôn, khách hàng lưu ý đăng nhập đúng
            tài khoản đã đăng ký để có thể đăng nhập sử dụng được khóa học.
          </p>
        </div>

        <section id='footer-landingpage' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem", width: "20rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>

              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
              <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal" ,width:"9.125rem"}}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div style={{ height: "24.125rem" }} className='is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Tablet */}
      <div className='container-landingpage' id='tablet-landingpage'>
        <section id='tablet-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
            <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
                {/* <img src="/images/landing-page/icon-arrow.svg" alt="" /> */}
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "15rem", height: "4rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>

        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "2rem", color: colorTextH1, fontWeight: 700, textTransform: "uppercase" }}>
            chính sách vận chuyển - trả hàng & hoàn tiền
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            1. Chính sách hoàn trả
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Quý khách có thể được hoàn tiền sản phẩm dịch vụ khi sử dụng dịch
            vụ của website Kurio.vn như sau:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Với gói học 1 tháng :
            Không áp dụng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b> Với gói học 6 tháng trở
            lên: Học sinh được hoàn trả học phí 100% nếu đáp ứng đủ điều kiện
            theo quy định.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Quy trình hoàn tiền: được thông báo trên website Kurio.vn hoặc
            thông báo trực tiếp cho Khách hàng tại thời điểm Khách hàng đăng
            ký thành công sản phẩm, dịch vụ.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Mọi thắc mắc, khiếu nại về vấn đề chính sách hoàn tiền vui lòng
            liên hệ số hotline <b>0971500120</b> để được hỗ trợ tốt nhất
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Điều kiện:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh đã thanh toán
            100% học phí của gói học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b> Học sinh chưa sử dụng tài
            khoản.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Thủ tục yêu cầu hoàn trả:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 1:</b>Liên hệ số Hotline
            0971500120 cung cấp thông tin số điện thoại đăng ký tài khoản của
            học sinh và thông tin gói học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 2:</b>Kurio.vn xem xét đề
            nghị hoàn học phí và điều kiện
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 3:</b>Kurio.vn thông báo
            quyết định hoàn học phí và xin thông tin ngân hàng để hoàn lại học
            phí cho học sinh
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 4:</b>Kurio.vn tiến hành
            hoàn phí. Thời gian hoàn tất hoàn phí dự kiến được thực hiện trong
            15 ngày làm việc kể từ ngày xác nhận đủ điều kiện hoàn phí.
          </p>
          <p className="has-text-weight-bold" style={{ color: "#272266" }}>
            Lưu ý: Để thông tin hoàn học phí được xác thực Quý phụ huynh vui
            lòng cung cấp chứng từ chuyển khoản để Kurio tiến hành kiểm tra
            thông tin và hoàn phí đúng quy định.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            2. Nghĩa vụ của người bán và nghĩa vụ của khách hàng trong mỗi
            giao dịch
          </h2>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Nghĩa vụ của người bán:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Hướng dẫn, tư vấn tất cả các thông tin cụ thể liên quan đến dịch
            vụ để Người mua hiểu và có thể sử dụng.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Cung cấp dịch vụ cho Người mua đúng thời hạn sau khi Người mua đã
            thanh toán đầy đủ cho Người bán.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Giải quyết các thắc mắc và những khó khăn trong quá trình sử dụng
            dịch vụ.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Cung cấp các chứng từ, giấy tờ liên quan tới việc Người mua thanh
            toán cho Người bán như hóa đơn, phiếu thu, .... với tổng số tiền
            mà Người mua yêu cầu.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Nghĩa vụ của người mua:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Thực hiện đúng quy định, quy trình liên quan đến dịch vụ do Người
            bán quy định.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Thanh toán đầy đủ cho Người bán số tiền theo mức phí đã quy định.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Hỗ trợ và cung cấp thông tin đầy đủ cho Người bán liên quan tới
            các giao dịch khi Người bán có yêu cầu.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            3. Thông tin về vận chuyển và giao nhận
          </h2>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Hình thức chuyển khoản:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Khi khách hàng đăng ký tài khoản và thực hiện thanh toán mua khóa
            học trên website Kurio.vn thành công. Đội ngũ chăm sóc khách hàng
            của Kurio sẽ kích hoạt tài khoản của khách hàng và liên hệ trực
            tiếp cho khách hàng. Hoặc sau khi chuyển khoản thành công khách
            hàng có thể chủ động liên hệ qua Hotline/Zalo 0971500120 của Kurio
            để thông báo về việc chuyển tiền mua khóa học Kurio sẽ hỗ trợ kích
            hoạt khóa học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Hình thức thanh toán qua cổng thanh toán VNPAY:
          </p>
          <p
            style={{
              color: "#272266",
              fontWeight: 500,
              marginLeft: "2rem",
            }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Khi khách hàng đăng ký tài khoản và chọn hình thức thanh toán mua
            khóa học qua cổng thanh toán VNPAY, sau khi thanh toán xong thì
            tài khoản sẽ được kích hoạt luôn, khách hàng lưu ý đăng nhập đúng
            tài khoản đã đăng ký để có thể đăng nhập sử dụng được khóa học.
          </p>
        </div>

        <section id='tablet-footer' style={{ background: colorWhite, width: "100%", }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học, <br />
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start' style={{ gap: "4rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                  <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                  <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                </div>

                <Link to={'/payment-policy'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                    <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                  </div>
                </Link>
                <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                    <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                  </div>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://www.facebook.com/kurio.vn'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                    </Link>
                    {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>

      {/* Mobile */}
      <div className='container-landingpage' id='mobile-landingpage'>
          {showPopupDownload && (
            <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
              <div id='bottom-popup' className='is-relative' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <span className='icon-cancel is-flex is-justify-content-center is-align-items-center' onClick={handleClosePopupDownload} style={{ position: "absolute",zIndex:2, right: "1rem", top: "15%", width: "1.5rem", height: "1.5rem", borderRadius: "1rem 1rem 0rem 0rem", background: "#E8E3F8" }}>
                  <img src="/images/landing-page/icon-cancel.svg" alt="" />
                </span>
                <div style={{ position: "relative", maxWidth: "100%", width: "100%", bottom: "-0.7rem" }}>
                  <img style={{ width: "100%" }} src="/images/landing-page/image-mobile-popup.svg" alt="" />
                  <button className='btn-mobile-popup is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{ position: "absolute",zIndex:1, top: "60%", right: "5%", transform: "translateY(-50%)", gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "11.5rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <img src="/images/landing-page/icon-download.svg" alt="" />
                    <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>tải app ngay</p>
                  </button>
                </div>
              </div>
            </div>
          )}

        <section id='mobile-header' className='fixedHeader' style={{ background: "#F1EEFC", width: "100%" }}>
          <header style={{ width: "100%", height: "5rem", padding: "0rem 1rem" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
            <img style={{ width: "2.63394rem", height: "3rem" }} src="/images/landing-page/logo-mobile.svg" alt="" />

            <Link to={'/'}>
              <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>Về trang chủ</p>
              </button>
              {/* <button className='btn-landingpage' type="" style={{ width: "12.5rem", height: "3rem", borderRadius: "2rem", border: "1px solid #0A2A66" }}>
                <p style={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: 700, color: colorWhite, lineHeight: "2.25rem" }}>Về trang chủ</p>
              </button> */}
            </Link>
          </header>
        </section>

        <div
          id="landingpage_introduction"
          style={{ padding: "8rem 5rem 0rem", color: "#272266", background: colorWhite, width: "100%" }}
          className="landingpage_desktop landingpage_horizontal-intro-payment-section"
        >
          <h1 style={{ fontSize: "1rem", color: colorTextH1, fontWeight: 700, textTransform: "uppercase" }}>
            chính sách vận chuyển - trả hàng & hoàn tiền
          </h1>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            1. Chính sách hoàn trả
          </h2>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Quý khách có thể được hoàn tiền sản phẩm dịch vụ khi sử dụng dịch
            vụ của website Kurio.vn như sau:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Với gói học 1 tháng :
            Không áp dụng
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b> Với gói học 6 tháng trở
            lên: Học sinh được hoàn trả học phí 100% nếu đáp ứng đủ điều kiện
            theo quy định.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Quy trình hoàn tiền: được thông báo trên website Kurio.vn hoặc
            thông báo trực tiếp cho Khách hàng tại thời điểm Khách hàng đăng
            ký thành công sản phẩm, dịch vụ.
          </p>
          <p style={{ color: "#272266", fontWeight: 500 }}>
            Mọi thắc mắc, khiếu nại về vấn đề chính sách hoàn tiền vui lòng
            liên hệ số hotline <b>0971500120</b> để được hỗ trợ tốt nhất
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Điều kiện:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>Phụ huynh đã thanh toán
            100% học phí của gói học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b> Học sinh chưa sử dụng tài
            khoản.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Thủ tục yêu cầu hoàn trả:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 1:</b>Liên hệ số Hotline
            0971500120 cung cấp thông tin số điện thoại đăng ký tài khoản của
            học sinh và thông tin gói học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 2:</b>Kurio.vn xem xét đề
            nghị hoàn học phí và điều kiện
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 3:</b>Kurio.vn thông báo
            quyết định hoàn học phí và xin thông tin ngân hàng để hoàn lại học
            phí cho học sinh
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>Bước 4:</b>Kurio.vn tiến hành
            hoàn phí. Thời gian hoàn tất hoàn phí dự kiến được thực hiện trong
            15 ngày làm việc kể từ ngày xác nhận đủ điều kiện hoàn phí.
          </p>
          <p className="has-text-weight-bold" style={{ color: "#272266" }}>
            Lưu ý: Để thông tin hoàn học phí được xác thực Quý phụ huynh vui
            lòng cung cấp chứng từ chuyển khoản để Kurio tiến hành kiểm tra
            thông tin và hoàn phí đúng quy định.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            2. Nghĩa vụ của người bán và nghĩa vụ của khách hàng trong mỗi
            giao dịch
          </h2>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Nghĩa vụ của người bán:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Hướng dẫn, tư vấn tất cả các thông tin cụ thể liên quan đến dịch
            vụ để Người mua hiểu và có thể sử dụng.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Cung cấp dịch vụ cho Người mua đúng thời hạn sau khi Người mua đã
            thanh toán đầy đủ cho Người bán.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Giải quyết các thắc mắc và những khó khăn trong quá trình sử dụng
            dịch vụ.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Cung cấp các chứng từ, giấy tờ liên quan tới việc Người mua thanh
            toán cho Người bán như hóa đơn, phiếu thu, .... với tổng số tiền
            mà Người mua yêu cầu.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Nghĩa vụ của người mua:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Thực hiện đúng quy định, quy trình liên quan đến dịch vụ do Người
            bán quy định.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Thanh toán đầy đủ cho Người bán số tiền theo mức phí đã quy định.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Hỗ trợ và cung cấp thông tin đầy đủ cho Người bán liên quan tới
            các giao dịch khi Người bán có yêu cầu.
          </p>
          <h2 style={{ color: "#272266", fontWeight: 700 }}>
            3. Thông tin về vận chuyển và giao nhận
          </h2>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Hình thức chuyển khoản:
          </p>
          <p
            style={{ color: "#272266", fontWeight: 500, marginLeft: "2rem" }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Khi khách hàng đăng ký tài khoản và thực hiện thanh toán mua khóa
            học trên website Kurio.vn thành công. Đội ngũ chăm sóc khách hàng
            của Kurio sẽ kích hoạt tài khoản của khách hàng và liên hệ trực
            tiếp cho khách hàng. Hoặc sau khi chuyển khoản thành công khách
            hàng có thể chủ động liên hệ qua Hotline/Zalo 0971500120 của Kurio
            để thông báo về việc chuyển tiền mua khóa học Kurio sẽ hỗ trợ kích
            hoạt khóa học.
          </p>
          <p
            style={{ color: "#272266", fontWeight: 700, marginLeft: "1rem" }}
          >
            Hình thức thanh toán qua cổng thanh toán VNPAY:
          </p>
          <p
            style={{
              color: "#272266",
              fontWeight: 500,
              marginLeft: "2rem",
            }}
          >
            <b style={{ marginRight: "1rem" }}>-</b>
            Khi khách hàng đăng ký tài khoản và chọn hình thức thanh toán mua
            khóa học qua cổng thanh toán VNPAY, sau khi thanh toán xong thì
            tài khoản sẽ được kích hoạt luôn, khách hàng lưu ý đăng nhập đúng
            tài khoản đã đăng ký để có thể đăng nhập sử dụng được khóa học.
          </p>
        </div>

        <section id='mobile-footer' style={{ background: colorWhite, width: "100%", marginBottom: "10%" }}>
          <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "3rem", padding: "4rem 2rem" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
              <div className='is-flex is-flex-direction-column' style={{ gap: "0.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.75rem" }}>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-phone.svg" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                </div>
                <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Số 128, phố Nguyễn Thái Học,
                    phường Điện Biên, quận Ba Đình, Hà Nội</p>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
              <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
              </div>
              <Link to={'/payment-policy'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                  <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                </div>
              </Link>
              <Link to={'/commitment'}>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "16.29631rem", height: "6.25rem", borderRadius: "1rem", border: "1px solid #FAFBFC", background: "linear-gradient(180deg, #2C5ED1 0%, #2D176B 100%)", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                  <img style={{ width: "3.8025rem", height: "3.5rem" }} src="/images/landing-page/image-commitment.png" alt="" />
                  <div className='' style={{ fontSize: "1.25rem", fontWeight: 700, color: colorWhite, lineHeight: "normal",width:"9.125rem" }}>
                    <p style={{fontSize:"0.875rem"}}>Cam kết</p>
                    <p>QUYỀN LỢI
                    KHÁCH HÀNG</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                  <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'https://www.facebook.com/kurio.vn'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                  </Link>
                  {/* <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                  <Link to={'https://zalo.me/0971500120'}>
                  <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                  </Link>
                </div>
              </div>
            </div>

            <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "4rem" }}>
              <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                <img style={{ width: "6.90625rem", height: "2.625rem" }} src="/images/landing-page/image-bct.png" alt="" />
              </Link>
              <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1rem" }}>
                <img onClick={() => backToTop()} style={{ zIndex: 2, width: "2.5rem", height: "2.5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                <p onClick={() => backToTop()} style={{ zIndex: 2, fontSize: "0.875rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "3.75rem" }}>Về đầu trang</p>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  )
}

export default ReturnPolicy