/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import InputText from "../../components/input/InputText";
import Otp from "../../components/input/Otp";
import {
  confirmResetPasswordPhone,
  createResetPasswordEmail,
  createResetPasswordPhone,
  resetPasswordPhone,
} from "../../config/auth";
import ConstantStrings from "../../constants/ConstantStrings";
import ReCaptChaV2 from "../../templates/helper/ReCaptCha";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const ResetPassword = () => {
  const [error, setError] = useState({
    email: "",
    error: "",
    phone: "",
    otp: "",
    newPassword: "",
    repeatPassword: "",
    captcha: "",
  });
  const [resetByEmail, setResetByEmail] = useState(false);
  const [resetByPhone, setResetByPhone] = useState(false);
  const [resetPasswordState, setResetPasswordState] = useState(false);
  const [complete, setComplete] = useState(false);
  const [remainTime, setRemainTime] = useState(60);
  const [sendOtpAgain, setSendOtpAgain] = useState(false);
  const [valueInput, setValueInput] = useState({ email: "", phone: "" });
  const [resetPassWord, setResetPassword] = useState({
    email: "",
    phone: "",
    newPassword: "",
    repeatPassword: "",
  });
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otp, setOtp] = useState([]);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const countRef = useRef(null);
  const navigate = useNavigate();
  var backgroundImage = {
    backgroundImage: resetPasswordState
      ? "url('/images/auth/bg-desktop-change-pw.svg')"
      : complete
      ? "url('/images/auth/bg-desktop-reset-pw-success.svg')"
      : "url('/images/auth/bg-desktop-reset-pw.svg')",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };
  const cookies = new Cookies();

  var nameField = "";
  if (resetByEmail && !resetByPhone) {
    nameField = "email";
  } else if (!resetByEmail && resetByPhone) {
    nameField = "phone";
  }
  const handleChange = (value, index, name) => {
    if (!verifyOtp && !resetPasswordState) {
      setValueInput({ ...valueInput, [name]: value });
    } else if (verifyOtp) {
      let newValue = [...otp];
      newValue[index] = value;
      setOtp(newValue);
    } else if (resetPasswordState) {
      setResetPassword({ ...resetPassWord, [name]: value });
    }
    setError({
      email: "",
      phone: "",
      otp: "",
      newPassword: "",
      repeatPassword: "",
    });
  };
  const getOtp = () => {
    let newOtp = otp[0];
    for (let index = 1; index < otp.length; index++) {
      newOtp += otp[index];
    }
    return newOtp;
  };
  const handleReset = (type) => {
    if (type === "email") {
      setResetByPhone(false);
      setResetByEmail(true);
    } else {
      setResetByPhone(true);
      setResetByEmail(false);
    }
    setValueInput({ email: "", phone: "" });
    setOtp([]);
    setResetPassword({
      email: "",
      phone: "",
      newPassword: "",
      repeatPassword: "",
    });
    clearInterval(countRef.current);
    setShowCaptcha(false);
    setVerifyOtp(false);
    setResetPasswordState(false);
    setRemainTime(60);
    setSendOtpAgain(false);
    setError({
      email: "",
      phone: "",
      otp: "",
      newPassword: "",
      repeatPassword: "",
    });
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (valueInput.email !== "" || valueInput.phone !== "") {
      if (resetByPhone && phoneno.test(valueInput.phone) === false) {
        setError({ ...error, phone: "Số điện thoại không hợp lệ" });
      } else if (!resetPasswordState && !complete && !verifyOtp) {
        setError({
          email: "",
          phone: "",
          otp: "",
          newPassword: "",
          repeatPassword: "",
        });
        setShowCaptcha(true);
      }
    } else {
      setError({
        ...error,
        [nameField]: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
      });
    }
    if (verifyOtp) {
      // if (remainTime <= 0) {
      //   setError({ ...error, otp: ConstantStrings.TIME_OUT });
      // } else {
      // }
      if (otp.length !== 6) {
        setError({
          ...error,
          otp: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
        });
      } else if (verifyOtp) {
        try {
          var newOtp = getOtp();
          if (resetByPhone) {
            await confirmResetPasswordPhone({
              otp: newOtp,
              phone: valueInput.phone,
            });
          }
          setError({
            email: "",
            phone: "",
            otp: "",
            newPassword: "",
            repeatPassword: "",
          });
          setVerifyOtp(false);
          setResetPasswordState(true);
        } catch (error) {
          setError({
            ...error,
            otp: error.response.data?.errorMessage,
          });
          setOtp([]);
        }
      }
    }
    if (resetPasswordState) {
      if (
        resetPassWord.newPassword === "" ||
        resetPassWord.repeatPassword === ""
      ) {
        resetPassWord.newPassword === ""
          ? setError({
              ...error,
              newPassword: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
            })
          : setError({
              ...error,
              repeatPassword: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
            });
      } else if (resetPassWord.newPassword !== resetPassWord.repeatPassword) {
        setError({
          ...error,
          newPassword: getConstantStringByLanguage(ConstantStrings.PASSWORD_INCORRECT),
          repeatPassword: getConstantStringByLanguage(ConstantStrings.PASSWORD_INCORRECT),
        });
      } else {
        const newOtp = getOtp();
        try {
          if (resetByEmail) {
            // await resetPasswordEmail({
            //   otp: newOtp,
            //   newPassword: resetPassWord.newPassword,
            //   requestId: requestId,
            // });
          }
          if (resetByPhone) {
            await resetPasswordPhone({
              otp: newOtp,
              newPassword: resetPassWord.newPassword,
              phone: valueInput.phone,
            });
          }
          setError({
            email: "",
            phone: "",
            otp: "",
            newPassword: "",
            repeatPassword: "",
          });
          setResetPasswordState(false);
          setVerifyOtp(false);
          setComplete(true);
        } catch (error) {
          if (error?.response?.data?.errorMessage) {
            setError({
              ...error,
              password: error?.response?.data?.errorMessage,
            });
          }
          setError({
            ...error,
            error: error.response.data?.errorMessage,
          });
        }
      }
    }
    if (complete) {
      navigate("/signin");
    }
  };

  if (remainTime <= 0) {
    clearInterval(countRef.current);
  }
  const sendOtp = () => {
    if (verifyOtp && remainTime === 0) {
      setSendOtpAgain(true);
      clearInterval(countRef.current);
      setOtp([]);
      setError({
        email: "",
        phone: "",
        otp: "",
        newPassword: "",
        repeatPassword: "",
      });
      setRemainTime(0);
      setVerifyOtp(true);
      setShowCaptcha(true);
    }
  };
  if (remainTime <= 0 || !verifyOtp) {
    clearInterval(countRef.current);
  }
  const handleChangeCaptcha = async (captcha) => {
    await handleSubmitCaptcha(captcha);
  };
  const handleSubmitCaptcha = async (captcha) => {
    if (
      // (resetByPhone || resetByEmail) &&
      !verifyOtp &&
      !resetPasswordState &&
      !complete
    ) {
      try {
        if (resetByEmail && !verifyOtp) {
          await createResetPasswordEmail({
            email: valueInput.email,
            captcha,
          });
          cookies.set("email", valueInput.email);
        } else if (resetByPhone && !verifyOtp) {
          await createResetPasswordPhone({
            phone: valueInput.phone,
            captcha,
          });
        }
        setError({
          email: "",
          phone: "",
          otp: "",
          newPassword: "",
          repeatPassword: "",
        });
        setVerifyOtp(true);
        clearInterval(countRef.current);
        setRemainTime(60);
        setShowCaptcha(false);
      } catch (error) {
        setError({
          ...error,
          captcha: error.response.data?.errorMessage,
        });
        // setTimeout(() => {
        //   setShowCaptcha(false);
        // }, 3000);
      }
    }
    //send otp again
    if (verifyOtp) {
      try {
        if (verifyOtp && !resetByEmail) {
          await createResetPasswordPhone({ phone: valueInput.phone, captcha });
        } else if (verifyOtp && resetByEmail) {
          await createResetPasswordEmail({ email: valueInput.email, captcha });
        }
        setSendOtpAgain(true);
        setShowCaptcha(false);
        clearInterval(countRef.current);
        setOtp([]);
        setError({
          email: "",
          phone: "",
          otp: "",
          newPassword: "",
          repeatPassword: "",
          captcha: "",
        });
        setRemainTime(60);
        setVerifyOtp(true);
      } catch (error) {
        setError({ ...error, captcha: error.response.data?.errorMessage });
        // setTimeout(() => {
        //   setShowCaptcha(false);
        // }, 3000);
      }
    }
  };
  const handleSetShowCaptcha = (state) => {
    setShowCaptcha(state);
    setSendOtpAgain(state);
    setVerifyOtp(state);
  };
  useEffect(() => {
    const countDown = () => {
      countRef.current = setInterval(() => {
        if (remainTime <= 0) {
          clearInterval(countRef.current);
          setRemainTime(0);
          return false;
        } else {
          setRemainTime((remainTime) => remainTime - 1);
        }
      }, 1000);
    };
    if (verifyOtp && !sendOtpAgain) {
      countDown();
    }
    if (sendOtpAgain) {
      clearInterval(countRef.current);
      setSendOtpAgain(false);
      // countDown()
    }
    if (!verifyOtp) {
      clearInterval(countRef.current);
    }
    return () => clearInterval(countRef.current);
  }, [sendOtpAgain, verifyOtp, remainTime]);
  return (
    <div className="hero is-fullheight is-relative" style={backgroundImage}>
      <div
        style={{
          width: "21.5rem",
          height: "8.3125rem",
          backgroundImage: "url('/images/auth/logo-reset-pw.svg')",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          left: "0",
          bottom: "0",
        }}
      ></div>
      {showCaptcha && (
        <>
          <div
            className="is-flex is-flex-direction-column m-auto is-align-items-center"
            style={{ gap: "2rem" }}
          >
            <ReCaptChaV2
              handleChangeCaptcha={(captcha) => handleChangeCaptcha(captcha)}
            />
            <p
              className="has-text-danger subtitle is-size-6 m-0 has-text-centered"
              style={{ width: "35rem" }}
            >
              {error.captcha}
            </p>
            {error?.captcha?.length > 0 && (
              <PrimaryButtonPageLevel
                isDarkColor={true}
                onClick={() => handleSetShowCaptcha(false)}
                style={{ height: "4rem" }}
              >
                Quay lại
              </PrimaryButtonPageLevel>
            )}
          </div>
        </>
      )}
      {!showCaptcha && (
        <div className="columns m-0" style={{ padding: "3.5rem" }}>
          <div className="column m-0 p-0">
            <div className=" ">
              <Link to={`/signin`}>
                <p
                  className="is-uppercase  is-align-items-center has-text-kurio-main-color-black"
                  style={{ fontSize: "0.875rem" }}
                >
                  <span className="has-text-weight-bold mr-2">&lt;</span>
                  Về đăng nhập
                </p>
              </Link>
            </div>
          </div>

          <div
            className={
              complete
                ? "column m-0 is-one-third has-background-white has-text-kurio-main-color-black"
                : "column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between has-background-white m-0 has-text-kurio-main-color-black"
            }
            style={{
              padding: complete
                ? "5.125rem 4rem 5.125rem 3.5rem"
                : "3.1875rem 4rem 2.5rem 3.5rem",
              height: "40rem",
              width: "27.5rem",
              maxWidth: "27.5rem",
              boxShadow: "0.75rem 0.6875rem #0A2A66",
              border: "0.0625rem solid #0A2A66",
            }}
          >
            {complete ? (
              <div
                className="is-flex is-flex-direction-column"
                style={{ gap: "2rem" }}
              >
                <p
                  style={{ fontSize: "2.25rem", lineHeight: "2.75rem" }}
                  className=" has-text-weight-bold has-text-centered"
                >
                  Đặt lại mật khẩu thành công
                </p>
                <div
                  style={{
                    height: "10rem",
                    width: "20rem",
                    backgroundImage:
                      "url('/images/auth/image-reset-pw-success.svg')",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <p style={{ fontSize: "0.875rem", lineHeight: "1rem" }}>
                  Bạn đã đặt lại mật khẩu thành công cho cho tài khoản
                  <span className="has-text-weight-bold ml-1">
                    {valueInput.phone}
                  </span>{" "}
                  Đăng nhập ngay để tiếp tục trải nghiệm Toán tư duy cùng KURIO.
                </p>
                <PrimaryButtonPageLevel
                  onClick={handleSubmit}
                  style={{
                    height: "4rem",
                    width: "20rem",
                    maxWidth: "20rem",
                  }}
                  type="submit"
                >
                  Đăng nhập
                </PrimaryButtonPageLevel>
              </div>
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <input hidden type="submit" />
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center p-0 "
                    style={{ gap: "2rem", width: "20rem" }}
                  >
                    <h2
                      style={{ fontSize: "2.25rem" }}
                      className=" has-text-weight-bold"
                    >
                      Quên mật khẩu
                    </h2>

                    {!resetByEmail &&
                      !resetByPhone &&
                      !resetPasswordState &&
                      !verifyOtp &&
                      !complete && (
                        <div
                          className="is-flex is-flex-direction-column"
                          style={{ gap: "1.5rem" }}
                        >
                          <PrimaryButtonPageLevel
                            style={{
                              height: "4rem",
                              width: "20rem",
                              maxWidth: "20rem",
                            }}
                            onClick={() => {
                              setResetByPhone(true);
                              setResetByEmail(false);
                            }}
                          >
                            Dùng số điện thoại
                          </PrimaryButtonPageLevel>
                          <PrimaryButtonPageLevel
                            style={{
                              height: "4rem",
                              width: "20rem",
                              maxWidth: "20rem",
                            }}
                            onClick={() => {
                              setResetByPhone(false);
                              setResetByEmail(true);
                            }}
                          >
                            Dùng Email
                          </PrimaryButtonPageLevel>
                        </div>
                      )}
                    {!resetPasswordState &&
                      (resetByEmail || resetByPhone) &&
                      !verifyOtp &&
                      !complete && (
                        <>
                          <div>
                            <div
                              className=""
                              style={{ width: "20rem", height: "5rem" }}
                            >
                              <InputText
                                label={
                                  resetByEmail
                                    ? "Email đăng nhập"
                                    : "Nhập số điện thoại"
                                }
                                type={"text"}
                                value={valueInput.nameField}
                                name={resetByEmail ? "email" : "phone"}
                                onChange={(e) =>
                                  handleChange(e.target.value, 0, e.target.name)
                                }
                              />
                            </div>
                            {resetByEmail && (
                              <p
                                className="is-italic has-text-justified"
                                style={{
                                  marginTop: "0.5rem",
                                  fontSize: "0.875rem",
                                  lineHeight: "normal",
                                }}
                              >
                                <b className="is-underlined">Lưu ý:</b> Chỉ dùng
                                cho các tài khoản phiên bản cũ hoặc các tài
                                khoản đăng ký theo email trường
                              </p>
                            )}
                          </div>
                          {error[nameField] && (
                            <p className="has-text-danger subtitle is-size-6 m-0">
                              {error[nameField]}
                            </p>
                          )}
                          <PrimaryButtonPageLevel
                            onClick={handleSubmit}
                            style={{
                              height: "4rem",
                              width: "20rem",
                              maxWidth: "20rem",
                            }}
                            type="submit"
                          >
                            Tiếp theo
                          </PrimaryButtonPageLevel>
                          {resetByPhone ? (
                            <p
                              className="is-underlined is-clickable"
                              onClick={() => handleReset("email")}
                              style={{
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                              }}
                            >
                              Dùng email?
                            </p>
                          ) : (
                            <p
                              className="is-underlined is-clickable"
                              onClick={() => handleReset("phone")}
                              style={{
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                              }}
                            >
                              Dùng số điện thoại?
                            </p>
                          )}
                        </>
                      )}
                    {!resetPasswordState && verifyOtp && !complete && (
                      <>
                        <div
                          className="is-flex is-flex-direction-column is-align-items-center"
                          style={{ gap: "1.5rem", fontSize: "0.875rem" }}
                        >
                          {resetByEmail ? (
                            <div className=" has-text-justified">
                              <p>
                                KURIO đã gửi một email xác thực tới hòm thư của
                                bạn tại địa chỉ email
                              </p>
                              <span className="has-text-weight-bold">
                                {valueInput.email}.
                              </span>
                              <span className="ml-1">
                                Xin hãy kiểm tra hộp thư đến (và cả hộp thư spam
                                nếu bạn không tìm thấy email của chúng tôi) và
                                làm theo hướng dẫn.
                              </span>
                              {/* <p>Vui lòng không tắt tab này.</p> */}
                            </div>
                          ) : (
                            <>
                              <p>
                                Mã OTP gồm 6 chữ số vừa được gửi đến số điện
                                thoại của bạn. Điền mã OTP để tiếp tục
                              </p>
                              <Otp
                                value={otp}
                                resetFocus={verifyOtp && error?.otp?.length > 0}
                                onChange={(value, name, index) =>
                                  handleChange(value, name, index)
                                }
                              />
                              {error.otp !== "" && verifyOtp && (
                                <div className="has-text-danger">
                                  <span>{error.otp}</span>
                                </div>
                              )}
                            </>
                          )}

                          {resetByEmail ? (
                            <p>
                              <span>Chưa nhận được mail ?</span>
                              <span
                                onClick={sendOtp}
                                className="is-underlined has-text-weight-bold is-clickable is-uppercase ml-3"
                              >
                                Gửi lại ({remainTime}S)
                              </span>
                            </p>
                          ) : (
                            <>
                              <span>Chưa nhận được mã OTP ?</span>
                              <span
                                onClick={sendOtp}
                                className="is-underlined has-text-weight-bold is-clickable"
                              >
                                Gửi lại ({remainTime}S)
                              </span>
                            </>
                          )}
                          {resetByPhone ? (
                            <p
                              className="is-underlined is-clickable"
                              onClick={() => handleReset("email")}
                              style={{
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                              }}
                            >
                              Dùng email?
                            </p>
                          ) : (
                            <p
                              className="is-underlined is-clickable"
                              onClick={() => handleReset("phone")}
                              style={{
                                fontSize: "0.875rem",
                                lineHeight: "normal",
                              }}
                            >
                              Dùng số điện thoại?
                            </p>
                          )}
                        </div>
                        {resetByPhone && (
                          <PrimaryButtonPageLevel
                            onClick={handleSubmit}
                            disabled={otp.length !== 6}
                            style={{
                              height: "4rem",
                              width: "20rem",
                              maxWidth: "20rem",
                            }}
                            type="submit"
                          >
                            Tiếp theo
                          </PrimaryButtonPageLevel>
                        )}
                      </>
                    )}
                    {!verifyOtp && resetPasswordState && !complete && (
                      <>
                        <div
                          className="is-flex is-flex-direction-column "
                          style={{ gap: "1.5rem" }}
                        >
                          <p className="has-text-danger has-text-centered subtitle is-size-6">
                            {error.error}
                          </p>
                          <div style={{ width: "20rem", height: "5rem" }}>
                            <InputText
                              readOnly={true}
                              label={"Email đăng nhập"}
                              type="text"
                              value={valueInput.email || valueInput.phone}
                              name={resetByEmail ? "email" : "phone"}
                              onChange={(e) =>
                                handleChange(e.target.value, 0, e.target.name)
                              }
                            />
                            {error.email && (
                              <p className="has-text-danger subtitle is-size-6">
                                {error.email}
                              </p>
                            )}
                          </div>
                          <div style={{ width: "20rem", height: "5rem" }}>
                            <InputText
                              label={"Tạo mật khẩu"}
                              type="password"
                              value={resetPassWord.newPassword}
                              name="newPassword"
                              onChange={(e) =>
                                handleChange(e.target.value, 0, e.target.name)
                              }
                            />

                            {error.password && (
                              <p className="has-text-danger subtitle is-size-7 mt-1 ">
                                {error.password}
                              </p>
                            )}
                          </div>
                          <div
                            style={{
                              width: "20rem",
                              height: "5rem",
                              marginTop: error.password ? "1rem" : "0rem",
                            }}
                          >
                            <InputText
                              label={"Xác nhận mật khẩu"}
                              type="password"
                              value={resetPassWord.repeatPassword}
                              name="repeatPassword"
                              onChange={(e) =>
                                handleChange(e.target.value, 0, e.target.name)
                              }
                            />

                            {error.repeatPassword && (
                              <p className="has-text-danger subtitle is-size-7 mt-1 mb-3">
                                {error.repeatPassword}
                              </p>
                            )}
                          </div>
                        </div>
                        <PrimaryButtonPageLevel
                          onClick={handleSubmit}
                          style={{
                            height: "4rem",
                            width: "20rem",
                            maxWidth: "20rem",
                            marginTop: error.repeatPassword ? "0.5rem" : "0rem",
                          }}
                          type="submit"
                        >
                          Tiếp theo
                        </PrimaryButtonPageLevel>
                      </>
                    )}
                    {complete && !resetPasswordState && (
                      <>
                        <div className="field ">
                          <span>
                            Bạn đã hoàn thành reset mật khẩu cho tài khoản
                          </span>
                          <span className="has-text-weight-bold">
                            {valueInput.email}.
                          </span>
                          <span>
                            Đăng nhập ngay để trải nghiệm Toán tư duy cùng KURIO
                          </span>
                          <button
                            className="button mt-6 is-medium is-fullwidth is-black is-uppercase has-text-weight-semibold"
                            style={{ borderRadius: "0.5rem" }}
                            type="submit"
                          >
                            Đăng nhập
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </form>
                <div
                  className="has-text-centered is-flex is-flex-direction-column is-align-items-center"
                  style={{
                    gap:
                      error.repeatPassword || error.password
                        ? "0.25rem"
                        : "1rem",
                  }}
                >
                  <div>
                    <div
                      className={``}
                      style={{ width: "2.125rem", height: "0.5rem" }}
                    ></div>
                  </div>
                  <Link
                    className=" has-text-kurio-main-color-black"
                    to="/register"
                  >
                    <p style={{ fontSize: "0.875rem" }}>
                      <span className=" mr-2">Chưa có tài khoản ?</span>
                      <span className="has-text-weight-bold is-underlined">
                        Đăng ký
                      </span>
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="column m-0 p-0"></div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
