/* eslint-disable react-hooks/exhaustive-deps */
// a React template for Gapfill type question

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import VerticalGapFill from "../../components/question/VerticalGapFill";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import {
  setQuestionState,
  setQuestionVisible,
} from "../../reducers/questionsSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";
import { getConstantStringByLanguage } from "../helper/getConstantStringByLanguage";

function VerticalGapfill({
  data,
  order,
  sections,
  question,
  explanation,
  backgroundImage,
  section,
  slide,
  submitLesson,
  nextSection,
}) {
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  const dispatch = useDispatch();
  const [checkInput, setCheckInput] = useState(false);
  const [answers, setAnswers] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.answers;
    } else {
      return [];
    }
  });
  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.correctAnswers;
    } else {
      return [];
    }
  });
  const [gapFillWithImages, setGapFillWithImages] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [explanationState, setExplanationState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.explanationState;
    } else {
      return false;
    }
  });
  const [checkingState, setCheckingState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.checkingState;
    } else {
      return false;
    }
  });
  const [seenAnswer, setSeenAnswer] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.seenAnswer;
    } else {
      return false;
    }
  });
  const [maxWidth, setMaxWidth] = useState(60);
  const [changeInput, setChangeInput] = useState(false);

  const divRef = useRef(null);

  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    height: "35rem",
    width: "50%",
  };

  var backgroundLeftStyle = {};
  if (
    backgroundImage[0] !== null &&
    backgroundImage[0] &&
    checkImage(backgroundImage[0])
  ) {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
      // backgroundImage: `url(${memoizedImageData[1][0]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
    };
  }
  if (
    backgroundImage[1] !== null &&
    backgroundImage[1] &&
    checkImage(backgroundImage[1])
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      // backgroundImage: `url(${memoizedImageData[1][1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      backgroundColor: "#E9EEF8",
    };
  }

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      questionChoices !== null &&
      questionChoices &&
      questionChoices.length > 0 &&
      questionChoices[0] !== null &&
      questionChoices[0].length > 0
    ) {
      setGapFillWithImages(true);
    }
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
    setChangeInput(true);
  };

  const handleCheck = () => {
    //alert please fill in all gaps if not all gaps are filled
    if (
      answers.length < questionSolutions.length ||
      answers.some((item) => item === undefined || item === "")
    ) {
      setCheckInput(true);
      return;
    }
    setCheckingState(true);
    //check answer to each gap and set correctAnswers
    let newCorrectAnswers = [];
    for (let i = 0; i < questionSolutions.length; i++) {
      if (answers[i] === questionSolutions[i]) {
        newCorrectAnswers[i] = true;
      } else {
        newCorrectAnswers[i] = false;
      }
    }
    setCorrectAnswers(newCorrectAnswers);
    //check if all answers are correct
    let allCorrect = true;
    for (let i = 0; i < newCorrectAnswers.length; i++) {
      if (newCorrectAnswers[i] === false) {
        allCorrect = false;
      }
    }
    setCorrect(allCorrect);
  };

  const handleReset = () => {
    setAnswers([]);
    setCorrect(false);
    setExplanationState(false);
    setCheckingState(false);
    setCheckInput(false);
    setCorrectAnswers([]);
    setChangeInput(false);
  };

  const handleShowExplanation = () => {
    setExplanationState(true);
    setSeenAnswer(true);
  };

  const showNextSlide = () => {
    dispatch(
      setQuestionState({
        section: section,
        index: slide,
        state: {
          answers: answers,
          correctAnswers: correctAnswers,
          checkingState: checkingState,
          explanationState: explanationState,
          seenAnswer: seenAnswer,
        },
      })
    );
    submitLesson(section, slide);
    if (sections[section].slides[slide].isLast) {
      nextSection();
    } else {
      dispatch(setQuestionVisible({ section: section, index: slide + 1 }));
    }
  };
  return (
    <div style={{ height: `35rem` }}>
      <ScrollRef />
      <div className="is-flex is-flex-direction-row">
        <div style={{ width: "50%" }} ref={divRef}>
          <BackgroundImageFromUrl
            style={backgroundLeftStyle}
            objectId={backgroundImage[0]}
            className={`${
              !checkImage(backgroundImage[0])
                ? "has-background-kurio-main-color-light-gray"
                : ""
            }`}
          ></BackgroundImageFromUrl>
        </div>
        <BackgroundImageFromUrl
          style={{
            ...backgroundStyle,
            padding: "2rem",
            width: "50%",
            position: "relative",
          }}
          className={`${
            !checkImage(backgroundImage[1])
              ? "has-background-kurio-main-color-light-gray"
              : ""
          }  is-flex is-flex-direction-column is-justify-content-space-between has-text-left`}
          objectId={backgroundImage[1]}
        >
          <div>
            <div>
              {data?.template.question !== null &&
                data?.template.question &&
                data?.template.question.length > 0 && (
                  <div className="mb-4">
                    <QuestionNumberTag
                      number={order === 0 ? data?.order : order}
                    />
                  </div>
                )}
            </div>
            {!explanationState && (
              <>
                {questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div className="mb-4 has-text-kurio-main-color-black">
                      {questionTexts}
                    </div>
                  )}
              </>
            )}

            {/* if gapFillWithImages is false
                if questionChoices has length 4, render the textinput in a 2x2 grid
                else use columns is-multiline is-centered
            */}

            {!explanationState && !gapFillWithImages && (
              <div>
                <div
                  className="columns m-0 is-multiline is-flex-direction-column is-centered"
                  style={{ gap: "1rem", padding: "0.5rem" }}
                >
                  {questionChoices.map((choice, index) => {
                    return (
                      <VerticalGapFill
                        key={index}
                        index={index}
                        onChange={handleChange(index)}
                        answer={answers[index] || ""}
                        correctAnswers={correctAnswers[index]}
                        checkingState={checkingState}
                        choice={questionChoices[index]}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {explanationState && (
              <div
                className="explanation-container main-content-multiple-vertical has-text-kurio-main-color-black is-flex is-flex-direction-column"
                style={{
                  overflow: "hidden",
                  height: "16.5rem",
                  overflowY: "scroll",
                  gap: "1rem",
                }}
              >
                <div>
                  {((explanationTexts !== null &&
                    explanationTexts !== undefined &&
                    explanationTexts?.length > 0) ||
                    (explanationImages !== null &&
                      explanationImages !== undefined &&
                      explanationImages?.length > 0)) &&
                    explanation.texts?.length >= explanation.images?.length && (
                      <>
                        {explanation.texts.map((item, index) => (
                          <div
                            key={index}
                            className="is-flex is-flex-direction-column is-align-items-center"
                            style={{ gap: "1.5rem" }}
                          >
                            {explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              item?.length > 0 && (
                                <div key={item}>{explanationTexts[index]}</div>
                              )}
                            {/* only appears when explanationImages has length greater than 0 */}
                            {explanationImages !== null &&
                              explanationImages !== undefined &&
                              explanationImages[index]?.length > 0 && (
                                <ImageFromUrl
                                  objectId={explanationImages[index]}
                                />
                              )}
                          </div>
                        ))}
                      </>
                    )}
                  {((explanationTexts !== null &&
                    explanationTexts !== undefined &&
                    explanationTexts?.length > 0) ||
                    (explanationImages !== null &&
                      explanationImages !== undefined &&
                      explanationImages?.length > 0)) &&
                    explanation.texts?.length < explanation.images?.length && (
                      <>
                        {explanationImages.map((item, index) => (
                          <div
                            key={item}
                            className="is-flex is-flex-direction-column is-align-items-center"
                            style={{ gap: "1.5rem" }}
                          >
                            {explanationTexts !== null &&
                              explanationTexts !== undefined &&
                              explanation.texts[index]?.length > 0 && (
                                <div key={index}>{explanationTexts[index]}</div>
                              )}
                            {/* only appears when explanationImages has length greater than 0 */}
                            {explanationImages !== null &&
                              explanationImages !== undefined &&
                              item?.length > 0 && (
                                <ImageFromUrl
                                  objectId={explanationImages[index]}
                                />
                              )}
                          </div>
                        ))}
                      </>
                    )}
                </div>
                {/* only appears when explanationImages has length greater than 0 */}
              </div>
            )}
          </div>

          <div
            className={` is-flex is-flex-direction-row ${
              (checkingState && !correct) || !checkingState
                ? "is-align-items-flex-end is-justify-content-space-between"
                : "is-align-items-flex-end"
            } `}
            style={{
              height: "9rem",
            }}
          >
            {/* only appears when checkingState is false */}
            {!checkingState && !seenAnswer && (
              <>
                <PrimaryButtonQuestion
                  className="button"
                  disabled={!changeInput}
                  onClick={handleCheck}
                >
                  {getConstantStringByLanguage(ConstantStrings.SUBMIT)}
                </PrimaryButtonQuestion>
                {checkInput && (
                  <p
                    style={{
                      color: "red",
                      display: "inline-block",
                      fontSize: "1.1rem",
                      lineHeight: "2.5rem",
                    }}
                  >
                    {ConstantStrings.REQUEST_FILL_IN_ALL_GAPS}
                  </p>
                )}
              </>
            )}

            {/* only appears when checkingState is true and the answers are incorrect */}
            <div>
              {/* Next and "Xem giai thich" button appears when checkingState is true */}
              <div
                className="is-flex is-flex-direction-column"
                style={{ gap: "1rem" }}
              >
                {checkingState && explanationState === false && (
                  <span>
                    {explanation !== null &&
                      explanation !== undefined &&
                      ((explanationImages !== null &&
                        explanationImages !== undefined &&
                        explanationImages.length > 0) ||
                        (explanationTexts !== null &&
                          explanationTexts !== undefined &&
                          explanationTexts.length > 0)) && (
                        <SecondaryButtonQuestion
                          className="button"
                          defaultColor={"is-kurio-purple-light-2"}
                          onClick={handleShowExplanation}
                        >
                          {getConstantStringByLanguage(ConstantStrings.SHOW_EXPLANATION)}
                        </SecondaryButtonQuestion>
                      )}
                  </span>
                )}
                {checkingState &&
                  answers.toString() !== questionSolutions.toString() &&
                  !seenAnswer && (
                    <PrimaryButtonQuestion
                      className="button"
                      onClick={handleReset}
                    >
                      {getConstantStringByLanguage(ConstantStrings.TRY_AGAIN)}
                    </PrimaryButtonQuestion>
                  )}

                {/* "Xem cau hoi" button appears when explanationState is true */}
                {explanationState && (
                  <SecondaryButtonQuestion
                    className="button"
                    defaultColor={"is-kurio-purple-light-2"}
                    onClick={() => {
                      setExplanationState(false);
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.SHOW_QUESTION)}
                  </SecondaryButtonQuestion>
                )}
                {(seenAnswer ||
                  (checkingState &&
                    answers.toString() === questionSolutions.toString())) && (
                  <div
                    className={`${
                      (
                        slide + 1 > sections[section].slides.length - 1
                          ? sections[section].isFinished
                          : sections[section].slides[slide + 1].visible
                      )
                        ? "is-hidden"
                        : ""
                    }`}
                  >
                    <PrimaryButtonQuestion onClick={showNextSlide}>
                      {getConstantStringByLanguage(ConstantStrings.NEXT)}
                    </PrimaryButtonQuestion>
                  </div>
                )}
              </div>
            </div>

            {((checkingState && !correct && !explanationState && !seenAnswer) ||
              (!checkingState && !explanationState && !seenAnswer)) && (
              <div
                className={`${
                  (
                    slide + 1 > sections[section].slides.length - 1
                      ? sections[section].isFinished
                      : sections[section].slides[slide + 1].visible
                  )
                    ? "is-hidden"
                    : ""
                }`}
                style={{
                  float: "right",
                }}
              >
                <TertiaryButtonQuestion
                  onClick={showNextSlide}
                  suffix={"admin/frontend-images/button/button-skip-icon"}
                >
                  {getConstantStringByLanguage(ConstantStrings.SKIP)}
                </TertiaryButtonQuestion>
              </div>
            )}
          </div>
        </BackgroundImageFromUrl>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(VerticalGapfill);
