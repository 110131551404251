import React, { useEffect, useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const SecondaryButtonQuestion = ({
  defaultColor,
  hoveredColor,
  clickedColor,
  disabled,
  isClick,
  prefix,
  suffix,
  onClick,
  children,
  style,
  prefixIconStyle,
  suffixIconStyle,
  iconStyle,
  isDefault=true
}) => {
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  const audio = new Audio("/sounds/click.mp3");
  var buttonStyle = {
    width: "12.5rem",
    height: "3rem",
    borderRadius: "1.5rem",
    boxShadow: "none",
  };
  var prefixStyle = {
    width: "1.125rem",
    height: "1.125rem",
    zIndex: "100",
    // backgroundImage: `url(${prefix}.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  var suffixStyle = {
    width: "1.125rem",
    height: "1.125rem",
    zIndex: "100",
    // backgroundImage: `url(${suffix}.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  if (hoveredState) {
    buttonStyle = {
      ...buttonStyle,
      boxShadow: "0rem 0.375rem 1rem rgba(10, 42, 102, 0.25)",
    };
  }
  var objectIdPrefix = `${prefix}.svg`;
  var objectIdSuffix = `${suffix}.svg`;
  if (disabled && isDefault) {
    objectIdPrefix = `${prefix}-disabled.svg`;
    objectIdSuffix = `${suffix}-disabled.svg`;
  }
  if (clickedState && isDefault) {
    objectIdPrefix = `${prefix}-clicked.svg`;
    objectIdSuffix = `${suffix}-clicked.svg`;
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      audio.play();
      setHoveredState(false);
      if (onClick) {
        onClick();
      } else {
        setClickedState(!clickedState);
        setDefaultState(clickedState);
      }
    }
  };
  const handleBlur = () => {
    setClickedState(false);
    setDefaultState(!disabled);
  };
  useEffect(() => {
    if (disabled === false || disabled === undefined) {
      setDefaultState(true);
    } else {
      setDefaultState(false);
      setClickedState(false);
    }
  }, [disabled]);
  return (
    <div
      className="main is-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      style={{
        ...style,
        maxWidth: `${style?.maxWidth ? `${style?.maxWidth}` : "12.5rem"}`,
      }}
    >
      <button
        className={`${
          defaultState
            ? defaultColor
              ? `${defaultColor}`
              : "is-kurio-main-color-white"
            : hoveredState
            ? hoveredColor
              ? `${hoveredColor}`
              : "is-kurio-main-color-white"
            : clickedState
            ? clickedColor
              ? `${clickedColor}`
              : "is-kurio-purple-dark"
            : disabled
            ? "is-kurio-gray-light-2"
            : ""
        }  button is-flex is-flex-direction-row is-align-items-center is-justify-content-center main-color has-text-weight-bold is-uppercase has-text-centered ${
          disabled
            ? "has-text-kurio-gray-main-accent"
            : clickedState
            ? "has-text-kurio-main-color-white"
            : style?.color
            ? ""
            : "has-text-kurio-main-color-purple"
        }`}
        style={{ ...buttonStyle, ...style, gap: "0.75rem" }}
        onClick={handleClick}
        type="button"
      >
        {prefix && (
          <BackgroundImageFromUrl
            objectId={objectIdPrefix}
            style={{ ...prefixStyle, ...prefixIconStyle, ...iconStyle }}
          ></BackgroundImageFromUrl>
        )}
        {children}
        {suffix && (
          <BackgroundImageFromUrl
            objectId={objectIdSuffix}
            style={{ ...suffixStyle, ...suffixIconStyle, ...iconStyle }}
          ></BackgroundImageFromUrl>
        )}
      </button>
    </div>
  );
};

export default SecondaryButtonQuestion;
