// Description: Drag and Drop question template
import React, { memo, useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import {
  setQuestionState,
  setQuestionVisible,
} from "../../reducers/questionsSlice";
import jsonParser from "../helper/jsonParser";
import { ChoiceContainer } from "./dragdrop-components/ChoiceContainer";
import { ItemTypes } from "./dragdrop-components/ItemTypes";
import { Target } from "./dragdrop-components/Target";
import { getConstantStringByLanguage } from "../helper/getConstantStringByLanguage";

export const DragDrop = memo(function DragDrop({
  sections,
  question,
  explanation,
  backgroundImage,
  section,
  slide,
  submitLesson,
  nextSection,
  setScrollTop,
}) {
  const dispatch = useDispatch();
  const questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  const questionChoices = question.choices;
  const questionTargets = question.targets?.length > 0 ? question.targets : question.choices.map((item) => "");
  const questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const correctStyle = {
    backgroundColor: "#E5F0CC",
    borderColor: "#4B9631",
  };

  const incorrectStyle = {
    backgroundColor: "#F8E3E3",
    borderColor: "#E34D4D",
  };
  var iconStyle = {
    width: "1.5rem",
    height: "1.5rem",
    zIndex: 100,
    position: "relative",
    top: "-0.75rem",
    left: "0rem",
  };

  //create a state variable to store the correct state
  const [isCorrect, setIsCorrect] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.isCorrect;
    } else {
      return false;
    }
  });
  //create a state variable to store the checking state
  const [checkingState, setCheckingState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  const [explanationState, setExplanationState] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.explanationState;
    } else {
      return false;
    }
  });

  const [answerStyle, setAnswerStyle] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.answerStyle;
    } else {
      return [];
    }
  });

  const [answers] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.answers;
    } else {
      return null;
    }
  });
  const [indexItems] = useState(() => {
    if (sections[section].slides[slide].state) {
      return sections[section].slides[slide].state.indexItems;
    } else {
      return null;
    }
  });

  //create a state variable to store the choices
  const [choices] = useState(
    questionChoices.map((choice, index) => {
      return {
        name: choice,
        type: ItemTypes.CHOICE,
        id: index,
      };
    })
  );

  //create a state variable to store the targets
  const [targets, setTargets] = useState(
    questionTargets.map((target, index) => {
      return {
        name: target,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: answers ? answers[index] : null,
        indexItem: indexItems ? indexItems[index] : null,
        id: index,
      };
    })
  );
  const isChoiceSelected = (choice, indexItem) => {
    let selected = false;
    targets.forEach((target) => {
      if (target.lastDroppedItem === choice && target.indexItem === indexItem) {
        selected = true;
      }
    });
    return selected;
  };

  const dropOutside = (item) => {
    if (item.index !== -1) {
      setTargets((targets) => {
        return targets.map((target, i) => {
          if (i === item.index) {
            return {
              ...target,
              lastDroppedItem: null,
              indexItem: null
            };
          } else {
            return target;
          }
        });
      });
      setAnswerStyle((answerStyle) => {
        return answerStyle.map((style, i) => {
          if (i === item.index) {
            return {
              $set: null,
            };
          } else {
            return style;
          }
        });
      });
    }
  };
  const handleDrop = useCallback((index, item) => {
    if (item.index !== -1) {
      setTargets((targets) => {
        return targets.map((target, i) => {
          if (i === index) {
            return {
              ...target,
              lastDroppedItem: item.name,
              indexItem: item.indexItem,
            };
          } else if (i === item.index) {
            return {
              ...target,
              lastDroppedItem: null,
              indexItem: null,
            };
          } else {
            return target;
          }
        });
      });
      setAnswerStyle((answerStyle) => {
        return answerStyle.map((style, i) => {
          if (i === index) {
            return {
              $set: null,
            };
          } else if (i === item.index) {
            return {
              $set: null,
            };
          } else {
            return style;
          }
        });
      });
    } else {
      setTargets((targets) => {
        return targets.map((target, i) => {
          if (i === index) {
            return {
              ...target,
              lastDroppedItem: item.name,
              indexItem: item.indexItem,
            };
          } else {
            return target;
          }
        });
      });
      setAnswerStyle((answerStyle) => {
        return answerStyle.map((style, i) => {
          if (i === index) {
            return {
              $set: null,
            };
          } else {
            return style;
          }
        });
      });
    }
  }, []);

  const handleCheck = () => {
    setCheckingState(true);
    let correct = true;
    let newAnswerStyle = [];
    for (let i = 0; i < targets.length; i++) {
      if (
        targets[i].indexItem === null ||
        targets[i].indexItem + 1 !== questionSolutions[i]
      ) {
        correct = false;
        newAnswerStyle.push(incorrectStyle);
      } else {
        newAnswerStyle.push(correctStyle);
      }
    }
    setAnswerStyle(newAnswerStyle);
    if (correct) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const handleReset = () => {
    setCheckingState(false);
    const newTargets = targets.map((target, index) => {
      return {
        name: target.name,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: null,
        indexItem: null,
        id: index,
      };
    });
    setTargets(newTargets);
    setAnswerStyle([]);
  };

  const handleShowExplanation = () => {
    setExplanationState(true);
  };

  const showNextSlide = () => {
    dispatch(
      setQuestionState({
        section: section,
        index: slide,
        state: {
          isCorrect: isCorrect,
          checkingState: checkingState,
          explanationState: explanationState,
          answerStyle: answerStyle,
          answers: targets.map((target) => target.lastDroppedItem),
          indexItems: targets.map((target) => target.indexItem),
        },
      })
    );
    submitLesson(section, slide);
    if (sections[section].slides[slide].isLast) {
      nextSection();
    } else {
      dispatch(setQuestionVisible({ section: section, index: slide + 1 }));
    }

    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: 'smooth',
    // });

    setScrollTop(false)
  };

  return (
    <div
      className="has-background-kurio-main-color-light-gray"
      style={{ borderRadius: "0 0 1rem 1rem" }}
    >
      {!explanationState && (
        <div
          className="question-container m-auto has-text-kurio-main-color-black"
          style={{ padding: "1.5rem 2rem", position: 'relative' }}
        >
          {questionTexts !== null &&
            questionTexts !== undefined &&
            questionTexts?.length > 0 && (
              <div
                className="has-text-centered m-auto"
                style={{ maxWidth: "40rem" }}
              >
                {questionTexts}
              </div>
            )}

          <div
            className="is-flex mt-4 is-flex-direction-row is-justify-content-center is-align-items-start has-background-kurio-gray-light-1"
            style={{
              padding: "1.5rem 0rem",
              borderRadius: "1rem 1rem 0 0",
            }}
          >
            {targets.length >= 1 &&
              targets.length <= 4 &&
              targets.map(
                ({ name, accepts, lastDroppedItem, indexItem }, index) => (
                  <Target
                    content={name}
                    accept={accepts}
                    lastDroppedItem={lastDroppedItem}
                    onDrop={(item) => handleDrop(index, item)}
                    disable={checkingState}
                    resultStyle={answerStyle[index]}
                    iconStyle={iconStyle}
                    key={index}
                    choices={choices}
                    dropOutside={dropOutside}
                    indexItem={indexItem}
                    index={index}
                  />
                )
              )}
          </div>
          <div
            className="has-background-kurio-gray-light-2 is-flex is-flex-direction-row is-justify-content-center is-align-items-start m-0"
            style={{
              padding: "1rem 0",
              borderRadius: "0 0 1rem 1rem",
              zIndex: 1
            }}
          >
            {(choices.length === 2 ||
              choices.length === 3 ||
              choices.length === 4) && (
                <div
                  className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start m-0"
                  style={{
                    padding: "1rem 0",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  {choices.map(({ name, type, id }, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "1rem",
                      }}
                    >
                      <ChoiceContainer
                        name={name}
                        type={type}
                        key={index}
                        indexItem={index}
                        isChoiceSelected={isChoiceSelected(name, index)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  ))}
                </div>
              )}
            {choices.length === 5 && (
              <>
                <div
                  style={{
                    padding: "1rem 0",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[0].name}
                        type={choices[0].type}
                        key={0}
                        indexItem={0}
                        isChoiceSelected={isChoiceSelected(choices[0].name, 0)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[1].name}
                        type={choices[1].type}
                        key={1}
                        indexItem={1}
                        isChoiceSelected={isChoiceSelected(choices[1].name, 1)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[2].name}
                        type={choices[2].type}
                        key={2}
                        indexItem={2}
                        isChoiceSelected={isChoiceSelected(choices[2].name, 2)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[3].name}
                        type={choices[3].type}
                        key={3}
                        indexItem={3}
                        isChoiceSelected={isChoiceSelected(choices[3].name, 3)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[4].name}
                        type={choices[4].type}
                        key={4}
                        indexItem={4}
                        isChoiceSelected={isChoiceSelected(choices[4].name, 4)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {choices.length === 6 && (
              <>
                <div
                  style={{
                    padding: "1rem 0rem",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[0].name}
                        type={choices[0].type}
                        key={0}
                        indexItem={0}
                        isChoiceSelected={isChoiceSelected(choices[0].name, 0)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[1].name}
                        type={choices[1].type}
                        key={1}
                        indexItem={1}
                        isChoiceSelected={isChoiceSelected(choices[1].name, 1)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[2].name}
                        type={choices[2].type}
                        key={2}
                        indexItem={2}
                        isChoiceSelected={isChoiceSelected(choices[2].name, 2)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[3].name}
                        type={choices[3].type}
                        key={3}
                        indexItem={3}
                        isChoiceSelected={isChoiceSelected(choices[3].name, 3)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[4].name}
                        type={choices[4].type}
                        key={4}
                        indexItem={4}
                        isChoiceSelected={isChoiceSelected(choices[4].name, 4)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                    <div className="column is-narrow">
                      <ChoiceContainer
                        name={choices[5].name}
                        type={choices[5].type}
                        key={5}
                        indexItem={5}
                        isChoiceSelected={isChoiceSelected(choices[5].name, 5)}
                        disable={checkingState}
                        dropOutside={dropOutside}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* only appears when explanationState is true */}
      {explanationState && (
        <div
          className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
          style={{
            gap: "1.5rem",
            fontSize: "1.25rem",
            padding: " 2rem 2rem 1.5rem",
          }}
        >
          {((explanationTexts !== null &&
            explanationTexts !== undefined &&
            explanationTexts?.length > 0) ||
            (explanationImages !== null &&
              explanationImages !== undefined &&
              explanationImages?.length > 0)) &&
            explanation.texts?.length >= explanation.images?.length && (
              <>
                {explanation.texts.map((item, index) => (
                  <div
                    key={index}
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{ gap: "1.5rem" }}
                  >
                    {explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      item?.length > 0 && (
                        <div key={item}>{explanationTexts[index]}</div>
                      )}
                    {/* only appears when explanationImages has length greater than 0 */}
                    {explanationImages !== null &&
                      explanationImages !== undefined &&
                      explanationImages[index]?.length > 0 && (
                        <ImageFromUrl objectId={explanationImages[index]} />
                      )}
                  </div>
                ))}
              </>
            )}
          {((explanationTexts !== null &&
            explanationTexts !== undefined &&
            explanationTexts?.length > 0) ||
            (explanationImages !== null &&
              explanationImages !== undefined &&
              explanationImages?.length > 0)) &&
            explanation.texts?.length < explanation.images?.length && (
              <>
                {explanationImages.map((item, index) => (
                  <div
                    key={item}
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{ gap: "1.5rem" }}
                  >
                    {explanationTexts !== null &&
                      explanationTexts !== undefined &&
                      explanation.texts[index]?.length > 0 && (
                        <div key={index}>{explanationTexts[index]}</div>
                      )}
                    {/* only appears when explanationImages has length greater than 0 */}
                    {explanationImages !== null &&
                      explanationImages !== undefined &&
                      item?.length > 0 && (
                        <ImageFromUrl objectId={explanationImages[index]} />
                      )}
                  </div>
                ))}
              </>
            )}
        </div>
      )}

      <div
        className={` is-flex is-flex-direction-row ${!(slide + 1 > sections[section].slides.length - 1
          ? sections[section].isFinished
          : sections[section].slides[slide + 1].visible)
          ? checkingState &&
            isCorrect &&
            explanation?.texts?.every((item) => item?.length <= 0) &&
            explanation?.images?.every((item) => item?.length <= 0)
            ? "is-align-items-center is-justify-content-center"
            : "is-align-items-flex-end is-justify-content-space-between"
          : "is-align-items-center is-justify-content-center"
          }`}
        style={{ padding: "0 2rem 2rem" }}
      >
        <div
          className="is-flex is-flex-direction-row is-align-items-flex-start p-0"
          style={{ gap: "1rem" }}
        >
          {/* only appears when checkingState is false */}
          {!checkingState && (
            <PrimaryButtonQuestion
              className="button"
              onClick={handleCheck}
              disabled={targets.every((item) => item.lastDroppedItem === null)}
            >
              {getConstantStringByLanguage(ConstantStrings.SUBMIT)}
            </PrimaryButtonQuestion>
          )}
          {((checkingState && !isCorrect) || !checkingState) && (
            <SecondaryButtonQuestion
              disabled={targets.every((item) => item.lastDroppedItem === null)}
              className="button"
              onClick={handleReset}
              defaultColor="is-kurio-purple-light-2"
              style={{ maxWidth: "9rem" }}
              prefix={"admin/frontend-images/button/button-reset-icon"}
            >
              {ConstantStrings.RESET}
            </SecondaryButtonQuestion>
          )}
          {/* only appears when checkingState is true and any selected answer is incorrect */}
          {/* Next and "Xem giai thich" button appears when checkingState is true and all selected answers is correct */}
          {checkingState && isCorrect && explanationState === false && (
            <span>
              {explanation !== null &&
                explanation !== undefined &&
                ((explanationImages !== null &&
                  explanationImages !== undefined &&
                  explanationImages.length > 0) ||
                  (explanationTexts !== null &&
                    explanationTexts !== undefined &&
                    explanationTexts.length > 0)) && (
                  <SecondaryButtonQuestion
                    className="button"
                    defaultColor={"is-kurio-purple-light-2"}
                    onClick={handleShowExplanation}
                  >
                    {getConstantStringByLanguage(ConstantStrings.SHOW_EXPLANATION)}
                  </SecondaryButtonQuestion>
                )}
            </span>
          )}
          {/* "Xem cau hoi" button appears when explanationState is true */}
          {explanationState && (
            <SecondaryButtonQuestion
              className="button"
              defaultColor={"is-kurio-purple-light-2"}
              onClick={() => {
                setExplanationState(false);
              }}
            >
              {getConstantStringByLanguage(ConstantStrings.SHOW_QUESTION)}
            </SecondaryButtonQuestion>
          )}
        </div>
        {checkingState && isCorrect && (
          <div
            className={`${(
              slide + 1 > sections[section].slides.length - 1
                ? sections[section].isFinished
                : sections[section].slides[slide + 1].visible
            )
              ? "is-hidden"
              : ""
              }`}
          >
            <PrimaryButtonQuestion onClick={showNextSlide}>
              {getConstantStringByLanguage(ConstantStrings.NEXT)}
            </PrimaryButtonQuestion>
          </div>
        )}
        {((checkingState && !isCorrect) || !checkingState) && (
          <div
            className={`${(
              slide + 1 > sections[section].slides.length - 1
                ? sections[section].isFinished
                : sections[section].slides[slide + 1].visible
            )
              ? "is-hidden"
              : ""
              }`}
          >
            <TertiaryButtonQuestion

              suffix="admin/frontend-images/button/button-skip-icon"
              onClick={showNextSlide}
            >
              {getConstantStringByLanguage(ConstantStrings.SKIP)}
            </TertiaryButtonQuestion>
          </div>
        )}
      </div>
    </div>
  );
});

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(DragDrop);
