/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConstantStrings from "../../constants/ConstantStrings";
import "./MenuLeft.styles.css";
import {
  checkPremium,
  USER_IKMC_TYPE,
  USER_TEST_PREP_TYPE,
} from "../../templates/helper/CheckAndGetAnonymousUserId";
import { checkIsVn, getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const MenuLeft = ({ gradeName, userInfo }) => {
  const location = useLocation();

  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const language = sessionStorage.getItem("language");

  var MenuLeft = [
    {
      _id: "1",
      title: getConstantStringByLanguage(ConstantStrings.HOME),
      icon: "/images/menu/icon-home.svg",
      pathName: "/",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },
    {
      _id: "2",
      title: getConstantStringByLanguage(ConstantStrings.LESSONS),
      icon: "/images/menu/icon-book.svg",
      pathName: "/list-unit",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },
    {
      _id: "3",
      title: getConstantStringByLanguage(ConstantStrings.PRACTICE),
      icon: "/images/menu/icon-pen.svg",
      pathName: "/exercise",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },
    {
      _id: "4",
      title: getConstantStringByLanguage(ConstantStrings.MISSION),
      icon: "/images/menu/icon-game.svg",
      pathName: "/activities",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },
  ];
  if ((language && language.toUpperCase() === ConstantStrings.VN_LANGUAGE) || !language) {
    var newMenuLeft =[
    {
      _id: "5",
      title: "Thi tháng",
      icon: "/images/menu/icon-exam.svg",
      pathName: "/monthly-exams",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },

    {
      _id: "6",
      title: "Luyện thi",
      icon: "/images/menu/icon-kurio-menu.svg",
      pathName: "/exam-practice",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },
    {
      _id: "7",
      title: "Trò chơi",
      icon: "/images/menu/icon-games.svg",
      pathName: "/kho-tro-choi",
      stylesOnClick: {
        padding: "1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#D8E1F4",
      },
      stylesDefault: {
        padding: "1rem 0rem 1rem 1rem",
        width: "9rem",
        height: "3rem",
        borderRadius: "1rem",
        backgroundColor: "#FAFBFC",
        boxShadow: "2px 4px 0px 0px #0A2A66",
        border:
          "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
      },
    },]
    MenuLeft =  [...MenuLeft,...newMenuLeft]
  }
  // else{
  //   var newMenuLeftEN =[{
  //     _id: "5",
  //     title: "Contact",
  //     icon: "/images/menu/icon-exam.svg",
  //     pathName: "/contact",
  //     stylesOnClick: {
  //       padding: "1rem",
  //       width: "9rem",
  //       height: "3rem",
  //       borderRadius: "1rem",
  //       backgroundColor: "#D8E1F4",
  //     },
  //     stylesDefault: {
  //       padding: "1rem 0rem 1rem 1rem",
  //       width: "9rem",
  //       height: "3rem",
  //       borderRadius: "1rem",
  //       backgroundColor: "#FAFBFC",
  //       boxShadow: "2px 4px 0px 0px #0A2A66",
  //       border:
  //         "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
  //     },
  //   },]
  //   MenuLeft =  [...MenuLeft,...newMenuLeftEN]
  // }

  const onHandleClickMenu = (pathName) => {
    setSelected(pathName);
  };

  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
      style={{ position: "fixed", minHeight: "100%", paddingLeft: "2.5rem" }}
    >
      <div className="" style={{
        marginBottom: checkIsVn()?"": "11rem",
      }}>
        <div
          className="is-clickable logo-kurio"
          style={{ marginBottom: "1.5rem", padding: "0.75rem 1.636905rem" }}
          onClick={() => navigate("/")}
        >
          <img
            src={
              userInfo?.studentType.type === USER_TEST_PREP_TYPE
                ? "/images/upgrades/logo-kurio-test-prep.svg"
                : checkPremium({ userInfo: userInfo })
                ? "/images/upgrades/logo-kurio-max.svg"
                : "/images/menu/logo.svg"
            }
            alt=""
            style={{ objectFit: "cover" }}
          />
        </div>

        <div className="menu-nav-bar-left">
          {/* Trang chủ */}
          {MenuLeft.map((item) => {
            return (
              <Link
                // style={
                //   item._id === "6"
                //     ? {
                //         color: "#FAFBFC",
                //       }
                //     : { color: "#0A2A66" }
                // }
                className="has-text-kurio-main-color-black"
                key={item._id}
                to={item.pathName}
                onClick={() => onHandleClickMenu(item.pathName)}
              >
                <span
                  onClick={() => onHandleClickMenu(item.pathName)}
                  className={` ${
                    item.pathName === `${window.location.pathname}`
                      ? "has-text-weight-bold"
                      : "has-text-weight-bold"
                  } is-flex is-align-items-center is-clickable menu-left__item`}
                  style={
                    item.pathName === `${window.location.pathname}`
                      ? item.stylesDefault
                      : item.stylesOnClick
                  }
                >
                  <img
                    src={item.icon}
                    alt=""
                    style={{
                      width: "1.125rem",
                      height: "1.25rem",
                      marginRight: "0.75rem",
                    }}
                  />
                  <p
                    className="has-text-centered"
                    style={{
                      // fontWeight: 500,
                      fontSize: "0.95rem",
                      lineHeight: "1.0625rem",
                      marginTop: "0.2rem",
                    }}
                  >
                    {item.title}
                  </p>
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    gradeName: state.grades.gradeName,
    userInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(MenuLeft);
