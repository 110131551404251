import React from "react";
import { getConstantStringByLanguage } from "../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../constants/ConstantStrings";

function QuestionNumberTag({ number }) {
    return (
        <div
            className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
            style={{ borderRadius: "1rem", fontSize: "1.25rem" }}
        >
            <b>{ getConstantStringByLanguage(ConstantStrings.QUESTION) +" "+ number}</b>
        </div>
    );
}

export default QuestionNumberTag;
