import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import ExerciseCloseButton from "../../components/button/special-button/ExerciseCloseButton";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { getAllAchievement } from "../../config/achievement";
import {
  setAnswerDailyEvent,
  setClearStateDaily,
  setCorrectQuestion,
  setQuestionState,
  submitAnswer,
} from "../../reducers/dailyMissionSlice";
import DailyMissionContent from "./DailyMissionContent";
import ConstantStrings from "../../constants/ConstantStrings";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const DailyMissionMainWrapper = ({
  questions,
  seenQuestions,
  correctAnswers,
  setShowPopupStartDailyMission,
  showPopupStartDailyMission,
  setShowPopupCongratulations,
  showPopupCongratulations,
  userEventId,
  startHP,
  error,
  currentHP,
  imageUrls,
}) => {
  const [exitState] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDailyFinished, setShowDailyFinished] = useState(
    questions
      ?.map((item) => item.correct === true || item.correct === false)
      .flat()
      .indexOf(false) === -1
      ? true
      : false
  );

  const [showPopupCalculatePoints, setShowPopupCalculatePoints] =
    useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showImage2, setShowImage2] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const cookies = new Cookies();
  const dispatch = useDispatch();

  const closeModal = () => {
    const answer = cookies.get("quizEventState");
    if (answer) {
      // submitAnswers(answer);
    }
    cookies.remove("quizEventState");
    dispatch(setClearStateDaily());
    // setSelectedQuestion(0);

    // setShowDailyMission(false);

    if (JSON.parse(localStorage.getItem("popupCongratulationCheck"))) {
      if (currentHP === 0) {
        const getAchievements = async () => {
          try {
            const { result } = await getAllAchievement();

            const filterListAchievement = result.achievements.filter(
              (item) => item.menu === 4
            );

            const filterCongratulationAchievement =
              filterListAchievement.filter((item) =>
                item.name.includes((new Date().getMonth() + 1).toString())
              );

            const countAchievement = filterCongratulationAchievement.map(
              (item) => item.count
            );
            if (countAchievement[0] === 1) {
              // console.log(showPopupCongratulations);
              // console.log(congratulationsCheck);

              setShowPopupStartDailyMission(false);
              setShowPopupCongratulations(true);
            } else {
              setShowPopupStartDailyMission(false);

              setShowPopupCongratulations(false);
            }
          } catch {
            console.log(error);
          }
        };

        getAchievements();
      } else {
        setShowPopupStartDailyMission(false);
      }
    } else {
      setShowPopupStartDailyMission(false);
    }

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
  };

  const handleShowDailyFinished = () => {
    setShowDailyFinished(true);
  };

  const handleQuestionChange = (val) => {
    const result = cookies.get("quizEventState");

    if (result !== undefined && result !== null) {
      dispatch(setAnswerDailyEvent(result.questionsAnswered));
      dispatch(setQuestionState(result.questionState));
    }
    cookies.remove("dailyQuizState");

    setSelectedQuestion(val);
  };

  const handleChangeFinished = (val) => {
    setShowDailyFinished(false);
    setSelectedQuestion(val);
  };

  const submitAnswers = async (answer) => {
    const result = cookies.get("quizEventState");

    if (currentHP === 0) {
      if (
        JSON.stringify(result.submit.questions[0]?.answer) ===
        JSON.stringify(questions[selectedQuestion]?.question?.solutions)
      ) {
        dispatch(
          setCorrectQuestion({
            questionIndex: selectedQuestion,
            correct: true,
          })
        );
      } else {
        dispatch(
          setCorrectQuestion({
            questionIndex: selectedQuestion,
            correct: false,
          })
        );
      }
    }
    if (result !== undefined) {
      dispatch(setAnswerDailyEvent(result.questionsAnswered));
      dispatch(setQuestionState(result.questionState));
      var submitAnswerQuiz = result.submit;

      try {
        var submit = {
          questionId: questions[selectedQuestion]._id,
          answer: submitAnswerQuiz?.questions[0]?.answer,
        };

        dispatch(
          submitAnswer({
            questionId: userEventId,
            submit,
          })
        );

        setApiCalled(true);
      } catch (error) {
        console.log("err", error);
      }
    }
  };

  const showEndPractice = () => {
    if (questions.length - 1 === selectedQuestion) {
      setShowDailyFinished(true);
    }
  };

  function countValues() {
    let count = 0;
    for (let i = 0; i < questions?.length; i++) {
      if (questions[i]?.correct === null) {
        count++;
      }
    }
    return count;
  }

  function countTrueValues() {
    let count = 0;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].correct === true) {
        count++;
      }
    }
    return count;
  }

  function isValidImageAtIndex(list, index) {
    return (
      Array.isArray(list) &&
      list !== undefined &&
      list !== null &&
      list.length > 0 &&
      list[index] &&
      list[index] !== ""
    );
  }

  const renderScreen = () => {
    switch (currentIndex) {
      // case 0:
      //   return (
      //     <>
      //       <div
      //         className="is-flex is-justify-content-center is-flex-direction-row is-align-items-center"
      //         style={{
      //           height: "27.5rem",
      //           borderRadius: "4rem",
      //           opacity: "0.95",
      //           marginTop: "5.5rem",
      //           gap: "4.5rem",
      //         }}
      //       >
      //         <BackgroundImageFromUrl
      //           objectId={
      //             startHP !== 0 &&
      //             startHP / 2 < currentHP &&
      //             currentHP <= startHP
      //               ? isValidImageAtIndex(imageUrls, 13)
      //                 ? imageUrls[13]
      //                 : "admin/frontend-images/hub/6-1 quai vat khoe manh.png"
      //               : startHP !== 0 && currentHP <= startHP / 2
      //               ? isValidImageAtIndex(imageUrls, 14)
      //                 ? imageUrls[14]
      //                 : "admin/frontend-images/hub/6-2 quai vat yeu.png"
      //               : currentHP === 0
      //               ? isValidImageAtIndex(imageUrls, 15)
      //                 ? imageUrls[15]
      //                 : "admin/frontend-images/hub/6-3 quai vat dau hang.png"
      //               : "admin/frontend-images/hub/6-1 quai vat khoe manh.png"
      //           }
      //           style={{
      //             width: "25rem",
      //             height: "27.5rem",
      //             borderRadius: "4rem",
      //             backgroundRepeat: "no-repeat",
      //             backgroundSize: "cover",
      //             backgroundPosition: "center",
      //             // background: "#D9D9D9",
      //             position: "relative",
      //             // boxShadow: "0.3rem 0rem 0 0 rgb(206 198 198)",
      //           }}
      //         >
      //           <div
      //             className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center has-background-kurio-main-color-light-gray"
      //             style={{
      //               width: "22.5rem",
      //               position: "absolute",
      //               zIndex: 10,
      //               height: "2.5rem",
      //               top: "25.5rem",
      //               left: "1.5rem",
      //               gap: "0.75rem",
      //               borderRadius: "1rem 1rem 1rem 1rem",
      //               padding: "0.5rem 0rem",
      //               boxShadow: "0rem 0.3rem 0rem 0rem #570B0B",
      //             }}
      //           >
      //             <p
      //               className="has-text-kurio-main-color-black"
      //               style={{
      //                 fontSize: "1.25rem",
      //                 fontWeight: 700,
      //                 textTransform: "uppercase",
      //               }}
      //             >
      //               hp
      //             </p>

      //             <div className="progress-bar-container">
      //               <div
      //                 className="progress-bar"
      //                 style={{
      //                   width: `${(currentHP / startHP) * 100}%`,
      //                   backgroundColor: "#572CD1", // Màu xanh cho phần trước
      //                 }}
      //               ></div>
      //             </div>

      //             <p
      //               className="has-text-kurio-main-color-black"
      //               style={{
      //                 fontSize: "1.25rem",
      //                 fontWeight: 700,
      //                 textTransform: "uppercase",
      //               }}
      //             >
      //               {currentHP}/{startHP}
      //             </p>
      //           </div>
      //         </BackgroundImageFromUrl>
      //       </div>
      //     </>
      //   );
      default:
        return (
          <>
            <div>
              <div
                className={`is-flex is-flex-direction-row div-daily-container is-align-items-center`}
                style={{
                  height: "27.5rem",
                  background: "#FAFBFC",
                  borderRadius: "4rem",
                  opacity: "0.95",
                  marginTop: "5.5rem",
                }}
              >
                <BackgroundImageFromUrl
                  objectId={
                    showImage2
                      ? isValidImageAtIndex(imageUrls, 16)
                        ? imageUrls[16]
                        : "admin/frontend-images/hub/6-4 quai vat bi danh.png"
                      : startHP !== 0 &&
                        startHP / 2 < currentHP &&
                        currentHP <= startHP
                        ? isValidImageAtIndex(imageUrls, 13)
                          ? imageUrls[13]
                          : "admin/frontend-images/hub/6-1 quai vat khoe manh.png"
                        : startHP !== 0 &&
                          currentHP <= startHP / 2 &&
                          currentHP > 0
                          ? isValidImageAtIndex(imageUrls, 14)
                            ? imageUrls[14]
                            : "admin/frontend-images/hub/6-2 quai vat yeu.png"
                          : currentHP === 0
                            ? isValidImageAtIndex(imageUrls, 15)
                              ? imageUrls[15]
                              : "admin/frontend-images/hub/6-3 quai vat that bai.png"
                            : "admin/frontend-images/hub/6-1 quai vat khoe manh.png"
                  }
                  style={{
                    width: "25rem",
                    height: "27.5rem",
                    borderRadius: "4rem",
                    transition: "background-image 0.8s ease-in-out",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",

                    position: "relative",
                    // boxShadow: "0.3rem 0rem 0 0 rgb(206 198 198)",
                  }}
                >
                  <div
                    className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center has-background-kurio-main-color-light-gray"
                    style={{
                      width: "22.5rem",
                      background: "#FAFBFC",
                      position: "absolute",
                      zIndex: 10,
                      height: "2.5rem",
                      top: "25.5rem",
                      left: "1.5rem",
                      gap: "0.75rem",
                      borderRadius: "1rem 1rem 1rem 1rem",
                      padding: "0.5rem 0rem",
                      boxShadow: "0rem 0.3rem 0rem 0rem #570B0B",
                    }}
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      hp
                    </p>

                    <div className="progress-bar-container">
                      <div
                        className="progress-bar"
                        style={{
                          width: `${(currentHP / startHP) * 100}%`,
                          backgroundColor: "#572CD1", // Màu xanh cho phần trước
                        }}
                      ></div>
                    </div>

                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      {currentHP}/{startHP}
                    </p>
                  </div>
                </BackgroundImageFromUrl>

                <div
                  style={{
                    gap: "1rem",
                    width: "36.1875rem",
                    borderRadius: "5rem",
                    // background: "#D9E1F0",
                    padding: "2.94rem 5.25rem",
                  }}
                  className={`text-container is-flex is-flex-direction-column `}
                >
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{ fontSize: "2.5rem", fontWeight: 700 }}
                  >
                    {questions
                      ?.map(
                        (item) =>
                          item.correct === true || item.correct === false
                      )
                      .flat()
                      .indexOf(false) !== -1
                      ? getConstantStringByLanguage(ConstantStrings.COMPLETE_TODAY_MISSION)
                      : getConstantStringByLanguage(ConstantStrings.TODAY_MISSION_COMPLETED)}
                  </p>

                  {questions
                    ?.map(
                      (item) => item.correct === true || item.correct === false
                    )
                    .flat()
                    .indexOf(false) !== -1 ? (
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{ fontSize: "1.25rem", fontWeight: "500" }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.REMAIN)}
                      <b style={{ fontSize: "1.25rem" }}>
                        {countValues(questions?.map((item) => item.correct))}/
                        {questions?.length} {getConstantStringByLanguage(ConstantStrings.QUESTION_DAILY_MISSION)}
                      </b>
                    </p>
                  ) : (
                    <>
                      <span
                        style={{ gap: "0.75rem" }}
                        className="is-flex-direction-column is-flex has-text-kurio-main-color-black"
                      >
                        <p style={{ fontSize: "1.25rem", fontWeight: "500" }}>
                          {getConstantStringByLanguage(ConstantStrings.CORRECT_ANSWER)}
                          <b style={{ fontSize: "1.25rem" }}>
                            {countTrueValues(
                              questions?.map((item) => item.correct)
                            )}
                            /{questions?.length} {getConstantStringByLanguage(ConstantStrings.QUESTION_DAILY_MISSION)}
                          </b>
                        </p>

                        <p style={{ fontSize: "1.25rem", fontWeight: "500" }}>
                          {getConstantStringByLanguage(ConstantStrings.REMAINING_HP)}
                          <b style={{ fontSize: "1.25rem" }}>
                            {currentHP}/{startHP}
                          </b>
                        </p>
                      </span>

                      <p
                        className="has-text-kurio-main-color-black"
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: "500",
                          width: "101%",
                        }}
                      >
                        {getConstantStringByLanguage(ConstantStrings.RETURN_TO_BATTLE_TOMORROW)}
                      </p>
                    </>
                  )}

                  <div
                    className="is-flex is-flex-direction-column"
                    style={{ gap: "1rem" }}
                  >
                    <PrimaryButtonPageLevel
                      onClick={closeModal}
                      style={{ height: "4rem" }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.CLOSE)}
                    </PrimaryButtonPageLevel>

                    <p
                      className="has-text-kurio-main-color-black is-clickable"
                      style={{
                        fontSize: "0.875rem",
                        textDecorationLine: "underline",
                      }}
                      onClick={handleShowPopupCalculatePoints}
                    >
                      {getConstantStringByLanguage(ConstantStrings.HOW_TO_CALCULATE_SCORE)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const handleShowPopupCalculatePoints = () => {
    // setShowPopupCalculatePoints(!showPopupCalculatePoints);
    setShowPopupCalculatePoints(!showPopupCalculatePoints);
  };

  if (currentHP !== 0 || currentHP === startHP) {
    localStorage.setItem("popupCongratulationCheck", true);
  }

  useEffect(() => {
    if (apiCalled && currentHP !== 0) {
      // dispatch(getCurrentEvent());
      
      if (questions[selectedQuestion]?.correct === true) {
        if (!isButtonDisabled) {
          setIsButtonDisabled(true);
          setShowImage(true);
          setTimeout(() => {
            setShowImage(false);
            setIsButtonDisabled(false);
          }, 2000);
        }
      }
      setApiCalled(false);
    }
    
    if (showDailyFinished) {
      if (questions.some((item) => item?.correct === true)) {
        setShowImage2(true);
        setTimeout(() => {
          setShowImage2(false);
        }, 2000);
      }
      // Sau 300ms, hiển thị div text

      const isRunningTime = setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 2000);

      return () => clearTimeout(isRunningTime);
      // Clear timeout khi component bị unmount
    }
  }, [
    apiCalled,
    showDailyFinished,
    showPopupStartDailyMission,
    correctAnswers,
    currentHP,
    dispatch,
    selectedQuestion,
    isButtonDisabled,
    questions,
    startHP,
  ]);

  // const checkChangeImage = () => {
  //
  // };

  return (
    <>
      {!showDailyFinished ? (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          {!exitState && (
            <BackgroundImageFromUrl
              objectId={
                "admin/frontend-images/hub/background-popup-start-befor-daily-mission.svg"
              }
              className=" is-relative"
              style={{
                width: questions?.length > 3 ? "70rem" : "75rem",
                height: "calc(100% - 2.5rem)",
                background: "#EFEFEF",

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "7.5rem 7.5rem 0 0",
                padding: "3rem 3.13rem 3.31rem 3.12rem",
                top: "2.5rem",
                zIndex: "200",
                position: "relative",
              }}
            >
              <BackgroundImageFromUrl
                objectId={
                  isValidImageAtIndex(imageUrls, 12)
                    ? imageUrls[12]
                    : "admin/frontend-images/hub/5 bg bai lam.jpg"
                }
                className="is-justify-content-center"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5rem 5rem 2.5rem 2.5rem",
                  padding: "0 4.88rem 0 4.88rem",
                  border: "4px solid #DB6F30",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-1rem",
                    zIndex: 1000,
                    left: "-2rem",
                  }}
                >
                  <ExerciseCloseButton onClick={closeModal} />
                </div>

                <div
                  className="is-flex is-flex-direction-row"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    top: "-2rem",
                    left: "21rem",
                  }}
                >
                  <ImageFromUrl
                    className="is-relative"
                    objectId={
                      getConstantStringByLanguage(ConstantStrings.HEADER_TOP_POPUP_DAILY_MISSION_URL)
                    }
                    alt=""
                  />

                  <div
                    style={{
                      width: "6.75rem",
                      height: "6.75rem",
                      position: "absolute",
                      background: "#D9D9D9",
                      borderRadius: "50%",
                      top: "0.7rem",
                      left: "0.7rem",
                    }}
                  >
                    <BackgroundImageFromUrl
                      objectId={
                        showImage
                          ? isValidImageAtIndex(imageUrls, 11)
                            ? imageUrls[11]
                            : "admin/frontend-images/hub/4-4 quai vat bi danh.png"
                          : startHP !== 0 &&
                            startHP / 2 < currentHP &&
                            currentHP <= startHP
                            ? isValidImageAtIndex(imageUrls, 8)
                              ? imageUrls[8]
                              : "admin/frontend-images/hub/4-1 quai vat khoe manh.png"
                            : startHP !== 0 &&
                              currentHP <= startHP / 2 &&
                              currentHP > 0
                              ? isValidImageAtIndex(imageUrls, 9)
                                ? imageUrls[9]
                                : "admin/frontend-images/hub/4-2 quai vat yeu.png"
                              : currentHP === 0
                                ? isValidImageAtIndex(imageUrls, 10)
                                  ? imageUrls[10]
                                  : "admin/frontend-images/hub/4-3 quai vat dau hang.png"
                                : "admin/frontend-images/hub/4-1 quai vat khoe manh.png"
                      }
                      style={{
                        width: "6.75rem",
                        height: "6.75rem",
                        transition: "background-image 0.8s ease-in-out",
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        zIndex: 100,
                      }}
                    ></BackgroundImageFromUrl>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "2.8rem",
                      left: "-11.5rem",
                    }}
                  >
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center has-background-kurio-main-color-light-gray"
                      style={{
                        width: "22.5rem",
                        background: "#FAFBFC",
                        position: "absolute",
                        zIndex: 10,
                        height: "2.5rem",
                        top: "2.5rem",
                        left: "21.5rem",
                        gap: "0.75rem",
                        borderRadius: "1rem 1rem 1rem 1rem",
                        padding: "0.5rem 0rem",
                        boxShadow: "0rem 0.3rem 0rem 0rem #570B0B",
                      }}
                    >
                      <p
                        className="has-text-kurio-main-color-black"
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        hp
                      </p>

                      <div className="progress-bar-container">
                        <div
                          className="progress-bar"
                          style={{
                            width: `${(currentHP / startHP) * 100}%`,
                            backgroundColor: "#572CD1", // Màu xanh cho phần trước
                          }}
                        ></div>
                      </div>

                      <p
                        className="has-text-kurio-main-color-black"
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        {currentHP}/{startHP}
                      </p>
                    </div>
                  </div>
                </div>

                {questions !== undefined && questions && (
                  <DailyMissionContent
                    question={questions[selectedQuestion]}
                    seenQuestions={seenQuestions}
                    questions={questions}
                    handleQuestionChange={handleQuestionChange}
                    // closeModal={closeModal}
                    selectedQuestion={selectedQuestion}
                    showEndPractice={showEndPractice}
                    submitAnswers={submitAnswers}
                  />
                )}
              </BackgroundImageFromUrl>
              {!showDailyFinished && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "0rem",
                    display: "flex",
                    gap: "1rem",
                    left: questions?.length > 3 ? "1rem" : "8rem",
                    zIndex: 100,
                  }}
                >
                  <BackgroundImageFromUrl
                    objectId={
                      "admin/frontend-images/hub/background-list-exam.png"
                    }
                    className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                    style={{
                      display: "flex",
                      gap: questions?.length > 3 ? "1rem" : "2rem",

                      flexWrap: "nowrap",
                      width: questions?.length > 3 ? "56rem" : "48.3rem",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "6rem",
                      zIndex: "100",
                      bottom: "0rem",
                      borderRadius:
                        questions?.length > 3
                          ? "2.5rem 0rem 0rem 0rem"
                          : "2rem 2rem 0 0",
                    }}
                  >
                    {questions &&
                      questions.length > 0 &&
                      questions.map((question, index) => {
                        return (
                          <button
                            key={index}
                            disabled={isButtonDisabled}
                            onClick={() =>
                              handleQuestionChange(question.questionIndex)
                            }
                            className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                            style={{
                              width:
                                questions?.length > 3 ? "10rem" : "12.5rem",
                              cursor: "pointer",
                              height: "4rem",
                              borderRadius: "2rem",
                              gap: questions?.length > 3 ? "0.5rem" : "1.37rem",
                              border:
                                (question.correct === null ||
                                  question.correct === undefined) &&
                                  question.questionIndex === selectedQuestion
                                  ? "4px solid #FFF"
                                  : question.correct === true
                                    ? "4px solid #4B9631"
                                    : question.correct === false
                                      ? "4px solid #E34D4D"
                                      : "none",
                              background:
                                (question.correct === null ||
                                  question.correct === undefined) &&
                                  question.questionIndex === selectedQuestion
                                  ? " linear-gradient(180deg, #572CD1 0%, #9747FF 100%)"
                                  : question.correct === true
                                    ? "#E5F0CC"
                                    : question.correct === false
                                      ? "#F8E3E3"
                                      : "#FED131",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "1.5rem",
                                fontWeight: "700",
                                color:
                                  (question.correct === null ||
                                    question.correct === undefined) &&
                                    question.questionIndex === selectedQuestion
                                    ? "#FFF"
                                    : question.correct === true
                                      ? "green"
                                      : question.correct === false
                                        ? "red"
                                        : "#572CD1",
                                // ? " #FFF"
                                // : "#572CD1",
                              }}
                            >
                              {`${getConstantStringByLanguage(ConstantStrings.QUESTION_DAILY_MISSION)} ` + (question.questionIndex + 1)}
                            </p>
                            {question.correct === null ||
                              question.correct === undefined ? (
                              <div
                                style={{
                                  width: "2.5rem",
                                  height: "2.5rem",
                                  background: "#FFF",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            ) : question.correct === true ? (
                              <ImageFromUrl
                                style={{
                                  width: "2.5rem",
                                  height: "2.5rem",
                                }}
                                objectId={
                                  "admin/frontend-images/hub/icon-right.svg"
                                }
                                alt=""
                              />
                            ) : question.correct === false ? (
                              <ImageFromUrl
                                style={{
                                  width: "2.5rem",
                                  height: "2.5rem",
                                }}
                                objectId={
                                  "admin/frontend-images/hub/icon-wrong.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <div
                                style={{
                                  width: "2.5rem",
                                  height: "2.5rem",
                                  background: "#FFF",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            )}
                          </button>
                        );
                      })}
                  </BackgroundImageFromUrl>

                  <div>
                    <ImageFromUrl
                      onClick={handleShowDailyFinished}
                      style={{
                        cursor: "pointer",
                        marginLeft:
                          questions?.length > 3 ? "-1.25rem" : "-2.7rem",
                        zIndex: "100",
                        position: "absolute",
                      }}
                      objectId={
                        questions
                          ?.map(
                            (item) =>
                              item.correct === true || item.correct === false
                          )
                          .flat()
                          .indexOf(false) !== -1
                          ? getConstantStringByLanguage(ConstantStrings.IMAGE_BUTTON_SUBMIT_EXAM_DAILY_MISSION_URL)
                          : getConstantStringByLanguage(ConstantStrings.IMAGE_BUTTON_FINISHED_EXAM_DAILY_MISSION_URL)
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
            </BackgroundImageFromUrl>
          )}
        </div>
      ) : (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          {/* {!exitState && ( */}
          <BackgroundImageFromUrl
            objectId={
              isValidImageAtIndex(imageUrls, 17)
                ? imageUrls[17]
                : "admin/frontend-images/hub/7 bg ket qua.jpg"
            }
            className=" is-relative is-flex is-justify-content-center"
            style={{
              width: questions?.length > 3 ? "70rem" : "75rem",
              height: "calc(100% - 2rem)",
              background: "#EFEFEF",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "7.5rem 7.5rem 0 0",
              marginTop: "2rem",
              zIndex: "200",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-1rem",
                zIndex: 1000,
                left: "-2rem",
              }}
            >
              <ExerciseCloseButton onClick={closeModal} />
            </div>

            {renderScreen()}

            <div
              style={{
                position: "absolute",
                bottom: "0rem",
                left: questions?.length > 3 ? "0.75rem" : "10rem",
                display: "flex",
              }}
            >
              <BackgroundImageFromUrl
                objectId={"admin/frontend-images/hub/background-list-exam.png"}
                className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                style={{
                  display: "flex",
                  gap: questions?.length > 3 ? "1rem" : "2rem",
                  flexWrap: "nowrap",
                  width: questions?.length > 3 ? "56rem" : "45.5rem",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: "6rem",
                  zIndex: "100",
                  bottom: "0rem",
                  borderRadius:
                    questions?.length > 3
                      ? "2.5rem 0rem 0rem 0rem"
                      : "2rem 2rem 0 0",
                }}
              >
                {questions &&
                  questions.length > 0 &&
                  questions.map((question, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() =>
                          handleChangeFinished(question.questionIndex)
                        }
                        className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                        style={{
                          width: questions?.length > 3 ? "10rem" : "12.5rem",
                          cursor: "pointer",
                          height: "4rem",
                          borderRadius: "2rem",
                          gap: questions?.length > 3 ? "0.5rem" : "1.37rem",
                          border:
                            question.correct === true &&
                              question?.answer?.length > 0
                              ? "4px solid #4B9631"
                              : question.correct === false &&
                                question?.answer?.length > 0
                                ? "4px solid #E34D4D"
                                : "none",
                          background:
                            question.correct === true &&
                              question?.answer?.length > 0
                              ? "#E5F0CC"
                              : question.correct === false &&
                                question?.answer?.length > 0
                                ? "#F8E3E3"
                                : "#FED131",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "1.5rem",
                            fontWeight: "700",
                            color:
                              question.correct === true &&
                                question?.answer?.length > 0
                                ? "green"
                                : question.correct === false &&
                                  question?.answer?.length > 0
                                  ? "red"
                                  : "white",
                            // ? " #FFF"
                            // : "#572CD1",
                          }}
                        >
                          {`${getConstantStringByLanguage(ConstantStrings.QUESTION_DAILY_MISSION)} ` + (question.questionIndex + 1)}
                        </p>
                        {question.correct === true &&
                          question?.answer?.length > 0 ? (
                          <ImageFromUrl
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                            }}
                            objectId={
                              "admin/frontend-images/hub/icon-right.svg"
                            }
                            alt=""
                          />
                        ) : question.correct === false &&
                          question?.answer?.length > 0 ? (
                          <ImageFromUrl
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                            }}
                            objectId={
                              "admin/frontend-images/hub/icon-wrong.svg"
                            }
                            alt=""
                          />
                        ) : (
                          <div
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              background: "#FFF",
                              borderRadius: "50%",
                            }}
                          ></div>
                        )}
                      </button>
                    );
                  })}
              </BackgroundImageFromUrl>

              <div>
                <ImageFromUrl
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: questions?.length > 3 ? "-12.5rem" : "-11rem",
                    zIndex: 100,
                  }}
                  onClick={closeModal}
                  objectId={
                    questions
                      ?.map(
                        (item) =>
                          item.correct === true || item.correct === false
                      )
                      .flat()
                      .indexOf(false) !== -1
                      ? getConstantStringByLanguage(ConstantStrings.IMAGE_BUTTON_SUBMIT_EXAM_DAILY_MISSION_URL)
                      : getConstantStringByLanguage(ConstantStrings.IMAGE_BUTTON_FINISHED_EXAM_DAILY_MISSION_URL)
                  }
                  alt=""
                />
              </div>
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      {showPopupCalculatePoints && (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/hub/background-calculate-points.svg"
            className="is-relative "
            style={{
              width: "56.875rem",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "34.125rem",
              borderRadius: "3.8125rem",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "position",
                top: "2rem",
                right: "-2.7rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: "110",
                  top: "-3rem",
                  right: "12rem",
                }}
              >
                <img
                  className="is-relative"
                  src={getConstantStringByLanguage(ConstantStrings.NAV_BAR_CALCULATE_POINTS_URL)}
                  alt=""
                />
              </div>
            </div>

            <div style={{ padding: "2rem" }}>
              <div
                style={{
                  width: " 52.125rem",
                  height: "30.125rem",
                  borderRadius: "4rem",
                  padding: "5.19rem 6.06rem",
                  background: "#fff",
                  margin: "0 auto",
                }}
              >
                <div
                  className="main-caculator-point"
                  style={{
                    width: "41rem",
                    maxHeight: "22.5rem",
                  }}
                >
                  <div style={{ width: "39rem", height: "22.5rem" }}>
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.25",
                        fontWeight: 500,
                        textAlign: "justify",
                      }}
                    >
                       {getConstantStringByLanguage(ConstantStrings.MONSTER_MISSION_DESCRIPTION)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={handleShowPopupCalculatePoints}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )
      }
    </>
  );
};

function mapStateToProps(state) {
  return {
    userEventId: state.dailyMission.userEventId,
    correctAnswers: state.dailyMission.correctAnswers,
    questions: state.dailyMission.questions,
    seenQuestions: state.dailyMission.seenQuestions,
    startHP: state.dailyMission.startingHp,
    error: state.dailyMission.error,
    currentHP: state.dailyMission.currentHp,
    imageUrls: state.dailyMission.imageUrls,
  };
}

export default connect(mapStateToProps)(DailyMissionMainWrapper);
