import React from "react";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../constants/ConstantStrings";

const ServiceItem = ({ type, isMax, item, index, handleSelect }) => {
  // type =1 => 6 tháng
  // type =2 => 12 tháng
  // type =3 => 24 tháng
  var oldPrice = isMax ? "900.000đ" : "700.000đ";
  var newPrice = isMax ? "700" : "600";
  var leftStyle = {
    width: "7.25rem",
    height: "6.275rem",
    paddingBottom: "0.25rem",
    background: "linear-gradient(180deg, #EBEFFA 0%, #D8E0F1 100%)",
    borderRadius: "0.875rem 0rem 0rem 0.875rem",
    borderRight: "0.125rem dashed #0A2A66",
  };
  var saleStyle = {
    height: "2.75rem",
    border: "0.0625rem solid",
    borderRadius: "0.5rem",
    padding: "0.375rem 0.5rem 0.25rem 0.5rem",
    borderColor: "#0A2A66",
    boxShadow: "0px 2px 0px 0px #9EAFD3",
    background: "#EBEFFA",
    color: "#0A2A66",
    fontSize: "0.625rem",
    lineHeight: "normal",
    fontStyle: "normal",
  };
  var sale = "20";
  var tag = getConstantStringByLanguage(ConstantStrings.EXPERIENCE);
  if (type === 2) {
    leftStyle = {
      ...leftStyle,
      background: "linear-gradient(180deg, #FAFBFC 0%, #E1D9FA 100%)",
      borderRight: "0.125rem dashed #582ED2",
    };
    saleStyle = {
      ...saleStyle,
      background: "#572CD1",
      borderColor: "#E0D8FA",
      boxShadow: "0px 2px 0px 0px #B09BE9",
      color: "#FAFBFC",
    };
    tag = getConstantStringByLanguage(ConstantStrings.SUGGESTION);
    sale = "40";
    oldPrice = isMax ? "1.800.000đ" : "1.400.000đ";
    newPrice = isMax ? "1.100" : "900";
  } else if (type === 3) {
    leftStyle = {
      ...leftStyle,
      background: "linear-gradient(180deg, #8864F0 0%, #3E1D9C 100%)",
      borderRight: "0.125rem dashed #E0D8FA",
    };
    saleStyle = {
      ...saleStyle,
      background: "#FAFBFC",
      borderColor: "#8D53E4",
      boxShadow: "0px 2px 0px 0px #B09BE9",
      color: "#572CD1",
    };
    tag = getConstantStringByLanguage(ConstantStrings.SAVE);
    sale = "45";
    oldPrice = isMax ? "3.600.000đ" : "2.800.000đ";
    newPrice = isMax ? "2.000" : "1.500";
  }
  return (
    <div
      className="is-flex is-align-items-center is-clickable"
      onClick={() => handleSelect(item._id, index, type === 1 ? getConstantStringByLanguage(ConstantStrings.PACKAGE_6_MONTH) : type === 2 ? getConstantStringByLanguage(ConstantStrings.PACKAGE_1_YEAR) : getConstantStringByLanguage(ConstantStrings.PACKAGE_2_YEAR))}
      style={{
        width: "22.5rem",
        height: "6.5rem",
        borderRadius: "1rem",
        border: "0.125rem solid",
        borderColor:
          type === 1 ? "#0A2A66" : type === 2 ? "#572CD1" : "#CBA7EF",
        background: type === 1 ? "#FAFBFC" : type === 2 ? "#E1D9FA" : "#572CD1",
        boxShadow:
          type === 3
            ? "0rem 0.25rem 0rem 0rem #0A2A66"
            : "0rem 0.25rem 0rem 0rem #572CD1",
      }}
    >
      <div
        className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center is-justify-content-center"
        style={leftStyle}
      >
        <div
          className="is-uppercase has-text-centered has-text-weight-bold"
          style={{
            color: type === 1 ? "#0A2A66" : type === 2 ? "#572CD1" : "#FAFBFC",
            fontSize: "0.875rem",
            lineHeight: "140%",
            fontStyle: "normal",
          }}
        >
          <p
            style={{
              fontSize: "1.75rem",
              lineHeight: "140%",
              fontStyle: "normal",
            }}
          >
            {type === 1 ? 6 : type === 2 ? 1 : 2}
          </p>
          <p>{type === 1 ? getConstantStringByLanguage(ConstantStrings.MONTH) : getConstantStringByLanguage(ConstantStrings.YEAR)}</p>
        </div>
        <div
          className="is-flex is-align-items-center is-justify-content-center is-uppercase has-text-weight-bold"
          style={{
            borderRadius: "0.5rem",
            padding: "0.25rem 0.5rem",
            background:
              type === 1 ? "#0A2A66" : type === 2 ? "#572CD1" : "#F3C00E",
            color: type === 3 ? "#572CD1" : "#FFF",
            fontSize: "0.625rem",
          }}
        >
          <p>{tag}</p>
        </div>
      </div>
      <div
        className={`${
          type === 2
            ? "is-justify-content-flex-start ml-4"
            : "is-justify-content-center"
        } is-flex is-flex-gap-3 is-align-items-center is-flex-grow-1`}
      >
        <div className="is-flex is-align-items-center is-flex-gap-3">
          <div className="is-uppercase has-text-weight-bold" style={saleStyle}>
            <p className="has-text-centered has-text-weight-bold">
              {getConstantStringByLanguage(ConstantStrings.PROMOTION)} <br />
              <b
                style={{
                  fontSize: "1rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                {sale}
              </b>{" "}
              %
            </p>
          </div>
          <div>
            <p
              style={{
                textDecorationLine: "line-through",
                color:
                  type === 1 ? "#9EAFD3" : type === 2 ? "#572CD1" : "#D4C4FF",
                fontSize: "1rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              {oldPrice}
            </p>
            <p
              className="has-text-weight-bold"
              style={{
                fontSize: "2rem",
                lineHeight: "normal",
                fontStyle: "normal",
                marginTop: "-0.5rem",
                color:
                  type === 1 ? "#0A2A66" : type === 2 ? "#572CD1" : "#FAFBFC",
              }}
            >
              {newPrice}
              <span
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                .000đ
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
