/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import CheckBox from "../../../components/button/check-box-and-radio/CheckBox";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import InputText from "../../../components/input/InputText";
import Otp from "../../../components/input/Otp";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import {
  confirmRegisterOtp,
  registerInfo,
  registerPhone,
  validateEmail,
} from "../../../config/auth";
import ConstantStrings from "../../../constants/ConstantStrings";
import { checkAndGetAnonymousUserId } from "../../../templates/helper/CheckAndGetAnonymousUserId";
import ReCaptChaV2 from "../../../templates/helper/ReCaptCha";
import "./../../../styling/layout-components/Register.css";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";

function Register() {
  var backgroundImage = {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };
  const [phone, setPhone] = useState("");
  const [registerState, setRegisterState] = useState(false);
  const [registerInfoUser, setRegisterInfoUser] = useState({
    email: null,
    password: "",
    repeatPassword: "",
    phone: "",
    fullName: "",
    otp: "",
    lastName: "lastName",
    firstName: "firstName",
    fatherName: "fatherName",
    motherName: "motherName",
    fatherEmail: "fatherEmail@gmail.com",
    motherEmail: "fatherEmail@gmail.com",
    fatherPhone: "fatherPhone",
    motherPhone: "fatherPhone",
    gradeId: "",
    anonymousUser: checkAndGetAnonymousUserId(),
  });
  const [sendOtpAgain, setSendOtpAgain] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [complete, setComplete] = useState(false);
  const [otp, setOtp] = useState([]);
  const [remainTime, setRemainTime] = useState(60);
  const [error, setError] = useState({
    phone: "",
    error: "",
    // email: "",
    otp: "",
    password: "",
    repeatPassword: "",
    // fullName: "",
    captcha: "",
  });
  const [showCaptcha, setShowCaptcha] = useState(false);
  // const [showPopupPrivacyPolicy, setShowPopupPrivacyPolicy] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const cookies = new Cookies();
  var expires = 7 * 24 * 3600;
  // const cookieRegister = cookies.get("register");
  const input1Ref = useRef(null);
  const countRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (value, index, name) => {
    if (!verifyOtp && !registerState) {
      setPhone(value);
    }
    if (verifyOtp && !registerState) {
      let newValue = [...otp];
      newValue[index] = value;
      setOtp(newValue);
    }
    if (!verifyOtp && registerState) {
      setRegisterInfoUser({ ...registerInfoUser, [name]: value });
    }
    setError({
      phone: "",
      // email: "",
      otp: "",
      password: "",
      repeatPassword: "",
      // fullName: "",
    });
  };
  const getOtp = () => {
    let newOtp = otp[0];
    for (let index = 1; index < otp.length; index++) {
      newOtp += otp[index];
    }
    return newOtp;
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // verify phone
    if (!verifyOtp && !registerState && !complete) {
      if (phone === "" || phone.length === 0) {
        setError({ ...error, phone: "Vui lòng nhập số điện thoại" });
      } else if (phoneno.test(phone) === false) {
        setError({ ...error, phone: "Số điện thoại không hợp lệ" });
      } else {
        if (showCaptcha) {
        } else {
          // setShowCaptcha(true);
          setShowPrivacyPolicy(true);
        }
      }
    }
    if (complete) {
      navigate("/calibration");
    }

    //verify otp phone
    if (!registerState && verifyOtp && !verifyEmail) {
      if (otp.length !== 6) {
        setError({
          ...error,
          otp: ConstantStrings.REQUEST_FILL_IN_ALL_GAPS,
        });
      }
      var newOtp = getOtp();
      // if (remainTime <= 0) {
      //   setError({ ...error, otp: ConstantStrings.TIME_OUT });
      // } else {
      // }
      try {
        await confirmRegisterOtp({
          phone: phone,
          otp: newOtp,
        });
        clearInterval(countRef.current);
        setError({
          phone: "",
          error: "",
          // email: "",
          otp: "",
          password: "",
          repeatPassword: "",
          fullName: "",
        });
        setRemainTime(60);
        setVerifyOtp(false);
        setRegisterState(true);
      } catch (errors) {
        setError({
          ...error,
          otp: errors.response.data?.errorMessage,
        });
        setOtp([]);
        input1Ref.current?.focus();
      }
    }

    // info & verify EMail
    if (registerState && !verifyOtp) {
      registerInfoUser.otp = getOtp();
      registerInfoUser.phone = phone;
      var check = false;
      const checkArr = Object.keys(registerInfoUser).filter(
        (item) => item !== "fullName"
      );
      const keyErrorArr = Object.keys(error);
      var objError = { ...error };
      for (let index = 0; index < checkArr.length; index++) {
        if (
          keyErrorArr.includes(checkArr[index]) &&
          registerInfoUser[checkArr[index]] === ""
        ) {
          check = true;
          objError = {
            ...objError,
            [checkArr[index]]: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
          };
        }
      }
      if (check) {
        setError(objError);
      } else if (
        registerInfoUser.password !== registerInfoUser.repeatPassword
      ) {
        setError({
          ...error,
          password: getConstantStringByLanguage(ConstantStrings.PASSWORD_INCORRECT),
          repeatPassword: getConstantStringByLanguage(ConstantStrings.PASSWORD_INCORRECT),
        });
      } else {
        try {
          const dataInfo = await registerInfo(registerInfoUser);
          if (cookies.get("register")) {
            cookies.remove("register");
          }
          cookies.set("register", dataInfo.access_token, {
            maxAge: expires, // httpOnly:true
          });

          // setShowCaptcha(true);
          setOtp([]);
          setError({
            phone: "",
            // email: "",
            otp: "",
            password: "",
            repeatPassword: "",
            // fullName: "",
          });
          setVerifyOtp(false);
          // setVerifyEmail(true);
          setComplete(true);
          setRegisterState(false);
        } catch (errors) {
          if (errors?.response?.data?.errorMessage.includes("email")) {
            setError({
              ...error,
              email: errors?.response?.data?.errorMessage,
            });
          } else if (
            errors?.response?.data?.errorMessage.includes("password")
          ) {
            setError({
              ...error,
              password: errors?.response?.data?.errorMessage,
            });
          } else {
            setError({
              ...error,
              error: errors.response.data?.errorMessage,
            });
          }
        }
      }
    }
    // verify email OTP
    if (!registerState && verifyOtp && verifyEmail) {
      if (otp.length !== 6) {
        setError({
          ...error,
          otp: ConstantStrings.REQUEST_FILL_IN_ALL_GAPS,
        });
      }
      newOtp = getOtp();
      // if (remainTime <= 0) {
      //   setError({ ...error, otp: ConstantStrings.TIME_OUT });
      // } else {
      // }
      try {
        await validateEmail({ otp: newOtp }, cookies.get("register"));
        setError({
          phone: "",
          // email: "",
          otp: "",
          password: "",
          repeatPassword: "",
          // fullName: "",
        });
        clearInterval(countRef.current);
        setVerifyOtp(false);
        setVerifyEmail(false);
        setRegisterState(false);
        setComplete(true);
      } catch (errors) {
        setError({
          ...error,
          otp: errors.response.data?.errorMessage,
        });
        setOtp([]);
        input1Ref.current?.focus();
      }
    }
  };
  const handleSubmitCaptcha = async (captcha) => {
    if (!verifyOtp && !registerState && !complete) {
      try {
        await registerPhone({ phone: phone, captcha });
        setError({
          phone: "",
          error: "",
          // email: "",
          otp: "",
          password: "",
          repeatPassword: "",
          fullName: "",
        });
        setVerifyOtp(true);
        setRegisterState(false);
        setShowCaptcha(false);
      } catch (errors) {
        setError({
          ...error,
          captcha: errors.response.data?.errorMessage,
        });
        setTimeout(() => {
          setShowCaptcha(false);
        }, 5000);
      }
    }
    if (verifyOtp && !verifyEmail) {
      try {
        await registerPhone({ phone: phone, captcha });
        setError({
          phone: "",
          error: "",
          // email: "",
          otp: "",
          password: "",
          repeatPassword: "",
          // fullName: "",
        });
        setVerifyOtp(true);
        setRemainTime(60);
        setRegisterState(false);
        setShowCaptcha(false);
      } catch (errors) {
        setError({
          ...error,
          captcha: errors.response.data?.errorMessage,
        });
        setTimeout(() => {
          setShowCaptcha(false);
        }, 5000);
      }
    }
    // if (verifyOtp && verifyEmail) {
    //   try {
    //     await createValidateEmail(
    //       { email: registerInfoUser.email },
    //       cookieRegister
    //     );
    //     setRemainTime(60);
    //     setShowCaptcha(false);
    //   } catch (error) {
    //     setError({
    //       ...error,
    //       captcha: error.response.data?.errorMessage,
    //     });
    //     setTimeout(() => {
    //       setShowCaptcha(false);
    //     }, 5000);
    //   }
    // }
  };
  const handleChangeCaptcha = async (captcha) => {
    await handleSubmitCaptcha(captcha);
  };

  // send OTP again
  const sendOtp = async () => {
    if (remainTime === 0) {
      setShowCaptcha(true);
      setSendOtpAgain(true);
      clearInterval(countRef.current);
      setOtp([]);
      setError({
        phone: "",
        // email: "",
        otp: "",
        password: "",
        repeatPassword: "",
        // fullName: "",
      });
    }
    // setRemainTime(60);
    // try {
    // } catch (error) {
    //   setError({
    //     ...error,
    //     otp: error.response.data?.errorMessage || error.response.data,
    //   });
    //   input1Ref.current?.focus();
    // }
  };
  if (remainTime <= 0 || !verifyOtp) {
    clearInterval(countRef.current);
  }
  const removePopup = () => {
    setIsAgreed(false);
    setShowPrivacyPolicy(false);
  };
  const handleSetShowCaptcha = (state) => {
    setShowPrivacyPolicy(false);
    setIsAgreed(false);
    setShowCaptcha(state);
  };
  const handleClickCheckbox = (state) => {
    setIsAgreed(state);
  };
  useEffect(() => {
    const countDown = () => {
      countRef.current = setInterval(() => {
        if (remainTime <= 0) {
          clearInterval(countRef.current);
          setRemainTime(0);
          return false;
        } else {
          setRemainTime((remainTime) => remainTime - 1);
        }
      }, 1000);
    };
    if (verifyOtp && !sendOtpAgain) {
      countDown();
    }
    if (sendOtpAgain) {
      clearInterval(countRef.current);
      setSendOtpAgain(false);
      // countDown()
    }
    if (verifyOtp) {
      input1Ref.current?.focus();
    } else {
      clearInterval(countRef.current);
    }
    return () => clearInterval(countRef.current);
  }, [sendOtpAgain, verifyOtp, remainTime]);
  return (
    <BackgroundImageFromUrl
      objectId="admin/frontend-images/auth/bg-desktop-signup.svg"
      style={backgroundImage}
    >
      {showCaptcha && (
        <>
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/auth/bg-desktop-captcha.svg"
            className="is-flex is-flex-direction-column m-auto is-align-items-center is-relative"
            style={{
              gap: "1rem",
              minHeight: "100vh",
              width: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
            }}
          >
            <div
              className="is-flex is-flex-direction-column m-auto is-align-items-center has-background-white"
              style={{
                gap: "1rem",
                width: "40rem",
                height: "10rem",
                borderRadius: "1rem",
              }}
            >
              <ReCaptChaV2
                handleChangeCaptcha={(captcha) => handleChangeCaptcha(captcha)}
              />
              <p className="has-text-danger subtitle is-size-6 m-0">
                {error.captcha}
              </p>
            </div>
            <div
              style={{
                width: "21.5rem",
                height: "8.3125rem",
                backgroundImage: "url('/images/auth/logo-captcha.svg')",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "absolute",
                left: "0",
                bottom: "0",
              }}
            ></div>
          </BackgroundImageFromUrl>
        </>
      )}
      {showPrivacyPolicy && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={removePopup}
          ></div>
          <div
            className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center"
            style={{
              width: "55rem",
              padding: "2.5rem 4rem",
              borderRadius: "1rem",
              maxHeight: "35rem",
              // overflowY:"auto",
              // left:"6rem",
              // top:"-2rem"
            }}
          >
            <div
              className=" is-flex is-flex-direction-column is-flex-gap-4"
              style={{ maxHeight: "35rem" }}
            >
              <div
                className="py-6 box-privacy-policy"
                style={{
                  maxHeight: "25rem",
                  fontSize: "0.875rem",
                  overflowY: "auto",
                }}
              >
                <h1
                  className=" has-text-kurio-main-color-purple has-text-weight-bold"
                  style={{ fontSize: "1.5rem" }}
                >
                  CHÍNH SÁCH BẢO MẬT THÔNG TIN
                </h1>
                <p style={{ marginBottom: "21px" }}>
                  Đọc xuống dưới cùng và đồng ý để Tiếp Tục
                </p>
                <h2 style={{ color: "#272266", fontWeight: 700 }}>
                  1. Chúng tôi thu thập những thông tin gì?
                </h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký trên trang
                  web của chúng tôi. <br /> <br />
                  Dữ liệu cá nhân mà Kurio có thể thu thập bao gồm, nhưng không
                  giới hạn: <br />
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Họ tên{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ email{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Ngày sinh{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Tài khoản ngân hàng và
                  thông tin thanh toán{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Số điện thoại{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Giới tính{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ thanh toán{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Thông tin được gửi bởi
                  hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào
                  Các Dịch vụ hoặc Nền tảng của chúng tôi.{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Bất kỳ thông tin nào
                  khác về người dùng khi người dùng đăng nhập để sử dụng Các
                  Dịch Vụ hoặc Nền tảng của chúng tôi.{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Dữ liệu tổng hợp về
                  nội dung người dùng sử dụng.{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Bạn có thể tự chỉnh
                  sửa được tên trong trang quản lý tài khoản. Nếu cần đổi email
                  đăng nhập hoặc số điện thoại, bạn cần gửi yêu cầu cho chúng
                  tôi và trải qua các bước xác thực.{" "}
                </p>
                <h2>2. Chúng tôi sẽ làm gì với thông tin của bạn?</h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Kurio sử dụng thông tin chúng tôi thu thập cho các mục đích:{" "}
                  <br />
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để xem xét và/hoặc xử
                  lý đơn đăng ký/giao dịch; đăng ký email của bạn với chúng tôi{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để quản lý việc bạn sử
                  dụng dịch vụ của chúng tôi{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để đáp ứng, xử lý,
                  giải quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu
                  cầu của bạn đối với các sản phẩm và dịch vụ nhất định và thông
                  báo cho bạn về các vấn đề dịch vụ và các hoạt động tài khoản
                  bất thường{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để thực thi các Điều
                  Khoản Dịch Vụ của chúng tôi{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để bảo vệ sự an toàn
                  cá nhân và các quyền, tài sản hoặc sự an toàn của người khác{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để nhận dạng và/hoặc
                  xác minh{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để giải quyết hoặc tạo
                  điều kiện dịch vụ khách hàng, thực hiện các khiếu nại của bạn,
                  giải quyết hoặc trả lời bất kỳ thắc mắc nào được gửi bởi (hoặc
                  nhằm được gửi bởi) bạn hoặc thay mặt bạn{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để liên hệ với bạn
                  hoặc liên lạc với bạn qua điện thoại, tin nhắn văn bản, email
                  nhằm mục đích quản trị quan hệ của bạn với chúng tôi{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để gửi thông báo tới
                  bạn qua trình duyệt hoặc qua ứng dụng Kurio trên điện thoại{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để tiến hành các hoạt
                  động nghiên cứu, phân tích và phát triển (bao gồm, nhưng không
                  giới hạn ở, phân tích dữ liệu, khảo sát, phát triển và/hoặc
                  lập đặc tính sản phẩm và dịch vụ), để phân tích cách thức bạn
                  sử dụng Các Dịch Vụ của chúng tôi, để cải thiện Các Dịch Vụ
                  hoặc sản phẩm của chúng tôi và/hoặc để cải thiện trải nghiệm
                  khách hàng của bạn{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Vì mục đích tiếp thị,
                  gửi cho bạn qua email, thông tin và tài liệu tiếp thị và quảng
                  bá liên quan đến các sản phẩm và/hoặc dịch vụ. Bạn có thể hủy
                  đăng ký nhận các thông tin tiếp thị tại bất cứ thời điểm nào
                  bằng cách sử dụng chức năng hủy đăng ký email{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Để ngăn chặn hoặc điều
                  tra bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay hành vi
                  sai trái nào, cho dù có liên quan đến việc bạn sử dụng Các
                  Dịch Vụ của chúng tôi{" "}
                </p>
                <p />
                <h2 style={{ color: "#272266", fontWeight: 700 }}>
                  3. Bảo mật
                </h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Bảo mật thông tin cá nhân của Khách hàng là ưu tiên hàng đầu
                  của chúng tôi. Các thông tin đăng ký mua hàng của Khách hàng
                  sẽ được bảo mật bằng cách sử dụng công nghệ mã hóa an toàn
                  Secure Sockets Layer (SSL). Chúng tôi cam kết sẽ cố gắng để
                  đảm bảo an ninh ở mức cao nhất cho Khách hàng. Tuy nhiên,
                  Kurio xin nhấn mạnh không có phương án nào đảm bảo được tính
                  bảo mật 100%. Nếu bạn có bất kỳ câu hỏi về vấn đề bảo mật trên
                  trang web của chúng tôi, bạn có thể gửi thắc mắc về email
                  cskh@kurio.vn.
                </p>
                <h2 style={{ color: "#272266", fontWeight: 700 }}>
                  4. Cookies và công nghệ phân tích dữ liệu khác
                </h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Cookies là một lượng nhỏ dữ liệu, được gửi tới trình duyệt của
                  bạn từ một trang web và được lưu trữ trên ổ đĩa cứng của máy
                  tính. Cookies cho phép trang web hoặc hệ thống của các bên
                  cung cấp dịch vụ nhận diện trình duyệt của bạn và lưu lại một
                  số thông tin nhất định. Chúng tôi dùng cookies để hiểu và lưu
                  trữ hoạt động của bạn và đáp ứng các nhu cầu của bạn tốt hơn
                  cho lần truy cập sau.
                </p>
                <h2 style={{ color: "#272266", fontWeight: 700 }}>
                  5. Chúng tôi có cung cấp thông tin cho bên khác không?
                </h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức
                  thương mại khác với những thông tin cá nhân của bạn. Tuy
                  nhiên, thông tin không định danh của những người truy cập vào
                  trang web có thể được sử dụng và cung cấp cho các bên khác
                  nhằm mục đích marketing, quảng cáo hoặc mục đích sử dụng khác.
                </p>
                <h2 style={{ color: "#272266", fontWeight: 700 }}>
                  6. Xóa tài khoản
                </h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Các cá nhân sở hữu tài khoản trên Kurio được quyền xóa bỏ tài
                  khoản bất kỳ thời điểm nào. Trong trường hợp Khách hàng muốn
                  xóa tài khoản, vui lòng liên hệ với Kurio theo hotline
                  0971500120 hoặc email <a href>cskh@kurio.vn</a> để được hỗ
                  trợ. Ngoài ra, chúng tôi chỉ hỗ trợ khôi phục lại tài khoản
                  trong trường hợp nhầm lẫn hoặc tài khoản bị tấn công trong
                  thời gian 30 ngày kể từ thời điểm xóa. Vui lòng đọc kỹ các
                  thông tin được cung cấp trước khi thực sự làm hành động này.
                </p>
                <h2 style={{ color: "#272266", fontWeight: 700 }}>
                  7. Thông tin liên hệ
                </h2>
                <p style={{ color: "#272266", fontWeight: 500 }}>
                  Nếu bạn có thắc mắc, hãy liên hệ với chúng tôi: <br />
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>
                  Hotline: 0971500120{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Email: cskh@kurio.vn{" "}
                </p>
                <p
                  style={{
                    color: "#272266",
                    fontWeight: 500,
                    marginLeft: "2rem",
                  }}
                >
                  <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ: số 128 Nguyễn
                  Thái Học, phường Điện Biên, quận Ba Đình, Hà Nội.
                </p>
                <p /> <br />
                <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center">
                  <div className=" is-flex is-flex-gap-4 is-align-items-center">
                    <CheckBox onClick={() => handleClickCheckbox(!isAgreed)} />
                    <div
                      className=" is-flex is-align-items-center is-flex-gap-2"
                      style={{ fontSize: "1.125rem", lineHeight: "normal" }}
                    >
                      <p>Tôi đồng ý với </p>
                      <p
                        className=" has-text-kurio-main-color-black has-text-weight-bold"
                        // onClick={() => {
                        //   setShowPrivacyPolicy(!showPrivacyPolicy);
                        // }}
                      >
                        <span className="">
                          {" "}
                          Chính sách bảo mật và thu thập thông tin
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="is-flex"
                style={{ gap: "1rem", margin: "0 auto" }}
              >
                <PrimaryButtonPageLevel
                  style={{ height: "4rem" }}
                  disabled={!isAgreed}
                  onClick={() => handleSetShowCaptcha(true)}
                >
                  tiếp tục
                </PrimaryButtonPageLevel>
              </div>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
      {!showCaptcha && (
        <div
          className="columns is-centered m-0 p-0 is-justify-content-space-evenly "
          style={{ minHeight: "100vh", width: "100%" }}
        >
          <div className="column m-0 p-0 is-relative">
            <BackgroundImageFromUrl
              objectId="admin/frontend-images/auth/image-left-signup.svg"
              style={{
                width: "40.75rem",
                height: "37.375rem",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                marginLeft: "2.375rem",
                marginTop: "4.125rem",
              }}
            ></BackgroundImageFromUrl>
            <div
              style={{
                width: "21.5rem",
                height: "8.3125rem",
                backgroundImage: "url('/images/auth/logo-reset-pw.svg')",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "absolute",
                left: "0",
                bottom: "0",
              }}
            ></div>
          </div>
          <div
            className={
              complete
                ? "column is-flex has-background-white has-text-kurio-main-color-black is-justify-content-center"
                : "column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between is-justify-content-center has-background-white  has-text-kurio-main-color-black is-flex-gap-4"
            }
            style={{
              padding: complete
                ? "5.125rem 4rem 5.125rem 3.5rem"
                : "3.1875rem 4rem 2.5rem 3.5rem",
              width: "27.5rem",
              height: Object.values(error).some((item) => item.length > 0)
                ? "47rem"
                : "40rem",
              marginRight: "5.25rem",
              marginTop: "3.4375rem",
              marginBottom: "3.4375rem",
              maxWidth: "27.5rem",
              position: "relative",
              boxShadow: "0.75rem 0.6875rem #0A2A66",
              border: "0.0625rem solid #0A2A66",
            }}
          >
            <div
              style={{
                height: "7.5rem",
                width: "7.443125rem",
                position: "absolute",
                bottom: "1rem",
                left: "-6rem",
                backgroundImage:
                  "url('/images/auth/floating-element-signup.svg')",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
            {complete ? (
              <div
                className="is-flex is-flex-direction-column is-align-items-flex-start has-text-kurio-main-color-black"
                style={{ gap: "2rem", width: "20rem" }}
              >
                <p
                  style={{ fontSize: "2.25rem", lineHeight: "normal" }}
                  className=" has-text-weight-bold "
                >
                  Hoàn thành đăng ký
                </p>
                <div
                  style={{
                    height: "10rem",
                    width: "20rem",
                    backgroundImage:
                      "url('/images/auth/image-reset-pw-success.svg')",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <p style={{ fontSize: "0.875rem", lineHeight: "normal" }}>
                  Bắt đầu hành trình{" "}
                  <span className="has-text-weight-bold">Toán tư duy</span> cùng{" "}
                  <span className="has-text-weight-bold">KURIO</span> ngay bây
                  giờ!
                </p>
                <PrimaryButtonPageLevel
                  style={{
                    maxWidth: "20rem",
                    width: "20rem",
                    height: "4rem",
                  }}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Bắt đầu
                </PrimaryButtonPageLevel>
              </div>
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <input hidden type="submit" />
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center p-0"
                    style={{
                      gap: verifyOtp || registerState ? "1.5rem" : "2rem",
                    }}
                  >
                    <p
                      className="has-text-weight-bold m-0"
                      style={{ fontSize: "2rem" }}
                    >
                      Đăng ký
                    </p>
                    {!registerState && !verifyEmail && !verifyOtp && (
                      <>
                        <div
                          className=""
                          style={{ width: "20rem", height: "5rem" }}
                        >
                          <InputText
                            label={"Nhập số điện thoại"}
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={(e) => {
                              handleChange(e.target.value, 0, "");
                            }}
                          />
                        </div>
                        {error.phone && (
                          <p
                            className=""
                            style={{
                              fontSize: "0.875rem",
                              color: "#CE1010",
                              lineHeight: "normal",
                            }}
                          >
                            {error.phone}
                          </p>
                        )}
                        <PrimaryButtonPageLevel
                          // className="button is-medium is-black is-uppercase has-text-weight-semibold"
                          style={{
                            maxWidth: "20rem",
                            width: "20rem",
                            height: "4rem",
                          }}
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Tiếp theo
                        </PrimaryButtonPageLevel>
                      </>
                    )}
                    {!registerState && (verifyOtp || verifyEmail) && (
                      <>
                        <div
                          className="is-flex is-flex-direction-column "
                          style={{ gap: "1.5rem", fontSize: "0.875rem" }}
                        >
                          {/* {verifyEmail ? (
                            <div>
                              <p>
                                KURIO đã gửi một email xác thực chứa mã OTP tới
                                hòm thư của bạn tại địa chỉ
                              </p>
                              <span className="has-text-weight-bold pr-1">
                                {registerInfoUser.email}.
                              </span>
                              Xin hãy kiểm tra hộp thư đến (và cả hộp thu spam
                              nếu bạn không tìm thấy email của chúng tôi) và làm
                              theo hướng dẫn.
                            </div>
                          ) : (
                            <>
                            </>
                          )} */}
                          <p>
                            Mã OTP gồm 6 chữ số vừa được gửi đến số điện thoại
                            của bạn. Điền mã OTP để tiếp tục
                          </p>

                          <Otp
                            value={otp}
                            resetFocus={verifyOtp && error?.otp?.length > 0}
                            onChange={(value, name, index) =>
                              handleChange(value, name, index)
                            }
                          />
                          {error !== "" && verifyOtp && error.otp && (
                            <div
                              className="has-text-left"
                              style={{
                                fontSize: "0.875rem",
                                color: "#CE1010",
                                lineHeight: "normal",
                              }}
                            >
                              <span>{error.otp}</span>
                            </div>
                          )}

                          {/* {verifyEmail ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-row is-align-items-flex-start"
                                style={{ gap: "0.75rem" }}
                              >
                                <span>Không nhận được mail ?</span>
                                <span
                                  onClick={sendOtp}
                                  className="is-underlined has-text-weight-bold is-clickable is-uppercase"
                                >
                                  Gửi lại ({remainTime}S)
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                            </>
                          )} */}
                          <div
                            className="is-flex is-flex-direction-row is-align-items-flex-start"
                            style={{ gap: "0.75rem" }}
                          >
                            <span>Không nhận được mã OTP ?</span>
                            <span
                              onClick={sendOtp}
                              className="is-underlined has-text-weight-bold is-clickable is-uppercase"
                            >
                              Gửi lại ({remainTime}S)
                            </span>
                          </div>
                        </div>
                        <PrimaryButtonPageLevel
                          style={{
                            width: "20rem",
                            height: "4rem",
                            maxWidth: "20rem",
                          }}
                          disabled={otp.length < 6}
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Tiếp theo
                        </PrimaryButtonPageLevel>
                      </>
                    )}
                    {registerState && !verifyOtp && (
                      <>
                        <div
                          className="is-flex is-flex-direction-column "
                          style={{
                            gap: "1.5rem",
                          }}
                        >
                          {/* <div
                            className="is-flex is-flex-direction-column is-flex-gap-4"
                            style={{
                              width: "20rem",
                              height: error?.email ? "auto" : "5rem",
                            }}
                          >
                            <InputText
                              label={"Email đăng ký"}
                              type="text"
                              value={registerInfoUser.email}
                              name="email"
                              onChange={(e) =>
                                handleChange(e.target.value, 0, e.target.name)
                              }
                            />
                            {error.email && (
                              <p className="has-text-danger subtitle is-size-6">
                                {error.email}
                              </p>
                            )}
                          </div> */}

                          <div
                            className="is-flex is-flex-direction-column is-flex-gap-4"
                            style={{
                              width: "20rem",
                              height: error?.password ? "auto" : "5rem",
                            }}
                          >
                            <InputText
                              label={"Tạo mật khẩu"}
                              type="password"
                              value={registerInfoUser.password}
                              name="password"
                              onChange={(e) =>
                                handleChange(e.target.value, 0, e.target.name)
                              }
                            />

                            {error.password && (
                              <p className="has-text-danger subtitle is-size-6">
                                {error.password}
                              </p>
                            )}
                          </div>
                          <div
                            className="is-flex is-flex-direction-column is-flex-gap-4"
                            style={{
                              width: "20rem",
                              height: error?.repeatPassword ? "auto" : "5rem",
                            }}
                          >
                            <InputText
                              label={"Xác nhận mật khẩu"}
                              type="password"
                              value={registerInfoUser.repeatPassword}
                              name="repeatPassword"
                              onChange={(e) =>
                                handleChange(e.target.value, 0, e.target.name)
                              }
                            />

                            {error.repeatPassword && (
                              <p className="has-text-danger subtitle is-size-6">
                                {error.repeatPassword}
                              </p>
                            )}
                          </div>
                          {error.error && (
                            <p className="has-text-danger has-text-centered subtitle is-size-6">
                              {error.error}
                            </p>
                          )}
                        </div>
                        <PrimaryButtonPageLevel
                          // className="button mt-4 mb-4 is-medium is-fullwidth is-black is-uppercase has-text-weight-semibold"
                          style={{
                            maxWidth: "20rem",
                            width: "20rem",
                            height: "4rem",
                            borderRadius: "1rem",
                          }}
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Tiếp theo
                        </PrimaryButtonPageLevel>
                      </>
                    )}
                  </div>
                </form>
                <div
                  className="has-text-centered is-flex is-flex-direction-column is-align-items-center"
                  style={{ gap: "1rem" }}
                >
                  {/* <div
                    className="is-flex is-flex-direction-row is-align-items-flex-start"
                    style={{ gap: "0.5rem" }}
                  >
                    <div
                      className={`has-background-kurio-main-color-black`}
                      style={{
                        width: "2.125rem",
                        height: "0.5rem",
                        borderRadius: "0.5rem",
                      }}
                    ></div>
                    <div
                      className={`${
                        (verifyOtp && !registerState) ||
                        registerState ||
                        (verifyEmail && verifyOtp)
                          ? "has-background-kurio-main-color-black"
                          : "has-background-kurio-gray-light-2"
                      } `}
                      style={{
                        width: "2.125rem",
                        height: "0.5rem",
                        borderRadius: "0.5rem",
                      }}
                    ></div>
                    <div
                      className={`${
                        registerState || (verifyEmail && verifyOtp)
                          ? "has-background-kurio-main-color-black"
                          : "has-background-kurio-gray-light-2"
                      } `}
                      style={{
                        width: "2.125rem",
                        height: "0.5rem",
                        borderRadius: "0.5rem",
                      }}
                    ></div>
                    <div
                      className={`${
                        verifyEmail && verifyOtp
                          ? "has-background-kurio-main-color-black"
                          : "has-background-kurio-gray-light-2"
                      } `}
                      style={{
                        width: "2.125rem",
                        height: "0.5rem",
                        borderRadius: "0.5rem",
                      }}
                    ></div>
                  </div> */}
                  <Link
                    className=" has-text-kurio-main-color-black"
                    to="/signin"
                  >
                    <p style={{ fontSize: "0.875rem" }}>
                      <span className=" mr-2">Đã có tài khoản ?</span>
                      <span className="has-text-weight-bold is-underlined">
                        Đăng nhập
                      </span>
                    </p>
                  </Link>

                  <p style={{ fontSize: "0.875rem" }}>
                    <span className="">
                      Liên hệ số 0971 500 120 để được trợ giúp
                    </span>
                  </p>
                  <Link to={"/privacy-policy"} target="_blank">
                    <p
                      className=" has-text-kurio-main-color-black has-text-weight-bold is-underlined"
                      style={{ fontSize: "0.875rem" }}
                    >
                      <span className="">Chính sách bảo mật</span>
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </BackgroundImageFromUrl>
  );
}

export default Register;
