/* eslint-disable react-hooks/exhaustive-deps */
import "@fontsource/montserrat";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import {
  getCurrentEvent,
  setClearStateImageUrlDaily,
} from "../../reducers/dailyMissionSlice";
import { clearExamQuestion } from "../../reducers/examsQuestionSlice";
import { setIsChangeGrade } from "../../reducers/gradesSlice";
import DailyMissionMainWrapper from "../daily-mission/DailyMissionMainWrapper";
import "./Activities.style.css";
import ConstantStrings from "../../constants/ConstantStrings";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const ActivitiesMainWrapper = ({
  listMonthlyExams,
  deadline,
  serverTime,
  isChangeGrade,
  grades,
  questionDaily,
  currentHP,
  startHP,
  imageUrls,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [showPopupStartDailyMission, setShowPopupStartDailyMission] =
    useState(false);
  const [showPopupCongratulations, setShowPopupCongratulations] =
    useState(false);
  const [showPopupCalculatePoints, setShowPopupCalculatePoints] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
    localStorage.setItem("showDailyQuiz", true);
    navigate("/achievement");
  };

  const handleClickShowPopupStartDaily = () => {
    if (
      !questionDaily ||
      (questionDaily === undefined && questionDaily === null)
    ) {
      alert(getConstantStringByLanguage(ConstantStrings.NO_DAILY_MISSION));
      return;
    }

    setShowPopupStartDailyMission(true);
    // setShowPopupDailyMission(false);
  };

  const handleShowPopupCalculatePoints = () => {
    // setShowPopupCalculatePoints(!showPopupCalculatePoints);
    setShowPopupCalculatePoints(!showPopupCalculatePoints);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function isValidImageAtIndex(list, index) {
    return (
      Array.isArray(list) &&
      list !== undefined &&
      list !== null &&
      list.length > 0 &&
      list[index] &&
      list[index] !== ""
    );
  }

  const removePopup = () => {
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
  };

  useEffect(() => {
    const lastLoginKey = "lastLoginDate";
    const clearAllKey = "clearAll";

    window.onbeforeunload = () => {
      if (window.location.pathname === "/activities") {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
          if (
            key !== lastLoginKey &&
            key !== clearAllKey &&
            key !== "accessToken"
          ) {
            localStorage.removeItem(key);
          }
        });
      }
      window.history.replaceState(null, "");
    };

    dispatch(clearExamQuestion());

    if (isChangeGrade && grades) {
      const lastLoginKey = "lastLoginDate";
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (
          key !== lastLoginKey &&
          key !== clearAllKey &&
          key !== "accessToken"
        ) {
          localStorage.removeItem(key);
        }
      });

      dispatch(setClearStateImageUrlDaily());
      dispatch(getCurrentEvent());

      dispatch(setIsChangeGrade(false));
      setIsLoading(true);
    }

    // if (questionDaily === null) {
    //   dispatch(getCurrentEvent());
    // }
  }, [isChangeGrade, dispatch, isLoading, questionDaily, grades]);

  return (
    <>
      <ScrollRefWrapper />

      {showPopupCongratulations && (
        <BackgroundImageFromUrl
          objectId="admin/frontend-images/hub/popup-chuc-mung-dat-thanh-tich.svg"
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="is-flex is-flex-direction-row is-align-items-center">
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "2.5rem" }}
            >
              <p
                style={{
                  fontSize: "8rem",
                  textAlign: "center",
                  fontFamily: "Sriracha",
                  lineHeight: "87.5%",
                  fontWeight: 400,
                  color: "#FAFBFC",
                }}
              >
                Chúc <br /> mừng!
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontFamily: "Montserrat",
                  lineHeight: "normal",
                  fontWeight: 500,
                  color: "#FAFBFC",
                }}
              >
                Bạn đã đạt được thêm <br />{" "}
                <b>1 huy hiệu cho Bảng Thành Tích!</b>
              </p>

              <div
                className="is-flex is-flex-direction-row"
                style={{ gap: "1rem" }}
              >
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "20rem",
                    borderRadius: "1rem",
                    maxWidth: "20rem",
                  }}
                  isDarkColor={true}
                  onClick={handleNavigate}
                >
                  TỚI TRANG THÀNH TÍCH
                </PrimaryButtonPageLevel>
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "10rem",
                    borderRadius: "1rem",
                  }}
                  onClick={removePopup}
                >
                  Đóng
                </PrimaryButtonPageLevel>
              </div>
            </div>

            <div style={{ width: "33.22rem" }}>
              <div className="is-relative" style={{ marginLeft: "-3rem" }}>
                <img src="/images/hub/background-congratulations.svg" alt="" />
                <div
                  style={{
                    position: "absolute",
                    left: "6.3rem",
                    top: "7rem",
                  }}
                >
                  <div className="is-relative">
                    <ImageFromUrl
                      style={{
                        position: "absolute",
                        left: "3.3rem",
                        top: "-4rem",
                      }}
                      objectId="admin/frontend-images/hub/image-congratulations-12.svg"
                      alt=""
                    />
                    <ImageFromUrl
                      objectId="admin/frontend-images/hub/bg-laurel.svg"
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      padding: "1.5rem 4rem",
                      width: "20.5rem",
                      height: "6.625rem",
                      borderRadius: "5rem",
                      border: "8px solid #fed131",
                      margin: "0 auto",
                    }}
                    className="is-flex is-align-items-center has-background-kurio-yellow-light-3"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        lineHeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Hạ gục Quái vật Tháng {new Date().getMonth() + 1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImageFromUrl>
      )}

      {showPopupStartDailyMission && (
        <DndProvider backend={HTML5Backend}>
          <DailyMissionMainWrapper
            setShowPopupCongratulations={setShowPopupCongratulations}
            showPopupCongratulations={showPopupCongratulations}
            setShowPopupCalculatePoints={setShowPopupCalculatePoints}
            setShowPopupStartDailyMission={setShowPopupStartDailyMission}
          />
        </DndProvider>
      )}

      {showPopupCalculatePoints && (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/hub/background-calculate-points.svg"
            className="is-relative "
            style={{
              width: "56.875rem",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "34.125rem",
              borderRadius: "3.8125rem",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "position",
                top: "2rem",
                right: "-2.7rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: "110",
                  top: "-3rem",
                  right: "12rem",
                }}
              >
                <img
                  className="is-relative"
                  src={getConstantStringByLanguage(ConstantStrings.NAV_BAR_CALCULATE_POINTS_URL)}
                  alt=""
                />
              </div>
            </div>

            <div style={{ padding: "2rem" }}>
              <div
                style={{
                  width: " 52.125rem",
                  height: "30.125rem",
                  borderRadius: "4rem",
                  padding: "5.19rem 6.06rem",
                  background: "#fff",
                  margin: "0 auto",
                }}
              >
                <div
                  className="main-caculator-point"
                  style={{
                    width: "41rem",
                    maxHeight: "22.5rem",
                  }}
                >
                  <div style={{ width: "39rem", height: "22.5rem" }}>
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.25",
                        fontWeight: 500,
                        textAlign: "justify",
                      }}
                    >
                       {getConstantStringByLanguage(ConstantStrings.MONSTER_MISSION_DESCRIPTION)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={handleShowPopupCalculatePoints}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      <div
        style={{ height: "calc(100% - 15.5rem)" }}
        className="is-flex is-justify-content-center is-align-items-center"
      >
        <div
          className="is-flex is-flex-direction-column is-align-items-center"
          style={{ height: "19.9375rem", gap: "2.5rem" }}
        >
          <p
            className="has-text-kurio-main-color-white"
            style={{
              fontWeight: 700,
              // position: "relative",
              // top: "4.81rem",
              // left: "29.5rem",
              fontSize: "2rem",
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.MISSION)}
          </p>

          <BackgroundImageFromUrl
            objectId={
              isValidImageAtIndex(imageUrls, 7)
                ? imageUrls[7]
                : "admin/frontend-images/hub/3-banner-daily-mission-default.svg"
            }
            className="is-relative is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            style={{
              width: "60rem",
              borderRadius: "1rem",
              boxShadow: " 2px 4px 0px 0px #0a2a66",
              border:
                "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
              // margin: "8rem auto",
              height: "15rem",
              gap: "1.84rem",
              backgroundPosition: "center",
              // backgroundImage: 'url("/images/hub/3-banner-daily-mission.svg")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "2rem",
                left: "2rem",
              }}
            >
              <img src={getConstantStringByLanguage(ConstantStrings.MESSENGER_KURIO_URL)} alt="" />
            </div>
            <div style={{ width: "19.375rem", textAlign: "center" }}>
              <ImageFromUrl
                style={{
                  width: "19.375rem",
                  position: "relative",
                  left: "2rem",
                  top: "0.7em",
                }}
                objectId={
                  // questionDaily !== null
                  startHP !== 0 &&
                  startHP / 2 < currentHP &&
                  currentHP <= startHP &&
                  questionDaily !== null
                    ? isValidImageAtIndex(imageUrls, 4)
                      ? imageUrls[4]
                      : "admin/frontend-images/hub/2-1 quai vat khoe manh.png"
                    : startHP !== 0 &&
                      currentHP <= startHP / 2 &&
                      questionDaily !== null
                    ? isValidImageAtIndex(imageUrls, 5)
                      ? imageUrls[5]
                      : "admin/frontend-images/hub/2-2 quai vat yeu.png"
                    : currentHP === 0 && questionDaily !== null
                    ? isValidImageAtIndex(imageUrls, 6)
                      ? imageUrls[6]
                      : "admin/frontend-images/hub/2-3 quai vat dau hang.png"
                    : ""
                  // : ""
                }
                alt=""
              />

              <div
                className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center has-background-kurio-main-color-light-gray"
                style={{
                  width: "21.0625rem",
                  background: "#FAFBFC",
                  position: "absolute",
                  zIndex: 1,
                  height: "2.5rem",
                  top: "11.8rem",
                  left: "4.5rem",
                  gap: "1rem",
                  borderRadius: "1rem 1rem 0rem 0rem",
                  padding: "0.5rem 0rem",
                  border:
                    "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                }}
              >
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${
                        isNaN(currentHP / startHP)
                          ? 100
                          : (currentHP / startHP) * 100
                      }%`,
                      backgroundColor: "#E34D4D", // Màu xanh cho phần trước
                    }}
                  ></div>
                </div>

                <p
                  className="has-text-kurio-main-color-black"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: 700,
                    textTransform: "uppercase",
                  }}
                >
                  <b
                    className="has-text-kurio-red-main-accent"
                    style={{ marginRight: "0.3rem" }}
                  >
                    {currentHP}
                  </b>
                  /<b style={{ marginLeft: "0.3rem" }}>{startHP}</b>
                </p>
              </div>
            </div>

            <div className="is-relative is-flex is-flex-direction-column is-justify-content-space-evenly ">
              <img
                style={{
                  width: "7.5625rem",
                  position: "absolute",
                  top: "1.2rem",
                  right: "0rem",
                  cursor: "pointer",
                }}
                onClick={handleShowPopupCalculatePoints}
                src={getConstantStringByLanguage(ConstantStrings.BUTTON_CALCULATE_POINTS_URL)}
                alt=""
              />

              <ImageFromUrl
                style={{ cursor: "pointer", marginTop: "1.5rem" }}
                objectId={
                  (questionDaily !== undefined || questionDaily !== null) &&
                  questionDaily
                    ?.map(
                      (item) => item.correct === true || item.correct === false
                    )
                    .flat()
                    .indexOf(false) !== -1
                    ? getConstantStringByLanguage(ConstantStrings.BUTTON_DAILY_MISSION_URL)
                    : getConstantStringByLanguage(ConstantStrings.TITLE_FINISHED_SUCCESS_QUIZ_DAILY_URL)
                }
                alt=""
                onClick={handleClickShowPopupStartDaily}
              />
            </div>
          </BackgroundImageFromUrl>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    listMonthlyExams: state.examsQuestion.listMonthlyExams,
    examQuestions: state.examsQuestion.questions,
    takeExamIdLPInfo: state.examsQuestion?.examInformation?.takeExamIdLP,
    maxScore: state.examsQuestion.maxScore,
    currentScore: state.examsQuestion.currentScore,
    deadline: state.examsQuestion.checkExamTime,
    serverTime: state.examsQuestion.serverTime,
    isChangeGrade: state.grades.isChangeGrade,
    grades: state.grades.gradeId,
    questionDaily: state.dailyMission.questions,
    startHP: state.dailyMission.startingHp,
    currentHP: state.dailyMission.currentHp,
    imageUrls: state.dailyMission.imageUrls,
  };
}

export default connect(mapStateToProps)(ActivitiesMainWrapper);
