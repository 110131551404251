/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonQuestion from "../../components/button/secondary-button/SecondaryButtonQuestion";
import TertiaryButtonQuestion from "../../components/button/tertiary-button/TertiaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import TagCheckMultipleResponse from "../../components/question/TagCheckMultipleResponse";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  setQuestionFinished,
  setQuestionState,
  setQuestionVisible,
} from "../../reducers/practiceElectiveSlice";
import "../../styling/layout-components/MultipleScroll.style.css";
import jsonParser from "../helper/jsonParser";
import { getConstantStringByLanguage } from "../helper/getConstantStringByLanguage";

function VerticalMultipleResponse({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  isLast,
  nextQuestion,
  showEndPractice,
  submitAnswers,
  isPreview,
  seenQuestions,
}) {
  const dispatch = useDispatch();
  var questionTexts = question.texts
    ? question.texts.map((text, index) => jsonParser(text, index))
    : question.texts;

  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  //check if explanation is null
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      explanation.texts.some((item) => item?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      explanation.images.some((item) => item?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  //create a state variable to store the selected answers
  var [selected, setSelected] = useState(() => {
    if (question.state) {
      return question.state.selected;
    } else {
      return [];
    }
  });
  //create a state variable to store the checking state
  var [checkingState, setCheckingState] = useState(() => {
    if (question.state) {
      return question.state.checkingState;
    } else {
      return false;
    }
  });
  //create a state variable to store the explanation state
  var [explanationState, setExplanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });
  const [allCorrect, setAllCorrect] = useState(() => {
    if (question.state) {
      return question.state.allCorrect;
    } else {
      return false;
    }
  });
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  const [widthButton, setWidthButton] = useState(maxLength >= 11 ? 20 : 10);
  const [maxWidth, setMaxWidth] = useState(60);
  var backgroundStyle = {
    borderRadius: "1rem 0 0 1rem",
    height: `35rem`,
    width: "50%",
  };
  var backgroundLeftStyle = {};
  if (
    backgroundImage[0] !== null &&
    backgroundImage[0] &&
    checkImage(backgroundImage[0])
  ) {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
      // backgroundImage: `url(${memoizedImageData[1][0]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundLeftStyle = {
      ...backgroundStyle,
      width: "100%",
    };
  }
  if (
    backgroundImage[1] !== null &&
    backgroundImage[1] &&
    checkImage(backgroundImage[1])
  ) {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
      // backgroundImage: `url(${memoizedImageData[1][1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      borderRadius: "0 1rem 1rem 0",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "3rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    buttonStyle = {
      padding: "0.75rem 1.25rem",
      width: `20rem`,
      height: "auto",
      fontWeight: "bold",
      whiteSpace: "pre-wrap",
      outline: "0.0625rem solid",
      opacity: 1,
      fontSize: "1.125rem",
    };
  }

  var selectedStyle = {
    ...buttonStyle,
    backgroundColor: "#E6E1F5",
    outline: "0.125rem solid #572CD1",
  };

  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };
  var exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
  const setLocalStorage = (
    answer,
    correct,
    selected,
    checkingState,
    explanationState,
    allCorrect
  ) => {
    localStorage.setItem(
      "exerciseState",
      JSON.stringify({
        correct,
        answer,
        questionState: {
          index: questionIndex,
          state: {
            selected: selected,
            checkingState: checkingState,
            allCorrect: allCorrect,
            explanationState: explanationState,
          },
        },
      })
    );
  };

  //McqButton component
  var maxWidthButton = 0;
  const divRef = useRef(null);
  const McqButton = ({ id, index, children }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
      if (buttonRef.current.clientWidth > maxWidthButton) {
        maxWidthButton = buttonRef.current.clientWidth;
      }
      if (widthButton < maxWidthButton / ConstantStrings.BASE_REM) {
        setWidthButton(maxWidthButton / ConstantStrings.BASE_REM);
        buttonRef.current.style.width = `${
          maxWidthButton / ConstantStrings.BASE_REM
        }rem`;
      } else {
        buttonRef.current.style.width = `${widthButton}rem`;
      }
      //set all auto-height buttons to the same height
    }, [id]);
    return (
      //if index in selected, set the style to selectedStyle
      //if index in selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {/* <button id={id} ref={buttonRef} className="button" style={index === selected ? (checkingState ? (questionSolutions[selected] === true ? correctStyle : incorrectStyle) : selectedStyle) : buttonStyle} onClick={() => handleSelect(index)} disabled={checkingState}>
                    {children}
                </button> */}
        {selected.includes(index) && checkingState ? (
          questionSolutions[index] === true && allCorrect ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <img
              src="/images/common/redX.svg"
              style={iconStyle}
              alt="loading"
            />
          )
        ) : (
          <>
            <div
              className="icon"
              style={{
                ...iconStyle,
                width: "0.75rem",
                height: "0.75rem",
              }}
            ></div>
          </>
        )}
        <button
          id={id}
          ref={buttonRef}
          className={`${
            selected.includes(index)
              ? checkingState && allCorrect
                ? questionSolutions[index] === true
                  ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                  : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
              : "has-background-kurio-main-color-white has-text-kurio-main-color-black is-kurio-main-color-black"
          } button is-shadowless has-text-weight-bold is-outlined`}
          style={
            selected.includes(index) && !checkingState
              ? selectedStyle
              : buttonStyle
          }
          onClick={() => handleSelect(index)}
          disabled={checkingState}
        >
          {checkImage(children) ? (
            <>
              <img alt={children} src={children} />
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div
        className="column is-narrow is-centered"
        style={{ padding: "0.5rem 0.75rem" }}
        key={index}
      >
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });

  //create a function to handle the selection of an answer
  //when an answer is selected, add the index of the answer to the selected state variable
  const handleSelect = (index) => {
    var answer = questionSolutions.map((item) => false);
    var newSelected = [];
    if (selected.includes(index)) {
      setSelected(selected.filter((item) => item !== index));
      newSelected = selected.filter((item) => item !== index);
      newSelected.map((item) => (answer[item] = true));
    } else {
      setSelected([...selected, index]);
      newSelected = [...selected, index];
      newSelected.map((item) => (answer[item] = true));
    }
    setLocalStorage(
      answer,
      null,
      newSelected,
      checkingState,
      explanationState,
      allCorrect
    );
  };

  //create a function to handle the checking of the answer
  //when the answer is checked, set the checking state variable to true
  //if all selected answers correct, alert "Correct!", change the background color of the selected answer to green
  //if not all selected answer correct, alert "Incorrect!", change the background color of the selected answer to red
  const handleCheck = () => {
    var correctFlag = true;
    var countCorrectANswer = 0;
    var answer = questionSolutions.map((item) => false);
    selected.map((item, index) => (answer[index] = true));
    if (selected.length > 0) {
      setCheckingState(true);
      for (var i = 0; i < selected.length; i++) {
        if (questionSolutions[selected[i]] !== true) {
          correctFlag = false;
        }
      }
      for (let i = 0; i < questionSolutions.length; i++) {
        if (questionSolutions[i] === true) {
          countCorrectANswer++;
        }
      }
      if (selected.length !== countCorrectANswer) {
        correctFlag = false;
      }
    } else {
      // alert("Please select an answer!");
    }
    setLocalStorage(answer, correctFlag, selected, true, false, correctFlag);
    if (correctFlag) {
      // alert("Correct!");
      setAllCorrect(true);
    } else {
      // alert("Incorrect!");
    }
  };

  //create a function to handle the reset of the question
  //when the question is reset, set the selected state variable to -1
  //set the checking state variable to false
  const handleReset = () => {
    setSelected([]);
    setLocalStorage(null, null, [], false, false, false);
    setCheckingState(false);
    setAllCorrect(false);
  };

  //create a function to handle the showing of the explanation
  const handleShowExplanation = () => {
    if (exerciseState) {
      exerciseState.questionState.state.explanationState = true;
      exerciseState.questionState.state.allCorrect = allCorrect;
      localStorage.setItem("exerciseState", JSON.stringify(exerciseState));
    } else {
      var questionState = {
        index: questionIndex,
        state: {
          selected: selected,
          checkingState: checkingState,
          allCorrect: allCorrect,
          explanationState: true,
        },
      };
      localStorage.setItem("exerciseState", JSON.stringify({ questionState }));
    }
    setExplanationState(true);
  };

  const showNextQuestion = () => {
    if (exerciseState === null || !checkingState) {
      setLocalStorage(
        null,
        null,
        selected,
        checkingState,
        explanationState,
        allCorrect
      );
    }
    if (!isPreview) {
      dispatch(setQuestionFinished(questionIndex));
      if (!isLast) {
        nextQuestion();
      } else {
        showEndPractice();
      }
    } else {
      const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
      dispatch(setQuestionState(exerciseState.questionState));
      dispatch(setQuestionVisible(questionIndex + 1));
    }
  };

  useEffect(() => {
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);
  return (
    <div style={{ height: `35rem` }} className="is-flex is-flex-direction-row">
      <div style={{ width: "50%" }} ref={divRef}>
        <BackgroundImageFromUrl
          objectId={backgroundImage[0]}
          style={backgroundLeftStyle}
          className={`${
            !checkImage(backgroundImage[0])
              ? "has-background-kurio-main-color-light-gray"
              : ""
          }`}
        ></BackgroundImageFromUrl>
      </div>
      <BackgroundImageFromUrl
        objectId={backgroundImage[1]}
        style={{
          ...backgroundStyle,
          width: "50%",
          padding: "2rem",
          position: "relative",
        }}
        className={`${
          !checkImage(backgroundImage[1])
            ? "has-background-kurio-main-color-light-gray"
            : ""
        }  is-flex is-flex-direction-column is-justify-content-space-between has-text-left`}
      >
        {!explanationState && (
          <div className="question-container">
            {
              //render questionTexts if its array has length greater than 0
              questionTexts !== null &&
                questionTexts !== undefined &&
                questionTexts?.length > 0 && (
                  <div className=" has-text-kurio-main-color-black mb-4">
                    {questionTexts}
                  </div>
                )
            }
            {questionChoices.length === 4 &&
            (widthButton + 1.5) * 2 + 4 < maxWidth ? (
              <div>
                <div className="columns  p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns  p-0 m-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
              </div>
            ) : // if questionChoices has length 5, render the choices in a 3x2 grid
            questionChoices.length === 5 ? (
              <>
                {(widthButton + 1.5) * 2 + 4 > maxWidth ? (
                  <div className="columns is-multiline  p-0 m-0">
                    {MapChoices}
                  </div>
                ) : (
                  <div>
                    <div className="columns p-0 m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                    </div>
                    <div className="columns p-0 m-0">
                      {MapChoices[2]}
                      {MapChoices[3]}
                    </div>
                    <div className="columns p-0 m-0">{MapChoices[4]}</div>
                  </div>
                )}
              </>
            ) : // if questionChoices has length 6, render the choices in a 3x3 grid
            questionChoices.length === 6 ? (
              <>
                {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                  <div>
                    <div className="columns  p-0 m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                    </div>
                    <div className="columns  p-0 m-0">
                      {MapChoices[2]}
                      {MapChoices[3]}
                    </div>
                    <div className="columns  p-0 m-0">
                      {MapChoices[4]}
                      {MapChoices[5]}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="columns  p-0 m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                      {MapChoices[2]}
                    </div>
                    <div className="columns  p-0 m-0">
                      {MapChoices[3]}
                      {MapChoices[4]}
                      {MapChoices[5]}
                    </div>
                  </div>
                )}
              </>
            ) : questionChoices.length === 3 ? (
              <>
                {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                  <div className="columns is-multiline  p-0 m-0">
                    {MapChoices}
                  </div>
                ) : (
                  <div>
                    <div className="columns  p-0 m-0">
                      {MapChoices[0]}
                      {MapChoices[1]}
                    </div>
                    <div className="columns  p-0 m-0">{MapChoices[2]}</div>
                  </div>
                )}
              </>
            ) : (
              <div className="columns is-multiline  p-0 m-0">{MapChoices}</div>
            )}

            {checkingState && allCorrect && (
              <div>
                <TagCheckMultipleResponse check={true} />
              </div>
            )}
            {checkingState && !allCorrect && (
              <div>
                <TagCheckMultipleResponse check={false} />
              </div>
            )}
          </div>
        )}

        {/* only appears when explanationState is true */}
        {explanationState && (
          <div className="explanation-container is-flex is-flex-direction-column">
            <div
              className="main-content-multiple-vertical is-flex is-flex-direction-column"
              style={{
                overflow: "hidden",
                overflowY: "scroll",
                height: "22rem",
              }}
            >
              {((explanationTexts !== null &&
                explanationTexts !== undefined &&
                explanationTexts?.length > 0) ||
                (explanationImages !== null &&
                  explanationImages !== undefined &&
                  explanationImages?.length > 0)) &&
                explanation.texts?.length >= explanation.images?.length && (
                  <>
                    {explanation.texts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="is-flex is-flex-direction-column is-align-items-center"
                          style={{
                            gap: "1.5rem",
                          }}
                        >
                          {explanationTexts !== null &&
                            explanationTexts !== undefined &&
                            item?.length > 0 && (
                              <div key={index}>{explanationTexts[index]}</div>
                            )}
                          {/* only appears when explanationImages has length greater than 0 */}
                          {explanationImages !== null &&
                            explanationImages !== undefined &&
                            explanationImages[index]?.length > 0 && (
                              <ImageFromUrl
                                objectId={explanationImages[index]}
                              />
                            )}
                        </div>
                      );
                    })}
                  </>
                )}
              {((explanationTexts !== null &&
                explanationTexts !== undefined &&
                explanationTexts?.length > 0) ||
                (explanationImages !== null &&
                  explanationImages !== undefined &&
                  explanationImages?.length > 0)) &&
                explanation.texts?.length < explanation.images?.length && (
                  <>
                    {explanationImages.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="is-flex is-flex-direction-column is-align-items-center"
                          style={{
                            gap: "1.5rem",
                          }}
                        >
                          {explanationTexts !== null &&
                            explanationTexts !== undefined &&
                            explanation.texts[index]?.length > 0 && (
                              <div key={index}>{explanationTexts[index]}</div>
                            )}
                          {/* only appears when explanationImages has length greater than 0 */}
                          {explanationImages !== null &&
                            explanationImages !== undefined &&
                            item?.length > 0 && (
                              <ImageFromUrl
                                objectId={explanationImages[index]}
                              />
                            )}
                        </div>
                      );
                    })}
                  </>
                )}
            </div>
          </div>
        )}

        <div
          className={` is-flex is-flex-direction-row ${
            (checkingState && !allCorrect) || !checkingState
              ? "is-align-items-flex-end is-justify-content-space-between"
              : "is-align-items-flex-end"
          }`}
          style={{
            height: "9rem",
          }}
        >
          {/* only appears when checkingState is false */}
          {!checkingState && !allCorrect && (
            <PrimaryButtonQuestion
              disabled={!(selected.length > 0)}
              className="button"
              onClick={handleCheck}
            >
              {getConstantStringByLanguage(ConstantStrings.SUBMIT)}
            </PrimaryButtonQuestion>
          )}
          {/* only appears when checkingState is true and any selected answer is incorrect */}
          {checkingState && !allCorrect && (
            <PrimaryButtonQuestion className="button" onClick={handleReset}>
              {getConstantStringByLanguage(ConstantStrings.TRY_AGAIN)}
            </PrimaryButtonQuestion>
          )}
          {/* Next and "Xem giai thich" button appears when checkingState is true and all selected answers is correct */}
          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1rem", marginTop: "2rem" }}
          >
            {checkingState &&
              allCorrect &&
              selected.every((item) => questionSolutions[item] === true) &&
              explanationState === false && (
                <>
                  {explanation !== null &&
                    explanation !== undefined &&
                    ((explanationImages !== null &&
                      explanationImages?.length > 0) ||
                      (explanationTexts !== null &&
                        explanationTexts?.length > 0)) && (
                      <SecondaryButtonQuestion
                        defaultColor={"is-kurio-purple-light-2"}
                        onClick={handleShowExplanation}
                      >
                        {getConstantStringByLanguage(ConstantStrings.SHOW_EXPLANATION)}
                      </SecondaryButtonQuestion>
                    )}
                </>
              )}
            {/* "Xem cau hoi" button appears when explanationState is true */}
            {explanationState && (
              <SecondaryButtonQuestion
                className="button"
                defaultColor={"is-kurio-purple-light-2"}
                onClick={() => {
                  setLocalStorage(
                    null,
                    null,
                    selected,
                    checkingState,
                    false,
                    allCorrect
                  );
                  setExplanationState(false);
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.SHOW_QUESTION)}
              </SecondaryButtonQuestion>
            )}
          </div>
          {checkingState &&
            allCorrect &&
            selected.every((item) => questionSolutions[item] === true) && (
              <div
                className={` ${
                  question.state && questionIndex < seenQuestions -1&& !isPreview
                    ? "is-hidden"
                    : ""
                }`}
              >
                <PrimaryButtonQuestion onClick={showNextQuestion}>
                  {getConstantStringByLanguage(ConstantStrings.NEXT)}
                </PrimaryButtonQuestion>
              </div>
            )}

          {(selected.some((item) => questionSolutions[item] !== true) ||
            (!checkingState && !allCorrect)) && (
            <div
              className={` ${
                question.state && questionIndex < seenQuestions -1&& !isPreview
                  ? "is-hidden"
                  : ""
              }`}
              style={{ float: "right" }}
            >
              <TertiaryButtonQuestion
                onClick={showNextQuestion}
                suffix={"admin/frontend-images/button/button-skip-icon"}
              >
                {getConstantStringByLanguage(ConstantStrings.SKIP)}
              </TertiaryButtonQuestion>
            </div>
          )}
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(VerticalMultipleResponse);
