import React, { useState } from "react";
import CheckBox from "../../components/button/check-box-and-radio/CheckBox";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import ConstantStrings from "../../constants/ConstantStrings";
import { UpgradeAccountContext } from "./UpgradeAccount";
import { USER_TEST_PREP_TYPE } from "../../templates/helper/CheckAndGetAnonymousUserId";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const PaymentMethod = ({
  handleSelectStep,
  handleNextStep,
  setnameSelectedPaymentMethod,
  setShowTermsPopup,
  showTermsPopup,
  paymentRecord,
  setDirectTransferConfirm,
  setSelectMethodsState,
  setConfirmState,
  setshowErrorPayment,
  showErrorPayment,
  studentType,
  isPremium,
}) => {
  const {
    nameSelectedService,
    directTransferConfirm,
    methods,
    setPaymentMethodId,
    PaymentMethodId,
  } = React.useContext(UpgradeAccountContext);
  const [selected, setSelected] = useState(PaymentMethodId);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const handleClickCheckbox = (state) => {
    setIsAgreed(state);
  };

  const removePopup = () => {
    setShowTermsPopup(false);
    setshowErrorPayment(false);
    setIsAgreed(false)
  };

  const handleSelect = (_id, name) => {
    var newName = name
    if (newName.toUpperCase().includes("VNPAY")) {
      newName = getConstantStringByLanguage(ConstantStrings.PAYMENT_VNPAY)
    }else{
      newName = getConstantStringByLanguage(ConstantStrings.TRANSFER_BANK)
    }
    if (selected === _id) {
      setSelected(-1);
      setPaymentMethod("");
      setPaymentMethodId("");
    } else {
      handleSelectStep(2);
      // setSelected(_id);
      setPaymentMethod(newName);
      setnameSelectedPaymentMethod(newName);
      handleNextStep(_id);
      // setPaymentMethodId(_id);
    }

    // handleNextStep(_id);
  };
  const handleContinue = () => {
    // setnameSelectedPaymentMethod(paymentMethod);
    if (paymentRecord.includes("vnpay")) {
      window.location.href = paymentRecord;
    } else {
      setDirectTransferConfirm({
        ...directTransferConfirm,
        paymentRecordId: paymentRecord,
      });
      setSelectMethodsState(false);
      setConfirmState(true);
    }
  };
  const selectStyle = {
    border: "1.5px solid #0A2A66",
    boxShadow: "2px 4px 0px 0px #0A2A66",
    padding: "2.5rem 2rem 3.5rem 2rem",
    height: "27.5rem",
    width: "26.25rem",
    background: "#FAFBFC",
    borderRadius: "1rem",
  };
  return (
    <div className="is-flex is-flex-direction-row" style={{ gap: "1.5rem" }}>
      {methods.map((method, index) => {
        return (
          <div key={index}>
            {/* {!method.name.includes("VNPAY") && (
            )} */}
            <div
              key={index}
              className={`${
                // selected === method._id
                //   ? "has-background-kurio-main-color-black has-text-kurio-main-color-white"
                //   :
                "has-text-kurio-main-color-black"
              } is-clickable is-flex is-flex-direction-column is-flex-gap-4 is-relative`}
              style={
                selected === method._id
                  ? selectStyle
                  : {
                      padding: "2.5rem 2rem 3.5rem 2rem",
                      borderRadius: "1rem",
                      width: "26.25rem",
                      border: "1.5px solid #0A2A66",
                      background: "#FAFBFC",
                      height: "27.5rem",
                      boxShadow: "2px 4px 0px 0px #0A2A66",
                    }
              }
            >
              <div
                className="is-flex is-flex-direction-column"
                style={{ gap: "1rem", textAlign: "center" }}
              >
                <div
                  className="is-flex is-flex-direction-column is-align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <p
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.PAY_VIA)}
                  </p>
                  {method.name.includes("VNPAY") ? (
                    <p
                      className="has-text-weight-bold"
                      style={{
                        fontSize: "1.875rem",
                        lineHeight: "normal",
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.QR_CODE_OR_BANK_CODE)}
                    </p>
                  ) : (
                    <p
                      className={`${
                        // selected === method._id
                        //   ? "has-text-kurio-main-color-white"
                        //   :
                        "has-text-kurio-main-color-black"
                      } has-text-weight-bold`}
                      style={{
                        width: "16.5rem",
                        fontSize: "1.875rem",
                        lineHeight: "normal",
                      }}
                    >
                      {/* {method.name} */}
                      {getConstantStringByLanguage(ConstantStrings.TRANSFER_DIRECTLY)}
                    </p>
                  )}
                </div>

                {method.name.includes("VNPAY") ? (
                  <img
                    src="/images/upgrades/quan-upgrade.png"
                    style={{
                      width: "14.64706rem",
                      height: "7.5rem",
                      margin: "0 auto",
                    }}
                    alt=""
                  />
                ) : (
                  <img
                    src="/images/upgrades/ly-upgrade.png"
                    style={{
                      width: "10.64706rem",
                      height: "7.5rem",
                      margin: "0 auto",
                    }}
                    alt=""
                  />
                )}
                <div
                  className="is-flex is-flex-direction-column is-align-items-center"
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {method.name.includes("VNPAY") ? (
                    <p>
                      {getConstantStringByLanguage(ConstantStrings.PAY_VIA_VNPAY)}
                    </p>
                  ) : (
                    getConstantStringByLanguage(ConstantStrings.DIRECT_TRANSFER_PAYMENT)
                  )}
                  {index === 0 && (
                    <img
                      style={{
                        width: "8.25rem",
                        height: "2.5rem",
                        margin: "0 auto",
                      }}
                      src="/images/upgrades/image-vnpay.svg"
                      alt=""
                    />
                  )}
                </div>
              </div>
              <PrimaryButtonPageLevel
                style={{
                  height: "4rem",
                  borderRadius: "1rem",
                  position: "absolute",
                  right: "5.625rem",
                  bottom: "-1.8rem",
                }}
                onClick={() => {
                  handleSelect(method._id, method.name);
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.CHOOSE)}
              </PrimaryButtonPageLevel>
            </div>
          </div>
        );
      })}

      {showErrorPayment && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={removePopup}
          ></div>
          <div
            className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center"
            style={{
              width: "50rem",
              padding: "2.5rem",
              borderRadius: "1rem",
              // maxHeight: "30rem",
              gap: "2.5rem",
              // overflowY:"auto",
              // left:"6rem",
              // top:"-2rem"
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-align-items-flex-start"
              style={{ gap: "1rem" }}
            >
              <p>
                {!isPremium ? (
                  <>
                    {getConstantStringByLanguage(ConstantStrings.CONTACT_SUPPORT)}
                  </>
                ) : (
                  <>
                    <b>
                      {studentType === USER_TEST_PREP_TYPE
                        ? getConstantStringByLanguage(ConstantStrings.CURRENT_TEST_PREP_PLAN)
                        : getConstantStringByLanguage(ConstantStrings.PREMIUM_ACCOUNT_ACTIVE)}
                    </b>{" "}
                    <br></br>
                    {getConstantStringByLanguage(ConstantStrings.SUPPORT_ASSISTANCE)}
                  </>
                )}
              </p>

              <div
                className="is-flex is-flex-direction-column is-align-items-flex-start"
                style={{ gap: "1rem" }}
              >
                <div
                  className="is-flex is-flex-direction-row is-align-items-center"
                  style={{ gap: "1rem" }}
                >
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      color: "#0A2A66",
                      lineHeight: "normal",
                    }}
                  >
                    contact@ikemso.com
                  </p>
                </div>
                <div
                  className="is-flex is-flex-direction-row is-align-items-center"
                  style={{ gap: "1rem" }}
                >
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "1.2rem",
                      color: "#0A2A66",
                      lineHeight: "normal",
                    }}
                  >
                    0971 500 120
                  </p>
                </div>
                <div
                  className="is-flex is-flex-direction-row is-align-items-flex-start"
                  style={{ gap: "1rem" }}
                >
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      color: "#0A2A66",
                      lineHeight: "1.5rem",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.KURIO_ADDRESS)}
                  </p>
                </div>
              </div>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-20%",
                left: "-4%",
                zIndex: 999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showTermsPopup && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={removePopup}
          ></div>
          <div
            className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center"
            style={{
              width: "50rem",
              padding: "2.5rem",
              borderRadius: "1rem",
              // maxHeight: "30rem",
              gap: "2.5rem",
              // overflowY:"auto",
              // left:"6rem",
              // top:"-2rem"
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-flex-start is-justify-content-center"
              style={{
                width: "12.5rem",
                padding: "1.5rem",
                borderRadius: "2rem",
                border: "1px dashed #572CD1",
              }}
            >
              <h1
                className=" has-text-kurio-main-color-purple has-text-weight-bold"
                style={{ fontSize: "1.5rem" }}
              >
                {getConstantStringByLanguage(ConstantStrings.SELECTED_PACKAGE)}
              </h1>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-2 has-text-kurio-main-color-black "
                style={{ lineHeight: "normal" }}
              >
                <p style={{ fontSize: "0.75rem" }}>{getConstantStringByLanguage(ConstantStrings.TYPE)}</p>
                <p
                  className="has-text-weight-bold"
                  style={{ fontSize: "1.125rem" }}
                >
                  {nameSelectedService}
                </p>
              </div>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-2 has-text-kurio-main-color-black "
                style={{ lineHeight: "normal" }}
              >
                <p style={{ fontSize: "0.75rem" }}>{getConstantStringByLanguage(ConstantStrings.PRICE)}</p>
                <p
                  className="has-text-weight-bold"
                  style={{ fontSize: "1.125rem" }}
                >
                  {directTransferConfirm?.transferAmount?.toLocaleString(
                    "vi-VN"
                  ) + " VNĐ"}
                </p>
              </div>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-2 has-text-kurio-main-color-black "
                style={{ lineHeight: "normal" }}
              >
                <p style={{ fontSize: "0.75rem" }}>{getConstantStringByLanguage(ConstantStrings.PAYMENT_METHOD)}</p>
                <p
                  className="has-text-weight-bold"
                  style={{ fontSize: "1.125rem" }}
                >
                  {paymentMethod}
                </p>
              </div>
            </div>
            <div
              className=" is-flex is-flex-direction-column is-flex-gap-4"
              style={{ width: "30rem" }}
            >
              <div
                className=" has-background-kurio-main-color-light-gray is-flex is-flex-direction-column is-align-items-center is-align-self-stretch"
                style={{
                  maxHeight: "17.5rem",
                  fontSize: "0.875rem",
                  // overflowY: "auto",
                  padding: " 1.5rem 0.75rem 0.75rem 0rem",
                  borderRadius: "0.5rem",
                  gap: "0.625rem",
                }}
              >
                <div
                  className="box-terms has-background-kurio-main-color-light-gray is-flex is-flex-direction-column is-align-items-flex-start is-align-self-stretch"
                  style={{
                    maxHeight: "17.5rem",
                    fontSize: "0.875rem",
                    overflowY: "auto",
                    padding: "0.5rem 1.25rem 1.25rem 2rem",
                    borderRadius: "0.5rem",
                    gap: "0.625rem",
                  }}
                >
                  {/* <h1 className="has-text-weight-bold is-uppercase">
                    ĐIỀU KHOẢN SỬ DỤNG
                  </h1> */}
                  <h1
                    className="has-text-weight-bold is-uppercase has-text-centered"
                    style={{ width: "100%" }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.ACCOUNT_TERMS_OF_USE)}
                  </h1>
                  <p style={{ color: "#272266", fontWeight: 400 }}>
                   {getConstantStringByLanguage(ConstantStrings.ACCOUNT_TERMS_OF_USE_CONTENT)}
                  </p>
                  {getConstantStringByLanguage(ConstantStrings.TERMS_OF_USE)}
                  {getConstantStringByLanguage(ConstantStrings.PURCHASE_SERVICE_TERMS)}
                  {getConstantStringByLanguage(ConstantStrings.PAYMENT_METHODS)}
                  {getConstantStringByLanguage(ConstantStrings.AGREEMENT)}
                  {getConstantStringByLanguage(ConstantStrings.FREE_RELEARNING_POLICY)}
                  {getConstantStringByLanguage(ConstantStrings.REFUND_POLICY)}
                  {getConstantStringByLanguage(ConstantStrings.CONTACT_INFO)}
                  <h1
                    className=" has-text-kurio-main-color-black has-text-weight-bold has-text-centered is-uppercase"
                    style={{ width: "100%" }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY)}
                  </h1>
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_1)}
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_2)}
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_3)}
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_4)}
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_5)}
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_6)}
                  {getConstantStringByLanguage(ConstantStrings.PRIVACY_POLICY_CONTENT_7)}
                </div>
              </div>
              <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center">
                <div className=" is-flex is-flex-gap-4 is-align-items-center">
                  <CheckBox onClick={() => handleClickCheckbox(!isAgreed)} />
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.75rem", lineHeight: "normal" }}
                  >
                    <p>{getConstantStringByLanguage(ConstantStrings.TERMS_AGREEMENT)}</p>
                  </div>
                </div>
              </div>
              <div
                className="is-flex"
                style={{ gap: "1rem", margin: "0 auto" }}
              >
                <PrimaryButtonPageLevel
                  style={{ height: "4rem", width: "30rem", maxWidth: "30rem" }}
                  disabled={!isAgreed}
                  onClick={handleContinue}
                >
                  {getConstantStringByLanguage(ConstantStrings.CONTINUE_PAYMENT)}
                </PrimaryButtonPageLevel>
              </div>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
