/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Description: Drag and Drop question template
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import { selectAnsweredQuestions } from "../../reducers/roadmapSlice";
import jsonParser from "../helper/jsonParser";
import { ChoiceContainer } from "./dragdrop-components/ChoiceContainer";
import { ItemTypes } from "./dragdrop-components/ItemTypes";
import { Target } from "./dragdrop-components/Target";
import { getConstantStringByLanguage } from "../helper/getConstantStringByLanguage";

export const DragDrop = memo(function DragDrop({
  question,
  explanation,
  questionIndex,
  questions,
  seenQuestions,
  takeExamsQuestionId,
  isPreview,
  questionId,
  handleQuestionChange,
  IKMCMode,
}) {
  const questionTexts =
    question.texts !== null &&
      question.texts !== undefined &&
      question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  const questionChoices = question.choices;
  const questionTargets =
    question.targets?.length > 0
      ? question.targets
      : question.choices.map((item) => "");
  const questionSolutions = question.solutions;
  const checkingState = question.showSolution;

  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation.images[1].length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const [imgWidth, setImgWidth] = useState(0);
  const cookies = new Cookies();
  const answeredQuestions = useSelector(selectAnsweredQuestions);

  var nextButtonStyle = {
    width: "100%",
    maxWidth: "60rem",
    padding: "2rem 0 0 0",
  };
  if (IKMCMode) {
    nextButtonStyle = {
      ...nextButtonStyle,
      position: "absolute",
    };
  }

  const correctStyle = {
    backgroundColor: "#E5F0CC",
    borderColor: "#4B9631",
  };

  const incorrectStyle = {
    backgroundColor: "#F8E3E3",
    borderColor: "#E34D4D",
  };

  var iconStyle = {
    width: "1.5rem",
    height: "1.5rem",
    zIndex: 100,
    position: "relative",
    top: "-0.75rem",
    left: "0rem",
  };

  const [selectedItem] = useState(() => {
    return questions
      .filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat();
  });

  //create a state variable to store the correct state
  const [isCorrect, setIsCorrect] = useState(() => {
    if (question.state) {
      return question.state.isCorrect;
    } else {
      return false;
    }
  });

  //create a state variable to store the targets
  const [targets, setTargets] = useState(
    questionTargets.map((target, index) => {
      return {
        name: target,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: selectedItem
          ? questionChoices[selectedItem[index] - 1]
          : answers
            ? answers[index]
            : null,
        id: index,
      };
    })
  );

  const [targetsSolution] = useState(
    questionTargets.map((target, index) => {
      return {
        name: target,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: questionChoices[questionSolutions[index] - 1],
        id: index,
      };
    })
  );

  const [answerSolutionStyle] = useState(() => {
    if (checkingState || isPreview) {
      let correct = true;
      let newAnswerStyle = [];
      for (let i = 0; i < targetsSolution.length; i++) {
        // console.log(questionChoices.indexOf(targets[i].lastDroppedItem) + 1);

        if (questionSolutions[i]) {
          //   console.log(12);
          //   correct = false;
          //   newAnswerStyle.push(incorrectStyle);
          // } else {
          //   console.log(1);

          newAnswerStyle.push(correctStyle);
        }
      }
      // if (correct) {
      //   setIsCorrect(true);
      // } else {
      //   setIsCorrect(false);
      // }
      return newAnswerStyle;
    } else {
      return [];
    }
  });

  if (!JSON.parse(localStorage.getItem("targets"))) {
    questionTargets.map((target, index) => {
      return {
        name: target,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: selectedItem
          ? questionChoices[selectedItem[index] - 1]
          : answers
            ? answers[index]
            : null,
        id: index,
      };
    });
  }

  //create a state variable to store the explanation state
  const [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [answerStyle, setAnswerStyle] = useState(() => {
    if (checkingState) {
      let correct = true;
      let newAnswerStyle = [];
      for (let i = 0; i < targets.length; i++) {
        if (
          questionChoices.indexOf(targets[i].lastDroppedItem) + 1 !==
          questionSolutions[i]
        ) {
          correct = false;
          newAnswerStyle.push(incorrectStyle);
        } else {
          newAnswerStyle.push(correctStyle);
        }
      }
      // if (correct) {
      //   setIsCorrect(true);
      // } else {
      //   setIsCorrect(false);
      // }
      return newAnswerStyle;
    } else {
      return [];
    }
  });

  const [answers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      return null;
    }
  });

  //create a state variable to store the choices
  const [choices] = useState(
    questionChoices.map((choice, index) => {
      return {
        name: choice,
        type: ItemTypes.CHOICE,
        id: index,
      };
    })
  );

  const saveAnswer = (index, item) => {
    var newTargets = targets.map((target, i) => {
      if (i === index) {
        return {
          ...target,
          lastDroppedItem: item.name,
        };
      } else if (i === item.index) {
        return {
          ...target,
          lastDroppedItem: null,
        };
      } else {
        return target;
      }
    });

    if (item.index !== -1) {
      newTargets = targets.map((target, i) => {
        if (i === item.index) {
          return {
            ...target,
            lastDroppedItem: null,
          };
        } else {
          return target;
        }
      });
    }

    var questionState = {
      index: questionIndex,
      state: {
        answers: newTargets.map((target) => target.lastDroppedItem),
      },
    };
    var answer = questionTargets.map((item) => false);

    if (newTargets.some((target) => target.lastDroppedItem !== null)) {
      newTargets.map(
        (target, index) =>
          (answer[index] = questionChoices.indexOf(target.lastDroppedItem) + 1)
      );
    } else {
      answer = null;
    }
    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
    };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }

    var questionsAnswered = result;
    const submit = { submit: false, questions: [newAnswer] };
    var questionAnswered = { questionIndex: questionIndex, status: true };

    if (answer === null) {
      questionAnswered.status = false;
    }

    const value = {
      questionState,
      questionAnswered,
      questionsAnswered,
      submit,
    };

    localStorage.setItem("questionStateExams", JSON.stringify(value));
  };

  const isChoiceSelected = (choice) => {
    let selected = false;
    targets.forEach((target) => {
      if (target.lastDroppedItem === choice) {
        selected = true;
      }
    });

    return selected;
  };

  const dropOutside = (item) => {
    if (item.index !== -1) {
      setTargets((targets) => {
        return targets.map((target, i) => {
          if (i === item.index) {
            return {
              ...target,
              lastDroppedItem: null,
            };
          } else {
            return target;
          }
        });
      });
      var targetsLocal = JSON.parse(localStorage.getItem("targets"));
      var targetsLocalCurrent =
        targetsLocal &&
        targetsLocal !== null &&
        targetsLocal?.filter((item) => item.questionIndex === questionIndex)[0]
          ?.targets;
      targetsLocal = targetsLocal.map((targetsLocalItem) =>
        targetsLocalItem.questionIndex !== questionIndex
          ? targetsLocalItem
          : {
            questionIndex: questionIndex,
            targets: targetsLocalCurrent?.map((target, i) => {
              if (i === item.index) {
                return {
                  ...target,
                  lastDroppedItem: null,
                };
              } else {
                return target;
              }
            }),
          }
      );
      localStorage.setItem("targets", JSON.stringify(targetsLocal));

      var newTargets = targetsLocal.map((target, i) => {
        if (i === item.index) {
          return {
            ...target,
            lastDroppedItem: null,
          };
        } else {
          return target;
        }
      });
      var questionState = {
        index: questionIndex,
        state: {
          answers: newTargets.map((target) => target.lastDroppedItem),
        },
      };
      var answer = questionTargets.map((item) => false);

      if (newTargets.some((target) => target.lastDroppedItem != null)) {
        newTargets.map(
          (target, index) =>
          (answer[index] =
            questionChoices.indexOf(target.lastDroppedItem) + 1)
        );
      } else {
        answer = null;
      }
      var newAnswer = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      // setSelected(selected);
      var result = [...answeredQuestions];
      if (result.length === 0) {
        result.push(newAnswer);
      } else {
        var flag = true;
        for (
          let question = 0;
          question < answeredQuestions.length;
          question++
        ) {
          if (answeredQuestions[question].questionId === newAnswer.questionId) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }

        if (!flag) {
          result.push(newAnswer);
        } else {
          result = answeredQuestions.map((question) =>
            question.questionId === questionId ? newAnswer : question
          );
        }
      }
      var questionsAnswered = result;
      const submit = { submit: false, questions: [newAnswer] };
      var questionAnswered = {
        questionIndex: questionIndex,
        status: true,
      };

      if (answer === null) {
        questionAnswered.status = false;
      }

      const value = {
        questionState,
        questionAnswered,
        questionsAnswered,
        submit,
      };

      localStorage.setItem("questionStateExams", JSON.stringify(value));
    }
  };

  const handleDrop = useCallback(
    (index, item) => {
      if (item.index !== -1) {
        setTargets((targets) => {
          return targets.map((target, i) => {
            if (i === index) {
              return {
                ...target,
                lastDroppedItem: item.name,
              };
            } else if (i === item.index) {
              return {
                ...target,
                lastDroppedItem: null,
              };
            } else {
              return target;
            }
          });
        });
        let targetsLocal = JSON.parse(localStorage.getItem("targets"));
        let targetsLocalCurrent =
          targetsLocal &&
          targetsLocal !== null &&
          targetsLocal?.filter((item) => item.questionIndex === questionIndex);
        let setTargetsLocal = targets.map((target, i) => {
          if (i === index) {
            return {
              ...target,
              lastDroppedItem: item.name,
            };
          } else if (i === item.index) {
            return {
              ...target,
              lastDroppedItem: null,
            };
          } else {
            return target;
          }
        });
        if (targetsLocalCurrent && targetsLocalCurrent?.length > 0) {
          targetsLocal = targetsLocal.map((targetsLocalItem) =>
            targetsLocalItem.questionIndex === questionIndex
              ? { questionIndex: questionIndex, targets: setTargetsLocal }
              : targetsLocalItem
          );
        } else {
          if (
            targetsLocal &&
            targetsLocal !== null &&
            targetsLocal?.length > 0
          ) {
            targetsLocal.push({
              questionIndex: questionIndex,
              targets: setTargetsLocal,
            });
          } else {
            targetsLocal = [
              { questionIndex: questionIndex, targets: setTargetsLocal },
            ];
          }
        }

        localStorage.setItem("targets", JSON.stringify(targetsLocal));
        setAnswerStyle((answerStyle) => {
          return answerStyle.map((style, i) => {
            if (i === index) {
              return {
                $set: null,
              };
            } else if (i === item.index) {
              return {
                $set: null,
              };
            } else {
              return style;
            }
          });
        });
      } else {
        setTargets((targets) => {
          return targets.map((target, i) => {
            if (i === index) {
              return {
                ...target,
                lastDroppedItem: item.name,
              };
            } else {
              return target;
            }
          });
        });
        let targetsLocal = JSON.parse(localStorage.getItem("targets"));
        let targetsLocalCurrent =
          targetsLocal &&
          targetsLocal !== null &&
          targetsLocal?.filter((item) => item.questionIndex === questionIndex);
        let setTargetsLocal = targets.map((target, i) => {
          if (i === index) {
            return {
              ...target,
              lastDroppedItem: item.name,
            };
          } else {
            return target;
          }
        });
        if (targetsLocalCurrent && targetsLocalCurrent?.length > 0) {
          targetsLocal = targetsLocal.map((targetsLocalItem) =>
            targetsLocalItem.questionIndex === questionIndex
              ? { questionIndex: questionIndex, targets: setTargetsLocal }
              : targetsLocalItem
          );
        } else {
          if (
            targetsLocal &&
            targetsLocal !== null &&
            targetsLocal?.length > 0
          ) {
            targetsLocal.push({
              questionIndex: questionIndex,
              targets: setTargetsLocal,
            });
          } else {
            targetsLocal = [
              { questionIndex: questionIndex, targets: setTargetsLocal },
            ];
          }
        }

        localStorage.setItem("targets", JSON.stringify(targetsLocal));
        setAnswerStyle((answerStyle) => {
          return answerStyle.map((style, i) => {
            if (i === index) {
              return {
                $set: null,
              };
            } else {
              return style;
            }
          });
        });
      }
      saveAnswer(index, item);
    },
    [targets]
  );

  const showNextQuestion = () => {
    if (questionIndex !== questions.length - 1) {
      handleQuestionChange(questionIndex + 1);
    } else {
      return;
    }
  };

  const showPreviousQuestion = () => {
    handleQuestionChange(questionIndex - 1);
  };

  const handleReset = () => {
    const newTargets = targets.map((target, index) => {
      return {
        name: null,
        accepts: [ItemTypes.CHOICE],
        lastDroppedItem: null,
        id: index,
      };
    });

    var questionState = {
      index: questionIndex,
      state: {
        answers: newTargets.map((target) => target.lastDroppedItem),
      },
    };
    var answer = questionTargets.map((item) => false);

    if (newTargets.some((target) => target.lastDroppedItem != null)) {
      newTargets.map(
        (target, index) =>
        (answer[index] =
          questionChoices.indexOf(target.lastDroppedItem) + 1)
      );
    } else {
      answer = null;
    }
    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
    };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (
        let question = 0;
        question < answeredQuestions.length;
        question++
      ) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }

      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }
    var questionsAnswered = result;
    const submit = { submit: false, questions: [newAnswer] };
    var questionAnswered = {
      questionIndex: questionIndex,
      status: true,
    };

    if (answer === null) {
      questionAnswered.status = false;
    }

    const value = {
      questionState,
      questionAnswered,
      questionsAnswered,
      submit,
    };

    localStorage.setItem("questionStateExams", JSON.stringify(value));
    setTargets(newTargets);
  };

  return (
    <>
      <div
        className="has-background-kurio-main-color-light-gray is-relative"
        style={{ borderRadius: "0 0 1rem 1rem" }}
      >
        {!explanationState && (
          <>
            <div
              className="question-container is-flex is-flex-direction-column"
              style={{ padding: "1.5rem 2rem" }}
            >
              {questionTexts !== null &&
                questionTexts !== undefined &&
                questionTexts?.length > 0 && (
                  <div
                    className="has-text-centered m-auto"
                    style={{ maxWidth: "40rem" }}
                  >
                    {questionTexts}
                  </div>
                )}

              <div
                className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start has-background-kurio-gray-light-1"
                style={{
                  padding: "1.5rem 0rem",
                  borderRadius: "1rem 1rem 0 0",
                }}
              >
                {3 <= targets.length <= 4 &&
                  targets.map(({ name, accepts, lastDroppedItem }, index) => (
                    <Target
                      content={name}
                      accept={accepts}
                      lastDroppedItem={lastDroppedItem}
                      onDrop={(item) => handleDrop(index, item)}
                      resultStyle={answerStyle[index]}
                      iconStyle={iconStyle}
                      key={index}
                      choices={choices}
                      disable={checkingState}
                      dropOutside={dropOutside}
                      index={index}
                    />
                  ))}
              </div>
              <div
                className="has-background-kurio-gray-light-2 is-flex is-flex-direction-row is-justify-content-center is-align-items-start m-0"
                style={{
                  padding: "1rem 0",
                  borderRadius: "0 0 1rem 1rem",
                }}
              >
                {(choices.length === 2 ||
                  choices.length === 3 ||
                  choices.length === 4) && (
                    <div
                      className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start m-0"
                      style={{
                        padding: "1rem 0",
                        borderRadius: "0 0 1rem 1rem",
                      }}
                    >
                      {choices.map(({ name, type }, index) => (
                        <div
                          key={index}
                          style={{
                            padding: "1rem",
                          }}
                        >
                          <ChoiceContainer
                            name={name}
                            type={type}
                            key={index}
                            disable={checkingState}
                            isChoiceSelected={isChoiceSelected(name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                {choices.length === 5 && (
                  <>
                    <div
                      style={{
                        padding: "1rem 0",
                        borderRadius: "0 0 1rem 1rem",
                      }}
                    >
                      <div className="columns is-centered">
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[0].name}
                            type={choices[0].type}
                            key={0}
                            disable={checkingState}
                            isChoiceSelected={isChoiceSelected(choices[0].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[1].name}
                            type={choices[1].type}
                            disable={checkingState}
                            key={1}
                            isChoiceSelected={isChoiceSelected(choices[1].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[2].name}
                            type={choices[2].type}
                            disable={checkingState}
                            key={2}
                            isChoiceSelected={isChoiceSelected(choices[2].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                      </div>
                      <div className="columns is-centered">
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[3].name}
                            type={choices[3].type}
                            disable={checkingState}
                            key={3}
                            isChoiceSelected={isChoiceSelected(choices[3].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[4].name}
                            type={choices[4].type}
                            disable={checkingState}
                            key={4}
                            isChoiceSelected={isChoiceSelected(choices[4].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {choices.length === 6 && (
                  <>
                    <div
                      style={{
                        padding: "1rem 0rem",
                        borderRadius: "0 0 1rem 1rem",
                      }}
                    >
                      <div className="columns is-centered">
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[0].name}
                            type={choices[0].type}
                            disable={checkingState}
                            key={0}
                            isChoiceSelected={isChoiceSelected(choices[0].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[1].name}
                            type={choices[1].type}
                            disable={checkingState}
                            key={1}
                            isChoiceSelected={isChoiceSelected(choices[1].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[2].name}
                            type={choices[2].type}
                            disable={checkingState}
                            key={2}
                            isChoiceSelected={isChoiceSelected(choices[2].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                      </div>
                      <div className="columns is-centered">
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[3].name}
                            type={choices[3].type}
                            disable={checkingState}
                            key={3}
                            isChoiceSelected={isChoiceSelected(choices[3].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[4].name}
                            type={choices[4].type}
                            disable={checkingState}
                            key={4}
                            isChoiceSelected={isChoiceSelected(choices[4].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                        <div className="column is-narrow">
                          <ChoiceContainer
                            name={choices[5].name}
                            type={choices[5].type}
                            disable={checkingState}
                            key={5}
                            isChoiceSelected={isChoiceSelected(choices[5].name)}
                            dropOutside={dropOutside}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {(question.showSolution || isPreview) &&
              (localStorage.getItem("submitted") || localStorage.getItem("submittedProblemSet")) && (
                <>
                  <div
                    className="is-flex is-flex-direction-column is-justify-content-center"
                    style={{
                      gap: "2rem",
                    }}
                  >
                    <ImageFromUrl
                      style={{ padding: "0rem 2rem" }}
                      objectId="admin/frontend-images/exam/Vector 2.png"
                    />
                  </div>

                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 700,
                      textTransform: "uppercase",
                      padding: "1rem 0rem 0rem",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.ANSWER)}
                  </p>

                  <div
                    className="question-container is-flex is-flex-direction-column"
                    style={{ padding: "1.5rem 2rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-row is-justify-content-center is-align-items-start has-background-kurio-gray-light-1"
                      style={{
                        padding: "1.5rem 0rem",
                        borderRadius: "1rem 1rem 0 0",
                      }}
                    >
                      {3 <= targets.length <= 4 &&
                        targetsSolution.map(
                          ({ name, accepts, lastDroppedItem }, index) => (
                            <Target
                              content={name}
                              accept={accepts}
                              lastDroppedItem={lastDroppedItem}
                              onDrop={(item) => handleDrop(index, item)}
                              resultStyle={answerSolutionStyle[index]}
                              iconStyle={iconStyle}
                              key={index}
                              choices={choices}
                              disable={checkingState}
                              dropOutside={dropOutside}
                              index={index}
                            />
                          )
                        )}
                    </div>
                  </div>
                </>
              )
            }
          </>
        )}

        {(question.showSolution || isPreview) && (
          <>
            {(explanation?.images?.length > 0 ||
              explanation?.texts?.length > 0) && (
                <>
                  <div
                    className="is-flex is-flex-direction-column is-justify-content-center"
                    style={{
                      gap: "2rem",
                    }}
                  >
                    <ImageFromUrl
                      style={{ padding: "0rem 2rem" }}
                      objectId="admin/frontend-images/exam/Vector 2.png"
                    />
                  </div>
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 700,
                      textTransform: "uppercase",
                      padding: "1rem 0rem 0rem",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.EXPLAIN)}
                  </p>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{
                      gap: "1.5rem",
                      fontSize: "1.25rem",
                      padding: "1rem 3rem",
                      width: "100%",
                    }}
                  >
                    {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                    {(question.showSolution || isPreview) &&
                      explanationState === false && (
                        <>
                          {((explanationTexts !== null &&
                            explanationTexts !== undefined &&
                            explanationTexts?.length > 0) ||
                            (explanationImages !== null &&
                              explanationImages !== undefined &&
                              explanationImages?.length > 0)) &&
                            explanation.texts?.length >=
                            explanation.images?.length && (
                              <>
                                {explanation.texts.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      width: "100%",
                                      gap: "1.5rem",
                                    }}
                                    className="is-flex has-text-justified is-flex-direction-column is-align-items-center has-text-kurio-main-color-black"
                                  >
                                    {explanationTexts !== null &&
                                      explanationTexts !== undefined &&
                                      item?.length > 0 && (
                                        <div key={item}>
                                          {explanationTexts[index]}
                                        </div>
                                      )}
                                    {/* only appears when explanationImages has length greater than 0 */}
                                    {explanationImages !== null &&
                                      explanationImages !== undefined &&
                                      explanationImages[index]?.length > 0 && (
                                        <div
                                          className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          <ImageFromUrl
                                            objectId={explanationImages[index]}
                                          />
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </>
                            )}
                          {((explanationTexts !== null &&
                            explanationTexts !== undefined &&
                            explanationTexts?.length > 0) ||
                            (explanationImages !== null &&
                              explanationImages !== undefined &&
                              explanationImages?.length > 0)) &&
                            explanation.texts?.length <
                            explanation.images?.length && (
                              <>
                                {explanationImages.map((item, index) => (
                                  <div
                                    key={item}
                                    className="is-flex has-text-justified is-flex-direction-column has-text-kurio-main-color-black"
                                    style={{ gap: "1.5rem", width: "100%" }}
                                  >
                                    {explanationTexts !== null &&
                                      explanationTexts !== undefined &&
                                      explanation.texts[index]?.length > 0 && (
                                        <div key={index}>
                                          {explanationTexts[index]}
                                        </div>
                                      )}
                                    {/* only appears when explanationImages has length greater than 0 */}
                                    {explanationImages !== null &&
                                      explanationImages !== undefined &&
                                      item?.length > 0 && (
                                        <div
                                          className="column is-narrow is-flex is-justify-content-center is-align-items-center is-centered m-0 p-0"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          <ImageFromUrl
                                            objectId={explanationImages[index]}
                                          />
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </>
                            )}
                        </>
                      )}
                  </div>
                </>
              )}
          </>
        )}
        {!question.showSolution && (
          <div
            className="is-flex is-justify-content-space-around"
            style={{ padding: "0rem 0rem 1.5rem 0rem" }}
          >
            <PrimaryButtonQuestion
              className="button"
              onClick={handleReset}
              disabled={targets.every(
                (item) =>
                  item.lastDroppedItem === null ||
                  item.lastDroppedItem === undefined
              )}
              style={{ marginRight: "1.33rem" }}
            >
              {getConstantStringByLanguage(ConstantStrings.TRY_AGAIN)}
            </PrimaryButtonQuestion>
          </div>
        )}
        {question && (
          <div
            style={nextButtonStyle}
            className="is-flex is-flex-direction-row is-justify-content-space-between"
          >
            <PrimaryButtonQuestion
              onClick={showPreviousQuestion}
              // style={nextButtonStyle}
              disabled={questionIndex === 0}
            >
              {getConstantStringByLanguage(ConstantStrings.PREVIOUS_QUESTION)}
            </PrimaryButtonQuestion>

            {questionIndex !== questions.length - 1 &&
              (targets.some((item) => item.lastDroppedItem) ? (
                <PrimaryButtonQuestion
                  className=" is-flex is-is-align-items-flex-end"
                  onClick={showNextQuestion}
                >
                  {getConstantStringByLanguage(ConstantStrings.NEXT_QUESTION)}
                </PrimaryButtonQuestion>
              ) : (
                <PrimaryButtonQuestion
                  className=" is-flex is-is-align-items-flex-end"
                  onClick={showNextQuestion}
                >
                  {getConstantStringByLanguage(ConstantStrings.SKIP)}
                </PrimaryButtonQuestion>
              ))}
          </div>
        )}
      </div>
    </>
  );
});

export default DragDrop;
