import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import { clearLessons, clearStateGrade } from "../../../reducers/gradesSlice";
import { clearQuizState } from "../../../reducers/practiceQuestionSlice";
import jsonParser from "../../../templates/helper/jsonParser";
import "./../right/ListLessonRightWrapper.css";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../../constants/ConstantStrings";

const ListLessonLeftWrapper = ({
  listAnUnit,
  listGradeUnitProgress,
  gradeName,
}) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [displayDesc, setDisplayDesc] = useState(false);
  const ValueProgress = () => {
    var value = 0;
    var subArray = [];
    if (
      listAnUnit?.lessons &&
      listGradeUnitProgress &&
      listGradeUnitProgress.length > 0
    ) {
      for (var i = 0; i < listGradeUnitProgress.length; i++) {
        if (
          listGradeUnitProgress[i]._id === listAnUnit?._id &&
          listAnUnit?.lessons.length > 0 && listGradeUnitProgress[i].totalLessonsCompleted?.length > 0
        ) {
          subArray = listGradeUnitProgress[i].totalLessonsCompleted.filter(
            (item) =>
              listAnUnit?.lessons.map((lesson) => lesson._id).includes(item)
          );
          value = (subArray.length / listAnUnit?.lessons.length) * 100;
        }
      }
    }

    return value;
  };

  const navigate = useNavigate();

  var divStyleFinished = {
    padding: "0.5rem 0.375rem",
    gap: "0.6rem",
    width: "7.3125rem",
    height: "2.0625rem",
    borderRadius: "0.5rem",
    background: "#FED131",
  };

  var pStyleFinished = {
    fontWeight: 700,
    textAlign: "center",
    fontSize: "0.875rem",
    lineHeight: "1.066875rem",
    textTransform: "uppercase",
    color: "#572CD1",
  };

  var divStyleNew = {
    padding: "0.5rem 0.375rem",
    gap: "0.6rem",
    width: "4.3125rem",
    height: "2.0625rem",
    borderRadius: "0.5rem",
    background: "#0A2A66",
  };

  var pStyleNew = {
    fontWeight: 700,
    textAlign: "center",
    fontSize: "0.875rem",
    lineHeight: "1.066875rem",
    textTransform: "uppercase",
    color: "#FAFBFC",
  };

  var divStyleOnGoing = {
    padding: "0.5rem 0.375rem",
    gap: "0.6rem",
    width: "9.3125rem",
    height: "2.0625rem",
    borderRadius: "0.5rem",
    background: "#572CD1",
  };

  var pStyleOnGoing = {
    fontWeight: 700,
    textAlign: "center",
    fontSize: "0.875rem",
    lineHeight: "1.066875rem",
    textTransform: "uppercase",
    color: "#FAFBFC",
  };

  const onHandleClick = () => {
    localStorage.removeItem('daDatDiemToiDa')
    localStorage.removeItem('doneAllQuizFinshed')
    localStorage.removeItem('doneFirstTime')
    
    cookies.remove("unitId");
    dispatch(clearLessons());
    dispatch(clearQuizState())
    dispatch(clearStateGrade())
    navigate("/list-unit");
  };
  var descStyle = {
    fontSize: "1rem",
    width: "14rem",
    // height: "4.75rem",
    lineHeight: "normal",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  if (displayDesc) {
    descStyle = {
      ...descStyle,
      width: "20rem",
      WebkitLineClamp: "unset",
    };
  }
  const handleDisplay = () => {
    setDisplayDesc(true);
  };
  useEffect(() => {
   
    if (listAnUnit && listAnUnit !== null) {
      if (listAnUnit.description.length < 85) {
        setDisplayDesc(true);
      }
    }
  }, [listAnUnit]);
  return (
    <div
      className="is-flex is-flex-direction-column is-justify-content-space-between"
      style={{
        position: "fixed",
        width: "21.5rem",
        height: "32.5rem",
      }}
    >
      <div
        className="is-flex is-flex-direction-column"
        style={{ gap: "1.5rem" }}
      >
        <div
          className="has-text-uppercase has-text-weight-bold is-clickable is-flex is-align-items-center is-flex-gap-3 has-background-kurio-main-color-light-gray is-align-items-center"
          style={{
            fontSize: "0.875rem",
            lineHeight: "1rem",
            color: "#0A2A66",
            // width: "12.75rem",
            height: "2.5rem",
            borderRadius: "0rem 1rem 1rem 0rem",
            border: "0.09375rem solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            padding: "0.5rem 0.75rem",
            position: "absolute",
            top: "-3.85rem",
            left: "-2.59375rem",
          }}
          onClick={onHandleClick}
        >
          <p style={{ fontSize: "1.4rem", lineHeight: "1rem" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="14"
              viewBox="0 0 7 14"
              fill="none"
            >
              <path
                d="M5.82866 13.9997C5.53666 13.9997 5.24666 13.8727 5.04866 13.6267L0.22066 7.62674C-0.07734 7.25574 -0.07334 6.72574 0.23166 6.35974L5.23166 0.35974C5.58466 -0.0642604 6.21566 -0.12126 6.64066 0.23174C7.06466 0.58474 7.12166 1.21574 6.76766 1.63974L2.29266 7.01074L6.60766 12.3727C6.95366 12.8027 6.88566 13.4327 6.45466 13.7787C6.27066 13.9277 6.04866 13.9997 5.82866 13.9997Z"
                fill="#0A2A66"
              />
            </svg>
          </p>
          <p>{getConstantStringByLanguage(ConstantStrings.BACK_TO_LIST_UNIT)}</p>
        </div>

        <ImageFromUrl
          style={{
            width: "100%",
            borderRadius: "1rem",
            height: "14.25rem",
            paddingTop: "1rem",
          }}
          objectId={listAnUnit?.image}
          alt="Ảnh bài giảng"
        />
        <div
          className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start"
          style={{ width: "20rem" }}
        >
          <p
            className="is-uppercase has-text-weight-bold has-text-kurio-main-color-black has-background-kurio-main-color-light-gray"
            style={{
              fontSize: "0.875rem",
              padding: "0.5rem 0.75rem",
              borderRadius: "1rem",
            }}
          >
             {getConstantStringByLanguage(ConstantStrings.UNIT) +" "+  listAnUnit?.order}
          </p>
          <p
            className="has-text-kurio-main-color-black is-size-4 has-text-weight-bold"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "normal"
            }}
          >
            {listAnUnit?.unitName &&
              jsonParser(listAnUnit?.unitName, 0, "unit-name")}
          </p>
          <div
            className="is-flex is-align-items-flex-end"
            style={{ gap: "0.625rem" }}
          >
            <p className="has-text-kurio-gray-dark" style={descStyle}>
              {jsonParser(listAnUnit?.description, 0, "description")}
            </p>
            <p
              className={`${displayDesc
                ? "is-hidden"
                : " is-underlined has-text-kurio-main-color-black is-clickable"
                }`}
              style={{ fontSize: "1rem" }}
              onClick={handleDisplay}
            >
              {getConstantStringByLanguage(ConstantStrings.VIEW_MORE)}
            </p>
          </div>
        </div>
      </div>
      <div
        className="is-flex is-flex-direction-column"
        style={{ gap: "0.875rem" }}
      >
        <div
          style={
            ValueProgress() === 100
              ? { ...divStyleFinished, ...pStyleFinished }
              : ValueProgress() === 0 || listGradeUnitProgress.length < 0
                ? { ...divStyleNew, ...pStyleNew }
                : 1 <= ValueProgress() && ValueProgress() < 100
                  ? { ...divStyleOnGoing, ...pStyleOnGoing }
                  : {}
          }
        >
          {ValueProgress() === 100
            ? getConstantStringByLanguage(ConstantStrings.COMPLETED)
            : ValueProgress() === 0 || listGradeUnitProgress.length < 0
              ? getConstantStringByLanguage(ConstantStrings.NEW)
              : 1 <= ValueProgress() && ValueProgress() < 100
                ? getConstantStringByLanguage(ConstantStrings.ON_GOING)
                : ""}
        </div>

        <progress
          style={{ height: "0.75rem" }}
          className="progress is-kurio-main-color-purple"
          value={ValueProgress() ? ValueProgress() : 0}
          max="100"
        ></progress>
      </div>
    </div>
  );
};

export default ListLessonLeftWrapper;
