import React from "react";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../constants/ConstantStrings";

const SubPackage = ({ isMax, handleSelect, isSelected, handleShowInfo }) => {
  var logoUrl = "";
  var content = "";
  if (isMax) {
    content = getConstantStringByLanguage(ConstantStrings.EXPLORE_ABILITY);
    logoUrl = "images/upgrades/logo-kurio-max-white.svg";
  } else {
    logoUrl = "images/upgrades/logo-kurio-test-prep.svg";
    content = "Trải nghiệm Luyện Thi IKMC và Luyện thi Học kỳ không giới hạn!";
  }
  return (
    <div className="is-flex is-justify-content-center is-align-items-center">
      <div
        className="is-flex is-flex-direction-column is-flex-gap-5 is-justify-content-flex-start is-align-items-flex-start is-clickable"
        // onClick={() => handleSelect(isMax ? 0 : 1)}
        style={
          {
            // width: "26.5rem",
            // borderRadius: "2rem",
            // border: "0.09375rem solid #0A2A66",
            // background: isMax
            //   ? "radial-gradient(353.88% 61.27% at 62.03% 15.28%, #572CD1 0%, #48A9F8 100%)"
            //   : "#FAFBFC",
            // padding: "2rem 3rem",
          }
        }
      >
        <img src={logoUrl} alt="logo" />
        <p
          className=""
          style={{
            fontSize: "1rem",
            fontStyle: "normal",
            lineHeight: "140%",
            color: isMax ? "#FAFBFC" : "#572CD1",
          }}
        >
          {getConstantStringByLanguage(ConstantStrings.EXPLORE_ABILITY)}
        </p>
        <div
          className="is-flex is-align-items-flex-start is-flex-gap-5 has-text-weight-bold has-text-kurio-main-color-white"
          style={{
            fontSize: "1rem",
            lineHeight: "normal",
            fontStyle: "normal",
          }}
        >
          <div className="is-flex is-flex-direction-column is-flex-gap-1 is-align-items-flex-start is-justify-content-center">
            <div
              className="is-flex is-align-items-center is-align-self-stretch"
              style={{ gap: "0.3125rem" }}
            >
              <img
                src="/images/upgrades/ic-v.svg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              <p>{getConstantStringByLanguage(ConstantStrings.CREATIVE_LESSON)}</p>
            </div>
            <div
              className="is-flex is-align-items-center is-align-self-stretch"
              style={{ gap: "0.3125rem" }}
            >
              <img
                src="/images/upgrades/ic-v.svg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              <p>{getConstantStringByLanguage(ConstantStrings.LOGIC_FAIRY_TALE)}</p>
            </div>
            <div
              className="is-flex is-align-items-center is-align-self-stretch"
              style={{ gap: "0.3125rem" }}
            >
              <img
                src="/images/upgrades/ic-v.svg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              <p>{getConstantStringByLanguage(ConstantStrings.COMPREHENSIVE_EXAM_PREP)}</p>
            </div>
          </div>
          <div className="is-flex is-flex-direction-column is-flex-gap-1 is-align-items-flex-start is-justify-content-center">
            <div
              className="is-flex is-align-items-center is-align-self-stretch"
              style={{ gap: "0.3125rem" }}
            >
              <img
                src="/images/upgrades/ic-v.svg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              <p>{getConstantStringByLanguage(ConstantStrings.SMART_PRACTICE)}</p>
            </div>
            <div
              className="is-flex is-align-items-center is-align-self-stretch"
              style={{ gap: "0.3125rem" }}
            >
              <img
                src="/images/upgrades/ic-v.svg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              <p>{getConstantStringByLanguage(ConstantStrings.EXCITING_QUEST)}</p>
            </div>
            <div
              className="is-flex is-align-items-center is-align-self-stretch"
              style={{ gap: "0.3125rem" }}
            >
              <img
                src="/images/upgrades/ic-v.svg"
                style={{ objectFit: "cover" }}
                alt=""
              />
              <p>{getConstantStringByLanguage(ConstantStrings.AI_MATH_SOLVER)}</p>
            </div>
          </div>
        </div>
        <div
          className="is-flex is-align-items-center is-justify-content-center is-clickable"
          style={{ width: "26rem", height: "2rem", borderRadius: "0.5rem", background:"rgba(250, 251, 252, 0.20)" }}
          onClick={handleShowInfo}
        >
          <p
            className="has-text-weight-bold has-text-centered has-text-kurio-main-color-white is-uppercase"
            style={{
              fontSize: "0.75rem",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.DETAIL_PACKAGE_KURIO_MAX)}
          </p>
        </div>
      </div>
      {isSelected && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="88"
          height="16"
          viewBox="0 0 88 16"
          fill="none"
        >
          <path
            d="M0 8H88"
            stroke="#0A2A66"
            strokeWidth="16"
            strokeDasharray="8 8"
          />
        </svg>
      )}
    </div>
  );
};

export default SubPackage;
