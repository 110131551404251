import { debounce } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { registration } from "../../../config/auth";
import ButtonRegister from "./ButtonRegister";
import GoalItem from "./GoalItem";
import InputRegister from "./InputRegister";
import PackageItem from "./PackageItem";

const LandingPageForm = ({ isMobile = false, isTablet = false }) => {
  const initDataSendEmail = {
    name: "",
    phone: "",
    age: "",
    email: "",
    goals: [],
    package: 2,
    knowFrom: 0,
  };
  const goals = [
    {
      order: 1,
      content: "Bổ trợ kiến thức, nâng cao thành tích môn Toán trên lớp",
    },
    {
      order: 2,
      content: "Ôn luyện các Cuộc thi Toán quốc tế",
    },
    {
      order: 3,
      content: "Rèn luyện và phát triển tư duy toàn diện",
    },
  ];
  const packages = [
    {
      order: 1,
      content: "6 tháng",
      sale: 20,
      isSuggest: false,
      price: "700K",
      oldPrice: "900K",
      package: 1,
    },
    {
      order: 2,
      content: "1 năm",
      sale: 40,
      isSuggest: true,
      price: "1100K",
      oldPrice: "1800K",
      package: 2,
    },
  ];
  const howUserKnowUs = [
    { order: 1, content: "Xem quảng cáo" },
    { order: 2, content: "Thí sinh IKMC" },
    { order: 3, content: "Được giới thiệu" },
    { order: 4, content: "Khác" },
  ];
  const [dataSendEmail, setDataSendEmail] = useState(initDataSendEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSendEmail = async (event) => {
    event.preventDefault();
    if (handleCheckForm()) return;
    setIsLoading(true);
    try {
      await registration({ ...dataSendEmail, age: Number(dataSendEmail.age) });
      // setIsLoading(false);
      setDataSendEmail(initDataSendEmail);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      if (error?.response.data?.errorMessage?.toLowerCase().includes("email")) {
        setError("Sai định dạng email");
      } else {
        setError(error?.response.data?.errorMessage);
      }
    }
  };
  const handleChangeInput = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.name === "age" || e.target.name === "phone") {
      if (!regex.test(e.target.value) && e.target.value !== "") {
        return;
      }
      if (e.target.name === "phone" && e.target.value.length > 10) {
        return;
      }
      if (e.target.name === "age" && Number(e.target.value) > 25) {
        return;
      }
    }
    setDataSendEmail({
      ...dataSendEmail,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckForm = () => {
    if (
      dataSendEmail.name === "" ||
      dataSendEmail.phone === "" ||
      dataSendEmail.age === "" ||
      dataSendEmail.email === "" ||
      dataSendEmail.goals.length === 0 ||
      dataSendEmail.package === 0||
      dataSendEmail.knowFrom === 0
    ) {
      setError("Vui lòng không để trống");
      return true;
    } else {
      const regexText = /^[a-zA-Z\/\sÀ-ỹ ]+$/;
      const regexPhone = /^0[0-9]*$/;
      const regexEmail = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regexPhone.test(dataSendEmail.phone)) {
        setError("Sai định dạng số điện thoại");
        return true;
      } else if (
        dataSendEmail.phone.length < 10 ||
        dataSendEmail.phone.length > 10
      ) {
        setError("Số điện thoại gồm 10 chữ số");
        return true;
      } else if (!regexText.test(dataSendEmail.name)) {
        setError("Tên không hợp lệ");
        return true;
      }
      if (!regexEmail.test(dataSendEmail.email)) {
        setError("Sai định dạng email");
        return true;
      }
      if (Number(dataSendEmail.age) === 0) {
        setError("Tuổi phải lớn hơn 0");
        return true;
      }
    }
    return false;
  };

  const handleSelectGoal = (order) => {
    if (dataSendEmail.goals.includes(order)) {
      setDataSendEmail({
        ...dataSendEmail,
        goals: dataSendEmail.goals.filter((item) => item !== order),
      });
    } else {
      setDataSendEmail({
        ...dataSendEmail,
        goals: [...dataSendEmail.goals, order],
      });
    }
  };
  const handleSelectPackage = (typePackage) => {
    if (dataSendEmail.package === typePackage) {
      setDataSendEmail({
        ...dataSendEmail,
        package: 0,
      });
    } else {
      setDataSendEmail({
        ...dataSendEmail,
        package: typePackage,
      });
    }
  };

  const handleSelectIntroSource = (order) => {
    if (dataSendEmail.knowFrom !== order) {
      setDataSendEmail({
        ...dataSendEmail,
        knowFrom: order,
      });
    } else {
      setDataSendEmail({
        ...dataSendEmail,
        knowFrom: 0,
      });
    }
  };

  useEffect(() => {
    const handleMousedown = (event) => {
      if (event.target.closest(".dropdown-1")) {
        if (event.target.closest(".goal-item") && isActive1) {
          setIsActive1(true);
        } else {
          setIsActive1(!isActive1);
        }
        setIsActive2(false);
        setIsActive3(false);
      } else if (event.target.closest(".dropdown-2")) {
        // if (event.target.closest(".package-item") && isActive2) {
        //   setIsActive2(true);
        // } else {
        // }
        setIsActive2(!isActive2);
        setIsActive1(false);
        setIsActive3(false);
      } else if (event.target.closest(".dropdown-3")) {
        setIsActive3(!isActive3);
        setIsActive1(false);
        setIsActive2(false);
      } else {
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
      }
    };

    const debouncedHandleMousedown = debounce(handleMousedown, 50);

    document.addEventListener("mousedown", debouncedHandleMousedown);

    return () => {
      document.removeEventListener("mousedown", debouncedHandleMousedown);
    };
  }, [isActive1, isActive2, isActive3]);

  return (
    <div
      className={`${
        isMobile
          ? "is-flex is-flex-direction-column is-align-items-center is-flex-gap-5"
          : ""
      }`}
    >
      <form
        id="form-landingpage"
        className={`${
          isMobile ? "is-align-items-center" : " is-align-items-flex-start mb-4"
        } gform is-flex is-justify-content-flex-start is-relative`}
        onSubmit={handleSendEmail}
        style={{
          padding: isMobile
            ? "2.06rem 1.77rem 1.25rem 1.36rem"
            : "2.1875rem 1.75rem 0rem 1.75rem",
          width: isMobile ? " 20.787rem" : "37.5rem",
          borderRadius: "2rem",
          backgroundImage: "url('/images/landing-page/background-form.png')",
          boxShadow: "0rem 0.25rem 0rem 0rem #572CD1",
          backgroundSize: "cover",
          height: isMobile ? "26.58463rem" : "40.43663rem",
          gap: isMobile ? "0.75rem" : "1rem",
        }}
        action={process.env.REACT_APP_SEND_EMAIL_URL}
      >
        <InputRegister
          value={dataSendEmail.name}
          name={"name"}
          label="Tên*"
          onChange={handleChangeInput}
          isMobile={isMobile}
          heightInput={isMobile ? "3rem" : "3.5rem"}
        />
        <div
          className={`${
            isMobile
              ? "is-flex is-flex-direction-column is-flex-gap-3 is-align-items-flex-start"
              : "is-flex is-align-items-flex-start is-flex-grow-0 is-flex-gap-4 is-justify-content-space-between"
          }`}
          style={{ width: "100%" }}
        >
          <InputRegister
            value={dataSendEmail.phone}
            widthInput={isMobile ? "100%" : "11rem"}
            name={"phone"}
            maxLength={10}
            label="SDT*"
            onChange={handleChangeInput}
            isMobile={isMobile}
            heightInput={isMobile ? "3rem" : "3.5rem"}
          />
          <InputRegister
            value={dataSendEmail.age}
            widthInput={isMobile ? "100%" : "11rem"}
            maxLength={2}
            name={"age"}
            label="Tuổi con*"
            onChange={handleChangeInput}
            isMobile={isMobile}
            heightInput={isMobile ? "3rem" : "3.5rem"}
            width={isMobile ? "" : "calc((100% - 3.5rem)/2)"}
          />
        </div>
        <InputRegister
          value={dataSendEmail.email}
          name={"email"}
          label="Email"
          onChange={handleChangeInput}
          isMobile={isMobile}
          heightInput={isMobile ? "3rem" : "3.5rem"}
        />
        <div className="is-flex is-flex-direction-column is-flex-gap-3">
          <div
            className={` ${
              isMobile
                ? "is-align-items-center is-justify-content-center"
                : "is-flex-direction-column"
            } is-flex is-flex-gap-3 has-text-kurio-main-color-black has-text-weight-bold`}
          >
            <p
              className="select-title"
              style={{
                fontSize: isMobile ? "0.75rem" : "1rem",
                lineHeight: "normal",
                fontStyle: "normal",
                width:isMobile?"6rem":""
              }}
            >
              Ba mẹ biết Kurio qua đâu?
            </p>
            {isMobile && (
              <div
                className={`${
                  isActive3 ? "is-active" : ""
                } dropdown is-up dropdown-3`}
              >
                <div className="dropdown-trigger">
                  <div
                    className="is-flex px-4 py-3 is-justify-content-space-between is-align-items-center"
                    style={{
                      borderRadius: "1rem",
                      border: "2px solid",
                      borderColor: isActive3 ? "#572CD1" : "#FFFFFF",
                      background: isActive3
                        ? "#572CD1"
                        : dataSendEmail.knowFrom > 0
                        ? "#8864F0"
                        : "#FFFFFF",
                      width: "10.90833rem",
                    }}
                  >
                    <p
                      className={`${
                        isActive3 || dataSendEmail.knowFrom > 0
                          ? "has-text-kurio-main-color-white"
                          : "has-text-kurio-main-color-black"
                      }  is-size-7 has-text-weight-bold`}
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {dataSendEmail.knowFrom > 0
                        ? howUserKnowUs[dataSendEmail.knowFrom - 1].content
                        : "Chọn"}
                    </p>
                    <img
                      className={`${isActive3 ? "qa-show-answer" : ""}`}
                      src="/images/landing-page/ic-dropup.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="dropdown-menu dropdown-menu-source"
                  id="dropdown-menu7"
                  role="menu"
                  style={{
                    left: "calc((100% - 20.787rem) / 2)",
                  }}
                >
                  <div
                    className="dropdown-content is-flex is-flex-direction-column is-flex-gap-2 p-4"
                    style={{
                      background: "#F1EEFC",
                      borderRadius: "1rem",
                      boxShadow: "0px 4px 8px 0px rgba(10, 42, 102, 0.25)",
                      width: "15.75rem",
                      height: "auto",
                    }}
                  >
                    {howUserKnowUs.map((item) => (
                      <div className="" key={item.order}>
                        <GoalItem
                          item={item}
                          isMultiple={false}
                          handleSelect={handleSelectIntroSource}
                          isSelected={dataSendEmail.knowFrom === item.order}
                          isMobile={isMobile}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {!isMobile && (
              <div className="is-flex is-flex-gap-2 is-align-items-flex-start">
                {howUserKnowUs.map((item) => (
                  <Fragment key={item.order}>
                    <GoalItem
                      item={item}
                      isMultiple={false}
                      handleSelect={handleSelectIntroSource}
                      isSelected={dataSendEmail.knowFrom === item.order}
                    />
                  </Fragment>
                ))}
              </div>
            )}
          </div>
          <div
            className={`${
              isMobile
                ? "is-justify-content-center"
                : "is-align-items-flex-start is-align-self-stretch is-flex-direction-column"
            } is-flex is-flex-gap-3 has-text-kurio-main-color-black has-text-weight-bold`}
          >
            <div
              className={`is-flex is-flex-direction-column is-flex-gap-3 is-relative`}
            >
              <p
                className="select-title"
                style={{
                  fontSize: isMobile ? "0.75rem" : "1rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                Mục tiêu học tập
              </p>
              {isMobile && (
                <div
                  className={`${
                    isActive1 ? "is-active" : ""
                  } dropdown is-up dropdown-1`}
                >
                  <div className="dropdown-trigger">
                    <div
                      className="is-flex px-4 py-3 is-justify-content-space-between is-align-items-center"
                      style={{
                        borderRadius: "1rem",
                        border: "2px solid",
                        borderColor: isActive1 ? "#572CD1" : "#FFFFFF",
                        background: isActive1
                          ? "#572CD1"
                          : dataSendEmail.goals.length > 0
                          ? "#8864F0"
                          : "#FFFFFF",
                        width: "8.33rem",
                      }}
                    >
                      <p
                        className={`${
                          isActive1 || dataSendEmail.goals.length > 0
                            ? "has-text-kurio-main-color-white"
                            : "has-text-kurio-main-color-black"
                        }  is-size-7 has-text-weight-bold`}
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {dataSendEmail.goals.length > 0
                          ? "Đã Chọn " + dataSendEmail.goals.length
                          : "Chọn"}
                      </p>
                      <img
                        className={`${isActive1 ? "qa-show-answer" : ""}`}
                        src="/images/landing-page/ic-dropup.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="dropdown-menu7"
                    role="menu"
                  >
                    <div
                      className="dropdown-content is-flex is-flex-direction-column is-flex-gap-2 p-4"
                      style={{
                        background: "#F1EEFC",
                        borderRadius: "1rem",
                        boxShadow: "0px 4px 8px 0px rgba(10, 42, 102, 0.25)",
                        width: "15.75rem",
                        height: "auto",
                      }}
                    >
                      {goals.map((item) => (
                        <div className="" key={item.order}>
                          <GoalItem
                            item={item}
                            handleSelect={handleSelectGoal}
                            isSelected={dataSendEmail.goals.includes(
                              item.order
                            )}
                            isMobile={isMobile}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {!isMobile && (
                <div className="is-flex is-flex-gap-2 is-align-items-flex-start">
                  {goals.map((item) => (
                    <Fragment key={item.order}>
                      <GoalItem
                        item={item}
                        handleSelect={handleSelectGoal}
                        isSelected={dataSendEmail.goals.includes(item.order)}
                      />
                    </Fragment>
                  ))}
                </div>
              )}
            </div>
            <div className="is-flex is-flex-direction-column is-flex-gap-3">
              <p
                className="select-title"
                style={{
                  fontSize: isMobile ? "0.75rem" : "1rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                Gói học quan tâm
              </p>
              {!isMobile && (
                <div className="is-flex is-flex-gap-2 is-align-items-flex-start">
                  {packages.map((item) => (
                    <Fragment key={item.order}>
                      <PackageItem
                        item={item}
                        handleSelect={handleSelectPackage}
                        isSelected={dataSendEmail.package === item.package}
                      />
                    </Fragment>
                  ))}
                </div>
              )}
              {isMobile && (
                <div
                  className={`${
                    isActive2 ? "is-active" : ""
                  } dropdown is-up is-right dropdown-2`}
                >
                  <div className="dropdown-trigger">
                    <div
                      className="is-flex px-4 py-3 is-justify-content-space-between is-align-items-center"
                      style={{
                        borderRadius: "1rem",
                        border: "2px solid",
                        borderColor: isActive2 ? "#572CD1" : "#FFFFFF",
                        background: isActive2
                          ? "#572CD1"
                          : dataSendEmail.package !== 0
                          ? "#8864F0"
                          : "#FFFFFF",
                        width: "8.33rem",
                      }}
                    >
                      <p
                        className={`${
                          isActive2 || dataSendEmail.package !== 0
                            ? "has-text-kurio-main-color-white"
                            : "has-text-kurio-main-color-black"
                        }  is-size-7 has-text-weight-medium has-text-weight-bold is-uppercase`}
                      >
                        {dataSendEmail.package !== 0
                          ? dataSendEmail.package === 1
                            ? "6 Tháng"
                            : "1 Năm"
                          : "Chọn"}
                      </p>
                      <img
                        className={`${isActive2 ? "qa-show-answer" : ""}`}
                        src="/images/landing-page/ic-dropup.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="dropdown-menu7"
                    role="menu"
                  >
                    <div
                      className="dropdown-content is-flex is-flex-direction-column is-flex-gap-2 p-4"
                      style={{
                        background: "#F1EEFC",
                        borderRadius: "1rem",
                        boxShadow: "0px 4px 8px 0px rgba(10, 42, 102, 0.25)",
                        width: "15.75rem",
                        height: "auto",
                      }}
                    >
                      {packages.map((item) => (
                        <Fragment key={item.order}>
                          <PackageItem
                            item={item}
                            isMobile={isMobile}
                            handleSelect={handleSelectPackage}
                            isSelected={dataSendEmail.package === item.package}
                          />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isMobile && (
          <div
            className="btn-register-desktop"
            style={{ position: "absolute", bottom: "-2rem", right: "2.55rem" }}
          >
            <ButtonRegister
              onClick={handleSendEmail}
              // disable={handleCheckForm()}
              height={"5.5rem"}
              width={"32.5rem"}
              fontSize={"2rem"}
            />
          </div>
        )}
      </form>
      {isMobile && (
        <div className="btn-banner">
          <ButtonRegister
            onClick={handleSendEmail}
            height={"4rem"}
            width={"20rem"}
            fontSize={"1.5rem"}
          />
        </div>
      )}
      {(isLoading || error.length > 0) && (
        <div className="modal is-active">
          <div
            className="modal-background"
            onClick={() => {
              setIsSuccess(false);
              setIsLoading(false);
            }}
            style={{ opacity: 0.8, zIndex: 1 }}
          ></div>
          {!isSuccess && error.length === 0 && (
            <div className="loading-form"></div>
          )}
          {isSuccess && (
            <div
              className="has-background-kurio-main-color-white is-relative mx-4 is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center has-text-kurio-main-color-black has-text-weight-bold"
              style={{
                width: isMobile ? "18rem" : "30rem",
                height: isMobile ? "12rem" : "24rem",
                borderRadius: "1rem",
                zIndex: 2,
              }}
            >
              <p className={`${isMobile ? "is-size-4" : "is-size-3"}`}>
                Đăng ký thành công!
              </p>
              <ButtonRegister
                hideIcon={true}
                text="Xác nhận"
                width="15rem"
                height="4rem"
                onClick={() => {
                  setIsSuccess(false);
                  setIsLoading(false);
                }}
              />
            </div>
          )}
          {error.length > 0 && (
            <div
              className="has-background-kurio-main-color-white is-relative mx-4 is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center has-text-kurio-main-color-black has-text-weight-bold"
              style={{
                width: isMobile ? "18rem" : "30rem",
                height: isMobile ? "12rem" : "24rem",
                borderRadius: "1rem",
                padding: isMobile ? "0.75rem" : "2rem",
                zIndex: 2,
              }}
            >
              <p
                className={`is-size-5 has-text-kurio-red-main-accent has-text-weight-bold`}
              >
                {error}
              </p>
              <ButtonRegister
                hideIcon={true}
                text="Xác nhận"
                width="15rem"
                height="4rem"
                onClick={() => {
                  setIsSuccess(false);
                  setIsLoading(false);
                  setError("");
                  // setDataSendEmail(initDataSendEmail);
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LandingPageForm;
