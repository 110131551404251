/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import InputText from "../../../components/input/InputText";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import { getUserInfo, signin } from "../../../config/auth";
import ConstantStrings from "../../../constants/ConstantStrings";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";

function Signin() {
  var backgroundImage = {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };
  const [account, setAccount] = useState({
    userName: "",
    password: "",
  });
  const [error, setError] = useState({
    userName: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleChange = (value, index, name) => {
    setAccount({ ...account, [name]: value });
    setError({
      userName: "",
      password: "",
    });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    // const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
    if (account.password === "" || account.userName === "") {
      account.password === ""
        ? setError({
            ...error,
            password: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
          })
        : setError({
            ...error,
            userName: getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK),
          });
    } else {
      try {
        const login = await signin(account);
        var expires = 7 * 24 * 3600;
        if (cookies.get("signinUser")) {
          cookies.remove("signinUser");
        }
        cookies.set("signinUser", login.access_token, {
          maxAge: expires,
          path: "/",
          // httpOnly: true,
        });

        const decode = decodeToken(login.access_token);
        setError({
          userName: "",
          password: "",
        });
        cookies.remove("lessonId");
        cookies.remove("unitId");
        setIsLoading(true);
        const { result } = await getUserInfo();
        setTimeout(() => {
          if (decode.phone === "" || decode.phone.length === 0) {
            navigate("/validate-phone");
          } else if (
            result.fullName === "" ||
            result.birthday === "" ||
            result.gradeId === "" ||
            result.gradeId === null ||
            result.gradeName === "" ||
            result.gradeName === null
          ) {
            navigate("/calibration");
          } else {
            if (!decode?.firstLogin) {
              navigate("/");
            } else {
              navigate("/upgrade");
            }
          }
        }, 1000);
      } catch (error) {
        setError({
          ...error,
          userName: error.response.data?.errorMessage,
          password: error.response.data?.errorMessage,
        });
      }
    }
  };

  useEffect(() => {
    localStorage.clear();
    if (cookies.get("signinUser")) {
      if (!decodeToken(cookies.get("signinUser"))?.firstLogin) {
        navigate("/");
      } else {
        navigate("/upgrade");
      }
    }
  }, [navigate]);
  return (
    <BackgroundImageFromUrl
      objectId="admin/frontend-images/auth/bg-desktop-signin.svg"
      className=""
      style={backgroundImage}
    >
      <div
        className="columns m-0 p-0 is-justify-content-center is-align-content-flex-start"
        style={{ minHeight: "100vh", width: "100%" }}
      >
        {isLoading && (
          <>
            <div className="column m-0 p-0 is-relative">
              <div
                style={{
                  width: "15rem",
                  margin: "24.4375rem 0 0 5rem",
                }}
              >
                <p
                  style={{ fontSize: "1.125rem" }}
                  className="mb-4 has-text-kurio-main-color-white is-uppercase"
                >
                  Đang tải
                </p>
                <span
                  className="loader__quiz"
                  style={{ width: "15rem" }}
                ></span>
              </div>
              <div
                style={{
                  width: "21.5rem",
                  height: "8.3125rem",
                  backgroundImage: "url('/images/auth/logo-signin.svg')",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                }}
              ></div>
            </div>
            <BackgroundImageFromUrl
              objectId="admin/frontend-images/auth/image-left-signin.svg"
              style={{
                width: "42.9375rem",
                height: "47rem",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                marginRight: "8.375rem",
              }}
            ></BackgroundImageFromUrl>
            <div
              style={{
                height: "7.5rem",
                width: "7.443125rem",
                position: "absolute",
                bottom: "5.625rem",
                right: "6rem",
                // transform: "rotate(2deg)",
                backgroundImage:
                  "url('/images/auth/floating-element-signin.svg')",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </>
        )}
        {!isLoading && (
          <>
            <div className="column m-0 p-0 is-relative">
              <BackgroundImageFromUrl
                objectId="admin/frontend-images/auth/image-left-signin.svg"
                style={{
                  width: "42.9375rem",
                  height: "47rem",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  marginLeft: "4.625rem",
                }}
              ></BackgroundImageFromUrl>
              <div
                style={{
                  width: "21.5rem",
                  height: "8.3125rem",
                  backgroundImage: "url('/images/auth/logo-signin.svg')",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                }}
              ></div>
            </div>
            <div
              className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between has-background-white  has-text-kurio-main-color-black"
              style={{
                padding: "3.1875rem 4rem 2.5rem 3.5rem",
                width: "27.5rem",
                height: "40rem",
                marginRight: "5.25rem",
                marginTop: "3.4375rem",
                maxWidth: "27.5rem",
                position: "relative",
                boxShadow: "0.75rem 0.6875rem #290E76",
                border: "0.25rem solid #C4B5F2",
                borderRadius: "7.5rem",
              }}
            >
              <div
                style={{
                  height: "7.5rem",
                  width: "7.443125rem",
                  position: "absolute",
                  bottom: "3rem",
                  left: "-5.25rem",
                  // transform: "rotate(2deg)",
                  backgroundImage:
                    "url('/images/auth/floating-element-signin.svg')",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <form onSubmit={handleSubmit} noValidate>
                <input hidden type="submit" />
                <div
                  className="is-flex is-flex-direction-column is-align-items-center p-0"
                  style={{ gap: "2rem" }}
                >
                  <h2
                    style={{ fontSize: "2.25rem", lineHeight: "2.75rem" }}
                    className=" has-text-weight-bold is-uppercase"
                  >
                    Đăng nhập
                  </h2>
                  {error.error && (
                    <p className="has-text-danger has-text-centered subtitle is-size-6">
                      {error.error}
                    </p>
                  )}
                  <div style={{ width: "20rem", height: "5rem" }}>
                    <InputText
                      label={"Số điện thoại / Email"}
                      type="text"
                      value={account.userName}
                      name={"userName"}
                      onChange={(e) =>
                        handleChange(e.target.value, 0, e.target.name)
                      }
                    />
                    {error.email && (
                      <p className="has-text-danger subtitle is-size-6">
                        {error.email}
                      </p>
                    )}
                  </div>
                  <div style={{ width: "20rem", height: "5rem" }}>
                    <InputText
                      label={"Mật khẩu"}
                      type="password"
                      value={account.password}
                      name="password"
                      onChange={(e) =>
                        handleChange(e.target.value, 0, e.target.name)
                      }
                    />

                    {error.password && (
                      <p className="has-text-danger subtitle is-size-6">
                        {error.password}
                      </p>
                    )}
                  </div>
                  <PrimaryButtonPageLevel
                    onClick={handleSubmit}
                    style={{
                      height: "4rem",
                      width: "20rem",
                      maxWidth: "20rem",
                    }}
                    type="submit"
                  >
                    Đăng nhập
                  </PrimaryButtonPageLevel>
                  <Link
                    to={"/reset-password"}
                    className="is-underlined is-clickable has-text-kurio-main-color-black"
                  >
                    Quên mật khẩu?
                  </Link>
                </div>
              </form>
              <div
                className="has-text-centered is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "1rem" }}
              >
                <Link
                  className=" has-text-kurio-main-color-black"
                  to="/register"
                >
                  <p style={{ fontSize: "0.875rem" }}>
                    <span className=" mr-2">Chưa có tài khoản ?</span>
                    <span className="has-text-weight-bold is-underlined">
                      Đăng ký
                    </span>
                  </p>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </BackgroundImageFromUrl>
  );
}

export default Signin;
