import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import ScrollRef from "../../../detail-components/ScrollRef";
import { setQuestionVisible } from "../../../reducers/questionsSlice";
import DragDrop from "../../../templates/lesson/DragDrop";
import DragDropMulti from "../../../templates/lesson/DragDropMulti";
import Gapfill from "../../../templates/lesson/Gapfill";
import MultipleChoice from "../../../templates/lesson/MultipleChoice";
import MultipleResponse from "../../../templates/lesson/MultipleResponse";
import VerticalGapfill from "../../../templates/lesson/VerticalGapfill";
import VerticalMultipleChoice from "../../../templates/lesson/VerticalMultipleChoice";
import VerticalMultipleResponse from "../../../templates/lesson/VerticalMultipleResponse";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../../constants/ConstantStrings";


const NextLessonWrapper = ({
  sections,
  slide,
  section,
  data,
  unitId,
  template,
  order,
  submitLesson,
  nextSection,
  handleEndLesson,
  isPreview,
  setScrollTop
  // explanation,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const handleClick = async () => {
    setTimeout(() => {
      setScrollTop(false)
    }, 500)
    if (sections[section].slides[slide].isLast) {
      if (section === sections.length - 1) {
        submitLesson(section, slide);
        handleEndLesson();
      } else {
        nextSection();
      }
    } else {
      submitLesson(section, slide);
      dispatch(setQuestionVisible({ section: section, index: slide + 1 }));
    }
  };

  const TemplateQuestion = () => {
    switch (template) {
      case "MultipleChoice":
        return (
          <MultipleChoice
            setScrollTop={setScrollTop}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );
      case "VerticalMultipleChoice":
        return (
          <VerticalMultipleChoice
            data={data}
            order={order}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={[
              data?.content.backgroundImage,
              data?.question.backgroundImage,
            ]}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );

      case "VerticalMultipleResponse":
        return (
          <VerticalMultipleResponse
            data={data}
            order={order}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={[
              data?.content.backgroundImage,
              data?.question.backgroundImage,
            ]}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );

      case "VerticalGapfill":
        return (
          <VerticalGapfill
            data={data}
            order={order}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={[
              data?.content.backgroundImage,
              data?.question.backgroundImage,
            ]}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );

      case "MultipleResponse":
        return (
          <MultipleResponse
            setScrollTop={setScrollTop}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );
      case "Gapfill":
        return (
          <Gapfill
            setScrollTop={setScrollTop}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );
      case "DragDrop":
        return (
          <DragDrop
            setScrollTop={setScrollTop}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );
      case "DragDropMulti":
        return (
          <DragDropMulti
            setScrollTop={setScrollTop}
            question={data.question}
            explanation={data.explanation}
            backgroundImage={data?.question.backgroundImage}
            section={section}
            slide={slide}
            submitLesson={(section, slide) => submitLesson(section, slide)}
            nextSection={nextSection}
          />
        );
      default:
        return null;
    }
  };
  if (isLoading) {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }

  return (
    <div className="has-text-centered">
      {template && template.length > 0 ? (
        <>
          <div style={{ zIndex: 1 }}>
            <TemplateQuestion />
          </div>
        </>
      ) : (
        <div
          className={`${(
            slide + 1 > sections[section].slides.length - 1
              ? sections[section].isFinished
              : sections[section].slides[slide + 1].visible
          )
            ? "is-hidden"
            : ""
            } ${isLoading ? "is-hidden" : ""}  is-clickable`}
          style={{ marginTop: "4rem" }}
        >
          {/* {!isLoading && sections[section].slides[slide].isLast && (
            <ScrollRef />
          )} */}
          {sections[section].slides[slide].isLast && (
            <PrimaryButtonPageLevel
              onClick={handleClick}
              style={{ height: "4rem", margin: "0 auto" }}
            >
              {section === sections.length - 1
                ? getConstantStringByLanguage(ConstantStrings.END_LESSON)
                : getConstantStringByLanguage(ConstantStrings.CONTINUE)}
            </PrimaryButtonPageLevel>
          )}
          {!sections[section].slides[slide].isLast && (
            <div onClick={handleClick}>
              <img src={getConstantStringByLanguage(ConstantStrings.BUTTON_NEXT_SLIDE_URL)} alt="loading" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    sections: state.questions.sections,
  };
}
export default connect(mapStateToProps)(NextLessonWrapper);
