import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButtonPageLevel from "./button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "./button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "./parseImage/BackgroundImageFromUrl";
import { getConstantStringByLanguage } from "../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../constants/ConstantStrings";

const NotificationUpgradeAccount = ({ isShowPopup, handleSetShowError }) => {
  const [showPopup, setShowPopup] = useState(isShowPopup ? isShowPopup : false);
  const navigate = useNavigate();
  const removePopup = () => {
    setShowPopup(false);
    handleSetShowError(false);
    localStorage.removeItem("showPopupDailyNow");
  };
  const handleClick = () => {
    navigate("/upgrade");
    localStorage.removeItem("showPopupDailyNow");
  };
  return (
    <>
      {showPopup && (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/auth/background-popup-upgrade-acc.svg"
            className="is-relative "
            style={{
              width: "56.875rem",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "34.125rem",
              borderRadius: "2.5rem",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "22.5625rem",
                height: "24rem",
                position: "absolute",
                top: "5.06rem",
                left: "7.5rem",
              }}
            >
              <div
                className="is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center"
                style={{
                  borderRadius: "2.5rem",
                  padding: "2.5rem 2.5rem 3rem 2.5rem",
                  width: "22.5625rem",
                  height: "21.5rem",
                  boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
                  outline: "0.09375rem solid #0A2A66",
                }}
              >
                <div style={{ width: "5rem", height: "5rem", position: "absolute", top: "7.85rem", left: "-1.5rem" }}>
                  <img src="/images/auth/star-popup-upgrade-acc.svg" alt="" />
                </div>
                <div style={{ width: "5rem", height: "5rem", position: "absolute", top: "3.85rem", right: "-1.375rem" }}>
                  <img src="/images/auth/star-popup-upgrade-acc.svg" alt="" />
                </div>
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-4 has-text-weight-medium has-text-centered is-align-items-center is-justify-content-center"
                  style={{
                    fontFamily: "Dela Gothic One",
                  }}
                >
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      fontSize: "1.5rem",
                      width: "12.75rem",
                      lineHeight: "110%",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.EXCLUSIVE_FEATURE)}
                  </p>
                  <p
                    className=" has-text-kurio-yellow-dark"
                    style={{ fontSize: "3rem", lineHeight: "110%" }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.VIP_ACCOUNT)}
                  </p>
                </div>
                <div
                  className="has-text-kurio-main-color-black has-text-centered"
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                    width: "14.5625rem",
                    // maxHeight: "4.125rem",
                    // display: "-webkit-box",
                    // WebkitLineClamp: 3,
                    // WebkitBoxOrient: "vertical",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                >
                  {getConstantStringByLanguage(ConstantStrings.UPGRADE_ACCOUNT)}
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "-2.5rem",
                  }}
                >
                  <PrimaryButtonPageLevel
                    onClick={handleClick}
                    style={{
                      width: "20rem",
                      height: "4rem",
                      maxWidth: "20rem",
                      borderRadius: "1rem",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.GO_TO_UPGRADE_PAGE)}
                  </PrimaryButtonPageLevel>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "18.5rem",
                height: "28.5rem",
                position: "absolute",
                bottom: "0",
                right: "4.62rem",
              }}
            >
              <img src="/images/auth/cu-quan.svg" alt="" />
            </div>

            <div
              onClick={removePopup}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}
    </>
  );
};

export default NotificationUpgradeAccount;
