/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../../components/DuplicateLoginErrorComponent";
import NotificationUpgradeAccount from "../../../components/NotificationUpgradeAccount";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../../components/button/secondary-button/SecondaryButtonLevelPage";
import ExerciseLessonsListButton from "../../../components/button/special-button/ExcerciseLessonsListButton";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
// import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import { getListComplexity } from "../../../config/complexity";
import { createPractice } from "../../../config/practice";
import {
  clearState,
  createNewPractice,
  getAPracticeQuestions,
  getQuizInformation,
  setQuestionsAnswered,
} from "../../../reducers/practiceQuestionSlice";
import {
  clearStateQuestion,
  getALesson,
  getLessonSuccess,
} from "../../../reducers/questionsSlice";
import { LoadingImage } from "../../../templates/helper/LoadingImage";
import { getAllImage } from "../../../templates/helper/getAllImages";
import jsonParser from "../../../templates/helper/jsonParser";
import "./ListLessonRightWrapper.css";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../../constants/ConstantStrings";
import SecondaryButtonQuestion from "../../../components/button/secondary-button/SecondaryButtonQuestion";

const ListLessonRightWrapper = ({
  listAnUnit,
  unitId,
  unitLessonProgress,
  handleShowModal,
  lastLesson,
  quiz,
  sections,
  errorLesson,
}) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  var token = cookies.get("signinUser");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [ShowPopContinue, setShowPopContinue] = useState(false);
  const [clickState, setClickState] = useState(false);
  const [clickItem, setClickItem] = useState("");
  const navigate = useNavigate();
  // const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showError, setShowError] = useState(false);

  var divStyleNew = {
    // marginRight: "1.5rem",
    padding: "0.25rem 0.375rem",
    borderRadius: "0.5rem",
    width: "2.625rem",
    backgroundColor: "#0A2A66",
  };
  var divStyleFinished = {
    // marginRight: "1.5rem",
    padding: "0.25rem 0.375rem",
    borderRadius: "0.5rem",
    width: "7.1875rem",
    backgroundColor: "#FED131",
  };
  var divStyleOnGoing = {
    // marginRight: "1.5rem",
    padding: "0.25rem 0.375rem",
    borderRadius: "0.5rem",
    width: "5.875rem",
    backgroundColor: "#572CD1",
  };

  var pStyleNew = {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "0.875rem",
    textTransform: "uppercase",
    color: "#FAFBFC",
    lineHeight: "normal",
  };
  var pStyleFinished = {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "0.875rem",
    textTransform: "uppercase",
    color: "#572CD1",
    lineHeight: "normal",
  };
  var pStyleOnGoing = {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "0.875rem",
    textTransform: "uppercase",
    lineHeight: "normal",
    color: "#FAFBFC",
  };

  const handleClick = (lessonId, indexLesson) => {
    handleClickState(lessonId);
    cookies.set(
      "indexLesson",
      indexLesson > 9 ? indexLesson : `0` + indexLesson
    );
    if (cookies.get("lessonId")) {
      cookies.remove("lessonId");
    }
    cookies.set("lessonId", lessonId);
    if (unitId === null) {
      unitId = cookies.get("unitId");
    }
    localStorage.removeItem("lesson_end_lesson");
    try {
      dispatch(getALesson({ lessonId }));
      dispatch(getLessonSuccess({ unitId }));
      dispatch(clearStateQuestion());
      const lastLoginKey = "lastLoginDate";
      const clearAllKey = "clearAll";
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (
          key !== lastLoginKey &&
          key !== clearAllKey &&
          key !== "accessToken"
        ) {
          localStorage.removeItem(key);
        }
      });
      setLoadingImage(true);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  const handleClickState = (id) => {
    if (clickItem === id) {
      setClickItem("");
      setClickState(!clickState);
    } else {
      setClickState(true);
      setClickItem(id);
    }
  };

  const handlePractice = async (item) => {
    if (FindUnitLessonProgress(item._id) === 100) {
      const { result: listComplexity } = await getListComplexity();
      try {
        const { result: practiceId } = await createPractice(
          {
            lessonIds: [item._id],
            unitIds: [unitId],
            complexityIds: listComplexity.map((item) => item._id),
          },
          token
        );
        handleShowModal(practiceId);
      } catch (error) {
        if (
          error?.response?.data?.errorMessage.includes(
            "Bạn không phải là tài khoản premium, không thể làm bài kiểm tra này"
          )
        ) {
          // return <NotificationUpgradeAccount isShowPopup={true}/>
          setShowError(true);
        } else {
          alert(error.response.data.errorMessage);
        }
      }
    }
  };

  const FindUnitLessonProgress = (id, action) => {
    var value = 0;

    if (unitLessonProgress && unitLessonProgress.length > 0) {
      for (var i = 0; i < unitLessonProgress.length; i++) {
        if (unitLessonProgress[i]._id === id) {
          value = unitLessonProgress[i].totalProgress;
          return value;
        }
      }
    }
    return value;
  };

  function checkArrays(array1, array2) {
    const arrayLessonId = array2.map((item) => item._id);
    const filterProgress = array1.filter((item) =>
      arrayLessonId.includes(item._id)
    );
    if (filterProgress.length === arrayLessonId.length) {
      if (filterProgress?.every((item) => item.totalProgress === 100)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

    // Kiểm tra xem tất cả totalProgress có bằng 100% không
  }

  const handleClickRetry = (practiceId) => {
    localStorage.setItem("unitName", listAnUnit?.unitName);
    dispatch(setQuestionsAnswered([]));
    dispatch(clearState([]));
    if (practiceId) {
      dispatch(createNewPractice({ practiceId, token }));
    }

    setTimeout(() => {
      navigate("/unit-quiz");
    }, 500);
  };

  const handleClickContinue = (practiceQuizId) => {
    dispatch(setQuestionsAnswered([]));
    dispatch(clearState([]));
    if (practiceQuizId) {
      cookies.set("practiceQuizId", practiceQuizId);

      dispatch(getAPracticeQuestions({ practiceQuizId }));
    }
    setTimeout(() => {
      navigate("/unit-quiz");
    }, 500);
  };

  const removePopup = () => {
    setIsClicked(false);
    setShowPopup(false);
    setShowPopupSuccess(false);
    setShowPopContinue(false);
  };

  const handleClickQuiz = async (practiceId) => {
    localStorage.setItem("unitName", listAnUnit?.unitName);
    setIsClicked(!isClicked);
    if (quiz.practiceQuizId && quiz.practiceQuizIdLP === null) {
      setShowPopup(true);
    } else if (
      quiz.practiceQuizIdLP &&
      quiz.practiceQuizId === null &&
      (quiz.correctAnswersLP || quiz.correctAnswersLP === 0)
    ) {
      setShowPopupSuccess(true);
    } else if (
      quiz.practiceQuizIdLP &&
      quiz.practiceQuizId &&
      (quiz.correctAnswersLP || quiz.correctAnswersLP === 0)
    ) {
      setShowPopContinue(true);
    } else {
      dispatch(createNewPractice({ practiceId, token }));
      cookies.set("practiceQuizId", practiceId);
      setTimeout(() => {
        navigate("/unit-quiz");
      }, 500);
    }
  };
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  const handleSetShowError = (state) => {
    setShowError(state);
  };

  const handleClickShowSolution = (practiceQuizIdLP) => {
    if (practiceQuizIdLP) {
      cookies.set("practiceQuizIdLP", practiceQuizIdLP);

      dispatch(getAPracticeQuestions({ practiceQuizIdLP, page: 1, size: 50 }));
    }

    setTimeout(() => {
      navigate("/unit-quiz");
    }, 500);
  };

  useEffect(() => {
    if (cookies.get("lessonId")) {
      cookies.remove("lessonId");
    }
    const handleClickQuizz = () => {
      try {
        if (listAnUnit?.quizId) {
          let quizId = listAnUnit?.quizId;
          dispatch(
            getQuizInformation({
              quizId,
              token,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!window.location.pathname.includes("preview")) {
      handleClickQuizz();
    }

    if (
      quiz?.correctAnswersLP === quiz?.generatedQuestionsLP &&
      quiz?.correctAnswersLP !== undefined &&
      quiz?.correctAnswersLP !== null
    ) {
      localStorage.setItem("daDatDiemToiDa", "true");
    }
  }, [dispatch, listAnUnit?.quizId]);
  if (loadingImage) {
    if (sections !== null && sections?.length > 0) {
      const result = getAllImage(sections, "LESSON");
      if (result && result?.length > 0) {
        return (
          <>
            <LoadingImage
              images={result}
              router={"lesson"}
              handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            />
          </>
        );
      } else {
        navigate("/lesson");
        // setLoadingImage(false);
      }
    }
    if (errorLesson !== null && errorLesson === 409) {
      localStorage.setItem("clearAll", true);
      return <DuplicateLoginErrorComponent isShowPopup={true} />;
    }
  }

  return (
    <>
      {/* {loadingImage && <LoadingImage />} */}
      {showError && (
        <NotificationUpgradeAccount
          handleSetShowError={(state) => handleSetShowError(state)}
          isShowPopup={showError}
        />
      )}
      {!loadingImage && (
        <div
          className="is-flex is-flex-direction-column is-align-items-flex-start"
          style={{ gap: "2rem", width: "42rem" }}
        >
          {showPopup && (
            <div
              onClick={removePopup}
              className="modal is-active modal-background"
              style={{ opacity: 1 }}
            >
              <div className="modal-quiz is-relative">
                <h2 className="title-popup-quiz">
                  {getConstantStringByLanguage(ConstantStrings.CONTINUE)}
                </h2>
                <p className="document-quiz">
                  {getConstantStringByLanguage(
                    ConstantStrings.CONTINUE_PREVIOUS_SESSION
                  )}
                </p>

                <div
                  className="is-flex is-flex-direction-row"
                  style={{ gap: "1rem", margin: "0 auto" }}
                >
                  <SecondaryButtonLevelPage
                    onClick={() => handleClickRetry(quiz._id)}
                  >
                    {getConstantStringByLanguage(ConstantStrings.START_OVER)}
                  </SecondaryButtonLevelPage>
                  <PrimaryButtonPageLevel
                    onClick={() => {
                      handleClickContinue(quiz?.practiceQuizId);
                    }}
                    style={{ height: "4rem" }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.QUIZ_CONTINUE)}
                  </PrimaryButtonPageLevel>
                </div>

                <div
                  onClick={removePopup}
                  className=""
                  style={{
                    position: "absolute",
                    top: "-8%",
                    left: "-4%",
                    zIndex: 9999,
                    background: "transparent",
                    border: "none",
                    width: "5rem",
                    fontSize: "2rem",
                  }}
                >
                  <PopupCloseButton />
                </div>
              </div>
            </div>
          )}

          {showPopupSuccess && (
            <div
              onClick={removePopup}
              className="modal is-active modal-background"
              style={{ opacity: 1 }}
            >
              <div className="modal-quiz is-relative">
                <div>
                  <span>
                    {getConstantStringByLanguage(
                      ConstantStrings.PREVIOUS_SCORE
                    )}
                  </span>
                  <h2 className="title-popup-quiz">
                    <p>
                      {quiz?.correctAnswersLP} / {quiz?.generatedQuestionsLP}
                    </p>
                  </h2>
                </div>

                <div
                  className="is-flex is-flex-direction-column"
                  style={{ gap: "1rem", margin: "0 auto" }}
                >
                  <PrimaryButtonPageLevel
                    style={{ height: "4rem" }}
                    onClick={() => handleClickRetry(quiz._id)}
                  >
                    {getConstantStringByLanguage(ConstantStrings.TRY_AGAIN)}
                  </PrimaryButtonPageLevel>
                  <SecondaryButtonLevelPage
                    onClick={() => {
                      handleClickShowSolution(quiz.practiceQuizIdLP);
                    }}
                  >
                    {getConstantStringByLanguage(
                      ConstantStrings.VIEW_COMPLETED_LESSONS
                    )}
                  </SecondaryButtonLevelPage>
                </div>

                <div
                  onClick={removePopup}
                  className=""
                  style={{
                    position: "absolute",
                    top: "-8%",
                    left: "-4%",
                    zIndex: 9999,
                    background: "transparent",
                    border: "none",
                    width: "5rem",
                    fontSize: "2rem",
                  }}
                >
                  <PopupCloseButton />
                </div>
              </div>
            </div>
          )}

          {ShowPopContinue && (
            <div
              onClick={removePopup}
              className="modal is-active modal-background"
              style={{ opacity: 1 }}
            >
              <div className="modal-quiz is-relative">
                <div>
                  <span>
                    {getConstantStringByLanguage(
                      ConstantStrings.PREVIOUS_SCORE
                    )}
                  </span>
                  <h2 className="title-popup-quiz">
                    <p>
                      {quiz?.correctAnswersLP} / {quiz?.generatedQuestionsLP}
                    </p>
                  </h2>
                </div>

                <p className="sub-title-popup">
                  {getConstantStringByLanguage(
                    ConstantStrings.CONTINUE_PREVIOUS_SESSION
                  )}
                </p>

                <div
                  className="is-flex is-flex-direction-column"
                  style={{ gap: "1rem", margin: "0 auto" }}
                >
                  <PrimaryButtonPageLevel
                    onClick={() => {
                      handleClickContinue(quiz.practiceQuizId);
                    }}
                    style={{ height: "4rem" }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.QUIZ_CONTINUE)}
                  </PrimaryButtonPageLevel>
                  <SecondaryButtonLevelPage
                    style={{ height: "4rem" }}
                    onClick={() => handleClickRetry(quiz._id)}
                  >
                    {getConstantStringByLanguage(ConstantStrings.START_OVER)}
                  </SecondaryButtonLevelPage>
                  <SecondaryButtonLevelPage
                    onClick={() => {
                      handleClickShowSolution(quiz.practiceQuizIdLP);
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.VIEW_COMPLETED_LESSONS)}
                  </SecondaryButtonLevelPage>
                </div>

                <div
                  onClick={removePopup}
                  className=""
                  style={{
                    position: "absolute",
                    top: "-8%",
                    left: "-4%",
                    zIndex: 9999,
                    background: "transparent",
                    border: "none",
                    width: "5rem",
                    fontSize: "2rem",
                  }}
                >
                  <PopupCloseButton />
                </div>
              </div>
            </div>
          )}

          {listAnUnit?.lessons === undefined ||
          !listAnUnit?.lessons ||
          lastLesson === null ||
          !lastLesson ? (
            ""
          ) : listAnUnit?.lessons && listAnUnit?.lessons.length > 0 ? (
            <>
              {listAnUnit?.lessons.map((item, index) => {
                return (
                  <div
                    style={{ width: "42rem" }}
                    className="columns m-0 p-0 "
                    key={index}
                    // onClick={() => handleClickState(item._id)}
                  >
                    <div
                      className="column p-0 m-0 is-narrow is-relative"
                      // style={
                      //   `${
                      //     FindUnitLessonProgress(item._id) === 0 || item.length < 0
                      //   }`
                      //     ? {
                      //         filter:
                      //           "drop-shadow(0px 0.375rem 1rem rgba(10, 42, 102, 0.25))",
                      //       }
                      //     : {}
                      // }
                      style={{
                        height:
                          index === 0
                            ? lastLesson && lastLesson._id === item._id
                              ? "8"
                              : "6rem"
                            : lastLesson && lastLesson._id === item._id
                            ? "9.5"
                            : "7.5rem",
                      }}
                    >
                      {lastLesson && lastLesson._id === item._id && (
                        <div
                          className="ml-4 has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold is-uppercase"
                          style={{
                            borderRadius: "0.5rem 0.5rem 0rem 0rem",
                            fontSize: "1rem",
                            width: "7.75rem",
                            padding: "0.375rem 0.75rem",
                          }}
                        >
                          {getConstantStringByLanguage(
                            ConstantStrings.PREVIOUSLY
                          )}
                        </div>
                      )}
                      {index === 0 && (
                        <div
                          className="is-flex is-align-items-center is-justify-content-space-between is-clickable has-background-kurio-main-color-white"
                          onClick={() => handleClick(item._id, index)}
                          style={{
                            borderRadius: "1rem",
                            border: "0.09375rem solid #0A2A66",
                            boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
                            height: "6rem",
                            padding: "0rem 3rem 0rem 2.5rem",
                            width: "42rem",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundImage:
                              lastLesson && lastLesson._id === item._id
                                ? "url('/images/unit/background-unit-on-going.svg')"
                                : "",
                            gap: "2.5rem",
                          }}
                        >
                          <div className="is-flex is-flex-gap-5 is-align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="#0A2A66"
                            >
                              <path
                                d="M20.678 10.9051C19.682 9.98109 18.228 9.74109 16.976 10.2911C15.756 10.8231 15 11.9611 15 13.2591V26.7411C15 28.0391 15.756 29.1771 16.976 29.7091C17.422 29.9051 17.896 29.9991 18.362 29.9991C19.208 29.9991 20.038 29.6891 20.676 29.0971L27.96 22.3571C28.622 21.7451 29 20.8871 29 19.9991C29 19.1131 28.622 18.2531 27.96 17.6431L20.678 10.9051ZM20 36C11.178 36 4 28.822 4 20C4 11.178 11.178 4 20 4C28.822 4 36 11.178 36 20C36 28.822 28.822 36 20 36ZM20 0C8.972 0 0 8.972 0 20C0 31.028 8.972 40 20 40C31.028 40 40 31.028 40 20C40 8.972 31.028 0 20 0Z"
                                fill={
                                  lastLesson && lastLesson._id === item._id
                                    ? "#572CD1"
                                    : "#0A2A66"
                                }
                              />
                            </svg>
                            <p
                              className={`${
                                lastLesson && lastLesson._id === item._id
                                  ? "has-text-kurio-main-color-purple"
                                  : "has-text-kurio-main-color-black"
                              } has-text-weight-bold title-start-up`}
                              style={{
                                fontSize: "2rem",
                              }}
                            >
                              {/* {jsonParser(item.title, index, "lesson-start-up-title")} */}
                              {getConstantStringByLanguage(
                                ConstantStrings.START
                              )}
                            </p>
                          </div>
                          <div
                            className="is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-center"
                            style={{ gap: "0.625rem" }}
                          >
                            <div
                              style={
                                FindUnitLessonProgress(item._id) === 100
                                  ? {
                                      ...divStyleFinished,
                                      ...pStyleFinished,
                                      // boxShadow:
                                      //   "0.125rem 0.25rem 0rem 0rem #0A2A66",
                                    }
                                  : FindUnitLessonProgress(item._id) === 0 ||
                                    item.length < 0
                                  ? {
                                      ...divStyleNew,
                                      ...pStyleNew,
                                      // boxShadow:
                                      //   "0.125rem 0.25rem 0rem 0rem #0A2A66",
                                    }
                                  : 1 <= FindUnitLessonProgress(item._id) &&
                                    FindUnitLessonProgress(item._id) < 100
                                  ? {
                                      ...divStyleOnGoing,
                                      ...pStyleOnGoing,
                                      // boxShadow:
                                      //   "0.125rem 0.25rem 0rem 0rem #0A2A66",
                                    }
                                  : {}
                              }
                            >
                              {FindUnitLessonProgress(item._id) === 100
                                ? getConstantStringByLanguage(
                                    ConstantStrings.COMPLETED
                                  )
                                : FindUnitLessonProgress(item._id) === 0 ||
                                  item.length <= 0
                                ? getConstantStringByLanguage(
                                    ConstantStrings.NEW
                                  )
                                : FindUnitLessonProgress(item._id) >= 1 &&
                                  FindUnitLessonProgress(item._id) < 100
                                ? getConstantStringByLanguage(
                                    ConstantStrings.ON_GOING
                                  )
                                : ""}{" "}
                            </div>
                            {/* <div
                                className="has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center"
                                style={{
                                  height: "1rem",
                                  borderRadius: "0.5rem",
                                  width: "8.25rem",
                                  // boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
                                }}
                              >
                              </div> */}
                            <progress
                              style={{
                                borderRadius: "4rem",
                                height: "0.5rem",
                                width: "7.5rem",
                              }}
                              className={`${
                                FindUnitLessonProgress(item._id) > 0
                                  ? "is-kurio-main-color-purple"
                                  : "is-kurio-gray-light-1"
                              } progress`}
                              value={
                                FindUnitLessonProgress(item._id)
                                  ? FindUnitLessonProgress(item._id)
                                  : 0
                              }
                              max="100"
                            ></progress>
                          </div>
                        </div>
                      )}
                      {index > 0 && (
                        <div className="is-flex is-align-items-center is-justify-content-center is-clickable">
                          <div
                            className={`${
                              clickState && clickItem === item._id
                                ? lastLesson && lastLesson._id === item._id
                                  ? " has-background-kurio-purple-light-2 "
                                  : "has-background-kurio-gray-light-2 "
                                : lastLesson && lastLesson._id === item._id
                                ? " has-background-kurio-purple-light-3  lesson-item"
                                : "has-background-kurio-main-color-white lesson-item"
                            }  is-flex is-justify-content-space-between is-align-items-center`}
                            onClick={() => handleClick(item._id, index)}
                            style={{
                              padding: "1.875rem 4rem 1.875rem 2.5rem",
                              width: "35rem",
                              height: "7.5rem",
                              borderRadius: "1rem",
                              gap: "2.5rem",
                              border: "0.09375rem solid #0A2A66",
                              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundImage:
                                lastLesson && lastLesson._id === item._id
                                  ? "url('/images/unit/background-unit-on-going.svg')"
                                  : "",
                            }}
                          >
                            <div className="is-flex is-align-items-center is-flex-gap-5">
                              <p
                                className=" has-text-kurio-main-color-black is-uppercase has-text-weight-bold"
                                style={{
                                  width: "3.25rem",
                                  height: "3.6875rem",
                                  fontSize: "3rem",
                                  lineHeight: "normal",
                                }}
                              >
                                {`${index < 10 ? "0" + index : index} `}
                              </p>
                              <p
                                className=" has-text-kurio-main-color-black"
                                style={{
                                  fontSize: "1.125rem",
                                  lineHeight: "normal",
                                  width: "15.75rem",
                                  cursor: "pointer",
                                }}
                              >
                                {jsonParser(item.title, index, "lesson-title")}
                              </p>
                            </div>
                            <div>
                              <div
                                style={
                                  FindUnitLessonProgress(item._id) === 100
                                    ? { ...divStyleFinished, ...pStyleFinished }
                                    : FindUnitLessonProgress(item._id) === 0 ||
                                      item.length < 0
                                    ? { ...divStyleNew, ...pStyleNew }
                                    : 1 <= FindUnitLessonProgress(item._id) &&
                                      FindUnitLessonProgress(item._id) < 100
                                    ? { ...divStyleOnGoing, ...pStyleOnGoing }
                                    : {}
                                }
                              >
                                {FindUnitLessonProgress(item._id) === 100
                                  ? getConstantStringByLanguage(
                                      ConstantStrings.COMPLETED
                                    )
                                  : FindUnitLessonProgress(item._id) === 0 ||
                                    item.length <= 0
                                  ? getConstantStringByLanguage(
                                      ConstantStrings.NEW
                                    )
                                  : FindUnitLessonProgress(item._id) >= 1 &&
                                    FindUnitLessonProgress(item._id) < 100
                                  ? getConstantStringByLanguage(
                                      ConstantStrings.ON_GOING
                                    )
                                  : ""}{" "}
                              </div>

                              <div style={{ marginTop: "0.5rem" }}>
                                <progress
                                  style={{
                                    borderRadius: "4rem",
                                    height: "0.5rem",
                                    width: "7.5rem",
                                  }}
                                  className={`${
                                    FindUnitLessonProgress(item._id) > 0
                                      ? "is-kurio-main-color-purple"
                                      : "is-kurio-gray-light-1"
                                  } progress`}
                                  value={
                                    FindUnitLessonProgress(item._id)
                                      ? FindUnitLessonProgress(item._id)
                                      : 0
                                  }
                                  max="100"
                                ></progress>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: "1.5rem" }}>
                            <img src="/images/unit/stroke.svg" alt="" />
                          </div>
                          <div
                            className={`${
                              FindUnitLessonProgress(item._id) !== 100
                                ? "has-background-kurio-main-color-light-gray"
                                : "has-background-kurio-main-color-yellow"
                            } is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center is-justify-content-center is-align-self-stretch`}
                            onClick={() => handlePractice(item)}
                            style={{
                              width: "5.5rem",
                              maxHeight: "7.5em",
                              borderRadius: "1rem",
                              padding: "0 0.75rem",
                              border:
                                FindUnitLessonProgress(item._id) !== 100
                                  ? "0.09375rem solid #8C9DC1"
                                  : "0.09375rem solid #0A2A66",
                              boxShadow:
                                FindUnitLessonProgress(item._id) !== 100
                                  ? ""
                                  : "0.125rem 0.25rem 0rem 0rem #0A2A66",
                            }}
                          >
                            <ExerciseLessonsListButton
                              disabled={
                                FindUnitLessonProgress(item._id) !== 100
                              }
                            />
                            <p
                              className={`${
                                FindUnitLessonProgress(item._id) !== 100
                                  ? "has-text-kurio-gray-main-accent"
                                  : "has-text-kurio-main-color-purple"
                              } is-uppercase has-text-weight-bold has-text-centered`}
                              style={{ fontSize: "0.875rem" }}
                            >
                              {getConstantStringByLanguage(
                                ConstantStrings.PRACTICE
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div
                className="is-flex is-justify-content-center is-align-items-center has-text-kurio-main-color-black has-text-weight-bold"
                style={{ height: "100%", width: "100%" }}
              >
                <p className="is-uppercase is-size-1">
                  {getConstantStringByLanguage(ConstantStrings.COMING_SOON)}
                </p>
              </div>
            </>
          )}

          {listAnUnit?.quizId !== null && listAnUnit?.lessons ? (
            unitLessonProgress?.length >= 0 &&
            listAnUnit?.lessons?.length >= 0 &&
            checkArrays(unitLessonProgress, listAnUnit?.lessons) === false ? (
              <BackgroundImageFromUrl
                objectId="admin/frontend-images/quiz/background-quiz-disabled.svg"
                className="quiz__unit"
              >
                <div className="quiz__content-disbled">
                  <p>{getConstantStringByLanguage(ConstantStrings.GOAL)}</p>
                  <span>
                    {getConstantStringByLanguage(
                      ConstantStrings.COMPLETE_LESSONS_TO_UNLOCK
                    )}
                  </span>
                </div>

                {/* <img
                  style={{ height: "4rem" }}
                  src="/images/quiz/image-button-quiz-disabled.svg"
                  alt=""
                /> */}
                <SecondaryButtonQuestion
                  disabled={true}
                  isDefault={false}
                  className="button"
                  defaultColor="is-kurio-purple-light-2"
                  style={{
                    maxWidth: "10rem",
                    height: "4rem",
                    width: "10rem",
                    borderRadius: "1rem",
                  }}
                  prefix={"admin/frontend-images/units/lock"}
                >
                  {getConstantStringByLanguage(ConstantStrings.LOCK)}
                </SecondaryButtonQuestion>

                <div className="is-flex is-flex-direction-column">
                  {quiz?.correctAnswersLP !== null ? (
                    <>
                      <p
                        className="has-text-kurio-gray-main-accent"
                        style={{
                          width: "5rem",
                          lineHeight: "normal",
                          fontSize: "2.5rem",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        {quiz?.correctAnswersLP}
                      </p>

                      <span
                        className="has-text-kurio-gray-main-accent"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        <b style={{ marginRight: "0.5rem" }}>/</b>
                        {quiz?.generatedQuestionsLP !== null
                          ? quiz?.generatedQuestionsLP
                          : 0}
                      </span>
                    </>
                  ) : (
                    <p
                      className="has-text-kurio-gray-main-accent"
                      style={{
                        width: "5rem",
                        lineHeight: "normal",
                        fontSize: "2.5rem",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      -
                    </p>
                  )}
                </div>
              </BackgroundImageFromUrl>
            ) : (
              <>
                {quiz?.correctAnswersLP !== null &&
                quiz?.generatedQuestionsLP !== null &&
                quiz?.correctAnswersLP === quiz?.generatedQuestionsLP ? (
                  <BackgroundImageFromUrl
                    objectId={
                      "admin/frontend-images/quiz/background-quiz-success.svg"
                    }
                    className="quiz__unit"
                  >
                    <div className="quiz__content-success">
                      <p>{getConstantStringByLanguage(ConstantStrings.GOAL)}</p>
                      <span>
                        {getConstantStringByLanguage(
                          ConstantStrings.EXCEL_AT_THE_CHALLENGE
                        )}
                      </span>
                    </div>

                    {/* <img
                      style={{ height: "4rem" }}
                      onClick={() => handleClickRetry(quiz._id)}
                      src="/images/quiz/image-button-quiz-success.svg"
                      alt=""
                    /> */}
                    <PrimaryButtonPageLevel
                      onClick={() => handleClickRetry(quiz?._id)}
                      style={{
                        width: "10rem",
                        height: "4rem",
                        borderRadius: "1rem",
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.TRY_AGAIN)}
                    </PrimaryButtonPageLevel>

                    <div className="is-flex is-flex-direction-column">
                      <p
                        className="has-text-kurio-main-color-purple"
                        style={{
                          width: "5rem",
                          lineHeight: "normal",
                          fontSize: "2.5rem",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        {quiz?.correctAnswersLP !== null
                          ? quiz?.correctAnswersLP
                          : 0}
                      </p>
                      <span
                        className="has-text-kurio-main-color-purple"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        <b style={{ marginRight: "0.5rem" }}>/</b>
                        {quiz?.generatedQuestionsLP !== null
                          ? quiz?.generatedQuestionsLP
                          : 0}
                      </span>
                    </div>
                  </BackgroundImageFromUrl>
                ) : (
                  <BackgroundImageFromUrl
                    objectId={
                      "admin/frontend-images/quiz/background-quiz-open.svg"
                    }
                    className="quiz__unit"
                  >
                    <div className="quiz__content">
                      <p>{getConstantStringByLanguage(ConstantStrings.GOAL)}</p>
                      <span>
                        {getConstantStringByLanguage(
                          ConstantStrings.FINAL_CHALLENGE_COMPLETED
                        )}
                      </span>
                    </div>

                    {/* <img
                      style={{ height: "4rem" }}
                      onClick={() => handleClickQuiz(quiz?._id)}
                      src="/images/quiz/image-button-quiz.svg"
                      alt=""
                    /> */}
                    <PrimaryButtonPageLevel
                      isDarkColor={true}
                      onClick={() => handleClickQuiz(quiz?._id)}
                      style={{
                        width: "10rem",
                        height: "4rem",
                        borderRadius: "1rem",
                      }}
                    >
                      {getConstantStringByLanguage(ConstantStrings.TRY_NOW)}
                    </PrimaryButtonPageLevel>

                    <div className="is-flex is-flex-direction-column">
                      {quiz?.correctAnswersLP !== null ? (
                        <>
                          <p
                            className="has-text-kurio-main-color-white"
                            style={{
                              width: "5rem",
                              lineHeight: "normal",
                              fontSize: "2.5rem",
                              fontWeight: 700,
                              textAlign: "center",
                            }}
                          >
                            {quiz?.correctAnswersLP}
                          </p>

                          <span
                            className="has-text-kurio-main-color-white"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: 700,
                              textAlign: "center",
                            }}
                          >
                            <b style={{ marginRight: "0.5rem" }}>/</b>
                            {quiz?.generatedQuestionsLP !== null
                              ? quiz?.generatedQuestionsLP
                              : 0}
                          </span>
                        </>
                      ) : (
                        <p
                          className="has-text-kurio-main-color-white"
                          style={{
                            width: "5rem",
                            lineHeight: "normal",
                            fontSize: "2.5rem",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          -
                        </p>
                      )}
                    </div>
                  </BackgroundImageFromUrl>
                )}
              </>
            )
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    quiz: state.practiceQuestion.quizInformation,
    newPracticeId: state.practiceQuestion.newPracticeId,
    grades: state.grades.grades,
    lessons: state.grades.lessons,
    lastLesson: state.grades.lastlesson,
    sections: state.questions.sections,
    errorLesson: state.questions.error,
  };
}

export default connect(mapStateToProps)(ListLessonRightWrapper);
