import React, { useState } from "react";
import { connect } from "react-redux";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import ConstantStrings from "../../constants/ConstantStrings";
import convertTimestamp from "../../templates/helper/convertTimestamp";
import "./SelectService.style.css";
import { UpgradeAccountContext } from "./UpgradeAccount";
import SubPackage from "./SubPackage";
import ServiceItem from "./ServiceItem";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const SelectService = ({
  handleNextStep,
  handleSelectStep,
  setNameSelectedService,
  userInfo,
}) => {
  const {
    services,
    setSubscriptionPackageId,
    SubscriptionPackageId,
    setDirectTransferConfirm,
    directTransferConfirm,
  } = React.useContext(UpgradeAccountContext);
  const [selected, setSelected] = useState(SubscriptionPackageId);
  const [indexSelected, setIndexSelected] = useState(null);
  const [popupPremiumTo, setPopupPremiumTo] = useState({
    status: false,
    _id: null,
    index: 0,
    name: "",
  });
  const [showPopupInfo, setShowPopupInfo] = useState(false);

  const handleSelect = (_id, index, name) => {
    // if (userInfo?.premiumFrom !== null &&
    //   userInfo?.premiumTo !== null &&
    //   new Date(userInfo?.premiumFrom).getTime() <=
    //   new Date().getTime() &&
    //   new Date(userInfo?.premiumTo).getTime() >
    //   new Date().getTime()) {
    //   setPopupPremiumTo({
    //     status: true,
    //     _id: _id,
    //     index: index,
    //     name: name
    //   })
    // } else {
    if (selected === _id) {
      setIndexSelected(null);
      setSelected(-1);
      setNameSelectedService("");
      setSubscriptionPackageId("");
      setDirectTransferConfirm({
        ...directTransferConfirm,
        transferAmount: "",
      });
    } else {
      setSelected(_id);
      setIndexSelected(index);
      setNameSelectedService(name);
      handleSelectStep(1);
      setSubscriptionPackageId(_id);
      setDirectTransferConfirm({
        ...directTransferConfirm,
        transferAmount: services.filter((item) => item._id === _id)[0].price,
      });
    }

    handleNextStep();
    // }
  };

  // const handleSelectPopupPremiumTo = (_id, index, name) => {
  //   if (selected === _id) {
  //     setIndexSelected(null);
  //     setSelected(-1);
  //     setNameSelectedService("");
  //     setSubscriptionPackageId("");
  //     setDirectTransferConfirm({
  //       ...directTransferConfirm,
  //       transferAmount: "",
  //     });
  //   } else {
  //     setSelected(_id);
  //     setIndexSelected(index);
  //     setNameSelectedService(name);
  //     handleSelectStep(1);
  //     setSubscriptionPackageId(_id);
  //     setDirectTransferConfirm({
  //       ...directTransferConfirm,
  //       transferAmount: services.filter((item) => item._id === _id)[0].price,
  //     });
  //   }

  //   handleNextStep();
  // }

  const removePopup = () => {
    setShowPopupInfo(false);
  };

  const handleShowPopupInfo = () => {
    if (!showPopupInfo) {
      setShowPopupInfo(true);
    }
  };

  const selectStyle = {
    border: "0.09375rem solid #0A2A66",
    padding: "2.5rem 2rem 3.5rem 2rem",
    borderRadius: "4rem 4rem 1rem 1rem",
    width: "17.5rem",
    height: indexSelected === 1 ? "33.78862rem" : "30.125rem",
    background: "#FAFBFC",
  };

  return (
    <div
      className="is-flex is-flex-gap-7 is-justify-content-center is-align-items-center"
      style={{
        width: "100%",
        borderRadius: "1rem",
        background: "linear-gradient(90deg, #572CD1 0%, #48A9F8 100%)",
        padding: "1.5rem 3rem",
      }}
    >
      {/* {popupPremiumTo.status ? <>
        <div
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              Bạn vẫn còn hạn sử dụng . Bạn có muốn tiếp tục không ?
            </h2>
            <p className="documnent-quiz">
              Hạn sử dụng đến: <b> {userInfo?.premiumTo === null ? 'DD/mm/YYYY' : convertTimestamp(userInfo?.premiumTo)} </b>
            </p>

            <div className="is-flex " style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                onClick={() => handleSelectPopupPremiumTo(popupPremiumTo._id, popupPremiumTo.index, popupPremiumTo.name)}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                Tiếp tục
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      </> : ""} */}
      {/* {!userInfo?.isCombo ? ( */}
      {/* <>
        <div
          className="is-flex is-flex-direction-row select-service is-align-items-flex-end"
          style={{
            gap: "1.5rem",
          }}
        >
          {services.map((service, index) => {
            return (
              <div
                key={index}
                className={`${
                  // selected === service._id
                  //   ? "has-background-kurio-main-color-black has-text-kurio-main-color-white"
                  //   :
                  "has-text-kurio-main-color-black"
                  } is-relative`}
                style={
                  selected === service._id
                    ? selectStyle
                    : {
                      padding: "2.5rem 2rem 3.5rem 2rem",
                      borderRadius: "4rem 4rem 1rem 1rem",
                      boxShadow:
                        index === 1 ? "0.125rem 0.25rem 0rem 0rem #0A2A66" : "",
                      width: "17.5rem",
                      height: index === 1 ? "33.78862rem" : "30.125rem",
                      border: "1.5px solid #0A2A66",
                      background: "#FAFBFC",
                    }
                }
              >
                {index === 1 && (
                  <div
                    style={{
                      position: "absolute",
                      padding: "0.5rem 1rem 0.25rem 1rem",
                      boxShadow: "0.1rem 0rem 0rem 0rem #0A2A66",
                      borderRadius: "1rem 1rem 0rem 0rem",
                      background: "#C4B5F2",
                      top: "-2.5rem",
                      left: "5.125rem",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        color: "#572CD1",
                      }}
                    >
                      Đề xuất
                    </p>
                  </div>
                )}
                <div
                  className="is-flex is-relative is-flex-direction-column is-align-items-center is-justify-content-center"
                  style={{ gap: "1rem" }}
                >
                  <div>
                    <div
                      className={`${
                        // selected === service._id
                        //   ? "has-text-kurio-main-color-white"
                        //   :
                        "has-text-kurio-main-color-black"
                        } has-text-weight-bold `}
                      style={{ textAlign: "center", lineHeight: "normal" }}
                    >
                      <p className="is-uppercase" style={{ fontSize: "1.125rem" }}>
                        {service.name.slice(0, 4)}
                      </p>{" "}
                      <p
                        className={` ${index === 1
                          ? "has-text-kurio-main-color-purple"
                          : "has-text-kurio-main-color-black"
                          }`}
                        style={{ fontSize: "2.5rem" }}
                      >
                        {service.name.slice(4)}
                      </p>
                    </div>
                  </div>
                  {index === 0 ? (
                    <img src="/images/upgrades/icon-nam-1.svg" alt="" />
                  ) : index === 1 ? (
                    <img src="/images/upgrades/icon-kurio-2.svg" alt="" />
                  ) : (
                    <img src="/images/upgrades/icon-girl-3.svg" alt="" />
                  )}

                  <div
                    className="has-text-kurio-main-color-black"
                    style={{ textAlign: "center" }}
                  >
                    <p
                      style={{
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {ConstantStrings.SERVICE_CONTENT}

                      <b>{service.duration} ngày.</b>
                    </p>
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className={`${
                      // selected === service._id
                      //   ? "has-text-kurio-main-color-white"
                      //   :
                      index === 1
                        ? "has-text-kurio-main-color-purple"
                        : "has-text-kurio-main-color-black"
                      } is-flex is-flex-direction-row is-align-items-flex-start has-text-weight-bold`}
                  >
                    <p style={{ fontSize: "3rem" }}>
                      {service.price.toLocaleString("vi")}
                    </p>
                    <p>Đ</p>
                  </div>
                  <PrimaryButtonPageLevel
                    style={{
                      height: "4rem",
                      borderRadius: "1rem",
                      position: "absolute",
                      // right: "-2.1rem",
                      bottom: "-4.5rem",
                    }}
                    onClick={() => {
                      handleSelect(service._id, index, service.name);
                    }}
                  >
                    Chọn
                  </PrimaryButtonPageLevel>
                </div>
              </div>
            );
          })}
        </div>
      </> */}

      {showPopupInfo && (
        <div
          className="modal is-active is-justify-content-end"
          style={{ zIndex: 1000 }}
        >
          <div className=" modal-background" style={{ opacity: "0.8" }}></div>
          <div
            className="has-background-kurio-main-color-white is-relative is-flex is-flex-direction-column is-align-items-center"
            style={{
              borderRadius: "2rem",
              height: "auto",
              maxHeight: "84.7%",
              width: "50rem",
              padding: "4rem",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-align-items-center select-service pr-2"
              style={{ overflowY: "auto", gap: "2.5rem" }}
            >
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-flex-gap-6">
                <p
                  className="has-text-kurio-main-color-black has-text-weight-bold"
                  style={{
                    fontSize: "2rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {getConstantStringByLanguage(ConstantStrings.KURIO_MAX_BENEFITS)}
                  <span className="has-text-kurio-main-color-purple">
                    KURIO MAX
                  </span>
                </p>
                <img src="/images/upgrades/interest.png" alt="" />
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-flex-gap-6">
                <p
                  className="has-text-kurio-main-color-black has-text-weight-bold"
                  style={{
                    fontSize: "2rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {getConstantStringByLanguage(ConstantStrings.COMPARE_ABILITY)}
                </p>
                <img src="/images/upgrades/compare-feature.png" alt="" />
              </div>
              <PrimaryButtonPageLevel onClick={removePopup}>
                {getConstantStringByLanguage(ConstantStrings.CLOSE)}
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
      <SubPackage isMax={true} handleShowInfo={handleShowPopupInfo} />
      <div
        className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start"
        style={
          {
            // padding: "1.5rem",
            // borderRadius: "1rem",
            // border: "0.09375rem solid #0A2A66",
            // background:
            //   "radial-gradient(353.88% 61.27% at 62.03% 15.28%, #2CD1B9 0%, #48A9F8 100%)",
            // boxShadow: "0rem 0.25rem 0rem 0rem #0A2A66 inset",
          }
        }
      >
        <ServiceItem
          index={0}
          type={1}
          isMax={true}
          handleSelect={handleSelect}
          item={services[0]}
        />
        <ServiceItem
          index={1}
          type={2}
          isMax={true}
          handleSelect={handleSelect}
          item={services[1]}
        />
        <ServiceItem
          index={2}
          type={3}
          isMax={true}
          handleSelect={handleSelect}
          item={services[2]}
        />
      </div>
      {/* ) : (
        <>
          <div
            className="is-flex is-flex-direction-column select-service is-align-items-flex-end"
            style={{
              gap: "1.5rem",
            }}
          >
            {services?.sort((a, b) => b.price - a.price).map((service, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    // selected === service._id
                    //   ? "has-background-kurio-main-color-black has-text-kurio-main-color-white"
                    //   :
                    "has-text-kurio-main-color-black"
                    } is-relative`}
                  style={
                    selected === service._id
                      ? selectStyle
                      : {
                        padding: "2.5rem 2rem 3.5rem 2rem",
                        borderRadius: "4rem",
                        boxShadow:
                          "2px 4px 0px 0px #0A2A66",
                        width: "50rem",
                        height: index === 0 ? "16.5rem" : "15rem",
                        border: "1.5px solid #0A2A66",
                        background: "#FAFBFC",
                      }
                  }
                >
                  <div
                    className="is-flex is-relative is-flex-direction-row is-align-items-center is-justify-content-center"
                    style={{ gap: "1.5rem" }}
                  >
                    {index === 1 ? (
                      <img src="/images/upgrades/icon-nam-1.svg" alt="" />
                    ) : index === 0 ? (
                      <img src="/images/upgrades/icon-kurio-2.svg" alt="" />
                    ) : (
                      ""
                    )}
                    <div>
                      <div
                        className={`${
                          // selected === service._id
                          //   ? "has-text-kurio-main-color-white"
                          //   :
                          "has-text-kurio-main-color-black"
                          } has-text-weight-bold `}
                        style={{ textAlign: "center", lineHeight: "normal" }}
                      >
                        <p className="is-uppercase" style={{ fontSize: "1.125rem" }}>
                          {service.name.slice(0, 4)}
                        </p>{" "}
                        <p
                          className={` ${index === 0
                            ? "has-text-kurio-main-color-purple"
                            : "has-text-kurio-main-color-black"
                            } is-flex is-flex-direction-row is-align-items-center is-justify-content-center`}
                          style={{ fontSize: "2.5rem", gap: "0.25rem" }}
                        >
                          {index === 0 ? service.name.slice(4, 9) : service.name.slice(4)}

                          {index === 0 ? (
                            <div className="is-flex is-justify-content-center is-align-items-center has-background-kurio-main-color-purple" style={{ padding: "0.25rem 0.5rem", borderRadius: "0.25rem", width: "3.875rem", height: "2.3rem" }}>
                              <p className="has-text-kurio-main-color-white" style={{ fontSize: "0.75rem" }}>{service.name.slice(11)}</p>
                            </div>
                          ) : ""}

                        </p>
                      </div>

                      <div
                        className="has-text-kurio-main-color-black"
                        style={{ textAlign: "center", width: "13.5rem" }}
                      >
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 500,
                            lineHeight: "normal",
                          }}
                        >
                          {ConstantStrings.SERVICE_CONTENT}

                          <b>{service.duration} ngày.</b>
                        </p>
                      </div>
                    </div>

                    <div className="is-flex is-flex-direction-column is-align-items-center" style={{ gap: "0.5rem" }}>
                      {index === 0 && (
                        <div
                          style={{
                            padding: "0.5rem 1rem 0.25rem 1rem",
                            borderRadius: "1rem",
                            background: "#C4B5F2",
                            textAlign: "center"
                          }}
                        >
                          <p
                            style={{
                              fontSize: "1.125rem",
                              fontWeight: 700,
                              textTransform: "uppercase",
                              color: "#572CD1",
                            }}
                          >
                            Đề xuất
                          </p>
                        </div>
                      )}

                      <div
                        style={{ textAlign: "center" }}
                        className={`${
                          // selected === service._id
                          //   ? "has-text-kurio-main-color-white"
                          //   :
                          index === 0
                            ? "has-text-kurio-main-color-purple"
                            : "has-text-kurio-main-color-black"
                          } is-flex is-flex-direction-row is-align-items-flex-start has-text-weight-bold`}
                      >
                        <div className="is-flex is-flex-direction-column is-align-items-center has-text-weight-bold">
                          {index === 0 && (
                            <div style={{ marginLeft: '1rem' }}>
                              <p className="has-text-kurio-gray-main-accent is-relative" style={{ fontSize: "1.125rem", lineHeight: "normal", textAlign: "center" }}>
                                800000Đ

                                <hr style={{
                                  width: "5.8rem",
                                  position: "absolute",
                                  top: "-0.8rem",
                                  height: "0.1rem",
                                  transform: "rotate(-4.5deg)",
                                  fontWeight: 700
                                }} className="has-background-kurio-gray-main-accent"></hr>
                              </p>
                            </div>
                          )}
                          <p style={{ fontSize: "3rem", lineHeight: "normal" }}>
                            {service.price.toLocaleString("vi")}
                          </p>
                        </div>
                        <p style={{ marginTop: index === 0 ? "1rem" : "-0.5rem" }}>Đ</p>
                      </div>
                      <PrimaryButtonPageLevel
                        style={{
                          height: "4rem",
                          borderRadius: "1rem",
                          // right: "-2.1rem",
                          width: "15rem",
                        }}
                        onClick={() => {
                          handleSelect(service._id, index, service.name);
                        }}
                      >
                        Chọn
                      </PrimaryButtonPageLevel>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )} */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(SelectService);
