/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import InputText from "../../components/input/InputText";
import Otp from "../../components/input/Otp";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { createValidateEmail, validateEmail } from "../../config/auth";
import ConstantStrings from "../../constants/ConstantStrings";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const ValidateEmailRegister = () => {
  var backgroundImage = {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };
  const [validate, setValidate] = useState({ email: "" });
  // const [turn, setTurn] = useState(3);
  const [sendOtpAgain, setSendOtpAgain] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(true);
  const [complete, setComplete] = useState(false);
  const [otp, setOtp] = useState([]);
  const [remainTime, setRemainTime] = useState(60);
  const [error, setError] = useState("");

  const countRef = useRef(null);
  const navigate = useNavigate();
  const cookies = new Cookies();

  if (!cookies.get("signinUser")) {
    navigate("/signin");
  }

  const handleChange = (value, index, name) => {
    if (verifyEmail && !verifyOtp) {
      setValidate({ ...validate, [name]: value });
      setError("");
    }
    if (!verifyEmail && verifyOtp) {
      let newValue = [...otp];
      newValue[index] = value;
      setOtp(newValue);
      setError("");
    }
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (verifyEmail && !verifyOtp) {
      if (validate.email !== "") {
        try {
          await createValidateEmail(validate, cookies.get("signinUser"));
          setError("");
          // setOtp([]);
          setVerifyOtp(true);
          setVerifyEmail(false);
        } catch (error) {
          setError(error.response.data?.errorMessage);
        }
      } else {
        setError(getConstantStringByLanguage(ConstantStrings.DO_NOT_LEAVE_BLANK));
      }
    } else if (!verifyEmail && verifyOtp) {
      if (otp.length !== 6) {
        setError(ConstantStrings.REQUEST_FILL_IN_ALL_GAPS);
      }
      let newOtp = otp[0];
      for (let index = 1; index < otp.length; index++) {
        newOtp += otp[index];
      }
      if (remainTime <= 0) {
        setError(ConstantStrings.TIME_OUT);
      } else {
        try {
          await validateEmail(
            {
              otp: newOtp,
            },
            cookies.get("signinUser")
          );
          setError("");
          setVerifyOtp(false);
          setVerifyEmail(false);
          setComplete(true);
        } catch (error) {
          setError(error.response.data?.errorMessage);
          setOtp([]);
        }
      }
    } else if (complete) {
      navigate("/calibration");
    }
  };

  const sendOtp = async () => {
    try {
      await createValidateEmail(validate, cookies.get("signinUser"));
      clearInterval(countRef.current);
      // setTurn(3);
      setSendOtpAgain(true);
      setOtp([]);
      setError("");
      setRemainTime(60);
    } catch (error) {
      setError(error.response.data?.errorMessage);
    }
  };
  if (remainTime <= 0) {
    clearInterval(countRef.current);
  }
  useEffect(() => {
    const countDown = () => {
      countRef.current = setInterval(() => {
        if (remainTime <= 0) {
          clearInterval(countRef.current);
          setRemainTime(0);
          return false;
        } else {
          setRemainTime((remainTime) => remainTime - 1);
        }
      }, 1000);
    };

    if (verifyOtp && !sendOtpAgain) {
      countDown();
    }
    if (sendOtpAgain) {
      clearInterval(countRef.current);
      setSendOtpAgain(false);
      // countDown();
    }
    return () => clearInterval(countRef.current);
  }, [sendOtpAgain, verifyOtp, verifyEmail]);
  return (
    <BackgroundImageFromUrl
      objectId="admin/frontend-images/auth/bg-desktop-signup.svg" style={backgroundImage}>
      <div
        className="columns is-centered m-0 p-0 is-justify-content-space-evenly"
        style={{ minHeight: "100vh", width: "100%" }}
      >
        <div className="column m-0 p-0 is-relative">
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/auth/image-left-signup.svg"
            style={{
              width: "40.75rem",
              height: "37.375rem",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              marginLeft: "2.375rem",
              marginTop: "4.125rem",
            }}
          ></BackgroundImageFromUrl>
          <div
            style={{
              width: "21.5rem",
              height: "8.3125rem",
              backgroundImage: "url('/images/auth/logo-reset-pw.svg')",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "absolute",
              left: "0",
              bottom: "0",
            }}
          ></div>
        </div>
        <div
          className={
            complete
              ? "column  is-one-third box has-text-kurio-main-color-black"
              : "column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between has-background-white  has-text-kurio-main-color-black"
          }
          style={{
            padding: complete
              ? "5.125rem 4rem 5.125rem 3.5rem"
              : "3.1875rem 4rem 2.5rem 3.5rem",
            width: "27.5rem",
            height: "40rem",
            marginRight: "5.25rem",
            marginTop: "3.4375rem",
            maxWidth: "27.5rem",
            position: "relative",
            boxShadow: "0.75rem 0.6875rem #0A2A66",
            border: "0.0625rem solid #0A2A66",
          }}
        >
          {complete ? (
            <div
              className="is-flex is-flex-direction-column is-align-items-flex-start has-text-kurio-main-color-black"
              style={{ gap: "2rem" }}
            >
              <h2
                style={{ fontSize: "2.25rem", lineHeight: "2.75rem" }}
                className=" has-text-weight-bold is-uppercase"
              >
                Hoàn thành Đăng ký
              </h2>
              <div
                style={{
                  height: "10rem",
                  width: "20rem",
                  backgroundImage:
                    "url('/images/auth/image-reset-pw-success.svg')",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "0.875rem", lineHeight: "1rem" }}>
                Bắt đầu hành trình{" "}
                <span className="has-text-weight-bold">Toán tư duy</span> cùng{" "}
                <span className="has-text-weight-bold">KURIO</span> ngay bây
                giờ!
              </p>
              <PrimaryButtonPageLevel
                style={{
                  maxWidth: "20rem",
                  width: "20rem",
                  height: "4rem",
                }}
                onClick={handleSubmit}
                type="submit"
              >
                Bắt đầu
              </PrimaryButtonPageLevel>
            </div>
          ) : (
            <>
              <div
                style={{
                  height: "7.5rem",
                  width: "7.443125rem",
                  position: "absolute",
                  bottom: "1rem",
                  left: "-6rem",
                  backgroundImage:
                    "url('/images/auth/floating-element-signup.svg')",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <form onSubmit={handleSubmit}>
                <input type="submit" hidden />
                {verifyEmail && !verifyOtp && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-align-items-center p-0"
                      style={{ gap: "2rem" }}
                    >
                      <p style={{ fontSize: "0.875rem" }}>
                        Bạn chưa xác thực email vui lòng điền email vào form
                        dưới để thực hiện xác thực
                      </p>
                      <div
                        className=""
                        style={{ width: "20rem", height: "5rem" }}
                      >
                        <InputText
                          label={"Email đăng nhập"}
                          type="text"
                          name="email"
                          value={validate.email}
                          onChange={(e) => {
                            handleChange(e.target.value, 0, e.target.name);
                          }}
                        />
                      </div>
                      {error && (
                        <p className="has-text-danger subtitle is-size-6 m-0">
                          {error}
                        </p>
                      )}
                      <PrimaryButtonPageLevel
                        // className="button is-medium is-black is-uppercase has-text-weight-semibold"
                        style={{
                          maxWidth: "20rem",
                          width: "20rem",
                          height: "4rem",
                        }}
                        onClick={handleSubmit}
                        type="submit"
                      >
                        Tiếp theo
                      </PrimaryButtonPageLevel>
                    </div>
                  </>
                )}
                {!verifyEmail && verifyOtp && (
                  <>
                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{ gap: "1.5rem", fontSize: "0.875rem" }}
                    >
                      <div>
                        <p>
                          KURIO đã gửi một email xác thực chứa mã OTP tới hòm
                          thư của bạn tại địa chỉ
                        </p>
                        <span className="has-text-weight-bold pr-1">
                          {validate.email}.
                        </span>
                        Xin hãy kiểm tra hộp thư đến (và cả hộp thu spam nếu bạn
                        không tìm thấy email của chúng tôi) và làm theo hướng
                        dẫn.
                      </div>

                      <Otp
                        value={otp}
                        onChange={(value, name, index) =>
                          handleChange(value, name, index)
                        }
                      />
                      {error !== "" && verifyOtp && (
                        <div className="has-text-danger subtitle is-size-6">
                          <span>{error}</span>
                        </div>
                      )}

                      <div
                        className="is-flex is-flex-direction-row is-align-items-flex-start"
                        style={{ gap: "0.75rem" }}
                      >
                        <span>Không nhận được mail ?</span>
                        <span
                          onClick={sendOtp}
                          className="is-underlined has-text-weight-bold is-clickable is-uppercase"
                        >
                          Gửi lại ({remainTime}S)
                        </span>
                      </div>
                      <PrimaryButtonPageLevel
                        style={{
                          width: "20rem",
                          height: "4rem",
                          maxWidth: "20rem",
                        }}
                        disabled={otp.length < 6}
                        onClick={handleSubmit}
                        type="submit"
                      >
                        Tiếp theo
                      </PrimaryButtonPageLevel>
                    </div>
                  </>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </BackgroundImageFromUrl>
  );
};

export default ValidateEmailRegister;
