import React, { useEffect, useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";
import { getConstantStringByLanguage } from "../../../templates/helper/getConstantStringByLanguage";
import ConstantStrings from "../../../constants/ConstantStrings";

const SelectGradeButton = ({ onClick, disabled, children, isClick }) => {
  const [hoveredState, setHoveredState] = useState(false);
  const [clickState, setClickedState] = useState(isClick ? isClick : false);
  const [defaultState, setDefaultState] = useState(isClick ? false : true);
  const audio = new Audio("/sounds/click.mp3");

  var style = {
    minWidth: "10rem",
    height: "10rem",
    border: "none",
    borderRadius: "9999rem",
    boxShadow: "none",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "transparent",
  };
  var elipStyle = {
    maxWidth: "11.625rem",
    height: "11.625rem",
    borderRadius: "99999rem",
    border: "none",
    backgroundColor: "transparent",
  };
  if (hoveredState || clickState) {
    elipStyle = {
      ...elipStyle,
      border: "0.25rem dashed #DDD5F6",
      borderRadius: "9999rem",
    };
  }
  var objectId = "";
  if (defaultState) {
    objectId =
      "admin/frontend-images/button/select-grade-button-background-default.svg";
  } else if (hoveredState && !clickState) {
    objectId =
      "admin/frontend-images/button/select-grade-button-background-default.svg";
  } else if (clickState) {
    objectId =
      "admin/frontend-images/button/select-grade-button-bg-default.svg";
  }
  const handleMouseEnter = () => {
    if (!disabled && !clickState) {
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!disabled && !clickState) {
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    audio.play();
    if (onClick) {
      onClick();
    }
    setHoveredState(false);
    setClickedState(true);
  };
  useEffect(() => {
    if (!disabled && isClick !== undefined) {
      setDefaultState(!isClick);
      setClickedState(isClick);
    } else {
      setDefaultState(!disabled);
      setClickedState(!disabled);
    }
  }, [disabled, isClick]);
  return (
    <div className="m-auto button is-outline" style={elipStyle}>
      <BackgroundImageFromUrl style={style} objectId={objectId}>
        <button
          className={`main-color has-text-centered is-clickable`}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          type="button"
        >
          <p
            className={
              clickState
                ? "has-text-kurio-main-color-purple is-uppercase"
                : "is-uppercase has-text-kurio-main-color-purple"
            }
            style={{ fontSize: "1.5rem", fontWeight: 700 }}
          >
            {getConstantStringByLanguage(ConstantStrings.GRADE)}
          </p>
          <p
            className={
              clickState
                ? "has-text-kurio-main-color-purple is-uppercase has-text-weight-bold"
                : "is-uppercase has-text-kurio-main-color-purple has-text-weight-bold"
            }
            style={{ fontSize: "4rem", lineHeight: "4.875rem" }}
          >
            {children}
          </p>
        </button>
      </BackgroundImageFromUrl>
    </div>
  );
};

export default SelectGradeButton;
