/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import NotificationUpgradeAccount from "../../components/NotificationUpgradeAccount";
import PaginationButtonSmall from "../../components/button/pagination-button/PaginationButtonSmall";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import { createPractice } from "../../config/practice";
import ConstantStrings from "../../constants/ConstantStrings";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import { clearExamQuestion } from "../../reducers/examsQuestionSlice";
import {
  getAllLesson,
  getGradeUserInfo,
  setIsChangeGrade
} from "../../reducers/gradesSlice";
import { getPracticeElectiveQuestion } from "../../reducers/practiceElectiveSlice";
import PracticeElectiveMainWrapper from "../practice-elective/PracticeElectiveMainWrapper";
import "./FilterQuestion.style.css";
import SelectDifficult from "./SelectDifficult";
import SelectLesson from "./SelectLesson";
import SelectUnit from "./SelectUnit";
import { getConstantStringByLanguage, getGradeName } from "../../templates/helper/getConstantStringByLanguage";

const FilterQuestion = ({
  gradeName,
  error,
  listGrades,
  lessons,
  isChangeGrade,
  gradeId,
  listUserInfo,
  errorGrade,
  setShowExercise,
  setShowLayout
}) => {
  const [selectedUnitState, setSelectedUnitState] = useState(true);
  const [selectedLessonState, setSelectedLessonState] = useState(false);
  const [selectedDifficultState, setSelectedDifficultState] = useState(false);
  const [listUnitId, setListUnitId] = useState([]);
  const [listLessonId, setListLessonId] = useState([]);
  const [difficult, setDifficult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [practiceQuestionListId, setPracticeQuestionListId] = useState(null);
  const [heightDiv, setHeightDiv] = useState(36);
  const [showError, setShowError] = useState(false);
  const heightRef = useRef(null);
  const dispatch = useDispatch();

  const handleNextState = async () => {
    if (selectedUnitState && listUnitId.length > 0) {
      if (listUnitId.length > 1) {
        // dispatch(getAllLesson({ listUnitId }));
        setTimeout(() => {
          setSelectedDifficultState(true);
        }, 500);
      } else {
        setSelectedLessonState(true);
      }
      setSelectedUnitState(false);
    } else if (selectedLessonState && listLessonId.length > 0) {
      setSelectedLessonState(false);
      setSelectedUnitState(false);
      setSelectedDifficultState(true);
    } else if (selectedDifficultState && difficult.length > 0) {
      try {
        const { result } = await createPractice({
          lessonIds:
            listUnitId.length > 1
              ? null
              : listLessonId.length === lessons?.length
                ? null
                : listLessonId,
          unitIds: listGrades?.length === listUnitId.length ? null : listUnitId,
          complexityIds: difficult,
        });
        setPracticeQuestionListId(result);
        dispatch(
          getPracticeElectiveQuestion({
            id: result,
            page: 1,
            size: 50,
          })
        );
        setShowModal(true);
        setSelectedDifficultState(false);
      } catch (error) {
        // alert(error)
        if (
          error?.response?.data?.errorMessage.includes(
            "Bạn không phải là tài khoản premium, không thể làm bài kiểm tra này"
          ) || error?.response?.data?.errorMessage.includes(
            "Bài luyện tập tự chọn của bạn đã vượt quá số lượng cho phép"
          )
        ) {
          // return <NotificationUpgradeAccount isShowPopup={true}/>
          setShowError(true);
        } else {
          alert(error?.response?.data?.errorMessage);
        }
      }
      // alert("Filter");
      // navigate("/practice-elective")
    } else {
      if (selectedUnitState) {
        alert("Phải chọn chương");
        return;
      } else if (selectedLessonState) {
        alert("Phải chọn bài học");
        return;
      } else if (selectedDifficultState) {
        alert("phải chọn độ khó");
        return;
      }
    }
  };
  const handleEndPractice = () => {
    setSelectedUnitState(true);
    setSelectedLessonState(false);
    setSelectedDifficultState(false);
    setListUnitId([]);
    setListLessonId([]);
    setDifficult([]);
    setPracticeQuestionListId(null);
    setShowModal(false);
  };

  const handleSelectUnit = (units) => {
    setListUnitId(units);
  };

  const handleSelectLesson = (lessons) => {
    setListLessonId(lessons);
  };

  const handleSelectDifficult = (difficult) => {
    setDifficult(difficult);
  };

  const handleClickFilterToUnit = () => {
    setSelectedUnitState(true);
    setSelectedLessonState(false);
    setSelectedDifficultState(false);
    dispatch(getGradeUserInfo());
    setListUnitId([]);
    setListLessonId([]);
  };

  const handleClickFilterToLesson = () => {
    setSelectedLessonState(true);
    setSelectedUnitState(false);
    setSelectedDifficultState(false);
    dispatch(getAllLesson({ listUnitId }));
    setListLessonId([]);
  };

  const handleClickFilterDifficult = async () => {
    setSelectedLessonState(false);
    setSelectedUnitState(false);
    setSelectedDifficultState(true);
  };

  const handleSetShowError = (state) => {
    setShowError(state);
    setSelectedUnitState(true);
    setSelectedDifficultState(false);
    setSelectedLessonState(false);
    setListLessonId([]);
    setListUnitId([]);
    setDifficult([]);
  };

  const handleBackPage = () => {
    setShowLayout(false)
    setShowExercise(false)
  }

  useEffect(() => {
    dispatch(clearExamQuestion())

    if (selectedUnitState && listUserInfo === null) {
      dispatch(getGradeUserInfo());
    }
    if (selectedLessonState) {
      dispatch(getAllLesson({ listUnitId }));
    }
    if (selectedDifficultState && listUnitId.length > 1 && lessons !== null) {
      setListLessonId(
        listGrades
          .filter((unitItem) => listUnitId.includes(unitItem._id))
          .map((item) => item.lessons)
          .flat()
      );
      // lessons?.length > 0 && setListLessonId(lessons.map((item) => item._id));
    }
    if (error) {
      alert(error);
    }
    if (!showModal) {
      localStorage.removeItem("exerciseState");
    }

    if (isChangeGrade) {
      dispatch(setIsChangeGrade(false));
      setSelectedUnitState(true);
      setSelectedDifficultState(false);
      setSelectedLessonState(false);
      setListLessonId([]);
      setListUnitId([]);
      setDifficult([]);
    }

    if (heightRef?.current && heightDiv === 36) {
      setHeightDiv(heightRef.current.clientHeight / ConstantStrings.BASE_REM);
    }
  }, [
    dispatch,
    selectedLessonState,
    listUnitId,
    heightDiv,
    selectedUnitState,
    showModal,
    error,
    selectedDifficultState,
    listGrades,
    isChangeGrade,
    gradeId,
  ]);

  return (
    <div
      ref={heightRef}
      style={{
        // borderRadius: "2.5rem",
        // background: "rgba(250, 251, 252, 0.80)",
        height: "calc(100% - 6.5rem)",
      }}
      className="is-flex is-align-items-center is-justify-content-center"
    >
      <div
        style={{
          borderRadius: "2.5rem",
          // height: "calc(100% - 6.5rem)",
        }}
        className="has-background-kurio-purple-light-3 filter-question-container mx-auto"
      >
        {!showModal && <ScrollRefWrapper />}
        {!showModal && (listGrades === null || listGrades?.length === 0) && (
          <div className="has-text-centered has-text-kurio-main-color-black has-text-weight-bold">
            Loading...
          </div>
        )}
        {showError && (
          <NotificationUpgradeAccount
            handleSetShowError={(state) => handleSetShowError(state)}
            isShowPopup={showError}
          />
        )}
        {!showModal &&
          listGrades &&
          listGrades !== null &&
          listGrades?.length > 1 && (
            <div
              className="is-flex is-flex-direction-column is-align-items-center m-auto"
              style={{ gap: "1.5rem" }}
            >
              <div
                className="is-flex is-flex-direction-column "
                style={{ gap: "1.5rem" }}
              >
                <div className="is-flex is-flex-direction-column" style={{ gap: "0.5rem" }}>
                  <h4
                    style={{
                      fontWeight: 700,
                      fontSize: "0.875rem",
                      lineHeight: "1rem",
                      textTransform: "uppercase",
                      color: "#0a2a66",
                      width: "15rem",
                      height: "1rem",
                      cursor: "pointer",
                      display: "flex",
                      gap: "0.8rem",
                    }}
                    onClick={handleBackPage}
                  >
                    {" "}
                    <span style={{ fontSize: "1.5rem" }}><svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.82866 13.9995C5.53666 13.9995 5.24666 13.8725 5.04866 13.6265L0.22066 7.6265C-0.07734 7.2555 -0.07334 6.7255 0.23166 6.3595L5.23166 0.359495C5.58466 -0.0645046 6.21566 -0.121505 6.64066 0.231495C7.06466 0.584495 7.12166 1.2155 6.76766 1.6395L2.29266 7.0105L6.60766 12.3725C6.95366 12.8025 6.88566 13.4325 6.45466 13.7785C6.27066 13.9275 6.04866 13.9995 5.82866 13.9995Z" fill="#0A2A66" />
                    </svg></span> {getConstantStringByLanguage(ConstantStrings.BACK)}
                  </h4>
                  <p
                    className="has-text-weight-bold"
                    style={{
                      fontSize: "2rem",
                      lineHeight: "2.4375rem",
                      color: "#0A2A66",
                    }}
                  >
                    {getConstantStringByLanguage(ConstantStrings.OPTIONAL_PRACTICE)} - {getGradeName(gradeName)}
                  </p>
                </div>
                <div className="is-flex is-flex-direction-column" style={{ gap: "1rem" }}>
                  <div
                    className={`is-flex p-0 ${selectedDifficultState
                      ? "is-flex-direction-column "
                      : " is-flex-direction-row is-justify-content-space-between "
                      }`}
                    style={{ maxWidth: "43.75rem", gap: "2.5rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-row is-align-items-start p-0"
                      style={{ gap: "1rem" }}
                    >
                      <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center has-text-weight-bold">
                        <PaginationButtonSmall
                          children={1}
                          onClick={handleClickFilterToUnit}
                          isClick={selectedUnitState}
                        ></PaginationButtonSmall>
                        {selectedDifficultState &&
                          !selectedUnitState &&
                          listUnitId.length > 1 && (
                            <>
                              <div
                                style={{
                                  width: "2.5rem",
                                  border: "0.1rem dashed #8C9DC1",
                                }}
                              ></div>
                              <PaginationButtonSmall
                                children={2}
                                onClick={handleClickFilterDifficult}
                                disabled={!selectedDifficultState}
                                isClick={selectedDifficultState}
                              />
                            </>
                          )}
                        {(selectedLessonState || selectedDifficultState) &&
                          !selectedUnitState &&
                          listUnitId.length === 1 && (
                            <>
                              <div
                                style={{
                                  width: "2.5rem",
                                  border: "0.1rem dashed #8C9DC1",
                                }}
                              ></div>
                              <PaginationButtonSmall
                                children={2}
                                onClick={handleClickFilterToLesson}
                                disabled={selectedUnitState}
                                isClick={selectedLessonState}
                              ></PaginationButtonSmall>
                            </>
                          )}
                        {selectedDifficultState &&
                          listUnitId.length === 1 &&
                          !selectedLessonState && (
                            <>
                              <div
                                style={{
                                  width: "2.5rem",
                                  border: "0.1rem dashed #8C9DC1",
                                }}
                              ></div>
                              <PaginationButtonSmall
                                children={3}
                                onClick={handleClickFilterDifficult}
                                disabled={!selectedDifficultState}
                                isClick={selectedDifficultState}
                              ></PaginationButtonSmall>
                            </>
                          )}
                      </div>
                      {selectedUnitState && (
                        <p className="" style={{ color: "#0A2A66" }}>
                          <span
                            className="has-text-weight-bold"
                            style={{ color: "#0A2A66" }}
                          >
                            {getConstantStringByLanguage(ConstantStrings.STEP)} 1:
                          </span>{" "}
                          {getConstantStringByLanguage(ConstantStrings.SELECT_UNIT)}
                        </p>
                      )}
                      {selectedLessonState && (
                        <p className="" style={{ color: "#0A2A66" }}>
                          <span
                            className="has-text-weight-bold"
                            style={{ color: "#0A2A66" }}
                          >
                            {getConstantStringByLanguage(ConstantStrings.STEP)} 2:
                          </span>{" "}
                          {getConstantStringByLanguage(ConstantStrings.SELECT_LESSON)}
                        </p>
                      )}
                      {selectedDifficultState && (
                        <p className="" style={{ color: "#0A2A66" }}>
                          <span
                            className="has-text-weight-bold"
                            style={{ color: "#0A2A66" }}
                          >
                            {listUnitId?.length > 1 ? getConstantStringByLanguage(ConstantStrings.STEP) + " 2:" : getConstantStringByLanguage(ConstantStrings.STEP) + " 3:"}
                          </span>{" "}
                          {getConstantStringByLanguage(ConstantStrings.SELECT_DIFFICULTY)}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="">
                    {selectedUnitState && (
                      <SelectUnit
                        listUnitId={listUnitId}
                        height="17.5rem"
                        handleSelect={handleSelectUnit}
                      />
                    )}
                    {selectedLessonState && (
                      <SelectLesson
                        listLessonId={listLessonId}
                        height="17.5rem"
                        handleSelect={handleSelectLesson}
                      />
                    )}
                    {selectedDifficultState && (
                      <SelectDifficult
                        difficult={difficult}
                        handleSelect={handleSelectDifficult}
                      />
                    )}
                  </div>
                </div>

                {selectedDifficultState ? (
                  <div className="is-flex is-align-items-flex-end is-justify-content-flex-end" style={{ width: "100%" }}>
                    <PrimaryButtonPageLevel
                      style={{ height: "4rem", borderRadius: "1rem" }}
                      onClick={handleNextState}
                      disabled={difficult.length === 0}
                    >
                      {getConstantStringByLanguage(ConstantStrings.START_PRACTICE)}
                    </PrimaryButtonPageLevel>
                  </div>

                ) : (
                  <div className="is-flex is-align-items-flex-end is-justify-content-flex-end" style={{ width: "100%" }}>
                    {!selectedDifficultState &&
                      (selectedUnitState ||
                        (selectedLessonState && lessons !== null)) ? (
                      <PrimaryButtonPageLevel
                        style={{ height: "4rem", borderRadius: "1rem" }}
                        disabled={
                          selectedUnitState
                            ? listUnitId.length === 0
                              ? true
                              : false
                            : listLessonId.length === 0
                              ? true
                              : false
                          // : true
                        }
                        onClick={handleNextState}
                      >
                        {getConstantStringByLanguage(ConstantStrings.NEXT)}
                      </PrimaryButtonPageLevel>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>


            </div>
          )}

        {showModal && (
          <PracticeElectiveMainWrapper
            practiceQuestionListId={practiceQuestionListId}
            handleEndPractice={handleEndPractice}
          />

          // <DailyMission
          //   practiceQuestionListId={practiceQuestionListId}
          //   handleEndPractice={handleEndPractice}
          // />
        )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    gradeName: state.grades.gradeName,
    error: state.practiceElective.error,
    errorGrade: state.grades.error,
    listGrades: state.grades.grades,
    isChangeGrade: state.grades.isChangeGrade,
    lessons: state.grades.lessons,
    gradeId: state.grades.gradeId,
    listUserInfo: state.grades.listUserInfo,
  };
}
export default connect(mapStateToProps)(FilterQuestion);
