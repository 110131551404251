import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import { getAPaymentRecord } from "../../config/payment";
import ConstantStrings from "../../constants/ConstantStrings";
import convertTimestamp from "../../templates/helper/convertTimestamp";
import "./SelectService.style.css";
import { getConstantStringByLanguage } from "../../templates/helper/getConstantStringByLanguage";

const PaymentSuccess = ({ listUserInfo }) => {
  const [searchParams] = useSearchParams();
  const [resultPayment, setResultPayment] = useState(null);
  const [error, setError] = useState("");
  const [showPhone, setShowPhone] = useState(false);
  const navigate = useNavigate();
  const handleClosePopup = () => {
    setShowPhone(false);
  };
  const handleChangeShowPhone = (state) => {
    setShowPhone(state);
  };
  useEffect(() => {
    const getResultPayMent = async () => {
      if (
        searchParams.get("vnp_TxnRef") !== null &&
        searchParams.get("vnp_TxnRef")
      ) {
        try {
          const { result } = await getAPaymentRecord(
            searchParams.get("vnp_TxnRef")
          );
          setResultPayment(result.status);
        } catch (error) {
          setError(error?.response?.data?.errorMessage);
        }
      } else {
        navigate("/error");
      }
    };
    getResultPayMent();
  }, [navigate, searchParams]);
  const handleClick = (action) => {
    if (action === "GO_BACK") {
      navigate("/");
    } else if (action === "RE_PAYMENT") {
      navigate("/upgrade");
    } else {
      navigate("/upgrade");
    }
  };
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center is-relative"
      style={{ minHeight: "100vh", top: "-3.5rem" }}
    >
      {showPhone && (
        <div className="modal is-active " style={{ zIndex: 21 }}>
          <div
            className="modal-background"
            onClick={handleClosePopup}
            style={{ opacity: 0.6, zIndex: 20 }}
          ></div>
          <div
            className="is-relative has-background-kurio-main-color-light-gray"
            style={{
              padding: "2rem",
              border: "1.5px solid #0A2A66",
              borderRadius: "2.5rem",
              boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
              maxWidth: "16.5rem",
              zIndex: 21,
            }}
          >
            <div
              className="is-flex is-flex-direction-column has-text-kurio-main-color-black is-flex-gap-4 is-align-items-flex-start"
              style={{ maxWidth: "100%" }}
            >
              <p
                className="has-text-centered"
                style={{
                  fontSize: "0.875rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                {getConstantStringByLanguage(ConstantStrings.CONTACT_SUPPORT)}
              </p>
            </div>
            <div
              className="has-background-kurio-main-color-light-gray has-text-centered is-flex is-align-items-center"
              onClick={handleClosePopup}
              style={{
                position: "absolute",
                top: "-1rem",
                right: "-0.59375rem",
                height: "2.5rem",
                padding: "0.5rem 0.75rem",
                border: "1.5px solid #0a2a66",
                boxShadow: " 0.125rem 0.125rem 0rem 0rem #0a2a66",
                borderRadius: "1rem",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="#0A2A66"
              >
                <path
                  d="M7.91425 6.00001L12.2072 1.70701C12.5982 1.31601 12.5982 0.684006 12.2072 0.293006C11.8162 -0.0979941 11.1842 -0.0979941 10.7933 0.293006L6.50025 4.58601L2.20725 0.293006C1.81625 -0.0979941 1.18425 -0.0979941 0.79325 0.293006C0.40225 0.684006 0.40225 1.31601 0.79325 1.70701L5.08625 6.00001L0.79325 10.293C0.40225 10.684 0.40225 11.316 0.79325 11.707C0.98825 11.902 1.24425 12 1.50025 12C1.75625 12 2.01225 11.902 2.20725 11.707L6.50025 7.41401L10.7933 11.707C10.9882 11.902 11.2443 12 11.5002 12C11.7562 12 12.0122 11.902 12.2072 11.707C12.5982 11.316 12.5982 10.684 12.2072 10.293L7.91425 6.00001Z"
                  fill="#0A2A66"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {resultPayment === 1 && (
        <div
          className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered payment-result"
          style={{ gap: "2.5rem", height: "30rem",  }}
        >
          <div>
            <p
              className="has-text-kurio-main-color-black has-text-weight-bold"
              style={{ fontSize: "2.25rem", lineHeight: "normal" }}
            >
              {getConstantStringByLanguage(ConstantStrings.UPGRADE_SUCCESS)}
            </p>
          </div>
          <img src="/images/auth/image-reset-pw-success.svg" alt="" />
          <div>
            <p
              className="has-text-kurio-main-color-black has-text-weight-medium has-text-centered"
              style={{
                fontSize: "1.125rem",
                lineHeight: "normal",
                maxWidth: "39rem",
              }}
            >
              {getConstantStringByLanguage(ConstantStrings.UPGRADE_COMPLETED)}
              <p>
                {getConstantStringByLanguage(ConstantStrings.EXPIRED_DATE)}: {" "}
                <b>
                  {convertTimestamp(listUserInfo?.premiumTo)}
                </b>
              </p>
            </p>
          </div>
          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1rem" }}
          >
            <PrimaryButtonPageLevel
              style={{ height: "4rem", borderRadius: "1rem" }}
              onClick={() => handleClick("GO_BACK")}
            >
              {getConstantStringByLanguage(ConstantStrings.BACK_TO_HOME)}
            </PrimaryButtonPageLevel>
          </div>
        </div>
      )}
      {error && error?.length > 0 && (
        <div
          className="has-text-centered is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          style={{ gap: "2.875rem" }}
        >
          <img src="/images/error/kurio-error-image.svg" alt="loading" />
          <p className="has-text-centered has-text-kurio-main-color-black">
            {error}
          </p>
          <PrimaryButtonPageLevel
            onClick={() => handleClick("GO_BACK")}
            style={{ height: "4rem", borderRadius: "1rem" }}
          >
            {getConstantStringByLanguage(ConstantStrings.BACK_TO_HOME)}
          </PrimaryButtonPageLevel>
        </div>
      )}
      {(resultPayment === 2 || resultPayment === 3) && (
        <div
          className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered payment-result"
          style={{ gap: "2.5rem", height: "30rem",  }}
        >
          <p
            className="has-text-kurio-main-color-black has-text-weight-bold"
            style={{ fontSize: "2.25rem", lineHeight: "normal" }}
          >
            {getConstantStringByLanguage(ConstantStrings.ERROR)}
          </p>
          <p
            className="has-text-kurio-main-color-black has-text-weight-medium has-text-centered"
            style={{
              fontSize: "1.125rem",
              lineHeight: "normal",
              maxWidth: "30rem",
            }}
          >
            {getConstantStringByLanguage(ConstantStrings.PAYMENT_FAILED_KURIO)}
          </p>
          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1rem" }}
          >
            <PrimaryButtonPageLevel
              style={{ height: "4rem", borderRadius: "1rem" }}
              onClick={() => handleClick("RE_PAYMENT")}
            >
              {getConstantStringByLanguage(ConstantStrings.RETRY)}
            </PrimaryButtonPageLevel>
            <PrimaryButtonPageLevel
              onClick={() => handleChangeShowPhone(true)}
              style={{ height: "4rem", borderRadius: "1rem" }}
            >
              {getConstantStringByLanguage(ConstantStrings.CALL)}
            </PrimaryButtonPageLevel>
            <PrimaryButtonPageLevel
              onClick={() => handleChangeShowPhone(true)}
              style={{ height: "4rem", borderRadius: "1rem" }}
            >
              Chat Zalo
            </PrimaryButtonPageLevel>
          </div>
        </div>
      )}
      {resultPayment !== 1 &&
        resultPayment !== 2 &&
        resultPayment !== 3 &&
        resultPayment !== 4 &&
        resultPayment !== null && (
          <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered payment-result"
            style={{ gap: "2.5rem", height: "30rem",  }}
          >
            <p
              className="has-text-kurio-main-color-black has-text-weight-bold"
              style={{ fontSize: "2.25rem", lineHeight: "normal" }}
            >
              {getConstantStringByLanguage(ConstantStrings.ERROR)}
            </p>
            <p
              className="has-text-kurio-main-color-black has-text-weight-medium has-text-centered"
              style={{
                fontSize: "1.125rem",
                lineHeight: "normal",
                maxWidth: "30rem",
              }}
            >
              {getConstantStringByLanguage(ConstantStrings.PAYMENT_FAILED_KURIO)}
            </p>
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "1rem" }}
            >
              <PrimaryButtonPageLevel
                style={{ height: "4rem", borderRadius: "1rem" }}
                onClick={() => handleClick("RE_PAYMENT")}
              >
                {getConstantStringByLanguage(ConstantStrings.RETRY)}
              </PrimaryButtonPageLevel>
              <PrimaryButtonPageLevel
                onClick={() => handleChangeShowPhone(true)}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                {getConstantStringByLanguage(ConstantStrings.CALL)}
              </PrimaryButtonPageLevel>
              <PrimaryButtonPageLevel
                onClick={() => handleChangeShowPhone(true)}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                Chat Zalo
              </PrimaryButtonPageLevel>
            </div>
          </div>
        )}
      {resultPayment === 4 && (
        <div
          className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered payment-result"
          style={{ gap: "2.5rem", height: "30rem",  }}
        >
          <div>
            <p
              className="has-text-kurio-main-color-black has-text-weight-bold"
              style={{ fontSize: "2.25rem", lineHeight: "normal" }}
            >
              {getConstantStringByLanguage(ConstantStrings.PAYMENT_ERROR_VNPAY)}
            </p>
          </div>
          <div>
            <p
              className="has-text-kurio-main-color-black has-text-weight-medium has-text-centered"
              style={{
                fontSize: "1.125rem",
                lineHeight: "normal",
                maxWidth: "30rem",
              }}
            >
              {getConstantStringByLanguage(ConstantStrings.PAYMENT_FAILED)}
            </p>
          </div>
          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1rem" }}
          >
            <PrimaryButtonPageLevel
              style={{
                height: "4rem",
                borderRadius: "1rem",
                maxWidth: "22.5rem",
                width: "22.5rem",
              }}
              onClick={() => handleClick("DIRECT_TRANFER")}
            >
              {getConstantStringByLanguage(ConstantStrings.TRANSFER_DIRECTLY)}
            </PrimaryButtonPageLevel>
            <PrimaryButtonPageLevel
              onClick={() => handleChangeShowPhone(true)}
              style={{
                height: "4rem",
                borderRadius: "1rem",
                maxWidth: "22.5rem",
                width: "22.5rem",
              }}
            >
              {getConstantStringByLanguage(ConstantStrings.CALL)}
            </PrimaryButtonPageLevel>
            <PrimaryButtonPageLevel
              onClick={() => handleChangeShowPhone(true)}
              style={{
                height: "4rem",
                borderRadius: "1rem",
                maxWidth: "22.5rem",
                width: "22.5rem",
              }}
            >
              Chat Zalo
            </PrimaryButtonPageLevel>
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    listUserInfo: state.grades.listUserInfo,
  };
}
export default connect(mapStateToProps)(PaymentSuccess);
